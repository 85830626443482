<template>
    <v-container fluid class="datenschutz">
        <v-row justify="center">
            <v-col cols="12" md="8" lg="6">
                <v-card flat>
                    <v-card-text>
                        <div class="text-h5 mb-4">Datenschutz</div>
                        <div class="title mb-2">Deine Daten gehören dir</div>
                        <p class="body-1">
                            Wir speichern nur die notwendigen Daten um dir dieses Produkt zu ermöglichen. 
                            Die Daten werden mit Sorgfalt und modernsten Standards behandelt — alles in Deutschland 🇩🇪. 
                            Auf dieser Seite werden deine Fragen rund um das Thema Daten in Zeipsy beantwortet.
                        </p>
                        <div class="text-h5 mb-2">Was speichern wir?</div>
                        <div class="title mb-1">Login-Daten</div>
                        <p class="body-1">
                            Die Account-Authentifizierung läuft über einen der besten Provider der Welt:
                            <a href="https://supabase.com" target="_blank">Supabase</a>. E-Mails und Passwörter sind mit
                            Industrie führenden Sicherheitsstandards bei Supabase gesichert und werden in Deutschland
                            gespeichert. Zeipsy kann zu keinem Zeitpunkt dein Passwort einsehen.
                        </p>
                        <div class="title mb-1">Praxis-Daten</div>
                        <p class="body-1">
                            Die Daten-Sicherheit deiner Klienten hat oberste Priorität! Mit einem zweiten, separaten
                            Passwort werden sämtliche personenbezognen Daten, wie Name, Adresse, E-Mail und die
                            Telefonnummer nach aktuellen Standards verschlüsselt. Dieser sogenannte "Safe-Schlüssel"
                            wird außschließlich auf deinem PC gespeichert. Selbst wenn eine unbefugte Person Zugriff auf
                            deinen Zeipsy Account hat, kann sie ohne den Safe-Schlüssel nicht die Daten deiner Klienten
                            einsehen. Damit du deine Praxis-Daten überall nutzen kannst, werden sie ebenso mithilfe von
                            <a href="https://supabase.com" target="_blank">Supabase</a> in Deutschland gespeichert.
                        </p>
                        <!-- <div class="subtitle-1 mb-2">Google Kalender Synchronisierung</div>
                        <p class="body-1">
                            Wenn du deine Termine mit deinem Google Kalender synchronisieren möchtest, werden deine Google Kalender-Daten über eine API von Google abgerufen.
                            Die Verarbeitung der Daten passiert ausschließlich auf deinem Computer und wird nicht an uns übertragen.
                            Somit werden die Daten <b>nicht</b> gespeichert und verlassen deinen Webbrowser / Computer nicht.
                        </p> -->
                        <v-btn :color="$store.state.theme.primary" dark block class="mt-4" @click="backToSignIn"
                            >Zur Anmeldung</v-btn
                        >
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'Datenschutz',

    methods: {
        backToSignIn() {
            this.$router.push({ name: 'login' });
        },
    },
};
</script>

<style scoped>
.datenschutz {
    margin-top: 2rem;
}
.datenschutz a {
    text-decoration: none;
}
</style>
