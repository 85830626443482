<template>
    <v-container fluid >
        <v-card elevation="2" color="white" class="pa-10" >
            <v-row class="justify-center ">
                <v-col class="text-center">
                    <h1 class="font-weight-bold">Wähle einen Plan der am Besten zu Dir passt.</h1>
                    <p class="subtitle-1 grey--text">Spare zwei Monate mit dem Jahresabo!</p>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
                <v-col class="mt-5">
                    <stripe-pricing-table 
                        v-if="!isInDevMode()"
                        pricing-table-id="prctbl_1NH067F97XdNiIDLlSiyAw7c"
                        publishable-key="pk_live_51N4rTyF97XdNiIDLrksrCyc8QlsqXFPIlL7cMeYLNP1RspOtCBd148N30jExG9VmSMxoZlrrGEZEV4j5gfhX3mv500Y5LeNW0E"
                        :customer-email="session.user.email"
                        :client-reference-id="session.user.id"
                    >
                    </stripe-pricing-table>
                    <stripe-pricing-table v-else
                        pricing-table-id="prctbl_1N9mXfF97XdNiIDLgBq1VkAl"
                        publishable-key="pk_test_51N4rTyF97XdNiIDLKwQiG7etaJKvBqIvCtwEMTXj2GjVmf2fgEpUDx02qr0o18vZBk0rQq7hEYNGb20fNimSiy4o00p9YLbvll"
                        :customer-email="session.user.email"
                        :client-reference-id="session.user.id"
                        >
                    </stripe-pricing-table>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
    export default {
        props: ['session'],

        methods: {
            isInDevMode() {
                return process.env.NODE_ENV === 'development';
            }
        }
    }

</script>