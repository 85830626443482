<template>
    <div>
        <FormattingControls class="mt-3" :editor="editor">
            <template #after-controls>
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined class="mb-sm-3 mb-1" dense v-bind="attrs" v-on="on"
                            :small="$vuetify.breakpoint.xsOnly">
                            <v-icon left :small="$vuetify.breakpoint.xsOnly">mdi-file-document-plus-outline</v-icon>
                            Vorlage laden
                        </v-btn>
                    </template>
                    <v-card>
                        <v-list dense>
                            <v-list-item @click="openNewTemplateDialog" dense>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon left>mdi-file-document-plus-outline</v-icon>
                                        Neue Vorlage erstellen
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item dense v-for="(item, i) in templates" :key="`template-${i}`"
                                @click="onTemplateSelected(item)">
                                <v-list-item-content dense>
                                    <v-list-item-title>
                                        <v-icon left>mdi-file-document-outline</v-icon>
                                        {{ item.name }}
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action dense>
                                    <div class="d-flex">
                                        <v-btn class="my-0 py-0" icon @click.stop="openEditTemplateDialog(item)"
                                            :disabled="item.deleting">
                                            <v-tooltip bottom open-delay="300">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on">
                                                        mdi-pencil
                                                    </v-icon>
                                                </template>
                                                <span>Vorlage bearbeiten</span>
                                            </v-tooltip>
                                        </v-btn>
                                        <v-btn class="my-0 py-0" icon @click.stop="deleteTemplate(item)"
                                            :disabled="item.deleting">
                                            <v-tooltip bottom open-delay="300">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon :color="$store.state.theme.red" v-bind="attrs" v-on="on">
                                                        mdi-delete
                                                    </v-icon>
                                                </template>
                                                <span>Vorlage löschen</span>
                                            </v-tooltip>
                                        </v-btn>
                                    </div>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>
            </template>
        </FormattingControls>

        <editor-content class="mt-0" :editor="editor"></editor-content>

        <v-dialog v-model="showNewTemplateDialog" max-width="1000px" persistent
            :fullscreen="$vuetify.breakpoint.xsOnly || isFullscreen">
            <v-card>
                <v-card-title class="mr-4 pr-0">
                    <span class="text-h5">{{ isEditingTemplate ? 'Vorlage bearbeiten' : 'Neue Vorlage erstellen'
                        }}</span>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom open-delay="300" v-if="$vuetify.breakpoint.smAndUp">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click="toggleFullscreen" v-bind="attrs" v-on="on">
                                <v-icon>{{ isFullscreen ? 'mdi-arrow-collapse' : 'mdi-arrow-expand' }}</v-icon>
                            </v-btn>
                        </template>
                        {{ isFullscreen ? 'Vollbild beenden.' : 'In den Vollbildmodus wechseln.' }}
                    </v-tooltip>
                </v-card-title>
                <v-card-text>
                    <v-text-field dense outlined label="Name der Vorlage" v-model="template_name" class="mt-3 mb-0"
                        :error-messages="templateNameErrors" @input="validateTemplateName" ref="templateNameInput" />
                    <div class="mt-0 mb-0">
                        <FormattingControls :editor="newTemplateEditor" />
                    </div>
                    <editor-content class="mt-0" :editor="newTemplateEditor"></editor-content>
                </v-card-text>
                <v-card-actions class="pb-5 px-6">
                    <v-spacer />
                    <v-btn :color="$store.state.theme.red" text @click="closeNewTemplateDialog"
                        :disabled="saving_template">Abbrechen</v-btn>
                    <v-btn :color="$store.state.theme.green" outlined @click="saveNewTemplate"
                        :disabled="!isTemplateNameValid" :loading="saving_template">
                        Speichern
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showDialog" width="500">
            <v-card>
                <v-card-title class="text-h5">Achtung!</v-card-title>
                <v-card-text class="text-body-1">
                    Das Laden einer Vorlage überschreibt den aktuellen Inhalt. Möchtest du die
                    Vorlage trotzdem laden?
                </v-card-text>
                <v-card-actions class="mx-3 pb-5">
                    <v-spacer />
                    <v-btn :color="$store.state.theme.red" text @click="cancelTemplateLoad">Nein</v-btn>
                    <v-btn :color="$store.state.theme.green" outlined @click="confirmTemplateLoad">Ja</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Highlight from '@tiptap/extension-highlight'
import TextAlign from '@tiptap/extension-text-align'
import FormattingControls from '../components/FormattingControls.vue'
import connector from '../helpers/supabase-connector.js'

export default {

    components: {
        EditorContent,
        FormattingControls
    },

    emits: ['input', 'fetchTemplates'],

    props: {
        value: {
            type: String,
            default: '',
        },

        session: {
            type: Object,
            default: null
        },

        templates: {
            type: Array,
            default: () => [], 
        }
    },

    data() {
        return {
            template_name: '',
            editor: null,
            showDialog: false, // Flag for warning dialog
            templateToLoad: null, // Store the template to load after confirmation

            showNewTemplateDialog: false,
            newTemplateEditor: null,

            templateNameErrors: [],

            saving_template: false,
            isFullscreen: false,

            isEditingTemplate: false,
            editingTemplateId: null,

        };
    },

    watch: {
        value(value) {
            // HTML
            const isSame = this.editor.getHTML() === value;

            if (isSame) {
                return;
            }
            this.editor.commands.setContent(value, false);
        },
    },

    mounted() {
        this.editor = new Editor({
            editorProps: {
                attributes: {
                    class: 'my-2 mx-auto focus:outline-none',
                },
            },
            extensions: [
                StarterKit.configure({
                    heading: {
                        levels: [1, 2, 3],
                    }
                }),
                Underline,
                Highlight.configure({
                    multicolor: true,
                }),
                TextAlign.configure({
                    types: ['heading', 'paragraph'],
                }),
            ],
            content: this.value,
            onUpdate: () => {
                // HTML
                this.$emit('input', this.editor.getHTML())
            },
        });

        this.newTemplateEditor = new Editor({
            editorProps: {
                attributes: {
                    class: 'my-2 mx-auto focus:outline-none',
                },
            },
            extensions: [
                StarterKit.configure({
                    heading: {
                        levels: [1, 2, 3],
                    }
                }),
                Underline,
                Highlight.configure({
                    multicolor: true,
                }),
                TextAlign.configure({
                    types: ['heading', 'paragraph'],
                }),
            ],
            content: '',
        });
    },

    computed: {
        isTemplateNameValid() {
            return this.templateNameErrors.length === 0;
        },
    },

    methods: {

        toggleFullscreen() {
            this.isFullscreen = !this.isFullscreen;
        },

        validateTemplateName() {
            this.templateNameErrors = []; // Clear previous errors

            if (this.template_name.length === 0) {
                this.templateNameErrors.push("Bitte gib einen Namen für die Vorlage ein.");
            }

            if (this.template_name.length > 40) {
                this.templateNameErrors.push("Der Name der Vorlage darf maximal 40 Zeichen lang sein.");
            }

            // Exclude the current template being edited from duplicate check
            const otherTemplates = this.templates.filter(t => t.id !== this.editingTemplateId);
            if (otherTemplates.some(t => t.name === this.template_name)) {
                this.templateNameErrors.push("Eine Vorlage mit diesem Namen existiert bereits.");
            }
        },

        closeNewTemplateDialog() {
            this.showNewTemplateDialog = false;
            this.$nextTick(() => {
                this.clearHistoryTemplate();
                this.isEditingTemplate = false;
                this.editingTemplateId = null;
            });
        },

        openNewTemplateDialog() {
            this.showNewTemplateDialog = true;

            this.isEditingTemplate = false;

            // Use $nextTick inside a $nextTick to ensure the dialog is rendered
            this.$nextTick(() => {
                this.$nextTick(() => {
                    this.validateTemplateName();

                    // Now focus on the input
                    if (this.$refs.templateNameInput) {
                        this.$refs.templateNameInput.focus();
                    }
                });
            });
        },

        openEditTemplateDialog(template) {
            this.isEditingTemplate = true;
            this.editingTemplateId = template.id;
            this.template_name = template.name;
            this.newTemplateEditor.commands.setContent(template.content);
            this.showNewTemplateDialog = true;

            this.$nextTick(() => {
                this.$nextTick(() => {
                    this.validateTemplateName();
                    if (this.$refs.templateNameInput) {
                        this.$refs.templateNameInput.focus();
                    }
                });
            });
        },

        async saveNewTemplate() {
            this.saving_template = true;
            const content = this.newTemplateEditor.getHTML();

            try {
                if (this.isEditingTemplate) {
                    await connector.updateRow(this, 'dokumentationsvorlagen', {
                        name: this.template_name,
                        content: content
                    }, this.editingTemplateId);
                } else {
                    await connector.insertRow(this, 'dokumentationsvorlagen', {
                        uid: this.session.user.id,
                        name: this.template_name,
                        content: content
                    });
                }

                this.$emit('fetchTemplates');
                
                this.clearHistoryTemplate();
                this.showNewTemplateDialog = false;
                this.isEditingTemplate = false;
            } catch (error) {
                // Handle the error, e.g., show an error message to the user.
                console.error('Error saving new template:', error);
            } finally {
                this.saving_template = false;
            }
        },

        async deleteTemplate(template) {
            let index = this.templates.findIndex((t) => t.id === template.id);
            if (index > -1) {
                this.$set(this.templates[index], 'deleting', true);
                let deleted = await connector.deleteRow(this, 'dokumentationsvorlagen', 'id', template.id);
                this.$emit('fetchTemplates');
            }
        },

        onTemplateSelected(templateId) {
            if (!(this.editor.getHTML() === '' || this.editor.getHTML() === '<p></p>')) {
                // Show warning dialog
                this.showDialog = true;
                this.templateToLoad = templateId;
            } else {
                // Load template directly
                this.loadTemplate(templateId);
            }
        },

        confirmTemplateLoad() {
            this.loadTemplate(this.templateToLoad);
        },

        loadTemplate(templateId) {
            const template = this.templates.find(t => t.id === templateId.id);
            if (template) {
                this.setContent(template.content, true);
                this.showDialog = false;
            }
        },
        // In your dialog's "No" button click handler:
        cancelTemplateLoad() {
            this.showDialog = false;
        },

        // this method is called from the parent
        clearHistory() {
            this.editor.commands.clearContent(true);
            this.editor.state.history$.prevRanges = null;
            this.editor.state.history$.done.eventCount = 0;

            // reset templates
            this.templateToLoad = null;
        },

        clearHistoryTemplate() {
            this.newTemplateEditor.commands.clearContent(true);
            this.newTemplateEditor.state.history$.prevRanges = null;
            this.newTemplateEditor.state.history$.done.eventCount = 0;
            this.template_name = '';
        },

        setContent(content, update=false) {
            this.editor.commands.setContent(content, update);
        },
    },

    beforeDestroy() {
        if (this.editor) {
            this.editor.destroy();
        }
        if (this.newTemplateEditor) {
            this.newTemplateEditor.destroy();
        }
    }
};
</script>

<style scoped>

.v-list-item {
    height: 50px; /* Adjust the height as needed */
}
::v-deep .ProseMirror {
    outline: none;
    min-height: 250px;
    font-size: 16px;
    font-weight: 400;
    color: black;
    border: 1px solid rgba(0, 0, 0, 0.30);
    border-radius: 4px;
    padding: 8px;
    max-height: 60vh;
    overflow-y: auto; /* Enable vertical scrolling when content exceeds the max height */
}

::v-deep .ProseMirror:hover {
    border: 1px solid rgba(0, 0, 0, 0.60);
    transition: .3s ease;
}

::v-deep .ProseMirror:focus {
    outline: 1.3px solid #1976D2;
    border: 1px solid #1976D2;
}

::v-deep .ProseMirror p {
    margin-top: 0;
    /* Reduces space above the paragraph */
    margin-bottom: 0.1em;
    /* Reduces space below the paragraph, adjust as needed */
}
</style>