import { supabase } from '../supabase'

export default {

      async checkOnboardingStatus(component) {
        // check if user has klienten in vwklienten
        // check if user has termine in vwtermine
        // check if user has services in vwdienstleistungen
        // do error handling

        let onboarding = await this.getDataOnly(component, 'vwonboarding', 'id', true);
        if (onboarding !== -1 && onboarding.length === 1) { 
          let onboarding_status = {
            has_clients: onboarding[0].has_clients,
            has_appointments: onboarding[0].has_appointments,
            has_services: onboarding[0].has_services,
          }

          return onboarding_status
        } else {
          return null
        }
      },
    
      async createInvoice(component, invoice, appointments) {
        
        // check if fk_institution_id is in invoice object, if not set it to null
        if (!('fk_institution_id' in invoice)) {
          invoice.fk_institution_id = null;
        }

        try {
          let { data, error } = await supabase.rpc('createinstitutionorklientinvoice', {
            uid: invoice.uid,
            fk_klienten_id: invoice.fk_klienten_id,
            fk_institution_id: invoice.fk_institution_id,
            datum: invoice.datum,
            nummer: invoice.nummer,
            termin_ids: appointments
          });
          if (error) throw error;
          if (data) return data;
        } catch (error) {

          if (error.message.includes('duplicate key value violates unique constraint')) {
            component.$emit('showError', {
              message: 'Diese Rechnungsnummer wurde bereits verwendet. Bitte wähle eine andere Rechnungsnummer.',
              timeout: 5000
            });
          } else {
            component.$emit('showError', error);
          }
          // set updating_invoice to false, to allow user to try again
          component.updating_invoice = false;
          return null;
        }
      },

      async deleteInstitution(component, user_id, institution_id) {
            
        try {
          let { error } = await supabase.rpc('deleteInstitution', {
            user_id: user_id,
            institution_id: institution_id,
          });
          if (error) throw error;
          return true;
        } catch (error) {

          if (error.message.includes('update or delete on table "institutionen" violates foreign key constraint "rechnungen_fk_institution_id_fkey"')) {
            component.$emit('showError', {
              message: 'Diese Institution kann nicht gelöscht werden, da es noch Rechnungen gibt, die mit dieser Institution verknüpft sind. Um diese Institution zu löschen, müssen zuerst alle Rechnungen gelöscht werden, die mit dieser Institution verknüpft sind.',
              timeout: 10000
            });
          } else {
            component.$emit('showError', error);
          }
          return false;
        }
      },

      async downloadFileFromBucket(component, bucket, path, file, blob=false) {
        try {
          const { data, error } = await supabase
            .storage
            .from(bucket)
            .download(path + file)
            if (error) throw error

            if (data) {
              if (blob) return data 
              return data.arrayBuffer()
            }

        } catch (error) {
          component.$emit('showError', error)
          throw error
        }
      },

      async downloadFile(component, bucket, path, file, blob = false) {
        const maxAttempts = 2;
        const retryDelay = 500; // 500ms delay

        for (let attempt = 1; attempt <= maxAttempts; attempt++) {
          try {
            const { data, error } = await supabase
              .storage
              .from(bucket)
              .download(path + file);

            if (error) throw error;

            if (data) {
              return blob ? data : data.arrayBuffer();
            }
          } catch (error) {

            const errorMessage = error.message || '';
            const shouldRetry = errorMessage.includes('Load failed') || errorMessage.includes('Failed to fetch');

            if (shouldRetry && attempt < maxAttempts) {
              this.logError(component, {
                uid: component.$store.state.client.id,
                message: 'LOG: Silently fail downloading file, retry second time.'
              });
              await new Promise(resolve => setTimeout(resolve, retryDelay));
              continue;
            } else if (shouldRetry && attempt === maxAttempts) {
              // log all failed
              this.logError(component, {
                uid: component.$store.state.client.id,
                message: 'LOG: Both download attempts failed. File: ' + bucket + '/' + path + file
              });
            }

            component.$emit('showError', error);
            return null;
          }
        }

        // This line is technically unreachable, but we'll keep it as a fallback
        return null;
      },


      async uploadFileToBucket(component, bucket, path, file_name, file, seconds_to_cache='3600', content_type=null) {
        try {

          let params = {
            cacheControl: seconds_to_cache,
            upsert: true
          }

          if (content_type) params.contentType = content_type

          const { data, error } = await supabase
            .storage
            .from(bucket)
            .upload(path + file_name, file, params)
          if (error) throw error
          if (data) return data

        } catch (error) {
          component.$emit('showError', error)
          return null;
        }
      },

      async moveFileInBucket(component, bucket, path, file_name_old, file_name_new) {
        try {

          const { data, error } = await supabase
            .storage
            .from(bucket)
            .move(path + file_name_old, path + file_name_new)
          if (error) throw error
          if (data) return data

        } catch (error) {
          component.$emit('showError', error)
          throw error
        }
      },

      async deleteFileFromBucket(component, bucket, path, file) {
        try {
          const { error } = await supabase
            .storage
            .from(bucket)
            .remove([path + file]);
          if (error) throw error
        } catch (error) {
          component.$emit('showError', error);
          return false;
        }
        return true;
      },

      async deleteAllFilesInBucket(component, bucket, path) {
        try {
          // get all files in current path, ignore folders and .emptyFolderPlaceholder files
          let files = await this.listFilesInBucket(component, bucket, path);
          if (files.length > 0) {
            let files_to_delete = files.filter(file => file.id !== null && file.name !== '.emptyFolderPlaceholder');
            if (files_to_delete.length === 0) {
              return true;
            }

            let file_names = files_to_delete.map(file => path + file.name);
            const { error } = await supabase
              .storage
              .from(bucket)
              .remove(file_names)
            if (error) throw error
            return true;
          } else {
            return true;
          }
        } catch (error) {
          component.$emit('showError', error)
          return false;
        }
      },

      async checkIfFilesExistInBucket(component, bucket, path) {
        // this method should only be used if there are no subfolders in the path
        try {
          let files = await this.listFilesInBucket(component, bucket, path);
          if (files.length > 0) {
            let files_in_bucket = files.filter(file => file.name !== '.emptyFolderPlaceholder');
            if (files_in_bucket.length === 0) {
              return false;
            }
            return true;
          } else {
            return false;
          }
        } catch (error) {
          component.$emit('showError', error)
          return false;
        }
      },

      async listFilesInBucket(component, bucket, path) {
      
        try {
          const { data, error } = await supabase
            .storage
            .from(bucket)
            .list(path, {
              // limit: 10,
              // offset: 0,
              sortBy: { column: 'name', order: 'asc' },
            })
            if (error) throw error
            if (data) return data
  
        } catch (error) {
          component.$emit('showError', error)
          return []
        }
      },

      getPublicURL(component, bucket, path_to_file) {
        
        try {
          const { data } = supabase
            .storage
            .from(bucket)
            .getPublicUrl(path_to_file)
          if (data) return data.publicUrl
        } catch (error) {
          component.$emit('showError', error)
          throw error
        }
        
      }, 

      async deleteRow(component, table, column, value) {
        try {
          const { error } = await supabase
            .from(table)
            .delete()
            .eq(column, value)
          if (error) throw error
        } catch (error) {
          if ('details' in error && error.details && error.details.startsWith('Key is still referenced from table')) {
            const matches = error.details.match(/table \"(.*?)\"/);
            if (matches && matches[1]) {
              let referenced_table = matches[1];
              referenced_table = referenced_table.charAt(0).toUpperCase() + referenced_table.slice(1);
              component.$emit('showError', {
                message: 'Dieser Eintrag kann nicht gelöscht werden, da es dafür bestehende Einträge unter "' + referenced_table + '" gibt. Um diesen Eintrag zu löschen müssen zuerst alle Einträge unter "' + referenced_table + '" gelöscht werden, die mit diesem Eintrag zusammenhängen.' 
              })
              return false;
            }
          }

          component.$emit('showError', error)
          throw error
        }
        return true;
      },

      // the difference to the above is that no error is thrown if the exception was handled.
      async delete(component, table, column, value) {
        try {
          const { error } = await supabase
            .from(table)
            .delete()
            .eq(column, value);
          if (error) throw error;
        } catch (error) {
          if ('details' in error && error.details && error.details.startsWith('Key is still referenced from table')) {
            const matches = error.details.match(/table \"(.*?)\"/);
            if (matches && matches[1]) {
              let referenced_table = matches[1];
              referenced_table = referenced_table.charAt(0).toUpperCase() + referenced_table.slice(1);
              component.$emit('showError', {
                message: 'Dieser Eintrag kann nicht gelöscht werden, da es dafür bestehende Einträge unter "' + referenced_table + '" gibt. Um diesen Eintrag zu löschen müssen zuerst alle Einträge unter "' + referenced_table + '" gelöscht werden, die mit diesem Eintrag zusammenhängen.' 
              });
              return false;
            }
          }

          component.$emit('showError', error)
          return false;
        }
        return true;
      },

      async deleteRows(component, table, column, values) {
        try {
          const { error } = await supabase
            .from(table)
            .delete()
            .in(column, values)
          if (error) throw error
        } catch (error) {
          component.$emit('showError', {message: 'Die Einträge konnten nicht gelöscht werden.'})
          console.log(error)
          throw error
        }
      },

      async deleteAll(component, table, column, value) {
        try {
          const { error } = await supabase
            .from(table)
            .delete()
            .eq(column, value)
          if (error) throw error
        } catch (error) {
          component.$emit('showError', error)
          console.log(error)
          throw error
        }
      },

      async insertRow(component, table, entry) {
        try {
          const { data, error } = await supabase
            .from(table)
            .insert(entry)
            .select()
          if (error) throw error
          if (data && data.length === 1) return data[0].id
        } catch (error) {
            if ('message' in error) {
              const pattern = /null value in column \"(.*?)\" of relation \"(.*?)\" violates not-null constraint/;
              const matches = error.message.match(pattern);
              if (matches && matches[1] && matches[2]) {
                let columnName = matches[1];
                columnName = columnName.charAt(0).toUpperCase() + columnName.slice(1);

                let tableName = matches[2];
                tableName = tableName.charAt(0).toUpperCase() + tableName.slice(1);

                component.$emit('showError', {
                  message: 'Es muss ein Wert bei "' + columnName + '" angegeben werden.'
                })
                return false;
              } else if (error.message.includes('duplicate key value violates unique constraint')) {
                // implemented for insertion of a duplicate merkmal
                component.$emit('showError', {message: 'Dieser Wert wird bereits verwendet. Bitte wähle einen anderen Wert.'})
                return false;
              } else if (error.message.includes('_.merkmale')) {
                this.logError(component, {
                  uid: component.$store.state.client.id,
                  message: 'LOG: Single In Table ' + table
                })
                return false;
              }
            }
            component.$emit('showError', error)
            return false;
        }
      },

      // this function is identical to the above, just added it to be consistent in naming and knowing which calls have been double checked.
      async insert(component, table, entry) {
        try {
          const { data, error } = await supabase
            .from(table)
            .insert(entry)
            .select()
          if (error) throw error
          if (data && data.length === 1) return data[0].id
        } catch (error) {
            if ('message' in error) {
              const pattern = /null value in column \"(.*?)\" of relation \"(.*?)\" violates not-null constraint/;
              const matches = error.message.match(pattern);
              if (matches && matches[1] && matches[2]) {
                let columnName = matches[1];
                columnName = columnName.charAt(0).toUpperCase() + columnName.slice(1);

                let tableName = matches[2];
                tableName = tableName.charAt(0).toUpperCase() + tableName.slice(1);

                component.$emit('showError', {
                  message: 'Es muss ein Wert bei "' + columnName + '" angegeben werden.'
                })
                return false;
              } else if (error.message.includes('duplicate key value violates unique constraint')) {
                // implemented for insertion of a duplicate merkmal
                component.$emit('showError', {message: 'Dieser Wert wird bereits verwendet. Bitte wähle einen anderen Wert.'})
                return false;
              } else if (error.message.includes('_.merkmale')) {
                this.logError(component, {
                  uid: component.$store.state.client.id,
                  message: 'LOG: Single In Table ' + table
                })
                return false;
              }
            }
            component.$emit('showError', error)
            return false;
        }
      },

      async logError(component, entry) {
        try {
          const { data, error } = await supabase
            .from('errors')
            .insert(entry)
            .select()
          if (error) throw error
          if (data && data.length === 1) return data[0].id
        } catch (error) {
            // component.$emit('showError', error)
            // just log it, hopefully sentry catches that.
            console.error(error);
        }
      },

      async logInfo(entry) {
        try {
          const { data, error } = await supabase
            .from('logs')
            .insert(entry)
            .select();
          if (error) throw error;
          if (data && data.length === 1) return data[0].id;
        } catch (error) {
            throw error;
        }
      },

      async insertRows(component, table, entries) {

        try {
          const { data, error } = await supabase
            .from(table)
            .insert(entries)
            .select()

          if (error) throw error
          if (data) return data
        } catch (error) {
          component.$emit('showError', error)
          // check if the error contains the message '_.merkmale' if yes, log the table name
          if ('message' in error && error.message.includes('_.merkmale')) {
            this.logError(component, {
              uid: component.$store.state.client.id,
              message: 'LOG: Multi In Table ' + table
            })
          }
          throw error
        }
      },

      async inserts(component, table, entries) {

        try {
          const { data, error } = await supabase
            .from(table)
            .insert(entries)
            .select();

          if (error) throw error;
          if (data) return data;
        } catch (error) {
          component.$emit('showError', error);
          // check if the error contains the message '_.merkmale' if yes, log the table name
          if ('message' in error && error.message.includes('_.merkmale')) {
            this.logError(component, {
              uid: component.$store.state.client.id,
              message: 'LOG: Multi In Table ' + table
            });
          }
          return null;
        }
      },

      async upsertRow(component, table, entry) {
        try {
            const { data, error } = await supabase
                .from(table)
                .upsert(entry)
                .select()
            if (error) throw error
            if (data && data.length === 1) return data
        } catch (error) {
            component.$emit('showError', error)
        }
        return null
      },

      async updateRow(component, table, entry, id) {
        try {
          const { data, error } = await supabase
            .from(table)
            .update(entry)
            .eq('id', id)
            .select();
          if (error) throw error;
          if (data) return data;
        } catch (error) {

          if (error.message.includes('duplicate key value violates unique constraint') && table === 'rechnungen') {
            component.$emit('showError', {
              message: 'Diese Rechnungsnummer wurde bereits verwendet. Bitte wähle eine andere Rechnungsnummer.',
              timeout: 10000
            });
            return null;
          } else {
            component.$emit('showError', error);
          }
          throw error;
        }
        return null;
      },

      // the difference to the above method is, that no error is thrown when the expection is handled.
      async update(component, table, entry, id) {
        try {
          const { data, error } = await supabase
            .from(table)
            .update(entry)
            .eq('id', id)
            .select()
          if (error) throw error
          if (data) return data
        } catch (error) {

          if (error.message.includes('duplicate key value violates unique constraint') && table === 'rechnungen') {
            component.$emit('showError', {
              message: 'Diese Rechnungsnummer wurde bereits verwendet. Bitte wähle eine andere Rechnungsnummer.',
              timeout: 10000
            });
            return null;
          } else {
            component.$emit('showError', error);
          }
        }
        return null
      },

      async updateRelationships(component, table, update, column_in, ids) {
        try {
          const { data, error } = await supabase
            .from(table)
            .update(update)
            .in(column_in, ids)
            .select()
          if (error) throw error
          if (data) return data
          
        } catch (error) {
          component.$emit('showError', error)
          throw error
        }
      },

    //   async getDataByYear(view, order_by_sql, ascending=false, sort_js_by_name) {
    //     try {  
    //       const { data, error } = await supabase
    //         .from(view)
    //         .select()
    //         .gte('datum', this.$store.state.selected_year + '-01-01 00:00')
    //         .lt('datum',  (this.$store.state.selected_year + 1) + '-01-01 00:00')
    //         .order(order_by_sql, { ascending: ascending })

    //       if (error) throw error

    //       if (data) {
    //         if (data.length === 0) return []
            
    //         let decrypted_data = await Promise.all(data.map((entry) => this.decryptObject(entry, $store.state.data_key)))
    //         if (sort_js_by_name) {
    //           return this.listOfListToListOfObjects(decrypted_data).sort(this.sortListOfObjectsByString)
    //         } else {
    //           return this.listOfListToListOfObjects(decrypted_data)
    //         }
    //       }

    //     } catch (error) {
    //       this.showError(error)
    //       return []
    //     } 
        
    //   },


      async getChartData(component, view, year) {
        try {
          const { data, error } = await supabase
            .from(view)
            .select('*')
            .eq('jahr', year)
          
          if (error) throw error

          if (data) {
            if (data.length > 0) {
              return data
            } 
          }
        } catch (error) {
          console.log(error)
          component.$emit('showError', error)
        } 
        return 0
      },

      async getStatsCardData(component, view, year) {
        try {
          const { data, error } = await supabase
            .from(view)
            .select('*')
            .eq('jahr', year)
          
          if (error) throw error

          if (data) {
            if (data.length > 0) {
              return data[0].betrag
            } 
          }
        } catch (error) {
          console.log(error)
          component.$emit('showError', error)
        } 
        return 0
      },

      async updateUserPassword(component, new_password) {
        try {
          
          const { data, error } = await supabase.auth.updateUser({password: new_password})

          if (error) throw error
          if (data) {
            return data
          } else {
            return null
          }
          
        } catch (error) {
          component.$emit('showError', error)
        } 
        return null

      },

      async getTurnover(component, year) {
        try {
          const { data, error } = await supabase
            .from('vwumsatz')
            .select('*')
            .eq('jahr', year)
          
          if (error) throw error

          if (data) {
            if (data.length > 0) {
              return data[0].umsatz
            } else {
              return 0
            }
          }

        } catch (error) {
          console.log(error)
          component.$emit('showError', error)
        } 
        return 0
      },

      sortListOfObjectsByString(a, b) {
        const nameA = a.nachname.toUpperCase() // ignore upper and lowercase
        const nameB = b.nachname.toUpperCase() // ignore upper and lowercase
        if (nameA > nameB) {
          return 1
        }
        if (nameA < nameB) {
          return -1
        }
        // names must be equal
        return 0
      },

      listOfListToListOfObjects(listOfLists) {
        let listOfObjects = []
        for (let i=0; i<listOfLists.length; i++) {
          listOfObjects.push(listOfLists[i].reduce((accumulator, currentValue) => (accumulator[currentValue[0]]=currentValue[1], accumulator), {}))
        }
        return listOfObjects
      },

      async getDataOnly(component, view, order_by_sql, ascending=false, select_columns=null) {
        let attempts = 2; // including initial attempt

        while (attempts > 0) {

          try {  

            let query = select_columns ? supabase.from(view).select(select_columns) : supabase.from(view).select();

            const { data, error } = await query.order(order_by_sql, { ascending: ascending });

            if (error) throw error;

            if (data) {
              if (data.length === 0) return [];
              return data;
            }

          } catch (error) {
            if ('message' in error && error['message'].includes('Modification detected') ) {
              component.$emit('showError', {message: 'Die sensiblen Daten von den Klienten konnten nicht entschlüsselt werden. Stelle sicher das der Safe-Schlüssel korrekt ist.'})
            } else if (attempts > 1 && 'message' in error && (error.message.includes('Load failed') || error.message.includes('Failed to fetch'))) {
                attempts--;
                if (component.session && component.session.user && component.session.user.id) {
                  this.logError(component, {
                      uid: component.session.user.id,
                      message: 'Silent Load failed: ' + error.message,
                  })
                }
                continue;
            } else {
              component.$emit('showError', error);
            }
            
            return -1;
          } 
        }
      },

      async getDataOnlyFiltered(component, view, comparison_type='is', filter_column, filter_value, order_by_sql, ascending=false, select_columns=null) {
        try {  
    
            let query = select_columns ? supabase.from(view).select(select_columns) : supabase.from(view).select();
    
            switch (comparison_type) {
                case 'is':
                    query = query.is(filter_column, filter_value);
                    break;
                case 'eq':
                    query = query.eq(filter_column, filter_value);
                    break;
                case 'gte':
                    query = query.gte(filter_column, filter_value);
                    break;
                case 'lte':
                    query = query.lte(filter_column, filter_value);
                    break;
                // ... Add more comparisons as needed
                default:
                    throw 'Unsupported comparison type';
            }
    
            const { data, error } = await query.order(order_by_sql, { ascending: ascending })
    
            if (error) throw error
    
            if (data) {
                if (data.length === 0) return []
                return data
            }
  
        } catch (error) {
            component.$emit('showError', error)
            return -1
        } 
    },    
}