<template>
    <v-dialog v-model="dialog" max-width="1000">
        <v-card>
            <v-card-title>Wichtige Information zur Termin-Erinnerung via SMS</v-card-title>
            <v-card-text class="text-body-1">
                <p class="text-justify">
                    Bitte lasse deine Klienten eine Datenschutz- und Einwilligungserklärung unterschreiben, bevor du die SMS-Erinnerungsfunktion aktivierst.
                    Dies ist notwendig, um den Datenschutz und die Zustimmung deiner Klienten sicherzustellen. 
                    Du findest eine Vorlage für die Datenschutz- und Einwilligungserklärung unten im Bereich der Termin-Erinnerung.
                </p>
                <p class="text-justify">
                    Beachte, dass die Telefonnummern für die SMS-Erinnerung nicht mehr mit dem Safe-Schlüssel verschlüsselt werden können, da sie für den SMS-Versand benötigt
                    werden. Die SMS-Nachricht enthält keine personenbezogenen Daten außer dem Namen deiner Praxis und dient nur zur Terminerinnerung. 
                    Für das Versenden der SMS wird die Telefonnummer und der Inhalt der SMS mit unserem Partner für den SMS-Versand (Amazon Web Services in Frankfurt) geteilt.
                </p>
                <p class="text-justify">
                    Der Preis für den SMS-Versand beträgt 0,12€ pro SMS und wird zusammen mit deinem Abonnement abgerechnet. Wenn du die SMS-Erinnerungsfunktion
                    aktivierst, bestätigst du, dass du die Einwilligung deiner Klienten eingeholt hast, und mit den Kosten sowie der Nutzung dieses Services einverstanden bist.
                </p>
            </v-card-text>
            <v-card-actions class="d-flex justify-end pb-5">
                <v-btn :color="$store.state.theme.red" text @click="dialog = false">Abbrechen</v-btn>
                <v-btn class="ml-3" :color="$store.state.theme.green" outlined @click="acceptTerms">Einverstanden</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import connector from "../helpers/supabase-connector.js";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(timezone);

export default {
    props: ['session'],

    data() {
        return {
            dialog: false,
        };
    },

    methods: {
        openDialog() {
            this.dialog = true;
        },

        closeDialog() {
            this.dialog = false;
        },

        async acceptTerms() {
            let client = await connector.updateRow(this, "kunden", { 
                // format as timestmapz
                accepted_sms_terms: dayjs.utc().tz("Europe/Berlin").format(),
            }, this.session.user.id);
            this.$store.commit('setAcceptedSMSTerms', client[0].accepted_sms_terms);
            this.closeDialog();
        },
    },
};
</script>

<style scoped></style>
