<template>
  <v-dialog v-model="dialog" max-width="700px" persistent>
    <v-card v-if="request">

      <v-card-text class="pt-4">
        <div class="text-h5 font-weight-medium mb-2">Termin-Anfrage am {{ formatDate(request.datum) }} um {{ formatTime(request.datum) }}</div>
        <div class="text-h6 font-weight-regular mb-2">{{ request.bezeichnung }}</div>

        <v-list-item dense class="px-0">
          <v-list-item-icon class="mr-2">
            <v-icon :color="$store.state.theme.primary">mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-body-1">
              {{ request.decryptedDetails.nachname }} {{ request.decryptedDetails.vorname }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item dense class="px-0">
          <v-list-item-icon class="mr-2">
            <v-icon color="blue">mdi-email-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-body-1 font-weight-regular">
              <a :href="`mailto:${request.decryptedDetails.email}`" @click.stop>{{ request.decryptedDetails.email }}</a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item dense class="px-0">
          <v-list-item-icon class="mr-2">
            <v-icon color="green">mdi-phone-in-talk</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-body-1 font-weight-regular">
              {{ request.decryptedDetails.telefon }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="my-3"></v-divider>

        <div v-if="request.decryptedDetails && request.decryptedDetails.message" class="mt-2">
          <div class="text-subtitle-2 font-weight-medium mb-1">
            <v-icon left>mdi-chat-outline</v-icon>
            Nachricht:
          </div>
          <p class="text-body-1">{{ request.decryptedDetails.message }}</p>
        </div>
      </v-card-text>

      <v-card-actions class="pb-5 px-5">
        <v-btn outlined :color="$store.state.theme.red" @click="deleteRequest">
          Anfrage Löschen
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="close">
          <span v-if="request.closed">Schließen</span>
          <span v-else>Abbrechen</span>
        </v-btn>
        <v-btn v-if="request.closed === null || request.closed === false" :color="$store.state.theme.green" @click="closeRequest" :loading="loading" dark>
          Anfrage abschließen
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card v-else>
      <v-card-text class="text-body-1 text-center py-5">
        <v-icon large color="grey" class="mb-3">mdi-alert-circle-outline</v-icon>
        <br>
        Keine Buchungsanfrage ausgewählt.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close">Schließen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import dayjs from 'dayjs'
import 'dayjs/locale/de'

export default {
  name: 'BookingRequestDialog',
  props: {
    dialog: Boolean,
    request: Object,
  },
  emits: ['close', 'closeRequest', 'deleteRequest'],
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    formatDate(dateString) {
      return dateString ? dayjs(dateString).locale('de').format('dddd, DD.MM.YYYY') : '';
    },
    formatTime(dateString) {
      return dateString ? dayjs(dateString).format('HH:mm') : '';
    },
    close() {
      this.$emit('close');
    },
    closeRequest() {
      if (this.request) {
        this.$emit('closeRequest', this.request);
      }
    },
    deleteRequest() {
      if (this.request) {
        this.$emit('deleteRequest', this.request.id);
      }
    }
  },
}
</script>
