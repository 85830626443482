<template>
    <v-dialog v-model="redirectDialog" persistent max-width="500px">
        <v-card>
            <v-card-title class="text-h6">Weiterleitung</v-card-title>
            <v-card-text class="text-body-1 text-justify">
                Dein Google-Kalender Zugriff ist abgelaufen. Bitte klicke auf Erneut verbinden, um den Zugriff zu erneuern.
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :color="$store.state.theme.primary" text @click="signInWithGoogle">Erneut verbinden</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { supabase } from "../supabase";
import dayjs from "dayjs";
import connector from "../helpers/supabase-connector.js";

export default {
    props: ["session"],
    emits: ["showError", "showInfo"],

    data() {
        return {
            color_mapping_events: {
                "1": "#7986CB",
                "2": "#33B679",
                "3": "#8E24AA",
                "4": "#E67C73",
                "5": "#F6BF26",
                "6": "#F4511E",
                "7": "#039BE5",
                "8": "#616161",
                "9": "#3F51B5",
                "10": "#0B8043",
                "11": "#D50000"
            },
        
            redirectDialog: false,
            gapi: null,
            gapi_inited: false,
            gapi_reload_count: 0,
            linked_google_account: false,
        };
    },

    async mounted() {
        this.gapi = window.gapi;
        this.linked_google_account = await this.listLinkedIdentities();
    },

    computed: {
        googleCalenderAuthState() {
            let linked_google_account = this.linked_google_account;
            let linked_google_calendar = this.$store.state.client.google_calendar;
            let provider_refresh_token = localStorage.provider_refresh_token;

            if (provider_refresh_token && linked_google_calendar) {
                return "synced";
            } else if (provider_refresh_token && !linked_google_calendar) {
                return "authorized";
            } else if (linked_google_account) {
                return "linked";
            } else {
                return "unlinked";
            }
        },
    },

    methods: {
        async deleteGoogleEvent(google_appointment) {
            return await this.gapi.client.calendar.events.delete({
                calendarId: this.$store.state.client.google_calendar,
                eventId: google_appointment.eventId,
            });
        },

        async updateGoogleEvent(event) {
            return await this.gapi.client.calendar.events.update(event);
        },

        async insertGoogleEvents(events) {
            try {
                const batch = this.gapi.client.newBatch();
                events.map((event) => {
                    batch.add(this.gapi.client.calendar.events.insert(event));
                });

                return await batch;
            } catch (error) {
                this.$emit("showError", {
                    message: "Fehler beim Einfügen von Google-Terminen.",
                });
                return null;
            }
        },

        async listLinkedIdentities() {
            try {
                // retrieve all identities linked to a user
                let result = await supabase.auth.getUserIdentities();
                if (result && result.data && result.data.identities) {
                    
                    const {
                        data: { identities },
                    } = result;

                    const googleIdentity = identities.find((identity) => identity.provider === "google");
                    if (googleIdentity) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    // handle error
                    this.$emit("showError", {
                        message: "Fehler beim Abrufen der verknüpften Google-Accounts. Bitte lade die Seite neu.",
                        timeout: 5000,
                    });
                    return false;
                }

            } catch (error) {
                console.log(error);
                this.$emit("showError", {
                    message: "Fehler beim Abrufen der verknüpften Google-Accounts. Bitte lade die Seite neu.",
                    timeout: 5000,
                });
                return false;
            }
        },

        async signInWithGoogle() {
            if (this.googleCalenderAuthState === "unlinked") {
                const { data, error } = await supabase.auth.linkIdentity({
                    provider: "google",
                    options: {
                        redirectTo: process.env.NODE_ENV === "development" ? "http://localhost:8080/kalender" : "https://app.zeipsy.com/kalender",
                        scopes: ["https://www.googleapis.com/auth/calendar.calendarlist.readonly", "https://www.googleapis.com/auth/calendar.events"],
                        queryParams: {
                            access_type: "offline",
                            prompt: "consent", // The refresh-token gets returned only immediately after consent.
                            // It will not be re-issued on sessionRefresh or Login.
                            // Therefore, "force" consent on re-login as refresh-token is not in local storage.
                        },
                    },
                });

                if (error) {
                    localStorage.removeItem("scopes");
                    localStorage.removeItem("provider_refresh_token");
                    this.$emit("showError", {
                        message: "Fehler beim Verbinden mit Google.",
                    });
                }
                if (data) {
                    localStorage.setItem(
                        "scopes",
                        "https://www.googleapis.com/auth/calendar.calendarlist.readonly,https://www.googleapis.com/auth/calendar.events"
                    );
                    // localStorage.setItem('provider_refresh_token', data.provider_refresh_token);
                }
            } else {
                const { data, error } = await supabase.auth.signInWithOAuth({
                    provider: "google",
                    options: {
                        redirectTo: process.env.NODE_ENV === "development" ? "http://localhost:8080/kalender" : "https://app.zeipsy.com/kalender",
                        scopes: ["https://www.googleapis.com/auth/calendar.calendarlist.readonly", "https://www.googleapis.com/auth/calendar.events"],
                        queryParams: {
                            access_type: "offline",
                            prompt: "consent", // The refresh-token gets returned only immediately after consent.
                            // It will not be re-issued on sessionRefresh or Login.
                            // Therefore, "force" consent on re-login as refresh-token is not in local storage.
                        },
                    },
                });

                if (error) {
                    localStorage.removeItem("scopes");
                    localStorage.removeItem("provider_refresh_token");
                    this.$emit("showError", {
                        message: "Fehler beim Verbinden mit Google.",
                    });
                }
                if (data) {
                    localStorage.setItem(
                        "scopes",
                        "https://www.googleapis.com/auth/calendar.calendarlist.readonly,https://www.googleapis.com/auth/calendar.events"
                    );
                    // localStorage.setItem('provider_refresh_token', data.provider_refresh_token);
                }
            }
        },

        async getRefreshToken(refresh_token) {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + this.session.access_token);
            myHeaders.append("Accept", "*/*");
            myHeaders.append("x-client-info", "supabase-js/2.8.0");

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                redirect: "follow",
                body: JSON.stringify({
                    refresh_token: refresh_token,
                }),
            };

            let responseJson = {};
            try {
                let response = await fetch("https://qgsfsflpvymafizvkpca.functions.supabase.co/google-refresh-token/", requestOptions);
                responseJson = await response.json();
            } catch (error) {
                connector.logError(this, {
                    uid: this.session.user.id,
                    message: "LOG: Google Calendar: Error during POST request to google-refresh-token function.",
                });
            }

            return responseJson;
        },

        async initializeGapiClient() {
            try {
                // only initialize if we have a refresh token
                if (localStorage.provider_refresh_token) {
                    let accessToken = await this.getRefreshToken(localStorage.provider_refresh_token);
                    await this.gapi.client.init({
                        apiKey: "AIzaSyBVojXCiR8LbVSlFt94GU0-O_yShuQpRTs",
                        discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
                    });
                    this.gapi.client.setToken({ access_token: accessToken.access_token });
                    this.gapi_inited = true;
                } else {
                    // check if a calendar is already set, if so, show the reconnect dialog
                    if (this.$store.state.client.google_calendar) {
                        this.redirectDialog = true;
                        connector.logError(this, {
                            uid: this.session.user.id,
                            message: "LOG: Google Calendar: Refresh token not found although calender is set. Prompting user to reconnect.",
                        });
                    }
                }
            } catch (error) {
                console.log(error);
                this.$emit("showError", {
                    message: "Fehler beim Initialisieren von Google-API.",
                });
            }
        },

        async getCalendarEvents(calendar_id) {
            // check if gapi client is already loaded
            if (!this.gapi_inited) {
                await new Promise((resolve) => {
                    this.gapi.load("client", async () => {
                        await this.initializeGapiClient();
                        resolve();
                    });
                });
            }

            let response = null;
            try {
                const request = {
                    calendarId: calendar_id,
                    timeMin: dayjs().subtract(2, "month").toISOString(),
                    showDeleted: false,
                    singleEvents: true,
                    maxResults: 250,
                    orderBy: "startTime",
                };
                response = await this.gapi.client.calendar.events.list(request);

                if (response) {
                    return response.result.items.map((appointment) => {
                        let color = null;
                        if (appointment.colorId &&  appointment.colorId in this.color_mapping_events) {
                            color = this.color_mapping_events[appointment.colorId]
                        }

                        return {
                            color: color,
                            start: appointment.start.date ? dayjs(appointment.start.date).format('YYYY-MM-DD') : dayjs(appointment.start.dateTime).format('YYYY-MM-DDTHH:mm'),
                            end: appointment.end.date ? dayjs(appointment.end.date).subtract(1, 'day').format('YYYY-MM-DD') : dayjs(appointment.end.dateTime).format('YYYY-MM-DDTHH:mm'),
                            name: appointment.summary,
                            type: "google",
                            id:
                                appointment.description && appointment.description.includes("zeipsy.com/termine/?id=")
                                    ? parseInt(appointment.description.split("id=")[1])
                                    : null,
                            eventId: appointment.id,
                        };
                    });
                }
                return [];
            } catch (err) {
                console.log("error", err);
                if (err.status === 404 || err.status === 401) {
                    // renew the access token
                    // check if provider refresh token is present, if so, get a new access token
                    if (localStorage.provider_refresh_token) {
                        if (this.gapi_reload_count > 0) {
                            // if we already tried to reload the gapi client, show the reconnect dialog
                            this.redirectDialog = true;
                            connector.logError(this, {
                                uid: this.session.user.id,
                                message: "LOG: Google Calendar: Getting in Auth Loop. Prompting user to reconnect.",
                            });
                            return [];
                        }
                        await new Promise((resolve) => {
                            this.gapi.load("client", async () => {
                                await this.initializeGapiClient();
                                resolve();
                            });
                        });
                        this.gapi_reload_count += 1;
                    } else {
                        // if not, redirect to google with prompt=consent and offline access
                        this.redirectDialog = true;
                        connector.logError(this, {
                            uid: this.session.user.id,
                            message: "LOG: Google Calendar: Refresh token not found. Prompting user to reconnect.",
                        });
                    }
                }
                if (err.status === 403) {
                    // insufficient permissions, probably the user revoked the permissions
                    // show redirection dialog
                    this.redirectDialog = true;
                    connector.logError(this, {
                        uid: this.session.user.id,
                        message: "LOG: Google Calendar: Insufficient permissions. Prompting user to reconnect.",
                    });
                }
                return [];
            }
        },
    },
};
</script>
