<template>
    <div>
        <v-btn-toggle v-model="undo_state" dense class="mr-3 mb-3">
            <v-btn @click="undo" :small="$vuetify.breakpoint.xsOnly">
                <v-icon :small="$vuetify.breakpoint.xsOnly">mdi-undo</v-icon>
            </v-btn>
            <v-btn @click="redo" :small="$vuetify.breakpoint.xsOnly">
                <v-icon :small="$vuetify.breakpoint.xsOnly">mdi-redo</v-icon>
            </v-btn>
        </v-btn-toggle>
        <!-- Toggle buttons for formatting -->
        <v-btn-toggle dense class="mr-3 mb-3">
            <v-btn v-for="item in formats" :key="item.value" @click="applyFormat(item.value)"
                :input-value="isActive(item.value)" :small="$vuetify.breakpoint.xsOnly">
                <v-icon :small="$vuetify.breakpoint.xsOnly">{{ item.icon }}</v-icon>
            </v-btn>
            <v-btn @click="applyFormat('highlight')" :input-value="isActive('highlight')"
                :small="$vuetify.breakpoint.xsOnly">
                <v-row align="center" class="flex-column" justify="center">
                    <v-icon :small="$vuetify.breakpoint.xsOnly">mdi-format-color-highlight</v-icon>
                    <v-sheet tile style="margin-top: -4px;" height="4" width="26" color="#fbf100">
                    </v-sheet>
                </v-row>
            </v-btn>
        </v-btn-toggle>
        <v-btn-toggle dense class="mr-3 mb-3">
            <v-btn @click="applyFormat('heading-1')" :input-value="isActiveHeading({ level: 1 })"
                :small="$vuetify.breakpoint.xsOnly">
                <v-icon :small="$vuetify.breakpoint.xsOnly">mdi-format-header-1</v-icon>
            </v-btn>
            <v-btn @click="applyFormat('heading-2')" :input-value="isActiveHeading({ level: 2 })"
                :small="$vuetify.breakpoint.xsOnly">
                <v-icon :small="$vuetify.breakpoint.xsOnly">mdi-format-header-2</v-icon>
            </v-btn>
            <v-btn @click="applyFormat('heading-3')" :input-value="isActiveHeading({ level: 3 })"
                :small="$vuetify.breakpoint.xsOnly">
                <v-icon :small="$vuetify.breakpoint.xsOnly">mdi-format-header-3</v-icon>
            </v-btn>
        </v-btn-toggle>

        <v-btn-toggle dense class="mr-3 mb-3">
            <v-btn @click="applyFormat('align-left')" :input-value="isActive({ textAlign: 'left' })"
                :small="$vuetify.breakpoint.xsOnly">
                <v-icon :small="$vuetify.breakpoint.xsOnly">mdi-format-align-left</v-icon>
            </v-btn>
            <v-btn @click="applyFormat('align-center')" :input-value="isActive({ textAlign: 'center' })"
                :small="$vuetify.breakpoint.xsOnly">
                <v-icon :small="$vuetify.breakpoint.xsOnly">mdi-format-align-center</v-icon>
            </v-btn>
            <v-btn @click="applyFormat('align-right')" :input-value="isActive({ textAlign: 'right' })"
                :small="$vuetify.breakpoint.xsOnly">
                <v-icon :small="$vuetify.breakpoint.xsOnly">mdi-format-align-right</v-icon>
            </v-btn>
        </v-btn-toggle>

        <slot name="after-controls"></slot>

    </div>
</template>

<script>

export default {

    props: ['editor'],

    data() {
        return {
            undo_state: null,
            formats: [
                { value: 'bold', icon: 'mdi-format-bold' },
                { value: 'italic', icon: 'mdi-format-italic' },
                { value: 'underline', icon: 'mdi-format-underline' },
            ],
        }
    },

    watch: {
        undo_state(value) {
            if (value === 0 || value === 1) {
                this.$nextTick(() => {
                    this.undo_state = null;
                });
            }
        }
    },

    methods: {
        
        isActive(format) {
            if (!this.editor) return false;
            if (format === 'highlight') {
                return this.editor.isActive(format, { color: '#faf594' });
            }
            return this.editor.isActive(format);
        },

        isActiveHeading(level) {
            if (!this.editor) return false;
            return this.editor.isActive('heading', level);
        },

        redo() {
            this.editor.chain().focus().redo().run();
        },
        undo() {
            this.editor.chain().focus().undo().run();
        },

        applyFormat(format) {
            if (!this.editor) return;

            switch (format) {
                case 'bold':
                    this.editor.chain().focus().toggleBold().run();
                    break;
                case 'italic':
                    this.editor.chain().focus().toggleItalic().run();
                    break;
                case 'underline':
                    this.editor.chain().focus().toggleUnderline().run();
                    break;
                case 'highlight':
                    this.editor.chain().focus().toggleHighlight({ color: '#faf594' }).run();
                    break;
                case 'align-left':
                    this.editor.chain().focus().setTextAlign('left').run();
                    break;
                case 'align-center':
                    this.editor.chain().focus().setTextAlign('center').run();
                    break;
                case 'align-right':
                    this.editor.chain().focus().setTextAlign('right').run();
                    break;
                case 'heading-1':
                    this.editor.chain().focus().toggleHeading({ level: 1 }).run();
                    break;
                case 'heading-2':
                    this.editor.chain().focus().toggleHeading({ level: 2 }).run();
                    break;
                case 'heading-3':
                    this.editor.chain().focus().toggleHeading({ level: 3 }).run();
                    break;
                default:
                    console.log('Unknown format:', format);
            }
        }
    }
}
</script>