<template>
    <v-sheet ref="calendarSheet" :height="height">
        <GoogleCalendar ref="googleCalendar" :session="session" @showError="(arg) => $emit('showError', arg)" @showInfo="(arg) => $emit('showInfo', arg)" />
        <v-calendar
            ref="calendar"
            v-model="value"
            :weekdays="weekdays"
            :events="events"
            :type="$vuetify.breakpoint.xsOnly ? '4day' : 'week'"
            v-touch="{ left: () => next(), right: () => prev() }"
            @mousedown:time="startTime"
            @mousemove:time="mouseMove"
            @mouseleave:day="clearMove"
            @mouseleave:day-category="clearMove"
            @mouseleave:time="clearMove"
            @mouseenter:event="setOverEvent"
            @mouseleave:event="clearOverEvent"
            :first-time="workingHoursStart"
            :interval-count="intervalCount"
            :interval-height="interval_height">
            <template v-slot:day-body="{ date, week }">
                <div class="v-current-time" :class="{ first: date === week[0].date }" :style="{ top: nowY() }"></div>
                <v-fade-transition>
                    <v-sheet
                        v-if="hoverDate && hoverDate === date && $vuetify.breakpoint.smAndUp"
                        class="v-current-time-insert pl-1"
                        color="grey lighten-4"
                        rounded
                        elevation="0"
                        :style="{ top: hoverTimePosition }">
                        <v-icon v-if="editedItem.datum" left>mdi-calendar-arrow-left</v-icon>
                        <v-icon v-else left>mdi-calendar-plus</v-icon>

                        {{ hoverTime }}
                    </v-sheet>
                </v-fade-transition>
            </template>
            <template v-slot:event="{ event }">
                <div
                    :class="'custom-event' + (event.preview ? ' preview-event' : '')"
                    :style="{
                        borderRadius: '4px',
                        backgroundColor: event.color,
                        border: event.color && event.preview ? '2px dashed ' + event.borderColor : '',
                    }"
                    style="height: 100%">
                    <strong :class="'event-name'">{{ event.name }}</strong>
                    <div class="event-time" v-if="event.end && event.start !== event.end">
                        <!-- Adjust time formatting as needed -->
                        {{ new Date(event.start).getHours() }}:{{ String(new Date(event.start).getMinutes()).padStart(2, "0") }} -
                        {{ new Date(event.end).getHours() }}:{{ String(new Date(event.end).getMinutes()).padStart(2, "0") }}
                    </div>
                </div>
            </template>
        </v-calendar>
    </v-sheet>
</template>

<script>
import connector from "../helpers/supabase-connector.js";
import cipher from "@/helpers/cipher";
import dayjs from "dayjs";
import Holidays from "date-holidays";
import GoogleCalendar from "./GoogleCalendar.vue";

export default {
    emits: ["selectedDateAndTime", "showError", "showInfo"],
    props: ["editedItem", "session", "height"],

    components: {
        GoogleCalendar,
    },

    data() {
        return {
            working_hours_start: this.$store.state.client.kalender_start || "00:00",
            working_hours_end: this.$store.state.client.kalender_ende || "23:00",
            interval_height: 48,

            interval_id: null,

            google_appointments: [],
            appointments: [],

            hide_holidays: 0,

            is_over_event: false,
            hoverDate: null,
            hoverTime: null,
            hoverTimePosition: null,

            weekdays: [1, 2, 3, 4, 5, 6, 0],
            value: "",
        };
    },

    computed: {

        intervalCount() {
            const startHour = parseInt(this.workingHoursStart.split(':')[0]);
            const endHour = parseInt(this.working_hours_end.split(':')[0]);
            return (endHour - startHour) + 1;
        },

        workingHoursStart() {
            if (this.working_hours_start.includes(':')) {
            let start_hour = parseInt(this.working_hours_start.split(':')[0]);
            if (start_hour > 0) {
                return `${start_hour-1}:00`;
            } else {
                return `${start_hour}:00`;
            }
            }
            return this.working_hours_start;
        },

        hasReachedAppointmentsLimit() {
            // check subscription

            if (this.$store.getters.subscriptionTier === "VOLLZEIT") {
                return false;
            }

            let appointment_limit = 10;

            if (this.$store.getters.subscriptionTier === "TEILZEIT") {
                appointment_limit = 30;
            }

            let existing_month_counts = {};

            let existing_appointments = this.appointments.map((appointment) => {
                // just return the date of the appointment stored in datum using dayjs
                let date = dayjs(appointment.datum);
                let month_key = date.format("YYYY-MM");

                if (!existing_month_counts[month_key]) {
                    existing_month_counts[month_key] = 1;
                } else {
                    existing_month_counts[month_key]++;
                }
                return date;
            });

            let new_appointments = [this.editedItem];

            // create an object to keep count of appointments per month for existing appointments
            // check if adding new appointments will exceed the limit
            for (let idx in new_appointments) {
                let date = dayjs(new_appointments[idx].datum);
                let month_key = date.format("YYYY-MM");

                // create or increment the count for this month
                if (!existing_month_counts[month_key]) {
                    existing_month_counts[month_key] = 1;
                } else {
                    existing_month_counts[month_key]++;
                }

                // check if the count for this month exceeds the limit
                if (existing_month_counts[month_key] > appointment_limit) {
                    return true;
                }
            }

            return false;
        },

        events() {
            let ids_in_google = this.google_appointments
                .map((appointment) => {
                    return appointment.id;
                })
                .filter((id) => id !== null);

            let appointment_ids = this.appointments.map((appointment) => {
                return appointment.id;
            });

            let holidays = [];
            if (!this.hide_holidays) {
                holidays = this.getHolidays();
            }

            let appointment_to_add = [];
            if (
                this.editedItem.datum &&
                this.editedItem.uhrzeit &&
                this.editedItem.datum.match(/^\d{4}-\d{2}-\d{2}$/) &&
                this.editedItem.uhrzeit.match(/^\d{2}:\d{2}(?::\d{2})?$/)
            ) {
                // construct dayjs object with date from editedItem.datum and time from editedItem.uhrzeit
                let date = dayjs(this.editedItem.datum)
                    .set("hour", parseInt(this.editedItem.uhrzeit.split(":")[0]))
                    .set("minute", parseInt(this.editedItem.uhrzeit.split(":")[1]));

                let appointment_name = this.editedItem.nachname + " " + this.editedItem.vorname;
                appointment_to_add.push({
                    name: appointment_name,
                    start: date.format("YYYY-MM-DDTHH:mm"),
                    end: date.add(this.editedItem.dauer, "m").format("YYYY-MM-DDTHH:mm"),
                    color: this.editedItem.farbe ? this.hexToRGBA(this.editedItem.farbe, 0.15) : this.hexToRGBA(this.$store.state.theme.primary, 0.15),
                    borderColor: this.editedItem.farbe ? this.editedItem.farbe : this.$store.state.theme.primary,
                    preview: true,
                });
            }

            return this.appointments
                .filter((appointment) => appointment.id !== this.editedItem.id)
                .map((appointment) => {
                    let original_appointment = Object.assign({}, appointment);
                    let appointment_name = appointment.nachname + " " + appointment.vorname;

                    original_appointment.name = appointment_name;
                    original_appointment.start = dayjs(appointment.datum).format("YYYY-MM-DDTHH:mm");
                    original_appointment.end = dayjs(appointment.datum).add(appointment.dauer, "m").format("YYYY-MM-DDTHH:mm");

                    // check if end time is outside of the visible area (stored in working_hours_end as "HH:mm"), if so show as daily event.
                    // Create a Day.js object for the end of the working hours on the same day as the appointment.
                    const currentWorkingHoursStart = dayjs(appointment.datum)
                        .format('YYYY-MM-DD')
                        .concat('T', this.workingHoursStart);

                    const currentWorkingHoursEnd = dayjs(appointment.datum)
                        .format('YYYY-MM-DD')
                        .concat('T', this.working_hours_end);

                    // check if end time is outside of the visible area 
                    if (dayjs(original_appointment.start).isAfter(currentWorkingHoursEnd)) {
                        original_appointment.start = dayjs(appointment.datum).format('YYYY-MM-DD');
                        delete original_appointment.end;
                    } else if (dayjs(original_appointment.end).isBefore(currentWorkingHoursStart)) {
                        original_appointment.start = dayjs(appointment.datum).format('YYYY-MM-DD');
                        delete original_appointment.end;
                    }

                    if (appointment.farbe) {
                        original_appointment.color = appointment.farbe;
                    } else {
                        original_appointment.color = ids_in_google.includes(original_appointment.id)
                            ? this.$store.state.theme.green
                            : this.$store.state.theme.primary;
                    }
                    return original_appointment;
                })
                .concat(
                    this.google_appointments
                        .filter((google_appointment) => google_appointment.id === null || !appointment_ids.includes(google_appointment.id))
                        .map((google_appointment) => {
                            let original_appointment = Object.assign({}, google_appointment);

                            // check if google_calendar_color is stored in localStore, if yes, use it as color, if not use default color
                            let google_calendar_color = localStorage.getItem("google_calendar_color");
                            if (google_calendar_color && !original_appointment.color) {
                                original_appointment.color = google_calendar_color;
                            }

                            return original_appointment;
                        })
                )
                .concat(holidays)
                .concat(appointment_to_add);
        },
    },

    beforeDestroy() {
        clearInterval(this.interval_id);
        window.removeEventListener('resize', this.updateIntervalHeight);
    },

    async mounted() {
        window.addEventListener('resize', this.updateIntervalHeight);

        let client = await connector.getDataOnly(this, 'vwkunden', 'id', true);
        if (client.length > 0) {
            this.$store.commit('setClient', client[0]);

            if (this.$store.state.client.kalender_start) {
                this.working_hours_start = this.$store.state.client.kalender_start;
            }

            if (this.$store.state.client.kalender_ende) {
                this.working_hours_end = this.$store.state.client.kalender_ende;
            }

            if (this.$store.state.client.kalender_start || this.$store.state.client.kalender_ende) {
                this.updateIntervalHeight();
            }
        }
    },

    methods: {
        updateIntervalHeight() {

            if (!this.$refs.calendarSheet) {
                this.interval_height = 48;
                return;
            }

            const header = this.$refs.calendar.$el.querySelector('.v-calendar-daily__head');
            // Get the header height
            let headerHeight = 100;

            if (header) {
                headerHeight = header.offsetHeight;
            }

            // Access the height directly from the ref
            const sheetHeight = this.$refs.calendarSheet.$el.clientHeight - headerHeight - 10;

            // Calculate the desired number of intervals to display
            const startHour = parseInt(this.workingHoursStart.split(':')[0]);
            const endHour = parseInt(this.working_hours_end.split(':')[0]);
            const numIntervals = endHour - startHour + 1;

            // Calculate and return the dynamic interval height
            this.interval_height = Math.max(Math.floor(sheetHeight / numIntervals), 48);
            return;
        },

        nowY() {
            if ("calendar" in this.$refs) {
                return this.$refs.calendar.timeToY(this.$refs.calendar.times.now) + "px";
            } else {
                return "-10px";
            }
        },

        async initialize(scroll_to_current_time = true) {

            this.updateIntervalHeight();

            this.hide_holidays = parseInt(localStorage.getItem("hide_holidays")) || 0;

            if (this.editedItem.datum) {
                // show the week of the edited item
                this.value = dayjs(this.editedItem.datum).format("YYYY-MM-DD");
            } else {
                // show current week
                this.value = "";
            }

            if (scroll_to_current_time) {
                this.scrollToTime(this.editedItem.uhrzeit);
            }
            this.updateTime();
            if (this.$refs.calendar) this.$refs.calendar.updateTimes();

            let appointments = await connector.getDataOnly(this, "vwtermine", "datum", false);
            this.appointments = await cipher.decryptDataSync(this, appointments);

            let customer = connector.getDataOnly(this, "vwkunden", "id", true);

            if (customer.length > 0) {
                this.$store.commit("setClient", customer[0]);
            }
            if (this.$store.state.client.google_calendar) {
                this.google_appointments = await this.$refs.googleCalendar.getCalendarEvents(this.$store.state.client.google_calendar);
            } else {
                this.google_appointments = [];
            }
        },

        async insertGoogleEvents(events) {
            let inserted = await this.$refs.googleCalendar.insertGoogleEvents(events);
            await this.initialize(false);
            return inserted;
        },

        async updateGoogleEvent(item) {
            // find the event with the same id
            let google_appointment = this.google_appointments.find((appointment) => appointment.id === item.id);

            // if no google appointment is found, try to get the latest from the google calendar
            if (!google_appointment && this.$store.state.client.google_calendar) {
                this.google_appointments = await this.$refs.googleCalendar.getCalendarEvents(this.$store.state.client.google_calendar);
            }

            if (google_appointment) {
                try {
                    let client_name = "Klienten-Termin";

                    if (item.vorname && item.nachname) {
                        if (this.$store.state.client.google_calendar_anonymize) {
                            client_name = item.nachname.slice(0, 2) + item.vorname.slice(0, 2);
                        } else {
                            client_name = item.nachname + " " + item.vorname;
                        }
                    }

                    const request = {
                        calendarId: this.$store.state.client.google_calendar,
                        eventId: google_appointment.eventId,
                        start: {
                            dateTime: dayjs(item.datum).format("YYYY-MM-DDTHH:mm:ss"),
                            timeZone: "Europe/Berlin",
                        },
                        end: {
                            dateTime: dayjs(item.datum).add(item.dauer, "minutes").format("YYYY-MM-DDTHH:mm:ss"),
                            timeZone: "Europe/Berlin",
                        },
                        summary: client_name,
                        description: "https://app.zeipsy.com/termine/?id=" + item.id,
                    };

                    return await this.$refs.googleCalendar.updateGoogleEvent(request);
                } catch (err) {
                    console.log(err);
                    // check if err is a string, if yes, log it, if not, convert it to a string and log it
                    if (typeof err === "string") {
                        connector.logError(this, err);
                    } else {
                        connector.logError(this, JSON.stringify(err));
                    }
                    return [];
                }
            } else {
                console.log("no google appointment found for id ", item.id);
            }
        },

        async deleteGoogleEvent(id) {
            try {
                // find the event with the same id
                let google_appointment = this.google_appointments.find((appointment) => appointment.id === id);

                // if no google appointment is found, try to get the latest from the google calendar
                if (!google_appointment && this.$store.state.client.google_calendar) {
                    this.google_appointments = await this.$refs.googleCalendar.getCalendarEvents(this.$store.state.client.google_calendar);
                }

                if (google_appointment) {
                    let response = await this.$refs.googleCalendar.deleteGoogleEvent(google_appointment);
                }
            } catch (err) {
                this.$emit("showError", {
                    message: "Fehler beim Löschen des Google Kalender Termins.",
                });
            }
        },

        hexToRGBA(hex, opacity) {
            let r = parseInt(hex.substring(1, 3), 16);
            let g = parseInt(hex.substring(3, 5), 16);
            let b = parseInt(hex.substring(5, 7), 16);

            return `rgba(${r}, ${g}, ${b}, ${opacity})`;
        },

        getCurrentTime() {
            // get current time in minutes using dayjs
            return dayjs().hour() * 60 + dayjs().minute();
            //return this.$refs.calendar.times.now.hour * 60 + this.$refs.calendar.times.now.minute;
        },

        scrollToTime(provided_time = null) {
            let time = 0;
            if (provided_time) {
                time = parseInt(provided_time.split(":")[0]) * 60 + parseInt(provided_time.split(":")[1]);
            } else {
                time = this.getCurrentTime();
            }
            const first = Math.max(0, time - (time % 30) - 5 * 60);
            this.$refs.calendar.scrollToTime(first);
        },

        updateTime() {
            this.interval_id = setInterval(() => {
                if (this.$refs.calendar) {
                    this.$refs.calendar.updateTimes();
                }
            }, 5 * 60 * 1000);
        },

        getHolidays() {
            const hd = new Holidays("AT", "de-at");
            // get last year, this year, and next years holidays
            let years = [new Date().getFullYear() - 1, new Date().getFullYear(), new Date().getFullYear() + 1];
            let holidays = [];
            years.forEach((year) => {
                holidays = holidays.concat(hd.getHolidays(year));
            });
            return holidays
                .filter((holiday) => ["public", "bank"].includes(holiday.type))
                .map((holiday) => {
                    return {
                        type: "holiday",
                        name: holiday.name,
                        start: dayjs(holiday.start).format("YYYY-MM-DD"),
                        color: this.$store.state.theme.green,
                    };
                });
        },

        startTime(tms) {
            if (this.is_over_event || this.$vuetify.breakpoint.xsOnly) {
                return;
            }

            // extract the minutes out of the time and round it to the nearest 15 minutes.
            const interval = 15;
            // Calculate total minutes from the beginning of the day
            const totalMinutes = tms.hour * 60 + tms.minute;
            // Round the total minutes to the nearest interval
            const roundedTotalMinutes = Math.round(totalMinutes / interval) * interval;

            // Calculate the new hour and minute values
            const newHours = Math.floor(roundedTotalMinutes / 60);
            const newMinutes = roundedTotalMinutes % 60;

            // create a dajys object with the date and time

            let date = dayjs(tms.date).set("hour", newHours).set("minute", newMinutes);
            this.$emit("selectedDateAndTime", date);
        },

        mouseMove(tms) {
            if (this.is_over_event) {
                this.clearMoveEvent();
                return;
            }

            this.hoverDate = tms.date;

            // extract the minutes out of the time and round it to the nearest 15 minutes.
            const interval = 15;
            // Calculate total minutes from the beginning of the day
            const totalMinutes = tms.hour * 60 + tms.minute;
            // Round the total minutes to the nearest interval
            const roundedTotalMinutes = Math.round(totalMinutes / interval) * interval;

            // Calculate the new hour and minute values
            const newHours = Math.floor(roundedTotalMinutes / 60);
            const newMinutes = roundedTotalMinutes % 60;

            this.hoverTime = (newHours < 10 ? "0" : "") + newHours + ":" + (newMinutes < 10 ? "0" : "") + newMinutes;
            this.hoverTimePosition =
                this.$refs.calendar.timeToY({
                    hour: newHours,
                    minute: newMinutes,
                }) + "px";
        },

        setOverEvent() {
            this.is_over_event = true;
        },

        clearOverEvent() {
            this.is_over_event = false;
        },

        clearMove() {
            this.hoverDate = null;
            this.hoverTime = null;
            this.hoverTimePosition = null;
        },

        clearMoveEvent() {
            this.hoverDate = null;
            this.hoverTime = null;
            this.hoverTimePosition = null;
        },

        setToday() {
            this.value = "";
        },

        prev() {
            this.$refs.calendar.prev();
            this.$nextTick(() => {
                this.updateIntervalHeight();
            });
        },
        next() {
            this.$refs.calendar.next();
            this.$nextTick(() => {
                this.updateIntervalHeight();
            });
        },

        title() {
            return this.$refs.calendar.title;
        },

        getEventClass(event) {
            // You can add logic here to decide which event gets the striped background
            return event.isStriped ? "striped-background" : "";
        },
    },

    watch: {
        "editedItem.datum": function (newVal, oldVal) {
            this.value = newVal;
        },

        hide_holidays(newValue) {
            localStorage.setItem("hide_holidays", newValue);
        },
    },
};
</script>

<style scoped>
.v-current-time {
    height: 2px;
    background-color: #ea4335;
    position: absolute;
    left: -1px;
    right: 0;
    pointer-events: none;
}
.first::before {
    content: "";
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
}
.v-current-time-insert {
    position: absolute;
    left: 0;
    right: 0;
    pointer-events: none;
}
.preview-event {
    color: black;
}
.custom-event {
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: auto;
}

.custom-event .event-name {
    font-weight: bold;
    margin-right: 8px;
}

.custom-event .event-time {
    display: inline; /* or 'block' if you want the time to be on a new line for longer events */
    /* font-size: 0.875rem; */
}
</style>
