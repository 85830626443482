<template>
  <v-container fluid>

    <v-dialog persistent max-width="1000px" v-model="dialog_configure_bookings" eager>
      <v-card>
        <v-card-title>
          Online Termin-Anfragen konfigurieren
        </v-card-title>
        <v-card-text>
          <v-row class="d-flex align-center">
            <v-col>
              <v-switch v-model="booking_on_holidays" @change="toggledHolidayBookings" :loading="updating_holiday_state" :disabled="updating_holiday_state" inset label="An Feiertagen ebenso buchbar"
                :color="$store.state.theme.green"></v-switch>
            </v-col>
            <v-col class="d-flex align-center">
              <v-select label="Vorlaufzeit" class="mr-2" v-model="booking_advance" :items="booking_advance_options"
                item-text="text" item-value="value" outlined dense hide-details />
              <v-tooltip bottom open-delay="200">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                </template>
                Das ist die Zeit zwischen jetzt und dem frühestmöglichen Termin. Wenn zum Beispiel "1 Tag"
                eingestellt ist, kann der nächste Termin frühestens ab morgen zur gleichen Uhrzeit angefragt werden.
              </v-tooltip>
            </v-col>
            <v-col class="d-flex align-center">
              <v-select label="Maximale Zeit" class="mr-2" v-model="booking_timeframe"
                :items="booking_timeframe_options" item-text="text" item-value="value" outlined dense hide-details />
              <v-tooltip bottom open-delay="200">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                </template>
                Diese Zeit bestimmt, wie weit in die Zukunft eine Termin-Anfrage maximal gestellt werden kann. 
                Bei einer Einstellung von "4 Wochen" kann ein Termin innerhalb der nächsten 4 Wochen angefragt werden.
              </v-tooltip>
            </v-col>
          </v-row>
          <v-card outlined :disabled="!appointment_booking" class="mt-3">
            <v-tabs v-model="tab" dense show-arrows color="grey darken-4" :background-color="$store.state.theme.background_tabs">
              <v-tab>
                <span class="mr-2">Wiederkehrende Termine</span>
                <v-tooltip bottom open-delay="300">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                  </template>
                  Wiederkehrende Termine sind regelmäßige Termine. 
                  Du kannst den Wochentag, die Uhrzeit und die Dienstleistung für diese Termine festlegen. 
                  Falls zu der gewählten Zeit schon ein anderer Termin stattfindet, wird der wiederkehrende Termin automatisch übersprungen.
                </v-tooltip>
              </v-tab>
            </v-tabs>
            <v-card-text>
              <v-form v-model="valid" ref="form">
                  <v-tabs-items v-model="tab">
                    <v-tab-item>
                      <v-row v-for="(slot, index) in recurringSlots" :key="index" class="mt-3">
                        <v-col cols="3" class="my-0 py-0">
                          <v-select v-model="slot.tag" :items="days" :rules="dayRule" label="Tag" item-text="text" item-value="value" outlined dense />
                        </v-col>
                        <v-col cols="2" class="my-0 py-0">
                          <v-text-field dense outlined v-model="slot.uhrzeit" label="Uhrzeit" value="12:00:00" type="time" :rules="timeRule" />
                        </v-col>
                        <v-col cols="7" class="d-flex my-0 py-0">
                          <v-select v-model="slot.fk_dienstleistungs_id" :items="services" label="Dienstleistung auswählen"
                            item-text="bezeichnung" item-value="id" :rules="serviceRule" outlined dense class="mr-5" />

                          <v-tooltip bottom :open-delay="300">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn class="mr-2" icon v-if="slot.id" :color="$store.state.theme.primary" @click="toggleRecurringSlotState(slot, index)" 
                                :disabled="pausing_slot" v-bind="attrs" v-on="on">
                                <v-icon v-if="slot.inaktiv === null || slot.inaktiv === false">mdi-pause-circle</v-icon>
                                <v-icon v-else :color="$store.state.theme.green">mdi-play-circle</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ (slot.inaktiv === null || slot.inaktiv === false) ? 'Den wiederkehrenden Termin vorübergehend pausieren.' : 'Den wiederkehrenden Termin wieder aktivieren.' }}</span>
                          </v-tooltip>

                          <v-tooltip bottom :open-delay="300">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon @click="removeRecurringSlot(slot, index)" :color="$store.state.theme.red" :disabled="deleting_slot" v-bind="attrs" v-on="on">
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </template>
                            <span>Den wiederkehrenden Termin löschen.</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <!-- Wiederkehrende Termine -->
                      <v-row class="my-0 py-0">
                        <v-col cols="12" class="my-0 py-0">
                          <v-btn @click="addRecurringSlot()" rounded small elevation="0">
                            <v-icon left>mdi-plus</v-icon>
                            <span v-if="recurringSlots.length === 0">Neuer Termin</span>
                            <span v-else>Weiterer Termin</span>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </v-tabs-items>
              </v-form>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="mx-2 pb-5 mt-0 pt-0">
          <v-spacer></v-spacer>
          <v-btn @click="dialog_configure_bookings = false" text>
            Schließen
          </v-btn>
          <v-btn class="ml-3" @click="saveConfiguration" :disabled="!valid" :loading="saving_configuration" :color="$store.state.theme.green" outlined>
            Änderungen Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <BookingRequestDialog
      :dialog="bookingRequestDialog"
      :request="selectedBookingRequest"
      @close="bookingRequestDialog = false"
      @closeRequest="closeBookingRequest"
      @deleteRequest="deleteBookingRequest"
    />

    <div class="d-flex justify-center mb-3" v-if="$vuetify.breakpoint.smAndDown">
      <v-btn class="mr-4" @click="setToday" outlined>
        Heute
      </v-btn>
      <v-btn-toggle v-model="toggle_calendar_view" mandatory dense>
        <v-btn>
          4 Tage
        </v-btn>
        <v-btn>
          Woche
        </v-btn>
        <v-btn>
          Monat
        </v-btn>
      </v-btn-toggle>
    </div>

    <v-row>
      <v-col :cols="$vuetify.breakpoint.mdAndUp && showBookingRequests ? 8 : 12">
        <v-card width="100%">
          <v-toolbar flat :class="$vuetify.breakpoint.smAndDown ? 'px-3 mb-3 pt-1' : ''">
            <v-btn v-if="$vuetify.breakpoint.mdAndUp" class="mr-4" @click="setToday" outlined>
              Heute
            </v-btn>
            <v-btn-toggle v-if="$vuetify.breakpoint.mdAndUp" v-model="toggle_calendar_view" mandatory dense>
              <v-btn>
                4 Tage
              </v-btn>
              <v-btn>
                Woche
              </v-btn>
              <v-btn>
                Monat
              </v-btn>
            </v-btn-toggle>
            <v-spacer></v-spacer>
            <v-btn fab text small :color="$store.state.theme.primary" @click="prev">
              <v-icon>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-toolbar-title class="text-body-1 mx-4" v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-btn fab text small :color="$store.state.theme.primary" @click="next">
              <v-icon>
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <!-- <v-btn @click="writeCalenderFileToBucket('calendar.ics')" dark :color="$store.state.theme.primary" :icon="$vuetify.breakpoint.smAndDown"  elevation="1">
          <div class="hidden-sm-and-down">
              <v-icon left >mdi-calendar-export-outline</v-icon>
              Kalender exportieren
            </div>
            <div class="hidden-md-and-up">
              <v-icon >mdi-calendar-export-outline</v-icon>
            </div>
        </v-btn> -->
            <v-menu offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" color="black">
                  <v-icon>mdi-cog</v-icon>
                </v-btn>
              </template>

              <v-card>
                <v-card-text>
                  <p class="d-flex align-center text-h6 black--text mb-3">
                    <span class="mr-2">Angezeigter Zeitraum</span>
                    <v-tooltip bottom open-delay="300">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                      </template>
                      Hier kannst du den Anzeigebereich deines Kalenders anpassen.
                      Termine die außerhalb dieses Zeitraums liegen, werden im Kalender ganz oben angezeigt.
                    </v-tooltip>
                  </p>
                  <v-row class="my-0 py-0">
                    <v-col class="my-0 py-0">
                      <v-text-field dense outlined v-model="working_hours_start" label="Von" value="08:00:00"
                        type="time" :rules="timeRule" @change="updateIntervalHeight" />
                    </v-col>
                    <v-col class="my-0 py-0">
                      <v-text-field dense outlined v-model="working_hours_end" label="Bis" value="18:00:00" type="time"
                        :rules="timeRule" @change="updateIntervalHeight" />
                    </v-col>
                    <v-col cols="12" class="my-0 py-0">
                      <v-btn @click="saveWorkingHours" block outlined :color="$store.state.theme.green"
                        :disabled="working_hours_start === $store.state.client.kalender_start && working_hours_end === $store.state.client.kalender_ende"
                        :loading="updating_working_hours">
                        Änderungen Speichern
                      </v-btn>
                    </v-col>
                  </v-row>

                  <div class="d-flex align-center mt-3">
                    <v-switch class="mr-1" v-model="show_holidays" inset :color="$store.state.theme.green" />
                    <span class="text-h6 black--text mr-3" style="flex: 1">Feiertage anzeigen</span>
                  </div>
                  
                  <p class="text-h6 black--text my-3">Google Kalender Verknüpfung</p>

                  <div v-if="googleCalenderAuthState === 'synced'" class="d-flex flex-column mb-0 pb-0">
                    <div class="d-flex align-center">
                      <span class="mr-2">Verknüpft mit:</span>
                      <v-chip>
                        {{ $store.state.client.google_calendar }}
                      </v-chip>
                    </div>

                    <div class="d-flex mt-4 mb-5 align-center">
                      <span class="mr-4">Klienten anonymisieren:</span>
                      <v-btn-toggle v-model="anonymize_clients" mandatory dense>
                        <v-btn :color="anonymize_clients === 0 ? $store.state.theme.red : ''"
                          :disabled="loading_anonymize_clients"
                          :loading="loading_anonymize_clients && anonymize_clients === 0"
                          @click="saveAnonymizeClients(false)">
                          <span :class="anonymize_clients === 0 ? 'white--text' : ''">Nein</span>
                        </v-btn>
                        <v-btn :color="anonymize_clients === 1 ? $store.state.theme.green : ''"
                          :disabled="loading_anonymize_clients"
                          :loading="loading_anonymize_clients && anonymize_clients === 1"
                          @click="saveAnonymizeClients(true)">
                          <span :class="anonymize_clients === 1 ? 'white--text' : ''">Ja</span>
                        </v-btn>
                      </v-btn-toggle>
                    </div>

                    <v-btn @click="removeGoogleCalendar()" :disabled="!gapi_inited" block outlined
                      :color="$store.state.theme.red">
                      <v-icon left dark>mdi-sync-off</v-icon>
                      Verknüpfung trennen
                    </v-btn>
                  </div>
                  <p v-else-if="googleCalenderAuthState === 'authorized'" class="d-flex flex-column mb-0 pb-0">
                    <v-select @click="loadGoogleCalendars" v-model="selected_calendar" :loading="loading_calendars"
                      :items="calendars" item-text="name" item-value="id" label="Kalender" outlined dense return-object
                      clearable clear-icon="mdi-close-circle" class="mb-0 pb-0">
                      <template v-slot:item="{ item }">
                        <div class="d-flex align-center justify-center mr-2">
                          <div
                            :style="{ backgroundColor: item.color, borderRadius: '6px', width: '24px', height: '24px' }">
                          </div>
                        </div>
                        {{ item.name }}
                      </template>
                      <template v-slot:no-data>
                        <v-progress-circular class="ml-5 mr-2" indeterminate color="primary" size="15"
                          width="3"></v-progress-circular>
                        <span>Lade Kalender</span>
                      </template>
                    </v-select>
                    <v-btn :disabled="selected_calendar === null" :color="$store.state.theme.green" text
                      @click="saveAndLoadCalendar()" block>
                      Kalender verknüpfen
                    </v-btn>
                  </p>
                  <div v-else-if="googleCalenderAuthState === 'linked' || googleCalenderAuthState === 'unlinked'"
                    class="text-justify mb-0 pb-0">
                    <p>
                      Um deinen Google Kalender zu verknüpfen,<br />
                      musst du ZEIPSY die notwendigen Berechtigungen erteilen.<br />
                      Klicke auf den Button, um den Vorgang zu starten.
                    </p>
                    <v-btn class="gsi-material-button" @click="signInWithGoogle" elevation="1" block>
                      <div class="gsi-material-button-content-wrapper">
                        <div class="gsi-material-button-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                            version="1.1" id="Livello_1" x="0px" y="0px" viewBox="0 0 200 200"
                            enable-background="new 0 0 200 200" xml:space="preserve">
                            <g>
                              <g transform="translate(3.75 3.75)">
                                <path fill="#FFFFFF"
                                  d="M148.882,43.618l-47.368-5.263l-57.895,5.263L38.355,96.25l5.263,52.632l52.632,6.579l52.632-6.579    l5.263-53.947L148.882,43.618z" />
                                <path fill="#1A73E8"
                                  d="M65.211,125.276c-3.934-2.658-6.658-6.539-8.145-11.671l9.132-3.763c0.829,3.158,2.276,5.605,4.342,7.342    c2.053,1.737,4.553,2.592,7.474,2.592c2.987,0,5.553-0.908,7.697-2.724s3.224-4.132,3.224-6.934c0-2.868-1.132-5.211-3.395-7.026    s-5.105-2.724-8.5-2.724h-5.276v-9.039H76.5c2.921,0,5.382-0.789,7.382-2.368c2-1.579,3-3.737,3-6.487    c0-2.447-0.895-4.395-2.684-5.855s-4.053-2.197-6.803-2.197c-2.684,0-4.816,0.711-6.395,2.145s-2.724,3.197-3.447,5.276    l-9.039-3.763c1.197-3.395,3.395-6.395,6.618-8.987c3.224-2.592,7.342-3.895,12.342-3.895c3.697,0,7.026,0.711,9.974,2.145    c2.947,1.434,5.263,3.421,6.934,5.947c1.671,2.539,2.5,5.382,2.5,8.539c0,3.224-0.776,5.947-2.329,8.184    c-1.553,2.237-3.461,3.947-5.724,5.145v0.539c2.987,1.25,5.421,3.158,7.342,5.724c1.908,2.566,2.868,5.632,2.868,9.211    s-0.908,6.776-2.724,9.579c-1.816,2.803-4.329,5.013-7.513,6.618c-3.197,1.605-6.789,2.421-10.776,2.421    C73.408,129.263,69.145,127.934,65.211,125.276z" />
                                <path fill="#1A73E8"
                                  d="M121.25,79.961l-9.974,7.25l-5.013-7.605l17.987-12.974h6.895v61.197h-9.895L121.25,79.961z" />
                                <path fill="#EA4335"
                                  d="M148.882,196.25l47.368-47.368l-23.684-10.526l-23.684,10.526l-10.526,23.684L148.882,196.25z" />
                                <path fill="#34A853"
                                  d="M33.092,172.566l10.526,23.684h105.263v-47.368H43.618L33.092,172.566z" />
                                <path fill="#4285F4"
                                  d="M12.039-3.75C3.316-3.75-3.75,3.316-3.75,12.039v136.842l23.684,10.526l23.684-10.526V43.618h105.263    l10.526-23.684L148.882-3.75H12.039z" />
                                <path fill="#188038"
                                  d="M-3.75,148.882v31.579c0,8.724,7.066,15.789,15.789,15.789h31.579v-47.368H-3.75z" />
                                <path fill="#FBBC04"
                                  d="M148.882,43.618v105.263h47.368V43.618l-23.684-10.526L148.882,43.618z" />
                                <path fill="#1967D2"
                                  d="M196.25,43.618V12.039c0-8.724-7.066-15.789-15.789-15.789h-31.579v47.368H196.25z" />
                              </g>
                            </g>
                          </svg>
                        </div>
                        <span class="gsi-material-button-contents ml-2">Google Kalender verbinden</span>
                      </div>
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-menu>

            <v-badge
              :content="openBookingRequestsCount"
              :value="openBookingRequestsCount"
              color="red"
              bordered
              offset-x="20"
              offset-y="20"
              v-if="$store.state.client.beta_circle"
            >
              <v-btn @click="toggleBookingRequests" icon color="black" class="ml-2">
                <v-icon>mdi-calendar-account</v-icon>
              </v-btn>
            </v-badge>

            <!-- <v-btn v-show="$vuetify.breakpoint.mdAndUp" class="ml-5" dark :color="$store.state.theme.green"
              @click="newItem()" :icon="$vuetify.breakpoint.smAndDown" elevation="1">
              <v-icon left dark>mdi-plus</v-icon>
              Neuer Termin
            </v-btn> -->

          </v-toolbar>
          <v-fab-transition>
            <v-btn :color="$store.state.theme.green" dark fixed bottom right fab @click="newItem()">
              <v-tooltip left open-delay="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-plus</v-icon>
                </template>
                <span>Einen neuen Termin erstellen</span>
              </v-tooltip>
            </v-btn>
          </v-fab-transition>

          <v-dialog v-model="redirectDialog" persistent max-width="500px">
            <v-card>
              <v-card-title class="text-h6">Weiterleitung</v-card-title>
              <v-card-text class="text-body-1 text-justify">
                Dein Google-Kalender Zugriff ist abgelaufen. Bitte klicke auf Erneut verbinden, um den Zugriff zu
                erneuern.
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :color="$store.state.theme.primary" text @click="signInWithGoogle">Erneut verbinden</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialog" persistent max-width="1000px">
            <DialogTermin :session="session" :google_appointments="google_appointments" :editedIndex="editedIndex"
              :editedItem="editedItem" :defaultItem="defaultItem" :dialog="dialog" :appointments="appointments"
              @close="close" @refreshAndClose="refreshAndClose" @updateAndClose="updateAndClose"
              @showError="$emit('showError', $event)" />
          </v-dialog>
          <v-sheet ref="calendarSheet" :height="computedHeight" elevation="0">
            <v-calendar ref="calendar" v-model="value" :weekdays="weekdays" :events="events" :type="calendarType"
              v-touch="{ left: () => next(), right: () => prev() }" @click:event="editItem" @click:date="navigateToWeek"
              @mousedown:time="startTime" @mousemove:time="mouseMove" @mouseleave:day="clearMove"
              @mouseleave:day-category="clearMove" @mouseleave:time="clearMove" @mouseenter:event="setOverEvent"
              @mouseleave:event="clearOverEvent" :first-time="workingHoursStart" :interval-count="intervalCount"
              :interval-height="interval_height">
              <template v-slot:day-body="{ date, week }">
                <div class="v-current-time" :class="{ first: date === week[0].date }" :style="{ top: nowY }"></div>
                <v-fade-transition>
                  <v-sheet v-if="ready && hoverDate && hoverDate === date && $vuetify.breakpoint.smAndUp"
                    class="v-current-time-insert pl-1" color="grey lighten-4" rounded elevation="0"
                    :style="{ top: hoverTimePosition }">
                    <v-icon left>mdi-plus</v-icon>{{ hoverTime }}
                  </v-sheet>
                </v-fade-transition>
              </template>
              <template v-slot:event="{ event }">
                <div
                    :class="'custom-event' + (event.preview ? ' preview-event' : '')"
                    :style="{
                        borderRadius: '4px',
                        backgroundColor: event.color,
                        border: event.color && event.preview ? '2px dashed ' + event.borderColor : '',
                    }"
                    style="height: 100%">
                    <strong :class="'event-name'">{{ event.name }}</strong>
                    <div class="event-time" v-if="event.end && event.start !== event.end">
                        {{ new Date(event.start).getHours() }}:{{ String(new Date(event.start).getMinutes()).padStart(2, "0") }} -
                        {{ new Date(event.end).getHours() }}:{{ String(new Date(event.end).getMinutes()).padStart(2, "0") }}
                    </div>
                </div>
            </template>
            </v-calendar>
          </v-sheet>
        </v-card>
      </v-col>
      <v-col v-show="$vuetify.breakpoint.mdAndUp && showBookingRequests" cols="4">
        <v-slide-x-transition>
          <v-card v-show="showBookingRequests" class="booking-requests-sidebar">
            <v-card-title class="d-flex justify-space-between">
              <span>Termin-Anfragen</span>
              <v-btn icon @click="toggleBookingRequests">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <div class="d-flex align-center justify-start">
                <v-switch class="mr-1" v-model="appointment_booking" inset :color="$store.state.theme.green" @change="updateAppointmentBookingState" :loading="updating_appointment_booking_state" />
                <span class="d-flex align-center text-body-1 mr-3" style="flex: 1">
                  <span class="mr-2">Termin-Anfragen aktivieren</span>
                  <v-tooltip bottom open-delay="300">
                    <template v-slot:activator="{ on, attrs }" color="black">
                      <v-icon v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <span>
                      Hier kannst du die Online Termin-Anfragen für Klienten aktivieren & deaktivieren.
                      Wenn du die Termin-Anfragen aktivierst, können Klienten unkompliziert online Termine anfragen.
                    </span>
                  </v-tooltip>
                </span>
              </div>
              <v-list>
                <v-list-item @click="showBookingConfiguration">
                  <v-list-item-icon>
                    <v-icon>mdi-cog</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Termin-Anfragen konfigurieren
                    </v-list-item-title>
                </v-list-item>
                <v-list-group
                  :value="openBookingRequestsCount > 0"
                  prepend-icon="mdi-calendar-badge"
                >
                  <template v-slot:activator>
                    <v-list-item-title class="text-body-1">Offene Anfragen</v-list-item-title>
                    <v-badge class="ml-2" inline :content="openBookingRequestsCount.toString()" :color="$store.state.theme.red" />
                  </template>
                  <v-list-item v-for="request in openBookingRequests" :key="'request-' + request.id" @click="openBookingRequest(request)">
                    <v-list-item-content>
                      <v-list-item-title>
                        <span class="font-weight-medium">{{ formatDate(request.datum) }} {{ formatTime(request.datum) }}</span><br/>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ request.bezeichnung }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <v-icon small left>
                          mdi-account
                        </v-icon>
                        {{ request.decryptedDetails ? request.decryptedDetails.vorname + ' ' + request.decryptedDetails.nachname : 'Unbekannt' }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="request.decryptedDetails && request.decryptedDetails.message">
                        <v-icon small left>
                          mdi-chat-outline
                        </v-icon>
                        {{ request.decryptedDetails.message }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="openBookingRequestsCount === 0">
                    <v-list-item-content>
                      <v-list-item-title>
                        Bisher keine Anfragen
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group> 
                <v-list-group
                  :value="false"
                  prepend-icon="mdi-calendar-check"
                >
                  <template v-slot:activator>
                    <v-list-item-title>Abgeschlossene Anfragen</v-list-item-title>
                    <v-badge class="ml-2" inline :content="closedBookingRequestsCount.toString()" :color="$store.state.theme.primary" />
                  </template>
                  <v-list-item v-for="request in closedBookingRequests" :key="'request-' + request.id" @click="openBookingRequest(request)">
                    <v-list-item-content>
                      <v-list-item-title>
                        <span class="font-weight-medium">{{ formatDate(request.datum) }} {{ formatTime(request.datum) }}</span><br/>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ request.bezeichnung }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <v-icon small left>
                          mdi-account
                        </v-icon>
                        {{ request.decryptedDetails ? request.decryptedDetails.vorname + ' ' + request.decryptedDetails.nachname : 'Unbekannt' }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="request.decryptedDetails && request.decryptedDetails.message">
                        <v-icon small left>
                          mdi-chat-outline
                        </v-icon>
                        {{ request.decryptedDetails.message }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="closedBookingRequestsCount === 0">
                    <v-list-item-content>
                      <v-list-item-title>
                        Bisher keine Anfragen
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list>
            </v-card-text>
          </v-card>
        </v-slide-x-transition>
      </v-col>
    </v-row>

    <!-- Dialog for Termin-Anfragen on smAndDown screens -->
    <v-dialog v-model="showBookingRequestsDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark :color="$store.state.theme.primary">
          <v-btn icon dark @click="showBookingRequestsDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Termin-Anfragen</v-toolbar-title>
        </v-toolbar>
        <v-list>
          <v-list-item @click="showBookingConfiguration">
            <v-list-item-icon>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              Termin-Anfragen konfigurieren
              </v-list-item-title>
          </v-list-item>
          <v-list-group
            :value="openBookingRequestsCount > 0"
            prepend-icon="mdi-calendar-badge"
          >
            <template v-slot:activator>
              <v-list-item-title class="text-body-1">Offene Anfragen</v-list-item-title>
              <v-badge class="ml-2" inline :content="openBookingRequestsCount.toString()" :color="$store.state.theme.red" />
            </template>
            <v-list-item v-for="request in openBookingRequests" :key="'request-' + request.id" @click="openBookingRequest(request)">
              <v-list-item-content>
                <v-list-item-title>
                  <span class="font-weight-medium">{{ formatDate(request.datum) }} {{ formatTime(request.datum) }}</span><br/>
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ request.bezeichnung }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-icon small left>
                    mdi-account
                  </v-icon>
                  {{ request.decryptedDetails ? request.decryptedDetails.vorname + ' ' + request.decryptedDetails.nachname : 'Unbekannt' }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="request.decryptedDetails && request.decryptedDetails.message">
                  <v-icon small left>
                    mdi-chat-outline
                  </v-icon>
                  {{ request.decryptedDetails.message }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="openBookingRequestsCount === 0">
              <v-list-item-content>
                <v-list-item-title>
                  Bisher keine Anfragen
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group> 
          <v-list-group
            :value="false"
            prepend-icon="mdi-calendar-check"
          >
            <template v-slot:activator>
              <v-list-item-title>Abgeschlossene Anfragen</v-list-item-title>
              <v-badge class="ml-2" inline :content="closedBookingRequestsCount.toString()" :color="$store.state.theme.primary" />
            </template>
            <v-list-item v-for="request in closedBookingRequests" :key="'request-' + request.id" @click="openBookingRequest(request)">
              <v-list-item-content>
                <v-list-item-title>
                  <span class="font-weight-medium">{{ formatDate(request.datum) }} {{ formatTime(request.datum) }}</span><br/>
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ request.bezeichnung }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-icon small left>
                    mdi-account
                  </v-icon>
                  {{ request.decryptedDetails ? request.decryptedDetails.vorname + ' ' + request.decryptedDetails.nachname : 'Unbekannt' }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="request.decryptedDetails && request.decryptedDetails.message">
                  <v-icon small left>
                    mdi-chat-outline
                  </v-icon>
                  {{ request.decryptedDetails.message }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="closedBookingRequestsCount === 0">
              <v-list-item-content>
                <v-list-item-title>
                  Bisher keine Anfragen
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-card>
    </v-dialog>

    <v-dialog v-model="show_calendar_url" width="800">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Dein persönlicher Kalender
        </v-card-title>

        <v-container class="px-5">
          <p class="text-justify">
            Du kannst deinen persönlichen Kalender mit deinen Terminen in deinem Kalenderprogramm (z.B. Apple Kalender,
            Google Kalender) importieren.
          </p>
          <v-card-actions>
            <v-btn :href="calendar_url" :color="$store.state.theme.primary" dark>
              <v-icon left>mdi-calendar-plus-outline</v-icon>
              Kalender abonnieren
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn :href="'https://calendar.google.com/calendar/r?cid=' + calendar_url" target="_blank"
              :color="$store.state.theme.primary" dark>
              <v-icon left>mdi-calendar-plus-outline</v-icon>
              In Google-Kalender abonnieren
            </v-btn>
          </v-card-actions>
          <p class="mt-5">
            Alternativ kannst du mit folgendem Link deinen Kalender manuell importieren.
            <br>
            <b>Wichtig:</b> Teile diesen Link nicht mit anderen Personen. Jeder mit diesem Link kann deinen Kalender
            einsehen.
          </p>
          <v-text-field dense outlined v-model="calendar_url"></v-text-field>

        </v-container>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :color="$store.state.theme.green" text @click="show_calendar_url = false">
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import connector from '../helpers/supabase-connector.js'
import DialogTermin from '../components/DialogTermin.vue'
import moment from 'moment'
import cipher from '@/helpers/cipher'
import * as ics from 'ics'
import { supabase } from '../supabase'
import levenshtein from 'fast-levenshtein'
import Holidays from 'date-holidays'
import dayjs from 'dayjs'
import 'dayjs/locale/de'
import BookingRequestDialog from '../components/BookingRequestDialog.vue'


export default {
  name: 'Kalender',
  props: ['session'],
  components: { DialogTermin, BookingRequestDialog },

  data() {
    return {

      tab: 0,
      updating_holiday_state: false,
      saving_configuration: false,

      updating_appointment_booking_state: false,
      dialog_configure_bookings: false,
      appointment_booking: false,
      deleting_slot: false,
      pausing_slot: false,

      showBookingRequests: false,
      showBookingRequestsDialog: false,

      bookingRequests: [],

      selectedBookingRequest: null,
      bookingRequestDialog: false,

      working_hours_start: this.$store.state.client.kalender_start || "00:00",
      working_hours_end: this.$store.state.client.kalender_ende || "23:00",
      interval_height: 48,
      updating_working_hours: false,

      anonymize_clients: false,
      loading_anonymize_clients: false,

      color_mapping_events: {
        "1": "#7986CB",
        "2": "#33B679",
        "3": "#8E24AA",
        "4": "#E67C73",
        "5": "#F6BF26",
        "6": "#F4511E",
        "7": "#039BE5",
        "8": "#616161",
        "9": "#3F51B5",
        "10": "#0B8043",
        "11": "#D50000"
      },

      color_mapping_calendars: {
        "1": "#795548",   // Cocoa
        "2": "#E67C73",   // Flamingo
        "3": "#D50000",   // Tomato
        "4": "#F4511E",   // Tangerine
        "5": "#EF6C00",   // Pumpkin
        "6": "#F09300",   // Mango
        "7": "#009688",   // Eucalyptus
        "8": "#0B8043",   // Basil
        "9": "#7CB342",   // Pistachio
        "10": "#C0CA33",  // Avocado
        "11": "#E4C441",  // Citron
        "12": "#F6BF26",  // Banana
        "13": "#33B679",  // Sage
        "14": "#039BE5",  // Peacock
        "15": "#4285F4",  // Cobalt
        "16": "#3F51B5",  // Blueberry
        "17": "#7986CB",  // Lavender
        "18": "#B39DDB",  // Wisteria
        "19": "#616161",  // Graphite
        "20": "#A79B8E",  // Birch
        "21": "#AD1457",  // Radicchio
        "22": "#D81B60",  // Cherry Blossom
        "23": "#8E24AA",  // Grape
        "24": "#9E69AF"   // Amethyst
      },

      hoverDate: null,
      hoverTime: null,
      hoverTimePosition: null,
      is_over_event: false,

      profil_id: null,
      booking_advance: null,
      booking_advance_options: [
        { text: '3 Stunden', value: 3 },
        { text: '6 Stunden', value: 6 },
        { text: '12 Stunden', value: 12 },
        { text: '1 Tag', value: 24 },
        { text: '2 Tage', value: 48 },
        { text: '3 Tage', value: 72 },
      ],

      booking_timeframe: null,
      booking_timeframe_options: [
        { text: '1 Woche', value: 7 },
        { text: '2 Wochen', value: 14 },
        { text: '3 Wochen', value: 21 },
        { text: '4 Wochen', value: 28 },
        { text: '5 Wochen', value: 35 },
        { text: '6 Wochen', value: 42 },
        { text: '7 Wochen', value: 49 },
        { text: '8 Wochen', value: 56 },
        { text: '9 Wochen', value: 63 },
        { text: '10 Wochen', value: 70 },
        { text: '11 Wochen', value: 77 },
        { text: '12 Wochen', value: 84 },
      ],

      booking_on_holidays: false,
      
      valid: false,
      days: [
        { text: 'Montags', value: 1 },
        { text: 'Dienstags', value: 2 },
        { text: 'Mittwochs', value: 3 },
        { text: 'Donnerstags', value: 4 },
        { text: 'Freitags', value: 5 },
        { text: 'Samstags', value: 6 },
        { text: 'Sonntags', value: 0 },
      ],
      recurringSlots: [],
      originalRecurringSlots: [],
      services: [],
      timeRule: [
        v => !!v || 'Uhrzeit wird benötigt',
        v => /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9](?::\d{2})?$/.test(v) || 'Uhrzeit muss das Format HH:MM haben.',
      ],

      serviceRule: [
        v => !!v || 'Dienstleistung wird benötigt',
      ],

      dayRule: [
        v => !!v || 'Wochentag wird benötigt',
      ],

      show_holidays: true,
      loading_calendars: false,
      toggle_calendar_view: 1,
      redirectDialog: false,
      gapi: null,
      gapi_inited: false,
      gapi_reload_count: 0,
      linked_google_account: false,

      weekdays: [1, 2, 3, 4, 5, 6, 0],
      appointments: [],
      google_appointments: [],
      today: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split('T')[0],
      value: '',
      ready: false,
      loading: false,
      n_appointments: 0,

      show_calendar_url: false,
      calendar_url: null,
      calendars: [],
      selected_calendar: null,

      dialog: false,
      editedIndex: -1,
      editedItem: {
        id: null,
        uid: this.session.user.id,
        fk_klienten_id: null,
        fk_rechnungs_id: null,
        selected: null,
        vorname: null,
        nachname: null,
        uhrzeit: "12:00",
        datum: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString(),
        datumFormatted: dayjs(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).format('DD.MM.YYYY'),
        preis: this.$store.state.client.std_preis,
        dauer: this.$store.state.client.std_dauer,
        bezeichnung: null,
        ust_befreiung: null,
        fk_dienstleistung: null,
        termin_erinnerung: null,
        erinnerung_gesendet: null,
      },
      defaultItem: {
        id: null,
        uid: this.session.user.id,
        fk_klienten_id: null,
        fk_rechnungs_id: null,
        selected: null,
        vorname: null,
        nachname: null,
        uhrzeit: "12:00",
        datum: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString(),
        datumFormatted: dayjs(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).format('DD.MM.YYYY'),
        preis: this.$store.state.client.std_preis,
        dauer: this.$store.state.client.std_dauer,
        bezeichnung: null,
        ust_befreiung: null,
        fk_dienstleistung: null,
        termin_erinnerung: null,
        erinnerung_gesendet: null,
      },
      customers: [],
    }
  },

  computed: {

    openBookingRequestsCount() {
      return this.openBookingRequests.length;
    },

    closedBookingRequestsCount() {
      return this.closedBookingRequests.length;
    },

    openBookingRequests() {
      return this.bookingRequests.filter(request => !request.closed);
    },

    closedBookingRequests() {
      return this.bookingRequests.filter(request => request.closed);
    },

    intervalCount() {
      const startHour = parseInt(this.workingHoursStart.split(':')[0]);
      const endHour = parseInt(this.working_hours_end.split(':')[0]);
      return (endHour - startHour) + 1;
    },

    workingHoursStart() {
      if (this.working_hours_start.includes(':')) {
        let start_hour = parseInt(this.working_hours_start.split(':')[0]);
        if (start_hour > 0) {
          return `${start_hour - 1}:00`;
        } else {
          return `${start_hour}:00`;
        }
      }
      return this.working_hours_start;
    },

    googleCalenderAuthState() {
      let linked_google_account = this.linked_google_account;
      let linked_google_calendar = this.$store.state.client.google_calendar;
      let provider_refresh_token = localStorage.provider_refresh_token;

      if (provider_refresh_token && linked_google_calendar) {
        return 'synced';
      } else if (provider_refresh_token && !linked_google_calendar) {
        return 'authorized';
      } else if (linked_google_account) {
        return 'linked';
      } else {
        return 'unlinked';
      }
    },

    computedHeight() {
      if (this.$vuetify.breakpoint.xs) {
        return '73vh'
      } else if (this.$vuetify.breakpoint.sm) {
        return '75vh'
      } else if (this.$vuetify.breakpoint.md) {
        return '75vh'
      } else if (this.$vuetify.breakpoint.lg) {
        return '85vh'
      } else if (this.$vuetify.breakpoint.xl) {
        return '85vh'
      }
    },

    events() {
      let ids_in_google = this.google_appointments.map((appointment) => {
        return appointment.id
      }).filter((id) => id !== null)

      let appointment_ids = this.appointments.map((appointment) => {
        return appointment.id
      })

      let holidays = [];
      if (this.show_holidays) {
        holidays = this.getHolidays();
      }

      return this.appointments.map((appointment) => {
        let original_appointment = Object.assign({}, appointment);
        let appointment_name = appointment.nachname + ' ' + appointment.vorname;

        original_appointment.name = appointment_name;
        original_appointment.start = dayjs(appointment.datum).format('YYYY-MM-DDTHH:mm');
        original_appointment.end = dayjs(appointment.datum).add(appointment.dauer, 'm').format('YYYY-MM-DDTHH:mm');

        // check if end time is outside of the visible area (stored in working_hours_end as "HH:mm"), if so show as daily event.
        // Create a Day.js object for the end of the working hours on the same day as the appointment.
        const currentWorkingHoursStart = dayjs(appointment.datum)
          .format('YYYY-MM-DD')
          .concat('T', this.workingHoursStart);

        const currentWorkingHoursEnd = dayjs(appointment.datum)
          .format('YYYY-MM-DD')
          .concat('T', this.working_hours_end);

        // check if end time is outside of the visible area 
        if (dayjs(original_appointment.start).isAfter(currentWorkingHoursEnd)) {
          original_appointment.start = dayjs(appointment.datum).format('YYYY-MM-DD');
          delete original_appointment.end;
        } else if (dayjs(original_appointment.end).isBefore(currentWorkingHoursStart)) {
          original_appointment.start = dayjs(appointment.datum).format('YYYY-MM-DD');
          delete original_appointment.end;
        }

        if (appointment.farbe) {
          original_appointment.color = appointment.farbe;
        } else {
          original_appointment.color = ids_in_google.includes(original_appointment.id) ? this.$store.state.theme.green : this.$store.state.theme.primary
        }
        return original_appointment
      }).concat(
        this.google_appointments.filter((google_appointment) => google_appointment.id === null || !appointment_ids.includes(google_appointment.id)).map((google_appointment) => {
          let original_appointment = Object.assign({}, google_appointment);

          // check if google_calendar_color is stored in localStore, if yes, use it as color, if not use default color
          let google_calendar_color = localStorage.getItem('google_calendar_color');
          if (google_calendar_color && !original_appointment.color) {
            original_appointment.color = google_calendar_color;
          }

          return original_appointment
        })
      ).concat(holidays).concat(
        this.bookingRequests.map((request) => {
          const color = this.services.find((service) => service.id === request.fk_dienstleistungs_id)?.farbe;
          return {
            id: request.id,
            name: request.decryptedDetails.nachname + ' ' +  request.decryptedDetails.vorname ,
            start: dayjs(request.datum).format('YYYY-MM-DDTHH:mm'),
            end: dayjs(request.datum).add(request.dauer, 'm').format('YYYY-MM-DDTHH:mm'),
            color: color ? this.hexToRGBA(color, 0.15) : this.hexToRGBA(this.$store.state.theme.primary, 0.15),
            borderColor: color ? color : this.$store.state.theme.primary,
            type: 'request',
            preview: true,
          }
        })
      );
    },
    cal() {
      return this.ready ? this.$refs.calendar : null
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
    },

    calendarType() {
      if (this.toggle_calendar_view === 0) {
        return '4day'
      } else if (this.toggle_calendar_view === 1) {
        return 'week'
      } else {
        return 'month'
      }
    }
  },

  beforeDestroy() {
    // Remove the event listener when the component is destroyed
    window.removeEventListener('resize', this.updateIntervalHeight);
  },

  async mounted() {

    this.updateIntervalHeight();
    window.addEventListener('resize', this.updateIntervalHeight);

    this.gapi = window.gapi;

    if (this.$vuetify.breakpoint.xsOnly) {
      // default to 4 day view on mobile
      this.toggle_calendar_view = 0;
    }

    this.linked_google_account = await this.listLinkedIdentities();

    // check if gapi client is already loaded
    if (this.gapi.client) {
      // already loaded
      this.gapi_inited = true;
      this.initialize();
    } else {
      this.gapi.load('client', this.initializeGapiClient);
    }
  },

  watch: {
    show_holidays(newValue) {
      if (newValue) localStorage.setItem('hide_holidays', 0);
      else localStorage.setItem('hide_holidays', 1);
    },

    '$vuetify.breakpoint.smAndDown': {
      immediate: true,
      handler(smAndDown) {
        // console.log("smAndDown", smAndDown, "showBookingRequest", this.showBookingRequests);
        if (smAndDown && this.showBookingRequests) {
          this.showBookingRequests = false;
          this.showBookingRequestsDialog = true;
          // console.log("showing dialog")
        } else if (!smAndDown && this.showBookingRequestsDialog) {
          this.showBookingRequestsDialog = false;
          this.showBookingRequests = true;
          // console.log("showing sidebar")
        }
        // in all other cases, do nothing^
      }
    },

  },

  methods: {

    async toggledHolidayBookings(state) {

      this.updating_holiday_state = true;

      let updated = await connector.update(this, 'buchungsprofile', {
        buchbare_feiertage: state
      }, this.session.user.id);

      if (updated === null) {
        // error has already been shown
        // revert state of toggle.
        this.booking_on_holiday = !state;
      } else {
        this.$emit('showInfo', {
          message: state ? 'Termin-Anfragen an Feiertagen erfolgreich aktiviert.' : 'Termin-Anfragen an Feiertagen erfolgreich deaktiviert.',
          timeout: 5000
        });
      }

      this.updating_holiday_state = false;

    },

    async showBookingConfiguration() {

      let profiles = await connector.getDataOnly(this, 'buchungsprofile', 'inserted_at', false);
      if (profiles !== -1 && profiles.length > 0) {
        this.profil_id = profiles[0].profil_id;
        this.appointment_booking = profiles[0].aktiv === true;
        this.booking_advance = profiles[0].vorlaufzeit;
        this.booking_timeframe = profiles[0].nachlaufzeit;
        this.booking_on_holidays = profiles[0].buchbare_feiertage === true;
      }

      // if there are no reccuringSlots, add a template slot
      if (this.recurringSlots.length === 0) {
        this.addRecurringSlot();
      }

      this.dialog_configure_bookings = true;
    },

    hexToRGBA(hex, opacity) {
        let r = parseInt(hex.substring(1, 3), 16);
        let g = parseInt(hex.substring(3, 5), 16);
        let b = parseInt(hex.substring(5, 7), 16);

        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    },

    openBookingRequest(request) {
      this.selectedBookingRequest = request;
      this.bookingRequestDialog = true;
    },

    async closeBookingRequest(request) {

      // check if aes_key_file is set, if not then try to load it again
      if (!this.$store.state.aes_key_file) {
          let keys = await cipher.getAESKeys(this);
          this.$store.state.aes_key_file = keys['aes_key_file'];
      }

      // encrypt the details with the local user key
      let encrypted_details = await cipher.encryptObject(this.$store.state.aes_key_file, request.decryptedDetails);

      // 6. Prepare JSON object for JSONB column
      const jsonbData = encrypted_details;

      let updated = await connector.update(this, 'buchungsanfragen', {
        details: jsonbData,
        encrypted_key: {},
        closed: true,
      }, request.id);

      if (updated) {
        this.bookingRequestDialog = false;
        this.fetchBookingRequests();
        this.$emit('showInfo', { 
          message: 'Die Termin-Anfrage wurde erfolgreich geschlossen.',
          timeout: 5000
        });
      }
    },

    async deleteBookingRequest(id) {
        let deleted = await connector.delete(this, 'buchungsanfragen', 'id', id);

        if (deleted) {
          this.bookingRequestDialog = false;
          this.fetchBookingRequests();
          this.$emit('showInfo', { 
            message: 'Die Termin-Anfrage wurde erfolgreich gelöscht.',
            timeout: 5000
          });
        }
    },

    toggleBookingRequests() {
      this.showBookingRequests = !this.showBookingRequests;
      if (this.$vuetify.breakpoint.smAndDown) {
        this.showBookingRequestsDialog = !this.showBookingRequestsDialog;
      }
      if (this.showBookingRequests || this.showBookingRequestsDialog) {
        this.fetchBookingRequests();
      }
    },

    async fetchBookingRequests() {
      try {
        let { data, error } = await supabase.functions.invoke('get-bookings', {
          body: {
            'type': 'getBookingRequests'
          }
        });

        if (error) throw error;

        // check if aes_key_file is set, if not then try to load it again
        if (!this.$store.state.aes_key_file) {
            let keys = await cipher.getAESKeys(this);
            this.$store.state.aes_key_file = keys['aes_key_file'];
        }

        // Decrypt the details for each booking request
        const decryptedRequests = await Promise.all(data.map(async (request) => {

          // check if it is decrypted with user key or sever key
          if (request.decrypted_key === null) {
            // user key
            const decryptedDetails = await cipher.decryptObject(this, this.$store.state.aes_key_file, request.details);
            return { ...request, decryptedDetails };
          } else {
            // server key
            const decryptedDetails = await cipher.decryptBookingDetails(request.details, request.decrypted_key);
            return { ...request, decryptedDetails };
          }
        }));

        this.bookingRequests = decryptedRequests;
      } catch (error) {
        console.error('Error fetching booking requests:', error);
        this.$emit('showError', { 
          message: 'Fehler beim Laden der Termin-Anfragen.', 
          timeout: 10000 
        });
      }
    },

    formatDate(dateString) {
      return dayjs(dateString).locale('de').format('dddd, DD.MM.YYYY');
    },

    formatTime(dateString) {
      return dayjs(dateString).format('HH:mm');
    },

    handleBookingRequest(request) {
      // Implement the logic to handle a booking request
      // For example, open a dialog to accept or reject the request
      console.log('Handling booking request:', request);
    },

    async saveWorkingHours() {

      this.updating_working_hours = true;

      let updated = await connector.update(this, 'kunden', {
        'kalender_start': this.working_hours_start,
        'kalender_ende': this.working_hours_end
      }, this.session.user.id);

      if (updated) {
        this.$store.state.client.kalender_start = this.working_hours_start;
        this.$store.state.client.kalender_ende = this.working_hours_end;
      }

      this.updating_working_hours = false;

    },

    updateIntervalHeight() {

      if (!this.$refs.calendarSheet) {
        this.interval_height = 48;
        return;
      }

      const header = this.$refs.calendar.$el.querySelector('.v-calendar-daily__head');
      // Get the header height
      let headerHeight = 100;

      if (header) {
        headerHeight = header.offsetHeight;
      }

      // Access the height directly from the ref
      const sheetHeight = this.$refs.calendarSheet.$el.clientHeight - headerHeight - 10;

      // Calculate the desired number of intervals to display
      const startHour = parseInt(this.workingHoursStart.split(':')[0]);
      const endHour = parseInt(this.working_hours_end.split(':')[0]);
      const numIntervals = endHour - startHour + 1;

      // Calculate and return the dynamic interval height
      this.interval_height = Math.max(Math.floor(sheetHeight / numIntervals), 48);
      return;
    },

    addRecurringSlot() {
      this.recurringSlots.push({
        day: null,
        time: null,
        service: null,
        menu: false,
      });
      this.$nextTick(() => {
        if (this.$refs.form) this.$refs.form.validate(true);
      })
    },

    async removeRecurringSlot(slot, index) {
      // check if the slot is already stored in the db, if not remove it directly.
      // this is for cases when a new slot is added, but not stored, and then removed again
      if (!('id' in slot)) {
        // just remove it and return
        this.recurringSlots.splice(index, 1);
        return;
      }

      this.deleting_slot = true;
      let deleted = await connector.delete(this, 'buchungstermine', 'id', slot.id);
      if (deleted) this.recurringSlots.splice(index, 1);
      this.deleting_slot = false;
      this.$emit('showInfo', {
        message: 'Der wiederkehrende Termin wurde erfolgreich gelöscht.',
        timeout: 5000
      })
    },

    async toggleRecurringSlotState(slot, index) {
      this.pausing_slot = true;

      let state = true;
      if (slot.inkativ === null) {
        state = true;
      } else if (slot.inaktiv === true) {
        state = false;
      }

      let updated = await connector.update(this, 'buchungstermine', {
        inaktiv: state
      }, slot.id);

      if (updated) {
        this.$set(this.recurringSlots[index], 'inaktiv', state);
        this.$emit('showInfo', {
          message: ' Der wiederkehrende Termin wurde erfolgreich ' + (state ? 'deaktiviert' : 'aktiviert') + '.',
          timeout: 5000
        });
      }
      this.pausing_slot = false;
    },

    async updateAppointmentBookingState() {
      this.updating_appointment_booking_state = true;

      let updated = await connector.update(this, 'buchungsprofile', {
        'aktiv': this.appointment_booking,
      }, this.session.user.id);

      if (updated) {
        this.$emit('showInfo', {
          message: 'Die Online Termin-Anfragen wurden erfolgreich ' + (this.appointment_booking ? 'aktiviert.' : 'deaktiviert.'),
          timeout: 5000
        });
      }
      
      this.updating_appointment_booking_state = false;
    },

    async saveConfiguration() {

      // save bookingAdvace etc.

      this.saving_configuration = true;

      let updated = await connector.update(this, 'buchungsprofile', {
        vorlaufzeit: this.booking_advance,
        nachlaufzeit: this.booking_timeframe,
        buchbare_feiertage: this.booking_on_holidays
      }, this.session.user.id);

      if (updated === null) {
        // error has already been displayed
        this.saving_configuration = false;
        return;
      }

      let error_during_upserts = false;

      // iterate over all recuring slots and check if they are modified (using originalRecurringSlots) or new and save them
      await Promise.all(this.recurringSlots.map(async (slot) => {
        if (slot.id) {
          // update
          let originalSlot = this.originalRecurringSlots.find((originalSlot) => originalSlot.id === slot.id);
          if (originalSlot.tag !== slot.tag || originalSlot.uhrzeit !== slot.uhrzeit || originalSlot.fk_dienstleistungs_id !== slot.fk_dienstleistungs_id) {
            // update
            let updated_appointment_slot = await connector.update(this, 'buchungstermine', {
              fk_dienstleistungs_id: slot.fk_dienstleistungs_id,
              tag: slot.tag,
              uhrzeit: slot.uhrzeit
            }, slot.id);

            if (updated_appointment_slot === null) {
              // error has already been displayed, the return is actually not needed
              error_during_upserts = true;
              return;
            }
          }
        } else {
          // insert
          let inserted_appointment_slot = await connector.insert(this, 'buchungstermine', {
            fk_dienstleistungs_id: slot.fk_dienstleistungs_id,
            tag: slot.tag,
            uhrzeit: slot.uhrzeit,
            uid: this.session.user.id,
            fk_profil_id: this.profil_id,
          });

          if (inserted_appointment_slot === false) {
            // error has already been displayed, the return is actually not needed
            error_during_upserts = true;
            return;
          }
        }
      }));

      if (error_during_upserts) {
        // error has already been displayed, so just return without closing
        this.saving_configuration = false;
        return;
      } else {

        // update the termine
        let recurringSlots = await connector.getDataOnly(this, 'buchungstermine', 'tag', true);
        if (recurringSlots === -1) {
          // error has already been shown
          this.saving_configuration = false;
          return;
        }

        this.recurringSlots = recurringSlots;
        this.originalRecurringSlots = JSON.parse(JSON.stringify(this.recurringSlots));

        this.saving_configuration = false;
        this.dialog_configure_bookings = false;
        this.$emit('showInfo', {
          message: 'Die Änderungen wurden erfolgreich gespeichert.',
          timeout: 5000
        });
      }
    },

    async saveAnonymizeClients(anonymize) {
      this.loading_anonymize_clients = true;
      try {
        await connector.updateRow(this, 'kunden', {
          id: this.session.user.id,
          google_calendar_anonymize: anonymize,
        }, this.session.user.id)
      } catch (error) {
        // since error is handled in connector, we don't need to do anything here
        this.loading_anonymize_clients = false;
      }
      this.loading_anonymize_clients = false;
      this.$store.state.client.google_calendar_anonymize = anonymize;

    },

    navigateToWeek(date) {
      this.value = date.date;
      if (this.toggle_calendar_view === 2 && this.$vuetify.breakpoint.xsOnly) {
        this.toggle_calendar_view = 0;
      } else if (this.toggle_calendar_view === 2 && this.$vuetify.breakpoint.smAndUp) {
        this.toggle_calendar_view = 1;
      }
    },

    startTime(tms) {
      if (this.is_over_event || this.$vuetify.breakpoint.xsOnly) {
        return;
      }

      // extract the minutes out of the time and round it to the nearest 15 minutes.
      const interval = 15;
      // Calculate total minutes from the beginning of the day
      const totalMinutes = tms.hour * 60 + tms.minute;
      // Round the total minutes to the nearest interval
      const roundedTotalMinutes = Math.round(totalMinutes / interval) * interval;

      // Calculate the new hour and minute values
      const newHours = Math.floor(roundedTotalMinutes / 60);
      const newMinutes = roundedTotalMinutes % 60;

      // create a dajys object with the date and time

      let date = dayjs(tms.date).set('hour', newHours).set('minute', newMinutes);
      this.newItem(date);
    },

    setOverEvent() {
      this.is_over_event = true;
    },

    clearOverEvent() {
      this.is_over_event = false;
    },

    clearMove() {
      this.hoverDate = null;
      this.hoverTime = null;
      this.hoverTimePosition = null;
    },

    clearMoveEvent() {
      this.hoverDate = null;
      this.hoverTime = null;
      this.hoverTimePosition = null;
    },

    mouseMove(tms) {
      if (this.is_over_event) {
        this.clearMoveEvent();
        return;
      }

      this.hoverDate = tms.date;

      // extract the minutes out of the time and round it to the nearest 15 minutes.
      const interval = 15;
      // Calculate total minutes from the beginning of the day
      const totalMinutes = tms.hour * 60 + tms.minute;
      // Round the total minutes to the nearest interval
      const roundedTotalMinutes = Math.round(totalMinutes / interval) * interval;

      // Calculate the new hour and minute values
      const newHours = Math.floor(roundedTotalMinutes / 60);
      const newMinutes = roundedTotalMinutes % 60;

      this.hoverTime = (newHours < 10 ? '0' : '') + newHours + ':' + (newMinutes < 10 ? '0' : '') + newMinutes;
      this.hoverTimePosition = this.cal ? this.cal.timeToY({
        hour: newHours,
        minute: newMinutes
      }) + 'px' : '-10px';
    },

    async listLinkedIdentities() {
      try {
        // retrieve all identities linked to a user
        let result = await supabase.auth.getUserIdentities();
        if (result && result.data && result.data.identities) {

          const {
            data: { identities },
          } = result;

          const googleIdentity = identities.find((identity) => identity.provider === "google");
          if (googleIdentity) {
            return true;
          } else {
            return false;
          }
        } else {
          // handle error
          this.$emit("showError", {
            message: "Fehler beim Abrufen der verknüpften Google-Accounts. Bitte lade die Seite neu.",
            timeout: 5000,
          });
          return false;
        }
      } catch (error) {
        console.log(error);
        this.$emit("showError", {
          message: "Fehler beim Abrufen der verknüpften Google-Accounts. Bitte lade die Seite neu.",
          timeout: 5000,
        });
        return false;
      }
    },

    getHolidays() {
      const hd = new Holidays('AT', 'de-at');
      // get last year, this year, and next years holidays
      let years = [new Date().getFullYear() - 1, new Date().getFullYear(), new Date().getFullYear() + 1];
      let holidays = [];
      years.forEach((year) => {
        holidays = holidays.concat(hd.getHolidays(year));
      })
      return holidays.filter((holiday) => ['public', 'bank'].includes(holiday.type)).map((holiday) => {
        return {
          type: 'holiday',
          name: holiday.name,
          start: dayjs(holiday.start).format('YYYY-MM-DD'),
          color: this.$store.state.theme.green,
          original: holiday.start
        }
      })
    },

    async signInWithGoogle() {

      if (this.googleCalenderAuthState === 'unlinked') {

        const { data, error } = await supabase.auth.linkIdentity({
          provider: 'google',
          options: {
            redirectTo: process.env.NODE_ENV === 'development' ? 'http://localhost:8080/kalender' : 'https://app.zeipsy.com/kalender',
            scopes: ['https://www.googleapis.com/auth/calendar.calendarlist.readonly', 'https://www.googleapis.com/auth/calendar.events'],
            queryParams: {
              access_type: 'offline',
              prompt: 'consent', // The refresh-token gets returned only immediately after consent. 
              // It will not be re-issued on sessionRefresh or Login.        
              // Therefore, "force" consent on re-login as refresh-token is not in local storage.
            },
          },
        })

        if (error) {
          localStorage.removeItem('scopes');
          localStorage.removeItem('provider_refresh_token');
          this.$emit('showError', { message: 'Fehler beim Verbinden mit Google.' });
        }
        if (data) {
          localStorage.setItem('scopes', 'https://www.googleapis.com/auth/calendar.calendarlist.readonly,https://www.googleapis.com/auth/calendar.events');
          // localStorage.setItem('provider_refresh_token', data.provider_refresh_token);
        }
      } else {
        const { data, error } = await supabase.auth.signInWithOAuth({
          provider: 'google',
          options: {
            redirectTo: process.env.NODE_ENV === 'development' ? 'http://localhost:8080/kalender' : 'https://app.zeipsy.com/kalender',
            scopes: ['https://www.googleapis.com/auth/calendar.calendarlist.readonly', 'https://www.googleapis.com/auth/calendar.events'],
            queryParams: {
              access_type: 'offline',
              prompt: 'consent', // The refresh-token gets returned only immediately after consent. 
              // It will not be re-issued on sessionRefresh or Login.        
              // Therefore, "force" consent on re-login as refresh-token is not in local storage.
            },
          },
        })

        if (error) {
          localStorage.removeItem('scopes');
          localStorage.removeItem('provider_refresh_token');
          this.$emit('showError', { message: 'Fehler beim Verbinden mit Google.' });
        }
        if (data) {
          localStorage.setItem('scopes', 'https://www.googleapis.com/auth/calendar.calendarlist.readonly,https://www.googleapis.com/auth/calendar.events');
          // localStorage.setItem('provider_refresh_token', data.provider_refresh_token);
        }
      }

    },

    async getRefreshToken(refresh_token) {
      var myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + this.session.access_token);
      myHeaders.append('Accept', '*/*');
      myHeaders.append('x-client-info', 'supabase-js/2.8.0');

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: JSON.stringify({
          refresh_token: refresh_token,
        }),
      };

      let responseJson = {};
      try {
        let response = await fetch(
          'https://qgsfsflpvymafizvkpca.functions.supabase.co/google-refresh-token/',
          requestOptions
        );
        responseJson = await response.json();
      } catch (error) {
        connector.logError(this, {
          uid: this.session.user.id,
          message: 'LOG: Google Calendar: Error during POST request to google-refresh-token function.',
        });
      }

      return responseJson;
    },

    async initializeGapiClient() {
      try {

        // only initialize if we have a refresh token
        if (localStorage.provider_refresh_token) {
          let accessToken = await this.getRefreshToken(localStorage.provider_refresh_token);
          //console.log('accessToken', accessToken);

          await this.gapi.client.init({
            apiKey: "AIzaSyBVojXCiR8LbVSlFt94GU0-O_yShuQpRTs",
            discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
          });
          //console.log('initialize', this.session);
          this.gapi.client.setToken({ access_token: accessToken.access_token });
          this.gapi_inited = true;
        } else {
          // check if a calendar is already set, if so, show the reconnect dialog
          if (this.$store.state.client.google_calendar) {
            this.redirectDialog = true;
            connector.logError(this, {
              uid: this.session.user.id,
              message: 'LOG: Google Calendar: Refresh token not found although calender is set. Prompting user to reconnect.',
            });
          }
        }

      } catch (error) {
        console.log(error);
        this.$emit('showError', { message: 'Fehler beim Initialisieren von Google-API.' });
      }

      this.initialize();
    },

    async removeGoogleCalendar() {
      await connector.updateRow(this, 'kunden', {
        id: this.session.user.id,
        google_calendar: null,
      }, this.session.user.id)

      this.$store.state.client.google_calendar = null
      this.initialize();
      this.$emit('showInfo', {
        message: 'Google Kalender erfolgreich getrennt.',
        timeout: 5000,
      });
    },

    async getCalendars() {
      let response = null
      try {
        response = await this.gapi.client.calendar.calendarList.list()
        if (response.status === 200 && response.result.items.length > 0) {
          return response.result.items.filter((item) => item.accessRole === "owner").map((item) => {
            let color = null;
            if (item.colorId && item.colorId in this.color_mapping_calendars) {
              color = this.color_mapping_calendars[item.colorId]
            }
            return {
              id: item.id,
              name: item.summary,
              color: color,
            }
          })
        }
      } catch (err) {
        this.$emit('showError', { message: 'Fehler beim Laden der Google Kalender.' });
        throw err
        return []
      }
      return []
    },

    async getCalendarEvents(calendar_id) {

      let response = null
      try {
        const request = {
          'calendarId': calendar_id,
          'timeMin': moment().subtract(2, 'month').toISOString(),
          'showDeleted': false,
          'singleEvents': true,
          'maxResults': 250,
          'orderBy': 'startTime',
        };
        response = await this.gapi.client.calendar.events.list(request)
      } catch (err) {
        if (err.status === 404 || err.status === 401) {
          // renew the access token
          // check if provider refresh token is present, if so, get a new access token
          if (localStorage.provider_refresh_token) {
            if (this.gapi_reload_count > 0) {
              // if we already tried to reload the gapi client, show the reconnect dialog
              this.redirectDialog = true;
              connector.logError(this, {
                uid: this.session.user.id,
                message: 'LOG: Google Calendar: Getting in Auth Loop. Prompting user to reconnect.',
              });
              return []
            }
            this.gapi.load('client', this.initializeGapiClient);
            this.gapi_reload_count += 1;
          } else {
            // if not, redirect to google with prompt=consent and offline access
            this.redirectDialog = true;
            connector.logError(this, {
              uid: this.session.user.id,
              message: 'LOG: Google Calendar: Refresh token not found. Prompting user to reconnect.',
            });
          }
        }
        if (err.status === 403) {
          // insufficient permissions, probably the user revoked the permissions
          // show redirection dialog
          this.redirectDialog = true;
          connector.logError(this, {
            uid: this.session.user.id,
            message: 'LOG: Google Calendar: Insufficient permissions. Prompting user to reconnect.',
          });
        }
        return []
      }
      if (response) {
        return response.result.items.map((appointment) => {
          let color = null;
          if (appointment.colorId && appointment.colorId in this.color_mapping_events) {
            color = this.color_mapping_events[appointment.colorId]
          }
          return {
            color: color,
            start: appointment.start.date ? dayjs(appointment.start.date).format('YYYY-MM-DD') : dayjs(appointment.start.dateTime).format('YYYY-MM-DDTHH:mm'),
            end: appointment.end.date ? dayjs(appointment.end.date).subtract(1, 'day').format('YYYY-MM-DD') : dayjs(appointment.end.dateTime).format('YYYY-MM-DDTHH:mm'),
            name: appointment.summary,
            type: 'google',
            id: appointment.description && appointment.description.includes('zeipsy.com/termine/?id=') ? parseInt(appointment.description.split('id=')[1]) : null,
            eventId: appointment.id,
          }
        })
      }
      return []
    },

    async loadGoogleCalendars() {
      this.loading_calendars = true;
      this.calendars = await this.getCalendars();
      this.loading_calendars = false;
    },

    async saveAndLoadCalendar() {
      // this.loading = true
      this.google_appointments = []

      // save calendar id to klienten
      await connector.updateRow(this, 'kunden', {
        id: this.session.user.id,
        google_calendar: this.selected_calendar.id,
      }, this.session.user.id)

      this.$store.state.client.google_calendar = this.selected_calendar.id

      // store calendar color in localStorage if available
      if (this.selected_calendar.color) {
        localStorage.setItem('google_calendar_color', this.selected_calendar.color);
      }

      this.google_appointments = await this.getCalendarEvents(this.selected_calendar.id)

      this.$emit('showInfo', {
        message: 'Google Kalender erfolgreich verknüpft.',
        timeout: 5000,
      });
    },

    async initialize(scroll_to_current_time = true) {

      let hide_holidays = parseInt(localStorage.getItem('hide_holidays')) || 0;
      this.show_holidays = hide_holidays === 0;

      connector.getDataOnly(this, 'vwtermine', 'datum', false)
        .then((appointments) => (this.n_appointments = appointments.length, appointments))
        .then((appointments) => cipher.decryptDataAsync(this, appointments, 'appointments', 'loading'));

      
      if (this.$store.state.client.beta_circle) {
        await this.fetchBookingRequests();

        // check if user has already a buchungsprofile
        let profiles = await connector.getDataOnly(this, 'buchungsprofile', 'inserted_at', false);
        if (profiles.length > 0) {
          this.profil_id = profiles[0].profil_id;
          this.appointment_booking = profiles[0].aktiv === true;
          this.booking_advance = profiles[0].vorlaufzeit;
          this.booking_timeframe = profiles[0].nachlaufzeit;
          this.booking_on_holidays = profiles[0].buchbare_feiertage === true;
        } else {
          // insert new buchungsprofile
          let profile = await connector.upsertRow(this, 'buchungsprofile', { id: this.session.user.id, email: this.session.user.email });
          this.profil_id = profile[0].profil_id;
        }
      }

      this.services = await connector.getDataOnly(this, 'vwdienstleistungen', 'id', true);
      this.recurringSlots = await connector.getDataOnly(this, 'buchungstermine', 'id', true);
      this.originalRecurringSlots = JSON.parse(JSON.stringify(this.recurringSlots));
      
      connector.getDataOnly(this, 'vwkunden', 'id', true)
        .then((client) => {
          if (client.length > 0) {
            this.$store.commit('setClient', client[0]);

            if (this.$store.state.client.kalender_start) {
              this.working_hours_start = this.$store.state.client.kalender_start;
            }

            if (this.$store.state.client.kalender_ende) {
              this.working_hours_end = this.$store.state.client.kalender_ende;
            }

            if (this.$store.state.client.kalender_start || this.$store.state.client.kalender_ende) {
              this.updateIntervalHeight();
            }
          }
          if (this.$store.state.client.google_calendar) {
            // set anomyze_clients to true if it is set in the client
            this.anonymize_clients = this.$store.state.client.google_calendar_anonymize === true ? 1 : 0;
            this.getCalendarEvents(this.$store.state.client.google_calendar)
              .then((appointments) => this.google_appointments = appointments)
          } else {
            this.google_appointments = []
          }
        })

      let customers_enc = await connector.getDataOnly(this, 'vwklientenkalender', 'id', true)
      this.customers = await cipher.decryptArray(this, customers_enc)

      this.ready = true
      if (scroll_to_current_time) {
        this.scrollToTime()
      }
      this.updateTime();
      if (this.$refs.calendar) this.$refs.calendar.updateTimes();

    },

    getCurrentTime() {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
    },
    scrollToTime() {
      const time = this.getCurrentTime()
      const first = Math.max(0, time - (time % 30) - 5 * 60)
      if (this.cal) {
        this.cal.scrollToTime(first);
      }
    },
    updateTime() {
      setInterval(() => {
        if (this.$refs.calendar) {
          this.$refs.calendar.updateTimes();
        }
      }, 60 * 1000)
    },
    prev() {
      this.$refs.calendar.prev();
      this.$nextTick(() => {
        this.updateIntervalHeight();
      });
    },
    next() {
      this.$refs.calendar.next();
      this.$nextTick(() => {
        this.updateIntervalHeight();
      });
    },
    setToday() {
      this.value = ''
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    newItem(pre_filled_datetime = null) {
      // check if user has already some services, if not prompt him to create one first
      if (!this.$store.getters.hasServices) {
        this.$emit('showError', { 'message': 'Um einen Termin zu erstellen, musst du zuerst eine Dienstleistung anlegen.' })
        return
      }

      this.editedIndex = -1
      this.editedItem = Object.assign({}, this.defaultItem)

      if (pre_filled_datetime) {
        this.editedItem.uhrzeit = pre_filled_datetime.format('HH:mm');
        this.editedItem.datum = pre_filled_datetime.toISOString();
        this.editedItem.datumFormatted = pre_filled_datetime.format('DD.MM.YYYY')
      }

      this.dialog = true;
    },

    findMostSimilarName(name, lastnamesArray, firstnamesArray) {
      const splitName = name.split(' ');
      const filteredName = splitName.filter((name) => name.toLowerCase() !== 'online' && name.toLowerCase() !== 'eg');
      const sortedName = filteredName.sort().join(' ');

      let only_check_lastname = false;

      if (filteredName.length === 1) {
        only_check_lastname = true;
      }

      const namesArray = lastnamesArray.map((lastname, index) => {
        if (only_check_lastname) {
          return `${lastname}`
        } else {
          return `${firstnamesArray[index]} ${lastname}`
        }
      })

      let minDistance = Infinity;
      let mostSimilarNameIndex = -1;

      for (let i = 0; i < namesArray.length; i++) {
        const splitArrayName = namesArray[i].split(' ');
        const sortedArrayName = splitArrayName.sort().join(' ');
        const distance = levenshtein.get(sortedName, sortedArrayName)

        if (distance < minDistance) {
          minDistance = distance;
          mostSimilarNameIndex = i;
        }
      }

      return mostSimilarNameIndex;
    },

    testMostSimilar() {
      const lastnames = ['Kiessling', 'Weingartner', 'Spatz', 'Gußenbauer', 'Aßbacher', 'Klient']
      const firstname = ['Bianca', 'Niklas', 'Victoria', 'Sepp', 'Katharina', 'Herbert']

      let name = 'Bianca Kiessling'
      let most_similar_index = this.findMostSimilarName(name, lastnames, firstname)
      if (most_similar_index !== 0) console.log('most_similar_index', most_similar_index, 'should be 0')

      name = 'Niklas Weingartner'
      most_similar_index = this.findMostSimilarName(name, lastnames, firstname)
      if (most_similar_index !== 1) console.log('most_similar_index', most_similar_index, 'should be 1')

      name = 'Weingartner'
      most_similar_index = this.findMostSimilarName(name, lastnames, firstname)
      if (most_similar_index !== 1) console.log('most_similar_index', most_similar_index, 'should be 1')

      name = 'Spatz'
      most_similar_index = this.findMostSimilarName(name, lastnames, firstname)
      if (most_similar_index !== 2) console.log('most_similar_index', most_similar_index, 'should be 2')

      name = 'Klient online'
      most_similar_index = this.findMostSimilarName(name, lastnames, firstname)
      if (most_similar_index !== 5) console.log(name, 'most_similar_index', most_similar_index, 'should be 5')

      name = 'Klient'
      most_similar_index = this.findMostSimilarName(name, lastnames, firstname)
      if (most_similar_index !== 5) console.log(name, 'most_similar_index', most_similar_index, 'should be 5')

      console.log('testMostSimilar() finished')

    },

    editItem({ nativeEvent, event }) {
      let item = Object.assign({}, event)

      // check if item is a holiday
      if (item.type && item.type === 'holiday') {
        return;
      }

      // check if item is from google calendar
      if (item.type && item.type === 'google') {
        // in this case, we ask the user if he wants to create a new appointment for this event
        // trigger custom dialog, with date and time pre-filled, and suggested customer
        this.editedIndex = -1
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItem.datum = item.start.split('T')[0]
        this.editedItem.datumFormatted = dayjs(item.start.split('T')[0]).format('DD.MM.YYYY')
        this.editedItem.uhrzeit = item.start.split('T')[1]
        //this.editedItem.dauer = moment(item.end).diff(moment(item.start), 'minutes')
        this.editedItem.dauer = this.$store.state.client.std_dauer // TODO: FÜR BIANCA HOTFIX!

        let most_similar_index = this.findMostSimilarName(item.name, this.customers.map((customer) => customer.nachname), this.customers.map((customer) => customer.vorname))
        if (most_similar_index !== -1) {
          this.editedItem.selected = {
            name: this.customers[most_similar_index].nachname + ' ' + this.customers[most_similar_index].vorname,
            fk_klienten_id: this.customers[most_similar_index].id,
          }
        }

        this.dialog = true
        return
      }

      // check if item is a booking request
      if (item.type && item.type === 'request') {
        let bookingRequestItem = this.bookingRequests.find((request) => request.id === item.id);
        if (bookingRequestItem) {
          this.openBookingRequest(bookingRequestItem);
        } else {
          console.error('Booking request not found');
        }
        return;
      }

      delete item.name
      delete item.color
      delete item.start
      delete item.end

      this.editedIndex = item.id
      this.editedItem = Object.assign({}, item)
      this.editedItem.datumFormatted = dayjs(this.editedItem.datum).format('DD.MM.YYYY')
      this.editedItem.selected = { name: this.editedItem.nachname + ' ' + this.editedItem.vorname, fk_klienten_id: this.editedItem.fk_klienten_id }
      this.dialog = true
    },

    refreshAndClose(scroll_to_current_time = true) {
      this.initialize(scroll_to_current_time)
      this.close()
    },

    updateAndClose(toUpdate, id) {
      // just introduced in the Termine view, so when updating an appointment that we do not reload all the appointments, causing a table refresh.
      // However, in the calendar it is fine to reload all appointments, so we can just call refreshAndClose
      this.refreshAndClose(false);
    },

    // ICAL

    writeCalenderFileToBucket(filename) {

      let events = this.appointments.map((appointment) => {
        return {
          title: 'Klienten-Termin',
          start: moment(appointment.datum).format('YYYY-M-D-H-m').split("-").map((x) => parseInt(x)),
          duration: { minutes: appointment.dauer },
          url: 'https://zeipsy.com',
          calName: 'ZEIPSY Kalender',
        }
      })

      const { error, value } = ics.createEvents(events)

      if (error) {
        console.log(error)
        this.$emit('showError', 'Fehler beim Erstellen des Kalendar.')
        return null
      }

      // add refresh-intervall to calendar
      let refresh = 'REFRESH-INTERVAL;VALUE=DURATION:PT1H'
      let calender_content = value.replace('X-PUBLISHED-TTL:PT1H', refresh + '\r\nX-PUBLISHED-TTL:P5M')

      let enc = new TextEncoder()
      let file = enc.encode(calender_content).buffer

      connector.upsertRow(this, 'kalender', { id: this.session.user.id })
        .then((data) => {
          if (data.length > 0) {
            return connector.uploadFileToBucket(this, 'public-calendar', data[0].secret_id + '/', filename, file, '60', 'text/calendar')
          } else {
            return null
          }
        })
        .then((result) => {
          if (result) {
            this.calendar_url = connector.getPublicURL(this, 'public-calendar', result.path)
            this.calendar_url = this.calendar_url.replace('https://', 'webcal://')
            this.show_calendar_url = true

          } else {
            this.$emit('showError', 'Fehler beim Hochladen des Kalendar.')
          }
        })
    },

  }
}
</script>

<style scoped>
.preview-event {
    color: black;
}
.custom-event {
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}

.custom-event .event-name {
    font-weight: bold;
    margin-right: 8px;
}

.custom-event .event-time {
    display: inline; /* or 'block' if you want the time to be on a new line for longer events */
    /* font-size: 0.875rem; */
}
.booking-requests-sidebar {
  height: 100%;
  overflow-y: auto;
}

.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;
}

.v-current-time-insert {
  position: absolute;
  left: 0;
  right: 0;
  pointer-events: none;
}


.first::before {
  content: '';
  position: absolute;
  background-color: #ea4335;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-top: -5px;
  margin-left: -6.5px;
}

.gsi-material-button {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  /* background-color: #f2f2f2;
  background-image: none;
  border: none;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  justify-content: center;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  min-width: min-content; */
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  /* margin-right: 10px; */
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
  justify-content: center;
  /* Center the contents */
}
</style>