<template>
  <v-container fluid>
    <v-dialog v-model="dialog_first_steps" persistent transition="dialog-bottom-transition" >
        <v-card>
            <v-toolbar dark :color="$store.state.theme.primary" >
            <v-btn icon dark @click="dialog_first_steps = false" >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Konto Einrichtung</v-toolbar-title>
            </v-toolbar>
            <div class="d-flex flex-column px-10">
                <span class="text-h5 my-5">Dein Konto-Modell</span>
                <span>Bevor wir starten, teile uns mit wieviele physische Bankkonten / Unterkonten du in deinem Geschäftskonto verwendest.</span>
                
                <v-item-group mandatory>
                    <v-row class="mx-10 mt-5">
                        <v-col>
                            <v-item>
                                <v-card :color="using_one_business_account ? $store.state.theme.primary : $store.state.theme.background" class="d-flex flex-column align-center justify-center" :dark="using_one_business_account" height="300" @click="changeAccountModel(false)" >
                                    <!-- <span v-if="using_one_business_account" class="text-overline text-center">Ausgewählt <v-icon small>mdi-check</v-icon></span> -->
                                    <v-chip :color="$store.state.theme.green" small v-if="using_one_business_account" class="text-overline text-center">Ausgewählt <v-icon small>mdi-check</v-icon></v-chip>
                                    <span v-else class="text-overline text-center">&nbsp;</span>
                                    <span class="text-button font-weight-black text-center">Ein-Konto-Modell</span>
                                    <span class="text-overline text-center">(Standard)</span>
                                    <span class="px-5 mt-3"><b>Erklärung:</b></span>
                                    <div class="px-5 my-3">
                                        <ul >
                                            <li>Du besitzt ein Konto über welches alle Transaktionen stattfinden.</li>
                                            <li>Die Rücklagen usw. befinden sich alle auf diesem Konto.</li>
                                        </ul>
                                    </div>
                                </v-card>
                            </v-item>
                        </v-col>
                        <v-col>
                            <v-item>
                                <v-card :color="!using_one_business_account ? $store.state.theme.primary : $store.state.theme.background" class="d-flex flex-column align-center justify-center" :dark="!using_one_business_account" height="300" @click="changeAccountModel(true)" >
                                    <!-- <span v-if="!using_one_business_account" class="text-overline text-center">Ausgewählt <v-icon small>mdi-check</v-icon></span> -->
                                    <v-chip :color="$store.state.theme.green" small v-if="!using_one_business_account" class="text-overline text-center">Ausgewählt <v-icon small>mdi-check</v-icon></v-chip>
                                    <span v-else class="text-overline text-center">&nbsp;</span>
                                    <span class="text-button font-weight-black text-center">Mehr-Konten-Modell</span>
                                    <span class="text-overline text-center">(Fortgeschritten)</span>
                                    <span class="px-5 mt-3"><b>Erklärung:</b></span>
                                    <div class="px-5 my-3">
                                        <ul >
                                            <li>Du besitzt ein Bankkonto mit mehreren Unterkonten (Spaces).</li>
                                            <li>Auf dem Hauptkonto gehen alle Zahlungen ein.</li>
                                            <li>Du hast ein oder mehrere separate Unterkonten für z.B. Rücklagen oder SVS Quartalszahlungen.</li>
                                        </ul>
                                    </div>
                                </v-card>
                            </v-item>
                        </v-col>
                    </v-row>
                </v-item-group>
            </div>

            <v-divider class="my-10 mx-5"></v-divider>

            <div class="d-flex flex-column px-10">
                <span class="text-h5 mb-5">Aktueller Kontostand</span>
                <span>Damit wir mit deiner Gehaltsplanung starten können, müssen wir erst einmal deinen aktuellen IST-Stand kennen.</span>
                <span><b>Achtung:</b> Bevor du die folgenden Daten ausfüllst, stelle bitte sicher das alle bezahlten Rechnungen auch in ZEIPSY als 'bezahlt' eingetragen sind.</span>
            </div>
            <v-list dense class="px-10 mt-5">
                <span class="text-button">Rücklagen für SVS Nachzahlung</span>
                <v-list-item dense>
                    <v-row class="pb-2">
                        <v-col cols="7">
                            <span class="d-flex text-justify body-2">
                                Du solltest laufend Rücklagen für eine etwaige SVS Nachzahlung bilden. 
                                Diese sollten in der Regel 25% vom Umsatz (nicht Gewinn) betragen.
                                Wieviel Rücklagen hast du aktuell auf deinem Konto und vieviel Prozent von deinem Umsatz möchtest du in Zukunft weglegen?
                            </span>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field dense outlined v-model="account_reserve" label="Rücklagen" type="number" suffix="€" ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <!-- <v-text-field dense outlined v-model="account_reserve" label="Prozent vom Umsatz" suffix="€" ></v-text-field> -->
                            <v-slider :color="$store.state.theme.primary" v-model="savings_rate" hint="Prozent vom Umsatz" persistent-hint >
                                <template v-slot:append>
                                    <v-chip small>{{ savings_rate }}%</v-chip>
                                </template>
                            </v-slider>
                        </v-col>
                    </v-row>
                </v-list-item>
                <v-list-item dense>
                    <v-row v-if="!using_one_business_account" class="d-flex align-center">
                        <v-col cols="7">
                            <span class="text-justify body-2">
                                Verwendest du ein separates (Unter-)Konto für deine Rücklagen?
                            </span>
                        </v-col>
                        <v-col cols="5">
                            <v-switch class="ma-2 pa-0" disabled inset v-model="using_separate_reserve_account" :color="$store.state.theme.green" :label="using_separate_reserve_account ? 'Ja' : 'Nein'"></v-switch>
                        </v-col>
                    </v-row>
                </v-list-item>
                <v-divider></v-divider>
                <span class="text-button">SVS Quartalszahlung</span>
                <v-list-item dense>
                    <v-row v-if="!using_one_business_account" class="d-flex align-top">
                        <v-col cols="7">
                            <span class="text-justify body-2">
                                Verwendest du ein separates (Unter-)Konto für deine SVS Quartalszahlung?
                            </span>
                        </v-col>
                        <v-col cols="2.5" class="d-flex align-top">
                            <v-switch class="ma-2 pa-0" inset v-model="using_separate_svs_account" :color="$store.state.theme.green" :label="using_separate_svs_account ? 'Ja' : 'Nein'"></v-switch>
                        </v-col>
                        <v-col cols="2.5">
                            <v-text-field v-if="using_separate_svs_account" dense outlined v-model="svs_account_name" label="Kontobezeichnung"></v-text-field>
                        </v-col>
                    </v-row>
                </v-list-item>
                <v-list-item dense>
                    <v-row class="pb-2">
                        <v-col cols="7">
                            <span class="d-flex text-justify body-2">
                                Pro Quartal ist eine SVS Zahlung fällig. 
                                Im Idealfall legst du jedes Monat einen Teil dafür auf die Seite.
                                Wie hoch ist deine nächste SVS Quartalszahlung und wann ist diese fällig?
                            </span>
                            <span class="d-flex text-justify body-2" v-if="!using_one_business_account && using_separate_svs_account">Wieviel hast du für die nächste Zahlung bereits auf die Seite gelegt?</span>
                        </v-col>
                        <v-col cols="5">
                            <v-row no-gutters>
                                <v-col :cols="using_one_business_account ? 12 : 6">
                                    <v-text-field :class="using_one_business_account ? `` : `mr-2`" dense outlined v-model="svs_payment" label="Betrag" type="number" suffix="€" ></v-text-field>
                                </v-col>
                                <v-col v-if="!using_one_business_account && using_separate_svs_account" cols="6">
                                    <v-text-field class="ml-2" dense outlined v-model="account_svs" label="Bereits weggelegt" type="number" suffix="€" ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="6">
                                    <v-menu v-model="menuDueDateSetup" :close-on-content-click="false" transition="scale-transition" offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field class="mr-2" v-model="computedSVSDueDateFormatted" dense outlined prepend-inner-icon="mdi-calendar" label="Fällig am" readonly v-bind="attrs" v-on="on" />
                                        </template>
                                        <v-date-picker first-day-of-week="1" v-model="svs_due_date" no-title @input="menuDueDateSetup = false"/>
                                    </v-menu>
                                </v-col>
                                <v-col cols="6">
                                    <v-select v-model="svs_quarter" class="ml-2" label="Quartal" :items="getQuarters" outlined dense />
                                </v-col>
                            </v-row> 
                        </v-col>
                    </v-row>
                </v-list-item>

                <v-divider></v-divider>
                <span class="text-button">Kontostand</span>
                <v-list-item dense>
                    <v-row class="pb-2">
                        <v-col cols="7">
                            <span v-if="using_one_business_account" class="d-flex text-justify body-2">
                                Wieviel Geld hast du aktuell auf deinem Konto (inklusive Rücklagen)?
                            </span>
                            <span v-else class="d-flex text-justify body-2">
                                Wieviel Geld hast du aktuell auf deinem Hauptkonto (ohne Rücklagen und weggelegtem Betrag für die SVS Quartalszahlung)?
                            </span>
                        </v-col>
                        <v-col cols="5">
                            <v-text-field dense outlined v-model="account_current" label="Kontostand" type="number" suffix="€"></v-text-field>
                        </v-col>
                    </v-row>
                </v-list-item>
                <v-divider></v-divider>
                <span class="text-button">Praxis-Miete</span>
                <v-list-item>
                    <v-row class="pb-2">
                        <v-col cols="7">
                            <span class="d-flex text-justify body-2">
                                Wieviel Miete zahlst du aktuell für deine Praxis? 
                                Hast du die Miete für dieses Monat schon bezahlt? 
                                Wenn ja, wann? 
                                Wenn nein, wann ist sie fällig?
                            </span>
                        </v-col>
                        <v-col cols="1.5">
                            <v-text-field dense outlined v-model="default_rent" label="Miete" type="number" suffix="€"></v-text-field>
                        </v-col>
                        <v-col cols="1.25" class="d-flex justify-center">
                            <v-switch class="mt-1 mx-0 px-0" :color="$store.state.theme.green" v-model="setup_paid_rent" :label="setup_paid_rent ? `Bezahlt: Ja`: `Bezahlt: Nein`" inset ></v-switch>
                        </v-col>
                        <v-col cols="2.25"> 
                            <v-menu v-model="menuPaidRentSetup" :close-on-content-click="false" transition="scale-transition" offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="computedPaidRentSetup" dense prepend-inner-icon="mdi-calendar" outlined :label="setup_paid_rent ? `Bezahlt am` : `Fällig am`" readonly v-bind="attrs" v-on="on" />
                                </template>
                                <v-date-picker first-day-of-week="1" v-model="setup_paid_rent_date" no-title @input="menuPaidRentSetup = false"/>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-list-item>
                <v-list-item class="d-flex justify-center ma-5">
                    <v-btn :color="$store.state.theme.primary" outlined dark @click="dialog_first_steps = false" class="mr-2">
                        Abbrechen
                    </v-btn>
                    <v-btn :color="$store.state.theme.primary" dark @click="saveInitialAccountValues" class="ml-2">
                        Speichern & Weiter
                    </v-btn>
                </v-list-item>
            </v-list>
        </v-card>
    </v-dialog>

    <v-container class="hidden-sm-and-up pa-5">
        <span class="text-h5">
            Das Finanzmodul ist nur auf Desktop-Geräten verfügbar.
        </span>
    </v-container>
    <div class="hidden-xs-only">    
        <v-row>
            <v-col cols="4" >
                <span class="text-h5">Kontostand</span>
                <v-card elevation="1" class="mt-2" :height="240 + 48 * (debit_accounts.length - 2) + 48 * (amount_should_save > 0)">
                    <div>
                        <v-simple-table v-if="!using_one_business_account">
                            <template v-slot:default>
                                <tbody>
                                    <tr class="text-button">
                                        <td><v-icon left>mdi-cash-register</v-icon>Hauptkonto</td>
                                        <td class="d-flex justify-end align-center font-weight-black">
                                            <v-skeleton-loader v-if="!finishedLoadingAccountData" type="text" width="70px"/>
                                            <span v-else>
                                                {{ displayAccountCurrent.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr class="text-button">
                                        <td>
                                            <v-icon left>mdi-cash-sync</v-icon>
                                            SVS ({{ svs_quarter }})
                                            <!-- <v-icon >mdi-information</v-icon> -->
                                        </td>
                                        <td class="d-flex justify-end align-center font-weight-black">
                                            <v-skeleton-loader v-if="!finishedLoadingAccountData" type="text" width="70px"/>
                                            <span v-else>
                                                {{ (-svsShouldHavePaid).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr v-for="account in displayOtherAccounts" :key="account.name" class="text-button">
                                        <td><v-icon left>mdi-piggy-bank-outline</v-icon>{{ account.name }}</td>
                                        <td class="d-flex justify-end align-center font-weight-black">
                                            <v-skeleton-loader v-if="!finishedLoadingAccountData" type="text" width="70px"/>
                                            <span v-else>
                                                {{ account.value.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr class="text-button">
                                        <td>
                                            <v-icon left>mdi-handshake-outline</v-icon>
                                            Mtl. Zahlungen
                                        </td>
                                        <td class="d-flex justify-end align-center font-weight-black">
                                            <v-skeleton-loader v-if="!finishedLoadingAccountData" type="text" width="70px"/>
                                            <span v-else>
                                                {{ (-displayObligations).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr class="text-button" v-if="amount_should_save > 0">
                                        <td>
                                            <v-icon left>mdi-cash-sync</v-icon>
                                            Rücklagen Bilden
                                        </td>
                                        <td class="d-flex justify-end align-center font-weight-black">
                                            <v-skeleton-loader v-if="!finishedLoadingAccountData" type="text" width="70px"/>
                                            <span v-else>
                                                {{ (-amount_should_save).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr class="text-button">
                                        <td><v-icon left>mdi-cash-check</v-icon>Verfügbar</td>
                                        <td class="d-flex justify-end align-center font-weight-black">
                                            <v-skeleton-loader v-if="!finishedLoadingAccountData" type="text" width="70px"/>
                                            <span v-else>
                                                ={{ (displayAccountAvailable).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr class="text-button">
                                        <td><v-icon left>mdi-piggy-bank-outline</v-icon>Rücklagen</td>
                                        <td class="d-flex justify-end align-center font-weight-black">
                                            <v-skeleton-loader v-if="!finishedLoadingAccountData" type="text" width="70px"/>
                                            <span v-else>
                                                {{ displayAccountReserves.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    
                        <v-simple-table v-else >
                            <template v-slot:default>
                                <tbody>
                                    <tr class="text-button">
                                        <td><v-icon left>mdi-cash-register</v-icon>Hauptkonto</td>
                                        <td class="d-flex justify-end align-center font-weight-black">
                                            <v-skeleton-loader v-if="!finishedLoadingAccountData" type="text" width="70px"/>
                                            <span v-else>
                                                {{ displayAccountCurrent.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr class="text-button">
                                        <td><v-icon left>mdi-piggy-bank-outline</v-icon>Rücklagen</td>
                                        <td class="d-flex justify-end align-center font-weight-black">
                                            <v-skeleton-loader v-if="!finishedLoadingAccountData" type="text" width="70px"/>
                                            <span v-else>
                                                {{ (-displayAccountReserves).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr class="text-button">
                                        <td>
                                            <v-icon left>mdi-cash-sync</v-icon>
                                            SVS ({{ svs_quarter }})
                                            <!-- <v-icon >mdi-information</v-icon> -->
                                        </td>
                                        <td class="d-flex justify-end align-center font-weight-black">
                                            <v-skeleton-loader v-if="!finishedLoadingAccountData" type="text" width="70px"/>
                                            <span v-else>
                                                {{ (-svsShouldHavePaid).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr class="text-button">
                                        <td>
                                            <v-icon left>mdi-handshake-outline</v-icon>
                                            Mtl. Zahlungen
                                            <!-- <v-icon >mdi-information</v-icon> -->
                                        </td>
                                        <td class="d-flex justify-end align-center font-weight-black">
                                            <v-skeleton-loader v-if="!finishedLoadingAccountData" type="text" width="70px"/>
                                            <span v-else>
                                                {{ (-displayObligations).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}
                                            </span>
                                        </td>
                                    </tr>
                                    
                                    <tr class="text-button">
                                        <td><v-icon left>mdi-cash-check</v-icon>Verfügbar</td>
                                        <td class="d-flex justify-end align-center font-weight-black">
                                            <v-skeleton-loader v-if="!finishedLoadingAccountData" type="text" width="70px"/>
                                            <span v-else>
                                                ={{ (displayAccountAvailable).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </div>
                </v-card>
            </v-col>
            <v-col cols="5">
                <v-dialog v-model="dialog_add_monthly_payment" persistent max-width="800">
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">Neue monatliche Zahlung hinzufügen</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container class="mt-5">
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field label="Beschreibung" v-model="current_obligation.beschreibung" dense outlined />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select dense outlined v-model="current_obligation.verteiler" :items="itemsExpenses" item-text="text" item-value="id" label="Verteiler"/>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-switch class="mt-1 mx-0 px-0" :color="$store.state.theme.green" v-model="already_paid_new_obligation" :label="already_paid_new_obligation ? `Dieses Monat bereits bezahlt: Ja`: `Dieses Monat bereits bezahlt: Nein`" inset ></v-switch>
                                    </v-col>
                                    <v-col cols="3" class="d-flex justify-center">
                                        <v-text-field dense outlined v-model="current_obligation.betrag" label="Betrag" type="number" suffix="€"></v-text-field>
                                </v-col>
                                    <v-col cols="3"> 
                                        <v-menu v-model="menu_datepicker_new_obligation" :close-on-content-click="false" transition="scale-transition" offset-y>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field :value="getFormatedDate(current_obligation.zahlung_am)" dense prepend-inner-icon="mdi-calendar" outlined :label="already_paid_new_obligation ? `Bezahlt am` : `Fällig am`" readonly v-bind="attrs" v-on="on" />
                                            </template>
                                            <v-date-picker first-day-of-week="1" v-model="current_obligation.zahlung_am" no-title @input="menu_datepicker_new_obligation = false"/>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </v-container>
                            </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn :color="$store.state.theme.red" text @click="dialog_add_monthly_payment = false">
                                Abbrechen
                            </v-btn>
                            <v-btn :color="$store.state.theme.green" text @click="addObligation(current_obligation)" >
                                Hinzufügen
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog persistent v-model="showPayObligationDialog" width="500" >
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">
                                {{ current_obligation.beschreibung + ' (' + getSelectedYearAndMonth + ')' }}
                            </span>
                        </v-card-title>

                        <v-divider></v-divider>

                        <v-row no-gutters v-if="delete_obligation" class="pa-5">
                            <span>Soll die monatliche Zahlung wirklich gelöscht werden? Bereits getätigte Zahlungen werden nicht gelöscht.</span>
                        </v-row>
                        <v-row v-else no-gutters class="ma-5">
                            <v-col cols="6">
                                <v-menu v-model="menuPaidObligationOn" :close-on-content-click="false" transition="scale-transition" offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="mr-2" :value="getFormatedDate(paid_obligation_on)" dense outlined prepend-icon="mdi-calendar" label="Bezahlt am" readonly v-bind="attrs" v-on="on" />
                                    </template>
                                    <v-date-picker first-day-of-week="1" v-model="paid_obligation_on" no-title @input="menuPaidObligationOn = false"/>
                                </v-menu>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field class="ml-2" type="number" :value="current_obligation.betrag" outlined dense label="Betrag" />
                            </v-col>
                        </v-row>
                        
                        <v-divider></v-divider>

                        <v-card-actions class="d-flex justify-end">
                            <v-btn :color="$store.state.theme.red" text @click="current_obligation = Object.assign({}, default_obligation); delete_obligation = false; showPayObligationDialog = false">
                                Abbrechen
                            </v-btn>
                            <v-btn v-if="delete_obligation" :color="$store.state.theme.green" text @click="deleteObligation(current_obligation)">
                                Löschen
                            </v-btn>
                            <v-btn v-else :color="$store.state.theme.green" text @click="payObligation(current_obligation)">
                                Bezahlen
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <div class="d-flex justify-space-between align-center">
                    <span class="text-h5">Monatliche Zahlungen</span>
                    <v-btn small class="ml-2" :color="$store.state.theme.primary" @click="dialog_add_monthly_payment = true" elevation="0" dark>
                        <v-icon small left>mdi-plus</v-icon>Hinzufügen
                    </v-btn>
                </div>

                <v-card elevation="1" class="mt-2" :height="240 + 48 * (debit_accounts.length - 2) + 48 * (amount_should_save > 0)">
                    <v-tabs v-model="selected_year_month" :background-color="$store.state.theme.primary" dark>
                    <v-tab  v-for="item in getActiveMonths" :key="item.id">
                        {{ item.jahr_monat }}
                    </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="selected_year_month">
                        <v-tab-item  v-for="item in getActiveMonths" :key="item.id" >
                            <v-simple-table fixed-header height="190">
                                <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            Bezeichnung
                                        </th>
                                        <th class="text-left">
                                            Fällig am
                                        </th>
                                        <th class="text-left">
                                            Betrag
                                        </th>
                                        <th class="text-left">
                                            Bezahlt am
                                        </th>
                                    </tr>
                                    
                                </thead>
                                <tbody>
                                    <tr v-for="obligation in getSortedObligations(item.datum, obligations)" :key="obligation.id">
                                        <td>{{ obligation.beschreibung }}</td>
                                        <td>{{ getFormatedDateForSelectedMonth(item.datum, obligation.zahlung_am) }}</td>
                                        <td>{{ getFormatedNumber(obligation.betrag) }}</td>
                                        <td class="d-flex justify-space-between align-center">
                                            <v-btn v-if="!isObligationPaid(item.datum, obligation)" rounded x-small elevation="1" :color="$store.state.theme.green" dark @click="{current_obligation = Object.assign({}, obligation); showPayObligationDialog = true}">
                                                Bezahlen
                                            </v-btn>
                                            <span v-else>
                                                {{ getFormatedDate(isObligationPaid(item.datum, obligation)) }}
                                            </span>
                                            <v-btn v-if="obligation.aktiv" fab text x-small @click="{current_obligation = Object.assign({}, obligation); delete_obligation = true; showPayObligationDialog = true}">
                                                <v-icon x-small dark :color="$store.state.theme.red">mdi-delete</v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                                </template>
                            </v-simple-table>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-col>
            <v-col cols="3">
                <div class="d-flex justify-space-between">
                    <span class="text-h5">SVS</span>
                    <v-btn v-if="changedSVSData" small outlined dark :color="$store.state.theme.green" @click="saveSVSData">
                        Speichern
                    </v-btn>
                    <v-btn v-else small elevation="0" dark :color="$store.state.theme.green" @click="showSVSPaymentInfo = true">
                        Bezahlen
                    </v-btn>
                </div>
                
                <v-card elevation="1" class="mt-2" :height="240 + 48 * (debit_accounts.length - 2) + 48 * (amount_should_save > 0)">
                    <div v-if="hasSVSTransactions === null">
                        <v-skeleton-loader type="list-item-two-line" />
                    </div>
                    <div v-else>
                        <v-row no-gutters class="d-flex flex-column px-5 py-2">
                            <span class="text-button">SVS Rücklagen</span>
                            <v-slider persistent-hint hint="(Prozent vom Umsatz)" class="mb-0 pb-0" :color="$store.state.theme.primary" v-model="savings_rate" @change="changedSVSData = true">
                                <template v-slot:prepend>
                                    <v-icon>mdi-piggy-bank-outline</v-icon>
                                </template>
                                <template v-slot:append>
                                    <v-chip small>{{ savings_rate }}%</v-chip>
                                </template>
                            </v-slider>
                        </v-row>
                        <span class="text-button px-5">Nächste Zahlung {{ svs_quarter }}</span>                    
                        <v-row no-gutters class="px-5 mt-2">
                            
                            <v-col cols="6">
                                <v-text-field class="mr-2" v-model="svs_payment" dense outlined type="number" label="Betrag" @change="changedSVSData = true"/>
                            </v-col>
                            <v-col cols="6">
                                <v-menu v-model="menuDueDate" :close-on-content-click="false" transition="scale-transition" offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="ml-2" v-model="computedSVSDueDateFormatted" dense outlined label="Fällig am" hint="" readonly v-bind="attrs" v-on="on" />
                                    </template>
                                    <v-date-picker first-day-of-week="1" v-model="svs_due_date" no-title @input="{menuDueDate = false; changedSVSData = true}"/>
                                </v-menu>     
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
            </v-col>
        </v-row>    

        <div class="text-center">
        <v-dialog v-model="showSVSPaymentInfo" persistent width="500" >

            <v-card >
                <v-card-title>SVS Quartalszahlung ({{ svs_quarter }})</v-card-title>
                <v-stepper v-model="stepSVSPayment">
                    <v-stepper-header>
                        <v-stepper-step :color="$store.state.theme.primary" :complete="stepSVSPayment > 1" step="1" >
                            Zahlung tätigen
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step :color="$store.state.theme.primary" step="2" >
                            Nächste Zahlung festlegen
                        </v-stepper-step>
                    </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">
                        <div>
                            <p class="text-body1 my-5 text-justify">
                                Bitte überweise den Betrag von <b>{{ getFormatedNumber(this.svs_payment) }}</b> auf dein SVS Konto.
                                Wenn du die Zahlung getätigt hast, gib bitte das Datum deiner Zahlung an und klicke anschließend auf <b>BEZAHLT</b>.
                            </p>

                            <v-row class="mt-5">
                                <v-col v-if="!using_one_business_account" cols="6">
                                    <v-select v-model="pay_svs_from_account_id" dense outlined :items="debit_accounts" item-text="text" item-value="id" label="Zu belastendes Konto" />
                                </v-col>
                                <v-col :cols="using_one_business_account ? 12 : 6">
                                    <v-menu v-model="menuSVSPaidOn" :close-on-content-click="false" transition="scale-transition" offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="computedSVSPaidOnFormatted" dense outlined label="Bezahlt am" hint="" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" />
                                        </template>
                                        <v-date-picker width="460" first-day-of-week="1" v-model="svs_paid_on" no-title @input="menuSVSPaidOn = false"/>
                                    </v-menu>
                                </v-col>
                                
                            </v-row>
                            

                            <v-divider></v-divider>

                            <div class="d-flex justify-end mt-2">
                                <v-btn :color="$store.state.theme.red" text @click="showSVSPaymentInfo = false" >
                                    Abbrechen
                                </v-btn>
                                <v-btn :color="$store.state.theme.green" text @click="payedSVSQuarterly" >
                                    Bezahlt
                                </v-btn>
                            </div>
                        </div>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <div>
                            <p class="text-body1 my-5 text-justify">
                                Wieviel beträgt deine <b>nächste</b> SVS Quartalszahlung und wann ist diese fällig?
                            </p>

                            <v-row no-gutters class="mt-5">
                                <v-col>
                                    <v-text-field class="mr-2" v-model="svs_payment" label="Betrag" outlined dense prepend-icon="mdi-cash-sync" type="number"></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-menu v-model="menuDueDatePaid" :close-on-content-click="false" transition="scale-transition" offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field class="ml-2" v-model="computedSVSDueDateFormatted" dense outlined label="Fällig am" hint="" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" />
                                        </template>
                                        <v-date-picker first-day-of-week="1" v-model="svs_due_date" no-title @input="menuDueDatePaid = false"/>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>

                            <div class="d-flex justify-end mt-2">
                                <v-btn :color="$store.state.theme.green" text @click="saveUpdatedSVSQuarterlyPayment" >
                                Speichern
                                </v-btn>
                            </div>
                        </div>
                    </v-stepper-content>

                </v-stepper-items>
            </v-stepper>
            </v-card>
        </v-dialog>
        </div>
        
        <v-data-table dense :headers="headers" :search="search" :items="allTransactions" :sort-by="sortItems" @update:sort-by="updateSortItems" :sort-desc="sortByDesc" @update:sort-desc="updateSortItemsDesc" class="elevation-1 mt-5">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>
                        Konto-Transaktionen
                    </v-toolbar-title>
                    <v-spacer/>
                    <v-text-field v-model="search" prepend-icon="mdi-magnify" label="Suche" single-line outlined dense hide-details />
                    <v-spacer/>
                    <!-- <v-menu rounded offset-y>
                        <template v-slot:activator="{ attrs, on }">
                            <v-btn v-bind="attrs" v-on="on" class="mb-2 mr-2">
                                <v-icon left>mdi-export</v-icon>
                                Abschluss
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item v-for="item in $store.state.active_years" :key="item.jahr" link>
                                <v-list-item-title @click="loadAndExportExcel(item.jahr)" v-text="item.jahr"></v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu> -->
                    <v-dialog v-model="dialog" persistent max-width="700">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn :color="$store.state.theme.primary" dark class="mb-2" v-bind="attrs" v-on="on">
                            <v-icon left dark>mdi-plus</v-icon>
                            Neue Transaktion
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col :cols="editedItem.kategorie === enum_categories['Ausgaben'] ||  editedItem.kategorie === enum_categories['Eigenübertrag'] ? 4 : 12">
                                        <v-select dense outlined v-model="editedItem.kategorie" :items="categories" item-text="text" item-value="id" label="Kategorie" @input="changedAmount"/>
                                    </v-col>
                                    <v-col v-if="editedItem.kategorie === enum_categories['Ausgaben']" :cols="editedItem.verteiler === itemsExpenses.find((expense) => expense.text === 'SVS').id ? 4: 8">
                                        <v-select dense outlined v-model="editedItem.verteiler" :items="itemsExpenses" item-text="text" item-value="id" label="Verteiler"/>
                                    </v-col>
                                    <v-col v-if="editedItem.kategorie === enum_categories['Eigenübertrag']" cols="4">
                                        <v-select dense outlined v-model="editedItem.fk_konto_id" label="Zu belastendes Konto" :items="debit_accounts.filter((account) => account.id !== editedItem.fk_konto_2_id)" item-text="text" item-value="id"></v-select>
                                    </v-col>
                                    <v-col v-if="editedItem.kategorie === enum_categories['Eigenübertrag']" cols="4">
                                        <v-select dense outlined v-model="editedItem.fk_konto_2_id" label="Zu begünstigendes Konto" :items="debit_accounts.filter((account) => account.id !== editedItem.fk_konto_id)" item-text="text" item-value="id" @input="changedAmount"></v-select>
                                    </v-col>
                                    <v-col v-if="editedItem.verteiler === itemsExpenses.find((expense) => expense.text === 'SVS').id" cols="4">
                                        <v-select dense outlined v-model="editedItem.fk_konto_id" label="Zu belastendes Konto" :items="debit_accounts" item-text="text" item-value="id"></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        <v-text-field dense outlined v-model="editedItem.beschreibung" label="Beschreibung"/>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field dense outlined v-model="computedDateFormatted" label="Datum" hint="" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" />
                                            </template>
                                            <v-date-picker first-day-of-week="1" v-model="editedItem.datum" no-title @input="menu = false"/>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="5">
                                        <v-text-field dense outlined :disabled="disableAmountOnSavingsTransaction" :suffix="transactionAmountSuffix" type="number" @input="changedAmount" v-model="editedItem.betrag" label="Betrag"/>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer/>
                            <v-btn :color="$store.state.theme.red" text @click="close">
                                Abbrechen
                            </v-btn>
                            <v-btn :color="$store.state.theme.green" text @click="saveTransaction">
                                Speichern
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="600px">
                <v-card>
                    <v-card-title class="text-h5">Soll dieser Eintrag wirklich gelöscht werden?</v-card-title>
                    <v-card-actions>
                    <v-spacer/>
                    <v-btn :color="$store.state.theme.red" text @click="closeDelete">Nein</v-btn>
                    <v-btn :color="$store.state.theme.green" text @click="deleteItemConfirm">Ja</v-btn>
                    <v-spacer/>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-toolbar>
            </template>
            <template v-slot:item.kategorie="{ item }">
                <span>
                    {{ categories_mapping[item.kategorie] }} 
                    {{ item.kategorie === enum_categories['Einbuchung'] || item.kategorie === enum_categories['Eigenübertrag'] ? '(' + item.konto_beschreibung  + ')' : '' }}
                    {{ item.verteiler ? '(' + itemsExpenses.find((expense) => expense.id === item.verteiler).text  + ')' : '' }}
                </span>
            </template>
            <template v-slot:item.betrag="{ item }">
                <span v-if="item.betrag > 0" class="green--text font-weight-medium">
                    {{ item.betrag.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}
                </span>
                <span v-else class="font-weight-medium">
                    {{ item.betrag.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}
                </span>
                <!-- <v-chip v-if="item.kategorie === enum_categories['Einnahmen']" :color="item.svs_prozent_ruecklagen !== null ? $store.state.theme.green : $store.state.theme.red" dark small label class="ml-2">
                    <span class="font-weight-medium">R</span>
                </v-chip> -->
                
            </template>
            <template v-slot:item.datum="{ item }">
                <span>{{ new Date(item.datum).toLocaleString([], {year: 'numeric', month: '2-digit', day: '2-digit'}) }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn v-if="item.deletable !== false" class="mx-1" elevation="0" fab text x-small :color="$store.state.theme.primary" @click="editItem(item)">
                    <v-icon x-small dark>mdi-pencil</v-icon>
                </v-btn>
                <v-btn v-if="item.deletable !== false" class="mx-1" elevation="0" fab text x-small color="#f44336" @click="deleteItem(item)">
                    <v-icon x-small dark>mdi-delete</v-icon>
                </v-btn>
            </template>
            <template v-slot:no-data >
            <div v-if="loading" class="text-center">
                <v-progress-circular indeterminate color="primary"/>
            </div>
            <div v-else>
                <span class="mt-5">Es sind noch keine Transaktionen angelegt.</span>
            </div>
            </template>
        </v-data-table>
    </div>
  </v-container>
</template>

<script>
import connector from '../helpers/supabase-connector.js'
import cipher from '../helpers/cipher.js'
import StatsCard from '../components/StatsCard.vue'
import moment, { months } from 'moment'
import dayjs from 'dayjs'
import { saveAs } from 'file-saver'
import XLSX from "xlsx";

export default {
    props: ['session'],
    components: {
        StatsCard,
    },
    data() {
        return {

            // ########## SETUP VARIABLES ##########
            using_separate_reserve_account: true,
            using_separate_svs_account: true,
            svs_account_name: 'SVS-Space',


            // ########## DATA TABLE VARIABLES ##########

            enum_categories: {
                'Einbuchung': 0,
                'Einnahmen': 1,
                'Ausgaben': 2,
                // 'Miete': 3,
                // 'Rücklagen - Weglegung': 4,
                // 'SVS Teilzahlung': 5,
                // 'SVS Nachzahlung aus Rücklagen': 6,
                // 'Einbuchung - Rücklagen': 7,
                // 'Einbuchung - SVS': 8,
                // 'Einbuchung': 9,
                'Persönliche Gehaltszahlung': 10,
                'SVS Quartalszahlung': 11,
                'Eigenübertrag': 12,
            },

            categories_mapping: {
                0: 'Einbuchung',
                1: 'Einnahmen',
                2: 'Ausgaben',
                // 3: 'Miete',
                // 4: 'Rücklagen - Weglegung',
                // 5: 'SVS Teilzahlung',
                // 6: 'SVS Nachzahlung aus Rücklagen',
                //7: 'Einbuchung', // legacy rücklagen
                //8: 'Einbuchung', // legacy svs
                9: 'Einbuchung', // legacy konto
                10: 'Persönliche Gehaltszahlung',
                11: 'SVS Quartalszahlung',
                12: 'Eigenübertrag',
            },
            default_category: {id: 2, text: 'Ausgaben' },
            categories: [
                {id: 2, text: 'Ausgaben' },
                {id: 1, text: 'Sonstige Einnahmen'},
                {id: 12, text: 'Eigenübertrag' },   
                {id: 10, text: 'Persönliche Gehaltszahlung' },
                // {id: 3, text: 'Miete' },
                // {id: 4, text: 'Rücklagen - Weglegung' },
                // {id: 5, text: 'SVS Teilzahlung' },
                // {id: 6, text: 'SVS Nachzahlung aus Rücklagen' },
                // {id: 7, text: 'Einbuchung - Rücklagen' },
                // {id: 8, text: 'Einbuchung - SVS' },
                // {id: 9, text: 'Einbuchung - Konto' },
                // {id: 11, text: 'SVS Quartalszahlung' },
            ],

            using_one_business_account: true,
            main_account_id: null,
            savings_account_id: null,
            svs_account_id: null,

            obligations: [],
            menuPaidObligationOn: null,
            showPayObligationDialog: false,
            paid_obligation_on: null,
            paid_obligation_amount: null,
            current_obligation: {
                id: null,
                beschreibung: null,
                betrag: null,
                datum_start: null,
                zahlung_am: null,
                verteiler: null,
                fk_konto_id: null,
            },
            default_obligation: {
                id: null,
                beschreibung: null,
                betrag: null,
                datum_start: null,
                zahlung_am: null,
                verteiler: null,
                fk_konto_id: null,
            },
            dialog_add_monthly_payment: false,
            menu_datepicker_new_obligation: false,
            already_paid_new_obligation: false,
            delete_obligation: false,

            account_available: 0,
            account_current: 0,
            account_svs: 0,
            account_reserve: 0,
            amount_should_save: 0,
            invoice_ids_for_savings: [],
            other_income_transaction_ids_for_savings: [],
            text_calculation_available_balance: '',
            account_current_deductions: [
                { text: 'Miete', amount: -0 , id: 1, icon: 'mdi-home-outline'},
                { text: 'Rücklagen', amount: -0 , id: 2, icon: 'mdi-piggy-bank-outline'},
                { text: 'SVS Teilzahlung', amount: -0 , id: 3, icon: 'mdi-cash-sync'},
            ],
            itemsExpenses: [
                { id: 1,  text:'Abschreibung als Anlage (AfA)' },
                { id: 2,  text:'Arbeitsmittel / Büro' },
                { id: 3, text: 'Arbeitszimmer' },
                //{ id: 4, text: 'Bewirtung' },
                //{ id: 5, text: 'EDV-Aufwand' },
                { id: 6, text: 'Fachliteratur' },
                { id: 7, text: 'GWG (geringw. Wirtschaftsgut)' },
                //{ id: 8, text: 'KFZ-Aufwand' },
                { id: 9, text: 'Miete' },
                { id: 10, text: 'Porto' },
                { id: 11, text: 'Rechtsberatung' },
                { id: 12, text: 'Reisekosten' },
                { id: 13, text: 'Sonstiges' },
                //{ id: 14, text: 'Spenden' },
                { id: 15, text: 'Steuerberatung' },
                { id: 16, text: 'SVS' },
                { id: 17, text: 'Telefon' },
                { id: 21, text: 'Therapiematerial'},
                { id: 20, text: 'Versicherung'},
                { id: 18, text: 'Weiterbildung' },
                { id: 19, text: 'Werbungskosten' },

            ],
            debit_accounts: [
                {id: 1, text: 'Hauptkonto'},
                {id: 2, text: 'Rücklagen'},
            ],

            setup_paid_rent: false,
            setup_paid_rent_date: null,
            hasSVSTransactions: null,
            changedSVSData: false,
            default_rent: 0,
            savings_rate: 25,
            svs_due_date: null,
            svs_payment: 0,
            svs_quarter: null,
            svs_paid_on: moment().format('YYYY-MM-DD'),

            pay_svs_from_account_id: null,

            disableAmountOnSavingsTransaction: false,
            showSVSPaymentInfo: false,
            stepSVSPayment: 1,
            last_recognition_date: null,
            loading: false,
            dialog_first_steps: false,
            dialog: false,
            dialogDelete: false,
            dialog_all_invoices_marked_as_paid: false,
            menu: false,
            menuDueDate: null,
            menuDueDateSetup: null,
            menuDueDatePaid: null,
            menuPaidRentSetup: null,
            menuSVSPaidOn: null,
            selected_year_month: 0,
            invoices: [],
            transactions: [],
            headers: [
                { text: 'Datum', value: 'datum'},
                // { text: 'Eingetragen am', value: 'inserted_at'},
                { text: 'Beschreibung', value: 'beschreibung'},
                { text: 'Betrag', value: 'betrag', align: "right"},
                { text: 'Kategorie', value: 'kategorie'},
                { text: 'Aktionen', value: 'actions', align: "center", sortable: false },
            ],
            search: '',
            sortItems: ['datum', 'inserted_at'],
            sortByDesc: [true, true],
            editedIndex: -1,
            editedItem: {
                id: null,
                uid: this.session.user.id,
                datum: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString(),
                beschreibung: null,
                betrag: null,
                kategorie: 2, // = Ausgaben
                svs_prozent_ruecklagen: null,
                fk_verpflichtungs_id: null,
                fk_konto_id: null,
                fk_konto_2_id: null,
                verteiler: null,
            },
            defaultItem: {
                id: null,
                uid: this.session.user.id,
                datum: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString(),
                beschreibung: null,
                betrag: null,
                kategorie: 2, // = Ausgaben
                svs_prozent_ruecklagen: null,
                fk_verpflichtungs_id: null,
                fk_konto_id: null,
                fk_konto_2_id: null,
                verteiler: null,
            }
        }
    },

    computed: {

        finishedLoadingAccountData () {
            return !this.loading
        },

        allTransactions() {
            return this.transactions.concat(this.transactionsFromInvoices)
        },

        transactionAmountSuffix() {
            switch (this.editedItem.kategorie) {
                
                case this.enum_categories['Rücklagen - Weglegung']:
                    return '(soll: ' + this.amount_should_save.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) + ')'
                case this.enum_categories['Persönliche Gehaltszahlung']:
                    return '(max. ' + this.displayAccountAvailable.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) + ')'
                default:
                    return ''
            }
        },

        transactionsFromInvoices() {
            return this.invoices.filter((invoice) => invoice.bezahlt).map((invoice) => {
                return {
                    id: 'inv_' + invoice.id,
                    datum: invoice.bezahlt,
                    beschreibung: invoice.nummer + ' | ' + invoice.nachname + ' ' + invoice.vorname + (invoice.bar ? ' (In bar erhalten, verbucht als Persönliche Gehaltszahlung)' : ''),
                    nummer: invoice.nummer,
                    bar: invoice.bar,
                    betrag: invoice.rechnungs_betrag,
                    svs_prozent_ruecklagen: invoice.svs_prozent_ruecklagen,
                    kategorie: this.enum_categories['Einnahmen'],
                    deletable: false,
                    inserted_at: invoice.updated_at, // use updated_at as inserted_at due to the fact it is updated when the invoice is marked as paid
                    updated_at: invoice.updated_at
                }
            })
        },
        formTitle () {
            return this.editedIndex === -1 ? 'Neue Transaktion' : 'Transaktion bearbeiten'
        },
        computedDateFormatted () {
            return this.formatDate(this.editedItem.datum)
        },

        computedSVSDueDateFormatted () {
            return this.formatDate(this.svs_due_date)
        },

        computedSVSPaidOnFormatted () {
            return this.formatDate(this.svs_paid_on)
        },

        computedSwitchPaidRentDate () {
            return this.setup_paid_rent_date 
        },

        // return element in array with id


        computedPaidRentSetup () {
            return this.formatDate(this.setup_paid_rent_date)
        },

        getQuarters() {
            let currentYear = moment().year()
            return [
                'Q4 ' + (currentYear - 1),
                'Q1 ' + currentYear,
                'Q2 ' + currentYear,
                'Q3 ' + currentYear,
                'Q4 ' + currentYear,
                'Q1 ' + (currentYear + 1),
            ]
        },

        // ########################################## KONTOSTAND ##########################################

        displayAccountCurrent() {
            let filtered_transactions = this.transactions.filter((transaction) => moment(transaction.datum) >= moment(this.last_recognition_date).startOf('day') && 
                                                                                  transaction.fk_konto_id === this.main_account_id 
                                                                )
            // the above line is problematic, as it also includes transactions (e.g. erste Miete) which where on the same day as the last recognition date
            // TODO: fix this (probably using the inserted_at timestamps)

            return filtered_transactions.concat(this.transactionsFromInvoices
                                                    .filter((transaction) => moment(transaction.datum) >= moment(this.last_recognition_date).startOf('day') && 
                                                                             moment(transaction.updated_at) > moment(this.last_recognition_date) && 
                                                                             transaction.bar !== true
                                                    )
                                        )                                        
                                        .reduce((acc, transaction) => acc + transaction.betrag, 0)

        },

        displayAccountReserves() {
            let filtered_transactions = this.transactions.filter((transaction) => moment(transaction.datum) >= moment(this.last_recognition_date).startOf('day') && 
                                                                                  transaction.fk_konto_id === this.savings_account_id 
                                                                )


            // calculate amount for savings
            let invoice_transactions = this.getInvoicesSinceSetup.filter((transaction) => transaction.svs_prozent_ruecklagen === null)
            let other_income_transactions = this.transactions.filter((transaction) => transaction.kategorie === this.enum_categories['Einnahmen'] && 
                                                                                         transaction.svs_prozent_ruecklagen === null && 
                                                                                         moment(transaction.datum) >= moment(this.last_recognition_date).startOf('day') && 
                                                                                         transaction.fk_konto_id === this.main_account_id
                                                                )

            let amount_savings = invoice_transactions.concat(other_income_transactions).reduce((acc, transaction) => acc + transaction.betrag * (this.savings_rate / 100) , 0)
            this.amount_should_save = amount_savings
            this.invoice_ids_for_savings = invoice_transactions.map((transaction) => parseInt(transaction.id.split('_')[1]))
            
            this.other_income_transaction_ids_for_savings = other_income_transactions.map((transaction) => transaction.id)

            if (this.using_one_business_account) {
                return filtered_transactions.reduce((acc, transaction) => acc + transaction.betrag, 0) + amount_savings
            } else {
                return filtered_transactions.reduce((acc, transaction) => acc + transaction.betrag, 0)
            }
        },


        svsShouldHavePaid() {
            let months_until_due_date = moment(this.svs_due_date).startOf('month').diff(moment().startOf('month'), 'months') 
            if (!this.svs_due_date) months_until_due_date = 0

            // get svs payments
            // let svs_payments = this.transactions.filter((transaction) => transaction.kategorie === this.enum_categories['SVS Quartalszahlung'])
            // calculate open
            // calculate should be paid
            // using those two, calculate amount for svs 

            let amount_should_be_paid =  (this.svs_payment / 3) * (3 - months_until_due_date) 
            let amount_svs = amount_should_be_paid //- this.displayAccountSVS // displayAccountSVS is zero if using one account model
            if (!this.svs_payment) amount_svs = 0
            if (months_until_due_date >= 3) amount_svs = 0

            return amount_svs
        },

        displayObligations() {

            let active_obligations = this.obligations.filter((obligation) => obligation.aktiv === true)
            let obligation_transactions = this.transactions.filter((transaction) => transaction.fk_verpflichtungs_id !== null)

            // for each obligation, get the start date and iterate through each month until the current month
            // then, for each month, check if the obligation is paid or not
            // if not paid, add the amount to the total amount
            let total_amount = 0
            active_obligations.forEach((obligation) => {
                let start_date = moment(obligation.datum_start)  
                while (start_date.isSameOrBefore(moment(), 'month')) {
                    let obligation_transaction = obligation_transactions.find((transaction) => transaction.fk_verpflichtungs_id === obligation.id && moment(transaction.datum).isSame(start_date, 'month'))
                    if (!obligation_transaction) {
                        total_amount += obligation.betrag
                    }
                    start_date.add(1, 'month')
                }
            })

            return total_amount

        },

        displayAccountAvailable() {
            if (this.using_one_business_account) return this.displayAccountCurrent - this.displayAccountReserves - this.svsShouldHavePaid - this.displayObligations
            else {
                if (this.displayOtherAccounts.length > 0) {
                    // separat svs account

                    return this.displayAccountCurrent - this.displayObligations - this.svsShouldHavePaid - this.amount_should_save + this.displayOtherAccounts[0].value
                } else {
                    // there is no seperate svs account
                    return this.displayAccountCurrent - this.displayObligations - this.svsShouldHavePaid - this.amount_should_save
                }
                
            }
        },


        displayOtherAccounts() {
            
            let other_accounts = this.debit_accounts.filter((account) => account.id !== this.main_account_id && account.id !== this.savings_account_id).map((account) => account.id)

            let account_values = []
            other_accounts.forEach((account_id) => {
                let account = this.debit_accounts.find((account) => account.id === account_id)
                let account_value = this.transactions.filter((transaction) => moment(transaction.datum) >= moment(this.last_recognition_date).startOf('day') && 
                                                                               transaction.fk_konto_id === account_id 
                                                            )
                                                     .reduce((acc, transaction) => acc + transaction.betrag, 0)
                account_values.push({name: account.text, value: account_value})
            })

            return account_values
        },

        // displayAccountAvailable() {
        //     if (this.transactions.length === 0) return 0

        //     // calculate amount for rent
        //     let amount_rent = this.rentShouldHavePaid

        //     // calculate amount for savings
        //     let invoice_transactions = this.getInvoicesSinceSetup.filter((transaction) => transaction.svs_prozent_ruecklagen === null)
        //     let amount_savings = invoice_transactions.reduce((acc, transaction) => acc + transaction.betrag * (this.savings_rate / 100) , 0)
        //     this.amount_should_save = amount_savings
        //     this.invoice_ids_for_savings = invoice_transactions.map((transaction) => transaction.id)

        //     // calculate amount for svs
        //     let amount_svs = this.svsShouldHavePaid

        //     let currentBalance = this.displayAccountCurrent

        //     // this.text_calculation_available_balance = 'Hauptkonto: ' + currentBalance.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) + 
        //     //                                           '<br/>- ' + amount_rent.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) + ' (Miete) = ' + (currentBalance - amount_rent).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) +
        //     //                                           '<br/>- ' + amount_savings.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) + ' (Rücklagen) = ' + (currentBalance - amount_rent - amount_savings).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) + 
        //     //                                           '<br/>- ' + amount_svs.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) + ' (SVS Teilzahlung) = <b>' + (currentBalance - amount_rent - amount_savings - amount_svs).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) + '</b>'


        //     // add svs part payments for next months
        //     // if (months_until_due_date > 0) this.text_calculation_available_balance += '<hr>'
        //     // for (let i = 0; i < months_until_due_date; i++) {
        //     //     let amount_svs_left_per_month =  (this.svs_payment / 3) 
        //     //     this.text_calculation_available_balance += 'Folgemonat: ' + amount_svs_left_per_month.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) + ' (SVS Teilzahlung)'
        //     //     if (i < months_until_due_date - 1) this.text_calculation_available_balance += '<br/>'
        //     // }


        //     // add deductions
        //     this.account_current_deductions = []

        //     this.account_current_deductions.push({ text: 'Miete', amount: -amount_rent , id: 1, icon: 'mdi-home-outline'})
        //     this.account_current_deductions.push({ text: 'Rücklagen', amount: -amount_savings , id: 2, icon: 'mdi-piggy-bank-outline'})
        //     this.account_current_deductions.push({ text: 'SVS Teilzahlung', amount: -amount_svs , id: 3, icon: 'mdi-cash-sync'})

        //     return currentBalance - amount_rent - amount_savings - amount_svs
        // },
        

        // ##########################################   




        getActiveMonths() {
            if (this.transactions.length === 0) return []

            let rent_transactions = this.transactions.filter((transaction) => transaction.kategorie === this.enum_categories['Miete']).map((transaction) => moment(transaction.datum).format('MM-YYYY'))
            // get minimum month and year
            let min_date = moment.min(this.transactions.map((transaction) => moment(transaction.datum)))
            let today = moment()
            let months = []
            let currentId = 0

            while (min_date.isSameOrBefore(today, 'month')) {
                let month_item = { 
                    jahr_monat: min_date.locale('de').format('MMMM YYYY'), 
                    datum: min_date.format('YYYY-MM-DD'), 
                    id: currentId 
                }
                
                if (!rent_transactions.includes(min_date.format('MM-YYYY'))) {
                    month_item['paid'] = false
                } else {
                    month_item['paid'] = true
                }

                months.push(month_item)
                min_date.add(1, 'month')
                currentId++
            }

            return months
        },

        getSelectedYearAndMonth() {
            if (this.getActiveMonths.length === 0) return ''
            return this.getActiveMonths[this.selected_year_month].jahr_monat
        },

        
        displayPaidIncome() {
            let income_transactions = this.transactions.filter((transaction) => transaction.kategorie === this.enum_categories['Persönliche Gehaltszahlung'])
                                                        .concat(this.transactionsFromInvoices
                                                                     .filter((transaction) => moment(transaction.datum) >= moment(this.last_recognition_date).startOf('day') && 
                                                                                              moment(transaction.updated_at) > moment(this.last_recognition_date) && 
                                                                                              transaction.bar === true
                                                                            )
                                                        )
                                                       .map((transaction) => ({jahr_monat: moment(transaction.datum).locale('de').format('MMMM YYYY'), betrag: transaction.betrag}))
            
            let selected_year_mont_income_transaction = income_transactions.filter((transaction) => transaction.jahr_monat === this.getActiveMonths[this.selected_year_month].jahr_monat)
            
            return selected_year_mont_income_transaction.reduce((acc, transaction) => acc + Math.abs(transaction.betrag), 0)
        },

        displayIncome() {
            let customer_transactions = this.transactionsFromInvoices.map((transaction) => ({jahr_monat: moment(transaction.datum).locale('de').format('MMMM YYYY'), betrag: transaction.betrag}))
            
            let selected_year_month_income_transaction = customer_transactions.filter((transaction) => transaction.jahr_monat === this.getActiveMonths[this.selected_year_month].jahr_monat)
            
            return selected_year_month_income_transaction.reduce((acc, transaction) => acc + Math.abs(transaction.betrag), 0)
        }, 

        displaySpendings() {
            let spending_transactions = this.transactions.filter((transaction) => transaction.kategorie === this.enum_categories['Ausgaben'])
                                                         .map((transaction) => ({jahr_monat: moment(transaction.datum).locale('de').format('MMMM YYYY'), betrag: transaction.betrag}))
            
            let selected_year_month_income_transaction = spending_transactions.filter((transaction) => transaction.jahr_monat === this.getActiveMonths[this.selected_year_month].jahr_monat)
            
            return selected_year_month_income_transaction.reduce((acc, transaction) => acc + Math.abs(transaction.betrag), 0)
        },

        getInvoicesSinceSetup() {
            return this.transactionsFromInvoices.filter((transaction) => moment(transaction.datum) >= moment(this.last_recognition_date).startOf('day') && 
                                                                         moment(transaction.updated_at) > moment(this.last_recognition_date)
                                                       )
        },

        rentShouldHavePaid() {
            let min_date = moment.min(this.transactions.map((transaction) => moment(transaction.datum)))
            let today = moment()

            let unpaid_rent_months = []
            let rent_transactions = this.transactions.filter((transaction) => transaction.kategorie === this.enum_categories['Miete']).map((transaction) => moment(transaction.datum).format('MM-YYYY'))
            
            while (min_date.isSameOrBefore(today, 'month')) {
                if (!rent_transactions.includes(min_date.format('MM-YYYY'))) {
                    unpaid_rent_months.push(min_date.format('MM-YYYY'))
                }
                min_date.add(1, 'month')
            }

            return (unpaid_rent_months.length * this.default_rent) 
        },  

        
    },

    mounted() {
        if (this.$vuetify.breakpoint.smAndUp) this.initialize()
    },

    methods: {


        async downloadAccountingTemplate() {

            let bucket = 'public-templates'
            let filename = 'abschluss.xlsx'
            let path = 'accounting/'
            let content = await connector.downloadFileFromBucket(this, bucket, path, filename);
            return content;

        },

        async loadAndExportExcel(year) {
            try {
                
                // Load the template
                const arrayBuffer = await this.downloadAccountingTemplate(); 
                
                // Parse the loaded file
                const workbook = XLSX.read(arrayBuffer, { cellStyles: true });

                // Remove workbook protection
                // if (workbook.Workbook && workbook.Workbook.WBProps) {
                //     workbook.Workbook.WBProps.lockRevision = false;
                //     workbook.Workbook.WBProps.lockStructure = false;
                //     workbook.Workbook.WBProps.lockWindows = false;
                // }

                // // Remove worksheet protection for each sheet
                // workbook.SheetNames.forEach(sheetName => {
                //     const worksheet = workbook.Sheets[sheetName];
                //     if (worksheet["!protect"]) {
                //         delete worksheet["!protect"];
                //     }
                // });
                
                // Get the first sheet (adjust as needed)
                //#endregion//const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets["Einnahmen"];

                // using the transactionsFromInvoices, add the following data to the excel file
                // - date
                // - description
                // - amount
                // - hard coded 0 for the tax
                // - formula for the net amount (amount - tax)

                let tableData = [];
                let income = 0;
                let spendings = 0;

                this.transactionsFromInvoices.filter((invoice) => dayjs(invoice.datum).year() === year)
                    .map((transaction) => {
                        return [
                            this.getFormatedDate(transaction.datum), 
                            transaction.nummer, 
                            transaction.betrag, 
                            0, 
                            //{t: "n", v: transaction.betrag, f: 'C' + (tableData.length + 2) + '-D' + (tableData.length + 2)}
                        ]
                    })    
                    .concat(
                        this.transactions.filter((transaction) => (dayjs(transaction.datum).year() === year) && (transaction.kategorie === 1)) // sonstige Einnahmen
                        .map((transaction) => {
                            return [
                                this.getFormatedDate(transaction.datum), 
                                transaction.beschreibung, 
                                transaction.betrag, 
                                0, 
                                //{t: "n", v: transaction.betrag, f: 'C' + (tableData.length + 2) + '-D' + (tableData.length + 2)}
                            ]
                        }) 
                    ).sort((a, b) => dayjs(a[0], "DD.MM.YYYY") - dayjs(b[0], "DD.MM.YYYY")).forEach((transaction) => {
                        tableData.push(transaction)
                    }
                    );

                // iterate through the tableData and add the formulas for the net amount
                tableData.forEach((row, index) => {
                    row.push({t: "n", v: row[2], f: 'C' + (index + 2) + '-D' + (index + 2)})
                })

                // calculate the sum of the income
                income = tableData.reduce((acc, row) => acc + row[2], 0)

                XLSX.utils.sheet_add_aoa(worksheet, tableData, { origin: "A2" });


                const worksheet_spendings = workbook.Sheets["Ausgaben"];
                tableData = [];

                this.transactions.filter((transaction) => (dayjs(transaction.datum).year() === year) && (transaction.kategorie === 2)).sort((a, b) => dayjs(a.datum) - dayjs(b.datum)).forEach((transaction) => {
                    tableData.push([
                        this.getFormatedDate(transaction.datum), 
                        transaction.beschreibung, 
                        -transaction.betrag, 
                        0, 
                        {t: "n", v: -transaction.betrag, f: 'C' + (tableData.length + 2) + '-D' + (tableData.length + 2)},
                        this.itemsExpenses.find((item) => item.id === transaction.verteiler).text
                    ])
                });

                // calculate the sum of the spendings
                spendings = tableData.reduce((acc, row) => acc + row[2], 0)

                XLSX.utils.sheet_add_aoa(worksheet_spendings, tableData, { origin: "A2" });


                const worksheet_overview = workbook.Sheets["Übersicht"];

                tableData = [];

                if (year >= 2022) {
                    tableData.push('abzüglich Grundfreibetrag 15%')
                    tableData.push('')
                    tableData.push({t: "n", v: (income - spendings) > 30000 ? -4500 : -(income - spendings) * 0.15, f: 'IF(D27>30000,-4500,-D27*0.15)', z: '#,##0.00 "€";-#,##0.00 "€"' }) 
                } else {
                    tableData.push('abzüglich Grundfreibetrag 13%')
                    tableData.push('')
                    tableData.push({t: "n", v: (income - spendings) > 30000 ? -3900 : -(income - spendings) * 0.13, f: 'IF(D27>30000,-3900,-D27*0.13)', z: '#,##0.00 "€";-#,##0.00 "€"' }) 
                }

                XLSX.utils.sheet_add_aoa(worksheet_overview, [tableData], { origin: "B28" });

                XLSX.writeFile(workbook, "Jahresabschluss_ZEIPSY_" + year + ".xlsx", { compression: false, cellStyles: true });
            } catch (error) {
                console.error("Error processing Excel file:", error);
                // emit error
                this.$emit('showError', {message: error});
            }
        },

        // ##########################################

        displayCustomAccount(account_id) {
            if (account_id === null) return 0
            let filtered_transactions = this.transactions.filter((transaction) => moment(transaction.datum) >= moment(this.last_recognition_date).startOf('day') && 
                                                                                  transaction.fk_konto_id === account_id
                                                                )
            // the above line is problematic, as it also includes transactions (e.g. erste Miete) which where on the same day as the last recognition date
            // TODO: fix this (probably using the inserted_at timestamps)

            return filtered_transactions.reduce((acc, transaction) => acc + transaction.betrag, 0)

        },


        
        initialize() {
            this.loading = true

            connector.getDataOnly(this, 'vwverpflichtungen', 'id', true)
                .then((obligations) => this.obligations = obligations)
                .then(() => connector.getDataOnly(this, 'vwkonten', 'id', true))
                .then((accounts) => {
                    if (accounts.length > 0) {
                        this.main_account_id = accounts.find((account) => account.hauptkonto === true).id
                        this.savings_account_id = accounts.find((account) => account.hauptkonto === false && account.beschreibung === "Rücklagen").id
                        this.debit_accounts = accounts.map((account) => ({text: account.beschreibung, id: account.id}))
                    }
                })
                .then(() => connector.getDataOnly(this, 'vwtransaktionen', ['datum', 'inserted_at'], false))
                .then((transactions) => this.transactions = transactions)
                .then(() => this.transactions.filter((transaction) => transaction.kategorie === this.enum_categories['SVS Quartalszahlung']).length === 0 ? this.hasSVSTransactions = false : this.hasSVSTransactions = true)
                .then(() => this.getActiveMonths.length === 0 ? this.selected_year_month = 0 : this.selected_year_month = this.getActiveMonths[this.getActiveMonths.length - 1].id)
                .then(() => this.transactions.length === 0 ? this.dialog_first_steps = true : this.dialog_first_steps = false )
                .then(() => connector.getDataOnly(this, 'vwkunden', 'id', true))
                .then((client) => this.setClientDefaultValues(client))
                .then(() => connector.getDataOnly(this, 'vwrechnungen', 'datum', false))
                .then((invoices) => cipher.decryptDataAsync(this, invoices, 'invoices', 'loading')) 
        },

        getFormatedNumber(number) {
            if (number !== null) {
                return number.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
            } else {
                return 0
            }
        },

        getFormatedDate(date) {
            return this.formatDate(date)
        },

        getFormatedDateForSelectedMonth(date_selected, date_to_pay) {

            date_to_pay = moment(date_to_pay, 'YYYY-MM-DD')

            let date_str = moment(date_selected).year().toString() + (moment(date_selected).month()+1).toString().padStart(2, '0') + date_to_pay.date().toString().padStart(2, '0')
            let pay_date = moment(date_str, 'YYYYMMDD')
            let days_substracted = 0

            while (!pay_date.isValid()) {
                days_substracted += 1
                date_str = moment(date_selected).year().toString() + (moment(date_selected).month()+1).toString().padStart(2, '0') + (date_to_pay.date() - days_substracted).toString().padStart(2, '0')
                pay_date = moment(date_str, 'YYYYMMDD')
            }

            return pay_date.format('DD.MM.YYYY')
        },


        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('T')[0].split('-')
            return `${day}.${month}.${year}`
        },

        changeAccountModel(use_multiple) {
            this.using_one_business_account = !use_multiple
            // connector.upsertRow(this, 'kunden', {
            // id: this.session.user.id,
            // eigene_vorlage: use_multiple
            // })
            // .then((data) => data ? null : this.usingStandardTemplate = useCustom)
        },

        setClientDefaultValues(client) {
            this.last_recognition_date = client[0].konto_einbuchung
            this.default_rent = client[0].praxis_miete
            this.svs_payment = client[0].svs_quartal_betrag
            this.svs_due_date = client[0].svs_zahlung_am
            this.savings_rate = client[0].svs_prozent_ruecklagen === null ? 25 : client[0].svs_prozent_ruecklagen
            this.svs_quarter = client[0].svs_quartal
            this.using_one_business_account = client[0].ein_konto_modell === null ? true : client[0].ein_konto_modell
        },

        changedSelectedYearMonth(item) {
            // toggle active state
            this.selected_year_month = item.id
        },

        editItem (item) {
            this.editedIndex = item.id
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem (item) {
            this.editedIndex = item.id
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        async deleteItemConfirm () {
            let deleted = await connector.deleteRow(this, 'transaktionen', 'id', this.editedIndex);
            if (!deleted) return;
            await this.initialize();
            this.closeDelete();
        },

        close () {
            this.dialog = false
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        },

        closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        },

        changedAmount() {
            if(this.editedItem.kategorie === this.enum_categories['Eigenübertrag'] && this.editedItem.fk_konto_2_id === this.savings_account_id) {
                this.editedItem.betrag = this.amount_should_save
                this.disableAmountOnSavingsTransaction = true
            } else {
                this.disableAmountOnSavingsTransaction = false
            }
            
            if(this.editedItem.betrag === null && this.editedItem.kategorie === this.enum_categories['Miete']) this.editedItem.betrag = this.default_rent
            if(this.editedItem.betrag === null && this.editedItem.kategorie === this.enum_categories['SVS Quartalszahlung']) this.editedItem.betrag = this.displayAccountSVS

            if (this.editedItem.kategorie !== this.enum_categories['Ausgaben']) this.editedItem.verteiler = null

            if ([this.enum_categories['Ausgaben'],
                 this.enum_categories['Persönliche Gehaltszahlung'],
                //  this.enum_categories['Miete'],
                //  this.enum_categories['Rücklagen - Weglegung'],
                //  this.enum_categories['SVS Teilzahlung'],
                //  this.enum_categories['SVS Quartalszahlung'],
                ].includes(this.editedItem.kategorie)) {
                if (this.editedItem.betrag > 0) this.editedItem.betrag = -this.editedItem.betrag
            } else {
                if (this.editedItem.betrag < 0) this.editedItem.betrag = Math.abs(this.editedItem.betrag)
            }
            
        },

        updateSortItems(sortBy) {
            // to correclty sort items when there are two transactions on the same day.
            if (sortBy.includes('datum')) {
                sortBy.push('updated_at')
            } else {
                sortBy = sortBy.filter((item) => item !== 'updated_at')
            }
            this.sortItems = sortBy
        },

        updateSortItemsDesc(sortByDesc) {
            if (this.sortItems.length === 0) {
                sortByDesc = []
            } else if (this.sortItems.length === 2) {
                sortByDesc = [sortByDesc[0], sortByDesc[0]]
            }
            this.sortByDesc = sortByDesc
        },

        saveInitialAccountValues() {
            let today = new Date(Date.now()).toISOString()
            this.last_recognition_date = today

            let transactions = []
            let accounts = [
                {
                    uid: this.session.user.id,
                    beschreibung: 'Hauptkonto',
                    hauptkonto: true
                },
                {
                    uid: this.session.user.id,
                    beschreibung: 'Rücklagen',
                    hauptkonto: false
                }
            ]

            if (this.using_one_business_account) {
                connector.insertRows(this, 'konten', accounts)
                .then((entries) => {
                    this.main_account_id = entries.find((entry) => entry.hauptkonto === true).id
                    this.savings_account_id = entries.find((entry) => entry.hauptkonto === false).id
                })
                .then(() => connector.insertRow(this, 'verpflichtungen', {
                    uid: this.session.user.id,
                    beschreibung: 'Praxis',
                    datum_start: today,
                    zahlung_am: this.setup_paid_rent_date,
                    betrag: this.default_rent,
                    verteiler: this.itemsExpenses.find((expense) => expense.text === 'Miete').id,
                    aktiv: true,
                    fk_konto_id: this.main_account_id
                }))
                .then((obligation_id) => {

                    transactions = [
                        {
                            uid: this.session.user.id,
                            datum: today,
                            beschreibung: 'Einbuchung',
                            betrag: this.account_current,
                            kategorie: this.enum_categories['Einbuchung'],
                            fk_konto_id: this.main_account_id
                        },
                        {
                            uid: this.session.user.id,
                            datum: today,
                            beschreibung: 'Einbuchung',
                            betrag: this.account_reserve,
                            kategorie: this.enum_categories['Einbuchung'],
                            fk_konto_id: this.savings_account_id
                        }]

                    if (this.setup_paid_rent) {
                        transactions.push({
                            uid: this.session.user.id,
                            datum: this.setup_paid_rent_date,
                            beschreibung: 'Praxis',
                            betrag: -this.default_rent,
                            kategorie: this.enum_categories['Ausgaben'],
                            verteiler: this.itemsExpenses.find((expense) => expense.text === 'Miete').id,
                            fk_verpflichtungs_id: obligation_id,
                            fk_konto_id: this.main_account_id
                        })
                    }
                })
                .then(() => connector.insertRows(this, 'transaktionen', transactions))
                .then(() => connector.upsertRow(this, 'kunden', {
                    id: this.session.user.id,
                    konto_einbuchung: today,
                    svs_zahlung_am: this.svs_due_date,
                    svs_quartal_betrag: this.svs_payment,
                    svs_prozent_ruecklagen: this.savings_rate,
                    svs_quartal: this.svs_quarter,
                    ein_konto_modell: this.using_one_business_account,
                }))
                .then(() => this.initialize())
                .then(() => this.dialog_first_steps = false)

            } else {
                
                if (this.using_separate_svs_account) {
                    accounts.push({
                        uid: this.session.user.id,
                        beschreibung: this.svs_account_name,
                        hauptkonto: false
                    })
                }

                connector.insertRows(this, 'konten', accounts)
                .then((entries) => {
                    this.main_account_id = entries.find((entry) => entry.hauptkonto === true).id
                    this.savings_account_id = entries.find((entry) => entry.hauptkonto === false && entry.beschreibung === 'Rücklagen').id
                    if (this.using_separate_svs_account) {
                        this.svs_account_id = entries.find((entry) => entry.hauptkonto === false && entry.beschreibung === this.svs_account_name).id
                    }
                })
                .then(() => connector.insertRow(this, 'verpflichtungen', {
                    uid: this.session.user.id,
                    beschreibung: 'Praxis',
                    datum_start: today,
                    zahlung_am: this.setup_paid_rent_date,
                    betrag: this.default_rent,
                    verteiler: this.itemsExpenses.find((expense) => expense.text === 'Miete').id,
                    aktiv: true,
                    fk_konto_id: this.main_account_id
                }))
                .then((obligation_id) => {

                    transactions = [
                        {
                            uid: this.session.user.id,
                            datum: today,
                            beschreibung: 'Einbuchung',
                            betrag: this.account_current,
                            kategorie: this.enum_categories['Einbuchung'],
                            fk_konto_id: this.main_account_id
                        },
                        {
                            uid: this.session.user.id,
                            datum: today,
                            beschreibung: 'Einbuchung',
                            betrag: this.account_reserve,
                            kategorie: this.enum_categories['Einbuchung'],
                            fk_konto_id: this.savings_account_id
                        }
                    ]

                    if (this.using_separate_svs_account) {
                        transactions.push({
                            uid: this.session.user.id,
                            datum: today,
                            beschreibung: 'Einbuchung',
                            betrag: this.account_svs,
                            kategorie: this.enum_categories['Einbuchung'],
                            fk_konto_id: this.svs_account_id
                        })
                    }

                    if (this.setup_paid_rent) {
                        transactions.push({
                            uid: this.session.user.id,
                            datum: this.setup_paid_rent_date,
                            beschreibung: 'Praxis',
                            betrag: -this.default_rent,
                            kategorie: this.enum_categories['Ausgaben'],
                            verteiler: this.itemsExpenses.find((expense) => expense.text === 'Miete').id,
                            fk_verpflichtungs_id: obligation_id,
                            fk_konto_id: this.main_account_id
                        })
                    }
                })
                .then(() => connector.insertRows(this, 'transaktionen', transactions))
                .then(() => connector.upsertRow(this, 'kunden', {
                    id: this.session.user.id,
                    konto_einbuchung: today,
                    svs_zahlung_am: this.svs_due_date,
                    svs_quartal_betrag: this.svs_payment,
                    svs_prozent_ruecklagen: this.savings_rate,
                    svs_quartal: this.svs_quarter,
                    ein_konto_modell: this.using_one_business_account,
                }))
                .then(() => this.initialize())
                .then(() => this.dialog_first_steps = false)
            }
        },

        saveTransaction () {
            let toInsert = Object.assign({}, this.editedItem)
            delete toInsert.id
            delete toInsert.konto_beschreibung
            if (toInsert.kategorie !== this.enum_categories['Eigenübertrag'] ) {
                delete toInsert.fk_konto_2_id
            }

            if (toInsert.fk_konto_id === null) toInsert.fk_konto_id = this.main_account_id 

            if (toInsert.kategorie === this.enum_categories['Ausgaben'] && !toInsert.verteiler) {
                this.$emit('showError', {message: 'Bitte wähle einen Verteiler aus!'})
                return
            }

            if (toInsert.kategorie !== this.enum_categories['Ausgaben']) delete toInsert.verteiler

            //if (this.using_one_business_account) toInsert.fk_konto_id = this.main_account_id
            //else this.$emit('showError', {message: 'Noch nicht implementiert!'})

            if (toInsert.kategorie === this.enum_categories['Eigenübertrag'] && toInsert.fk_konto_2_id === this.savings_account_id) {
                toInsert.svs_prozent_ruecklagen = this.savings_rate
            }

            if (this.editedIndex > -1) {
                // updated 
                connector.updateRow(this, 'transaktionen', toInsert, this.editedIndex)
                    .then(() => this.initialize())
                    .then(() => this.close())

            } else {
                // create new

                if (toInsert.kategorie === this.enum_categories['Eigenübertrag'] ) {
                    let beneficiary = toInsert.fk_konto_2_id
                    delete toInsert.fk_konto_2_id
                    let toInsert2 = Object.assign({}, toInsert)
                    toInsert.betrag = -toInsert.betrag // change after copying!

                    toInsert2.fk_konto_id = beneficiary

                    let entries = [
                        toInsert,
                        toInsert2
                    ]

                    connector.insertRows(this, 'transaktionen', entries)
                        .then((rows) => {
                            if (toInsert.kategorie === this.enum_categories['Eigenübertrag'] && toInsert2.fk_konto_id === this.savings_account_id) {
                                if (this.invoice_ids_for_savings.length > 0) {
                                    connector.updateRelationships(this, 'rechnungen', {
                                        fk_transaktions_id: rows[0].id
                                    }, 'id', this.invoice_ids_for_savings)
                                }
                                // check if there are other income transactions that need to be marked as savings
                                if (this.other_income_transaction_ids_for_savings.length > 0) {
                                    connector.updateRelationships(this, 'transaktionen', {
                                        svs_prozent_ruecklagen: this.savings_rate
                                    }, 'id', this.other_income_transaction_ids_for_savings)
                                }
                            }
                        })
                        .then(() => this.initialize())
                        .then(() => this.close())

                } else {
                    connector.insertRow(this, 'transaktionen', toInsert)
                    .then(() => this.initialize())
                    .then(() => this.close())
                }                
            }
        },

        // ############################### SVS Zahlung ###############################

        saveSVSData() {
            connector.upsertRow(this, 'kunden', {
                id: this.session.user.id,
                svs_zahlung_am: this.svs_due_date,
                svs_quartal_betrag: this.svs_payment,
                svs_prozent_ruecklagen: this.savings_rate
            })
            .then(() => this.initialize())
            .then(() => this.changedSVSData = false)
        },

        getNextQuarter() {
            let year = parseInt(this.svs_quarter.split(' ')[1])
            let quarter = this.svs_quarter.split(' ')[0]
            let next_quartal = ''

            if (quarter === 'Q4') {
                year = year + 1
                next_quartal = 'Q1 ' + year
            } else {
                next_quartal = 'Q' + (parseInt(quarter.split('Q')[1]) + 1) + ' ' + year
            }
            return next_quartal
        },

        payedSVSQuarterly() {

            let account_id = this.main_account_id
            if (!this.using_one_business_account) {
                //this.$emit('showError', {message: 'Noch nicht implementiert!'})
                account_id = this.pay_svs_from_account_id
            }

            connector.insertRow(this, 'transaktionen', {
                uid: this.session.user.id,
                datum: this.svs_paid_on,
                beschreibung: this.svs_quarter,
                betrag: -this.svs_payment,
                kategorie: this.enum_categories['Ausgaben'],
                verteiler: this.itemsExpenses.find((item) => item.text === 'SVS').id,
                fk_konto_id: account_id
            })
            .then(() => {
                this.svs_due_date = moment(this.svs_due_date).add(3, 'months').toISOString()
                this.svs_quarter = this.getNextQuarter()
                this.stepSVSPayment++
            })
        },

        saveUpdatedSVSQuarterlyPayment() {
            connector.upsertRow(this, 'kunden', {
                id: this.session.user.id,
                svs_zahlung_am: this.svs_due_date,
                svs_quartal_betrag: this.svs_payment,
                svs_quartal: this.svs_quarter
            })
            .then(() => this.initialize())
            .then(() => this.showSVSPaymentInfo = false)
        },

        // ################# MONATLICHE ZAHLUNGEN ####################

        payObligation(obligation) {
            connector.insertRow(this, 'transaktionen', {
                uid: this.session.user.id,
                datum: this.paid_obligation_on,
                beschreibung: obligation.beschreibung,
                betrag: -obligation.betrag,
                kategorie: this.enum_categories['Ausgaben'],
                verteiler: obligation.verteiler,
                fk_verpflichtungs_id: obligation.id,
                fk_konto_id: obligation.fk_konto_id
            })
            .then(() => this.initialize())
            .then(() => this.showPayObligationDialog = false)
        },

        addObligation(obligation) {
            let toInsert = Object.assign({}, obligation)
            toInsert.kategorie = this.enum_categories['Ausgaben']

            connector.insertRow(this, 'verpflichtungen', {
                uid: this.session.user.id,
                datum_start: moment().locale('de').format('YYYY-MM-DD'),
                zahlung_am: toInsert.zahlung_am,
                beschreibung: toInsert.beschreibung,
                betrag: toInsert.betrag,
                verteiler: toInsert.verteiler,
                aktiv: true,
                fk_konto_id: this.main_account_id // currently only supported from main account
                //this.using_one_business_account ? this.main_account_id : this.$emit('showError', {message: 'Noch nicht implementiert!'})
            })
            .then((id) => {
                if (this.already_paid_new_obligation) {
                    connector.insertRow(this, 'transaktionen', {
                        uid: this.session.user.id,
                        datum: toInsert.zahlung_am,
                        beschreibung: toInsert.beschreibung,
                        betrag: -toInsert.betrag,
                        kategorie: toInsert.kategorie,
                        fk_verpflichtungs_id: id,
                        verteiler: toInsert.verteiler,
                        fk_konto_id: this.main_account_id // currently only supported from main account
                        //this.using_one_business_account ? this.main_account_id : this.$emit('showError', {message: 'Noch nicht implementiert!'})
                    })
                }
                this.already_paid_new_obligation = false
                this.current_obligation = Object.assign({}, this.default_obligation)
            })
            .then(() => this.initialize())
            .then(() => this.dialog_add_monthly_payment = false)
        },

        isObligationPaid(selected_date, obligation) {
            // get all transactions for this obligation
            let obligation_transactions = this.transactions.filter((transaction) => transaction.fk_verpflichtungs_id === obligation.id)

            // check if there is a transaction in the same month and year for the selected date
            let paid_transactions = obligation_transactions.filter((transaction) => {
                return moment(transaction.datum).isSame(selected_date, 'month')
            })

            if (paid_transactions.length > 1) {
                console.log('WARNING: more than one transaction for obligation ' + obligation.id + ' in month ' + selected_date)
            } 

            return paid_transactions.length > 0 ? paid_transactions[0].datum : null  
        },

        getSortedObligations(selected_date, obligations) {
            let sorted_obligations = []
            for (let i = 0; i < obligations.length; i++) {

                // check if obligation is already active on selected date
                if (moment(obligations[i].datum_start).isAfter(selected_date, 'month')) {
                    continue
                }

                let paid = this.isObligationPaid(selected_date, obligations[i])

                // check if obligation is inactive and not paid on selected date
                if (paid === null && !obligations[i].aktiv) {
                    continue
                }

                let copied_obligation = Object.assign({}, obligations[i])
                if (paid !== null) {
                    sorted_obligations.push()
                    copied_obligation.sort_date = moment(paid)
                } else {
                    copied_obligation.sort_date = moment('9999-12-31', 'YYYY-MM-DD')
                }
                sorted_obligations.push(copied_obligation)
            }
            sorted_obligations.sort((a, b) => b.sort_date.diff(a.sort_date))
            return sorted_obligations
        },

        deleteObligation(obligation) {
            connector.updateRow(this, 'verpflichtungen', {
                uid: this.session.user.id,
                aktiv: false
            }, obligation.id)
            .then(() => this.initialize())
            .then(() => {
                this.delete_obligation = false
                this.showPayObligationDialog = false
            })
        }
    }
}
</script>

<style>
.custom-tooltip {
    opacity: 1!important;
}
</style>