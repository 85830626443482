<template>
    <v-card>
        <v-card-title class="d-flex flex-column align-start text-subtitle-1 text-sm-h6">
            <span>Rechnung Nr. {{ editedItem.nummer }} wurde bezahlt</span>
            <div v-if="editedItem.selected_customer" class="d-flex align-center">
                <div v-if="editedItem.selected_customer.type === 'client'">
                    <v-icon small left>mdi-account</v-icon>
                    <span class="text-body-2">{{ editedItem.selected_customer.name }}</span>
                    <v-chip v-for="merkmal in editedItem.selected_customer.merkmale" :key="'chip-' + merkmal.id" class="ml-2 font-weight-regular" small>{{ merkmal.merkmal }}</v-chip>
                </div>
                <div v-else-if="editedItem.selected_customer.type === 'institution'">
                    <v-icon small left>mdi-account</v-icon>
                    <span class="text-body-2">{{ editedItem.selected_customer.name }}</span>
                    <v-chip class="ml-2 font-weight-regular" small>Institution</v-chip>
                </div>
            </div>
        </v-card-title>

        <v-card-text>
            <v-form ref="form" v-model="formValid">
                <v-row class="d-flex align-start mt-5">
                    <v-col cols="12" sm="6" class="d-flex align-start my-0 py-0 order-2 order-sm-1">
                        <v-text-field 
                            dense 
                            outlined 
                            v-model="paidDateText"
                            label="Bezahlt am" 
                            prepend-inner-icon="mdi-calendar"
                            :rules="dateRule"
                            @input="updateDatePicker"
                            class="font-weight-medium"
                        />
                    </v-col>
                    <v-col cols="12" sm="6" class="d-flex align-start justify-end my-0 pb-0 pt-1 pr-0 order-1 order-sm-2">
                        <p class="text-body-2 mr-5 pt-1">Betrag in bar erhalten</p>
                        <v-switch :disabled="editedItem.bezahlt === null" v-model="editedItem.bar" inset dense
                            :color="$store.state.theme.green" hide-details class="mt-0">
                        </v-switch>
                    </v-col>
                </v-row>
            </v-form>

            <v-date-picker 
                full-width 
                first-day-of-week="1" 
                v-model="editedItem.bezahlt" 
                :color="$store.state.theme.green"
                no-title 
                elevation="1"
                @input="updateTextField" 
                class="mt-2 py-3"
            />

            <v-row class="d-flex justify-center align-center my-1">
                <span class="text-caption-1">
                    <v-icon left small> mdi-cursor-default-click-outline </v-icon>
                    Gib an wann die Rechnung bezahlt wurde.
                </span>
            </v-row>

            <v-alert v-if="editedIndex > -1 && editedItem.beleg_nummer !== null && editedItem.bar === false"
                outlined type="warning" prominent border="left">
                <span class="font-weight-bold">Achtung:</span>
                Du hast bereits einen Beleg für diese Rechnung erstellt.
                Wenn du die Barzahlung rückgängig machst und jetzt speicherst, wird der Beleg gelöscht!
            </v-alert>
        </v-card-text>

        <v-card-actions class="px-6 pb-5">
            <v-spacer></v-spacer>
            <v-btn :color="$store.state.theme.primary" text @click="$emit('close')">
                Abbrechen
            </v-btn>
            <v-btn :color="$store.state.theme.green" outlined @click="save" :loading="updating_invoice" :disabled="!editedItem.selected_customer || !formValid">
                Speichern
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import dayjs from 'dayjs';
import connector from '../helpers/supabase-connector.js';

export default {
    props: ['session', 'editedItem', 'editedIndex', 'invoices'],
    emits: ['close', 'initialize', 'showError', 'showInfo'],

    data() {
        return {
            updating_invoice: false,
            paidDateText: '',
            formValid: true,

            dateRule: [
                v => !!v || 'Zahlungsdatum wird benötigt.',
                v => /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(\d{4})$/.test(v) || 'Datum im Format TT.MM.JJJJ angeben.',
            ],
        }
    },

    computed: {

        computedPaidFormatted() {
            return this.formatDate(this.editedItem.bezahlt)
        },
    },

    watch: {
        'editedItem.bezahlt': {
            immediate: true,
            handler(newVal) {
                this.paidDateText = this.formatDate(newVal);
            }
        }
    },

    methods: {
        
        formatDate(date) {
            if (!date) return null;
            return dayjs(date).format('DD.MM.YYYY');
        },

        updateTextField(newDate) {
            this.paidDateText = this.formatDate(newDate);
        },

        updateDatePicker() {
            const parsedDate = dayjs(this.paidDateText, 'DD.MM.YYYY', true);
            if (parsedDate.isValid()) {
                this.editedItem.bezahlt = parsedDate.format('YYYY-MM-DD');
            }
        },

        async save() {

            this.updating_invoice = true;

            let to_update = {
                'bezahlt': this.editedItem.bezahlt
            };

            if (this.editedItem.bar === true) {
                to_update['bar'] = true;
            }

            let updated = await connector.update(this, 'rechnungen', to_update, this.editedIndex);

            if (!updated) {
                // error has already been displayed
                this.updating_invoice = false;
                return;
            }

            this.$emit('initialize');
            this.$emit('close');
            this.updating_invoice = false;
            this.$emit('showInfo', {
                message: 'Die Rechnung wurde erfolgreich als bezahlt markiert.',
                timeout: 5000
            });
        },
    }
}
</script>

<style scoped>

</style>
