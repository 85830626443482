<script>
import { supabase } from '../supabase';

export default {
    props: ['session'],

    data() {
        return {
            loading: false,
            email: '',
            password: '',
            passwordConfirm: '',
            passwordConfirmTouched: false,
            showPassword: false, // For toggling visibility of password
            showPasswordConfirm: false, // For toggling visibility of confirm password
            isSignUp: false,
            rules: {
                min: (v) => v.length >= 8 || 'Mindestens 8 Zeichen.',
            },
        };
    },
    mounted() {

        // check if user is already logged in
        if (this.session && 'user' in this.session && this.session.user.id !== null) {
            this.$router.push('/');
        }
        if (this.$route.query.signup) {
            this.isSignUp = true;
        }
    },

    computed: {
        passwordsMatch() {
            return this.password === this.passwordConfirm;
        },
    },

    methods: {

        // gtag_report_conversion(url) {
        //     var callback = function () {
        //         console.log('gtag_report_conversion callback');
        //         if (typeof(url) != 'undefined') {
        //             window.location = url;
        //         }
        //     };
        //     gtag('event', 'conversion', {
        //         'send_to': 'AW-11264315383/CEwyCLDC6e4YEPefn_sp',
        //         'event_callback': callback
        //     });
        //     return false;
        // },

        async signInWithGoogle() {
            let options = {
                provider: 'google',
            };

            if (localStorage.scopes) {
                options.options = {
                    scopes: localStorage.scopes.split(','),
                };
                if (process.env.NODE_ENV === 'development') {
                    options.options.redirectTo = 'http://localhost:8080'; // for local development
                }
            } else {
                if (process.env.NODE_ENV === 'development') {
                    options.options = {
                        redirectTo: 'http://localhost:8080', // for local development
                    };
                }
            }

            const { data, error } = await supabase.auth.signInWithOAuth(options);

            if (error) throw error;
            if (data) {
                if (this.$route.path !== '/') this.$router.push('/');
            }
        },

        async handleAuthentication() {
            try {
                this.loading = true;

                if (this.isSignUp) {

                    // this.gtag_report_conversion();

                    const { error } = await supabase.auth.signUp({
                        email: this.email,
                        password: this.password,
                        options: {
                            emailRedirectTo: 'https://app.zeipsy.com/welcome',
                        },
                    });
                    if (error) throw error;
                    this.$router.push('/welcome');
                } else {
                    const { data, error } = await supabase.auth.signInWithPassword({
                        email: this.email,
                        password: this.password,
                    });
                    if (error) throw error;
                    if (data) {
                        if (this.$route.path !== '/') this.$router.push('/');
                    }
                }
                if (this.isSignUp) {
                    //alert('Überprüfe deinen E-Mail Posteingang und bestätige deine E-Mail Adresse.')
                    this.$emit('showInfo', 'Überprüfe deinen E-Mail Posteingang und bestätige deine E-Mail Adresse.');
                }
            } catch (error) {
                if ('message' in error && error.message === 'Invalid login credentials') {
                    error = 'Die angegebene E-Mail Adresse oder das Passwort ist falsch.';
                    this.$emit('showError', { message: error });
                } else {
                    this.$emit('showError', { message: error });
                    throw error;
                }
            } finally {
                this.loading = false;
            }
        },

        updateQueryParams() {
            if (this.$route.query.signup) {
                this.isSignUp = true;
            } else {
                this.isSignUp = false;
            }
        },
    },
};
</script>

<template>
    <div>
        <v-card
            :class="$vuetify.breakpoint.xs ? 'px-10 pb-10 pt-0' : 'px-10 pb-10 pt-0'"
            :width="$vuetify.breakpoint.xs ? '350px' : '500px'"
        >
            <v-img class="mb-5 pa-0" src="../assets/logo-black.svg" height="120" />
            <form @submit.prevent="handleAuthentication">
                <h2>{{ isSignUp ? 'Account erstellen' : 'Mit deinem Account einloggen' }}</h2>
                <p>
                    Oder
                    <router-link
                        @click.native="updateQueryParams"
                        :to="{ name: 'login', query: isSignUp ? {} : { signup: '1' } }"
                        >{{
                            isSignUp ? 'melde dich mit deinem bestehenden Account an.' : 'registriere dich jetzt.'
                        }}</router-link
                    >
                </p>
                <v-text-field type="email" placeholder="E-Mail Adresse" required v-model="email" />
                <v-text-field
                    type="password"
                    placeholder="Passwort"
                    required
                    v-model="password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                    :type="showPassword ? 'text' : 'password'"
                    :rules="isSignUp ? [rules.min] : []"
                />
                <v-text-field
                    v-if="isSignUp"
                    type="password"
                    placeholder="Passwort wiederholen"
                    required
                    v-model="passwordConfirm"
                    @input="passwordConfirmTouched = true"
                    :error="passwordConfirmTouched && !passwordsMatch"
                    :error-messages="
                        passwordsMatch || !passwordConfirmTouched ? '' : 'Die Passwörter stimmen nicht überein'
                    "
                    :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPasswordConfirm = !showPasswordConfirm"
                    :type="showPasswordConfirm ? 'text' : 'password'"
                />
                <v-btn
                    type="submit"
                    class="mt-5"
                    :disabled="loading || (isSignUp && (!passwordsMatch || password.length < 8))"
                    width="100%"
                    >{{ loading ? 'Lädt...' : isSignUp ? 'Registrieren' : 'Einloggen' }}</v-btn
                >
                <v-btn class="d-flex justify-center gsi-material-button mt-5" width="100%" @click="signInWithGoogle">
                    <div class="gsi-material-button-content-wrapper">
                        <div class="gsi-material-button-icon">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlns:xlink="http://www.w3.org/1999/xlink" style="display: block;">
                                <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                                <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                                <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                                <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                                <path fill="none" d="M0 0h48v48H0z"></path>
                            </svg>
                        </div>
                        <span class="gsi-material-button-contents">Über Google anmelden</span>
                    </div>
                </v-btn>
            </form>
        </v-card>

        <v-footer padless absolute>
            <v-row class="d-flex justify-center align-center" no-gutters>
                <v-btn text rounded href="https://zeipsy.com/datenschutz/#app" target="_blank" class="my-2"> Datenschutz </v-btn>
            </v-row>
        </v-footer>
    </div>
</template>

<style scoped>
.gsi-material-button {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  background-color: #f2f2f2;
  background-image: none;
  border: none;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  justify-content: center; /* Center button contents */
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  min-width: min-content;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 10px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
  justify-content: center; /* Center the contents */
}

</style>
