<template>
    <v-container fluid>        
        <v-row :class="$vuetify.breakpoint.xs ? 'px-5 pt-5' : ''">
            <v-col cols="12" md="8" class="d-flex flex-column">
                <div class="d-flex justify-space-between align-center">
                    <span class="text-h5">Vorschau</span>
                    <v-dialog v-model="dialog_show_iframe" width="800" >
                        <v-card class="pa-5">
                            <span class="py-5 my-5 text-h5">Binde den folgenden Code auf deiner Webseite ein.</span>
                            <v-textarea dense outlined v-model="codeIFrame" ></v-textarea>
                            <v-btn :color="$store.state.theme.primary" dark @click="dialog_show_iframe = false" align="right">
                                OK
                            </v-btn>
                        </v-card>
                    </v-dialog>
                    <v-btn small class="ml-2" :color="$store.state.theme.primary" @click="dialog_show_iframe = true" elevation="0" dark>
                        <v-icon small left>mdi-plus</v-icon>Auf Webseite einbinden
                    </v-btn>
                </div>
                <v-card elevation="1" class="mt-2 flex d-flex flex-column">
                    <iframe id="zeipsy-iframe" :src="'https://booking.zeipsy.com/?id=' + this.profile_id + '&amp;primary=' + this.showColor.replace('#', '')" height="332" style="border:0px #ffffff none;" frameborder="0" scrolling="no" marginheight="0px" marginwidth="0px" width="100%" allowfullscreen="" sandbox="allow-scripts allow-same-origin allow-popups" :key="iframe_update"></iframe>
                </v-card>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="4" class="d-flex flex-column">
                <div class="d-flex justify-space-between">
                    <span class="text-h5">Einstellungen</span>
                    <v-btn small elevation="0" dark :color="$store.state.theme.primary" @click="saveMailAndPhone">
                        Speichern
                    </v-btn>
                </div>
                
                <v-card elevation="1" class="mt-2 flex">
                    <v-tabs v-model="selected_settings_page" :background-color="$store.state.theme.primary" dark>
                        <v-tab >
                            E-Mail
                        </v-tab>
                        <v-tab >
                            Farbschema
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="selected_settings_page" class="pa-5">
                        <v-tab-item >
                            <v-row class="px-3 pt-5">
                                <v-text-field dense outlined v-model="profile_mail" label="E-Mail" />
                                <p class="text-justify">Auf dieser E-Mail Adresse wirst du benachrichtigt wenn jemand einen Termin reserviert hat.</p>
                            </v-row>
                        </v-tab-item>

                        <v-tab-item  class="mx-auto">
                                <v-color-picker v-model="color" hide-mode-switch mode="hexa" canvas-height="100"/>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-col>
        </v-row>

        <v-row :class="$vuetify.breakpoint.xs ? 'px-3 pt-3' : ''">
            <v-col :cols="$vuetify.breakpoint.smAndDown ? 4 : 4" class="d-flex align-center">
                <span :class="$vuetify.breakpoint.xs ? 'subtitle-1' : 'text-h5'">
                    Buchungstermine 
                </span>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.smAndDown ? 5 : 4">
                <v-text-field v-model="search" prepend-inner-icon="mdi-magnify" label="Suche" single-line hide-details outlined dense/>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.smAndDown ? 3 : 4" class="d-flex justify-end">
                <v-btn elevation="1" class="mr-5" :color="$store.state.theme.primary" @click="newItemSeries()" :icon="$vuetify.breakpoint.smAndDown" :dark="$vuetify.breakpoint.mdAndUp">
                    <div class="hidden-sm-and-down">
                        <v-icon left>mdi-plus</v-icon>
                        Terminserie
                    </div>
                    <div class="hidden-md-and-up">
                        <v-icon>mdi-plus</v-icon>
                    </div>
                </v-btn>
                <v-dialog  v-model="dialog_series" persistent max-width="600px" >
                    <v-card class="px-5">
                        <v-row >
                            <span class="my-7 px-3 text-h5">Neue Terminserie</span>
                        </v-row>
                        <v-row>
                            <v-col >
                                <v-menu v-model="menu_series" :close-on-content-click="false" offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense outlined v-model="computedDateFormatted" label="Datum" hint="" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" />
                                    </template>
                                    <v-date-picker dense outlined first-day-of-week="1" v-model="editedItem.termin_start" no-title @input="menu_series = false"/>
                                </v-menu>
                            </v-col>
                            <v-col>
                                <v-text-field  dense outlined v-model="editedItem.uhrzeit" label="Start Uhrzeit" value="12:00:00" type="time"/>
                            </v-col>
                        </v-row>
                        <v-row >
                            <v-col>
                                <v-text-field  dense outlined type="number" v-model="number_of_appointments" label="Anzahl Termine" />
                            </v-col>
                            <v-col>
                                <v-text-field  dense outlined type="number" v-model="pause_between_appointments" label="Pause zwischen Termine (Minuten)"/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field  dense outlined type="number" v-model="editedItem.preis" label="Preis pro Termin" />
                            </v-col>
                            <v-col>
                                <v-text-field  dense outlined type="number" v-model="editedItem.dauer" label="Dauer pro Termin (Minuten)"/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <span class="px-4 py-0 mb-5">
                                Terminserie: {{ displayAppointmentSeries.map((appointment) => {
                                    return appointment.termin_start.format('HH:mm') + ' -  ' + appointment.end.format('HH:mm')
                                }).join(', ') }}
                            </span>
                        </v-row>
                        
                        <v-card-actions class="d-flex justify-end mx-0 px-0">
                            <v-btn :color="$store.state.theme.red" text @click="dialog_series = false">
                                Abbrechen
                            </v-btn>
                            <v-btn :color="$store.state.theme.green" text @click="saveBookingSeries">
                                Speichern
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-btn elevation="1" :color="$store.state.theme.primary" @click="newItem()" :icon="$vuetify.breakpoint.smAndDown" :dark="$vuetify.breakpoint.mdAndUp">
                    <div class="hidden-sm-and-down">
                        <v-icon left>mdi-plus</v-icon>
                        Einzeltermin
                    </div>
                    <div class="hidden-md-and-up">
                        <v-icon>mdi-plus</v-icon>
                    </div>
                </v-btn>
                <v-dialog  v-model="dialog" persistent max-width="600px" >
                    <v-card class="px-5">
                        <v-row >
                            <span class="my-7 px-3 text-h5">{{ formTitle }}</span>
                        </v-row>
                        <v-row>
                            <v-col >
                                <v-menu v-model="menu" :close-on-content-click="false" offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense outlined v-model="computedDateFormatted" label="Datum" hint="" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" />
                                    </template>
                                    <v-date-picker dense outlined first-day-of-week="1" v-model="editedItem.termin_start" no-title @input="menu = false"/>
                                </v-menu>
                            </v-col>
                            <v-col>
                                <v-text-field  dense outlined v-model="editedItem.uhrzeit" label="Uhrzeit" value="12:00:00" type="time"/>
                            </v-col>
                        </v-row>
                        <v-row >
                            <v-col>
                                <v-text-field  dense outlined type="number" v-model="editedItem.preis" label="Preis" />
                            </v-col>
                            <v-col>
                                <v-text-field  dense outlined type="number" v-model="editedItem.dauer" label="Dauer (Minuten)"/>
                            </v-col>
                        </v-row>
                        <v-row v-if="editedIndex > -1">
                            <v-col cols="4">
                                <v-select dense outlined v-model="editedItem.status" :items="items" item-text="text" item-value="status" label="Status" />
                            </v-col>
                            <v-col cols="4">
                                <v-text-field  dense outlined v-model="editedItem.email" label="E-Mail" />
                            </v-col>
                            <v-col cols="4">
                                <v-text-field  dense outlined v-model="editedItem.telefon" label="Telefon" />
                            </v-col>
                        </v-row>
                        <v-row v-if="editedIndex > -1" class="px-3">
                            <v-textarea dense outlined v-model="editedItem.message" label="Nachricht" auto-grow/>
                        </v-row>
                        <v-card-actions class="d-flex justify-end mx-0 px-0">
                            <v-btn :color="$store.state.theme.red" text @click="dialog = false">
                                Abbrechen
                            </v-btn>
                            <v-btn :color="$store.state.theme.green" text @click="saveBooking">
                                Speichern
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
        <v-data-table :headers="headers" sort-by="termin_start" sort-desc :items="computedBookings" class="elevation-1 mt-3">
            <template v-slot:top>
                <v-toolbar flat>
                <span class="text-overline mr-2">Filter:</span>
                <v-btn :x-small="$vuetify.breakpoint.xs" :color="filter_free ? $store.state.theme.primary: null"   :dark="filter_free" class="mr-2" elevation="0" :text="!filter_free"   @click="clickFree">Frei</v-btn>
                <v-btn :x-small="$vuetify.breakpoint.xs" :color="filter_reserved ? $store.state.theme.primary: null" :dark="filter_reserved" class="mr-2"  elevation="0" :text="!filter_reserved" @click="clickReserved">Reserviert</v-btn>
                <v-btn :x-small="$vuetify.breakpoint.xs" :color="filter_confirmed ? $store.state.theme.primary: null" :dark="filter_confirmed"      elevation="0" :text="!filter_confirmed" @click="clickConfirmed">Bestätigt</v-btn>
                <v-dialog v-model="dialogDelete" max-width="600px">
                    <v-card>
                    <v-card-title class="text-h5">Möchten Sie diesen Eintrag wirklich löschen?</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn :color="$store.state.theme.red" text @click="dialogDelete = false">Nein</v-btn>
                        <v-btn :color="$store.state.theme.green" text @click="deleteItemConfirm">Ja</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                    </v-card>
                </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn class="mx-2" elevation="0" fab dark x-small :color="$store.state.theme.primary" @click="editItem(item)">
                <v-icon dark>mdi-pencil</v-icon>
                </v-btn>
                <v-btn class="mx-2" elevation="0" fab dark x-small color="#f44336" @click="deleteItem(item)">
                <v-icon dark>mdi-delete</v-icon>
                </v-btn>
            </template>
            <template v-slot:no-data >
                <div v-if="loading" class="text-center">
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
                </div>
                <div v-else>
                <p class="mt-5">Sie haben noch keine Online-Buchungen festgelegt.</p>
                </div>
            </template>
            <template v-slot:item.termin_start="{ item }">
                <span>{{ getDisplayDateTimeFromLocalDateTimeString(item.termin_start) }}</span>
            </template>
            <template v-slot:item.status="{ item }">
                <v-chip dark small :color="getStatusColor(item.status)"> <span class="text-overline">{{ getStatusLabel(item.status) }}</span> </v-chip>
            </template>
            <template v-slot:item.email="{ item }">
                <div class="d-flex flex-column text-truncate">
                    <span v-if="item.email">
                        <v-icon small class="mr-1">mdi-email-outline</v-icon> 
                        {{ item.email }}
                    </span>
                    <span v-if="item.telefon">
                        <v-icon small class="mr-1">mdi-phone-in-talk</v-icon> 
                        {{ item.telefon }}
                    </span>
                </div>
            </template>
            <template v-slot:item.message="{ item }">
                <div v-if="item.message">
                    {{ item.message.length > 40 ? item.message.replace(/(\r\n|\n|\r)/gm, " ").substring(0, 40) + '...' : item.message.replace(/(\r\n|\n|\r)/gm, " ") }}
                </div>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
dayjs.extend(timezone)
import connector from '../helpers/supabase-connector'
import $ from 'jquery'

export default { 
    name: 'Terminplaner',
    props: ['session'],

    data() {
        return {
            profile_id: '',
            loading: false,
            search: null,
            dialog: null,
            dialogDelete: null,
            dialog_series: null,

            dialog_show_iframe: null,
            menu: null,
            menu_series: null,
            bookings: [],

            number_of_appointments: 0,
            pause_between_appointments: 0,

            profile_mail: this.session.user.email,

            selected_item: { text: 'Frei', status: 0 },
            items: [
                { text: 'Frei', status: 0 },
                { text: 'Reserviert', status: 1 },
                { text: 'Bestätigt', status: 2 },
            ],

            headers: [
                { text: 'Datum', value: 'termin_start' },
                { text: 'Dauer', value: 'dauer' },
                { text: 'Status', value: 'status' },
                //{ text: 'Preis', value: 'preis' },
                { text: 'Kontakt', value: 'email' },
                //{ text: 'Telefon', value: 'telefon' },
                { text: 'Nachricht', value: 'message'},
                { text: 'Aktionen', value: 'actions', sortable: false, align: 'center' },
            ],
            
            editedIndex: -1,
            editedItem: {
                id: null,
                uid: this.session.user.id,
                fk_profil_id: null,
                termin_start: this.getLocalDateTimeFromUTCString(dayjs.utc()),
                uhrzeit: "12:00",
                dauer: null,
                preis: null,
                email: null,
                telefon: null,
                message: null,
                status: 0,
            },

            defaultItem: {
                id: null,
                uid: this.session.user.id,
                fk_profil_id: null,
                //termin_start: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString(),
                termin_start: this.getLocalDateTimeFromUTCString(dayjs.utc()),
                uhrzeit: "12:00",
                dauer: null,
                preis: null,
                email: null,
                telefon: null,
                message: null,
                status: 0,
            },

            filter_free: false,
            filter_reserved: false,
            filter_confirmed: false,

            selected_settings_page: 0,

            iframe_update: false,
            types: ['hex'],
            type: 'hex',
            hex: this.$store.state.theme.primary,
        }
    },

    computed: {
        displayAppointmentSeries() {
            // return a list of appointments with start and end timestamp for each appointment using the number_of_appointments and pause_between_appointments
            let appointments = []
            let start = dayjs.tz(this.editedItem.termin_start.split('T')[0] + 'T' + this.editedItem.uhrzeit, 'YYYY-MM-DDTHH:mm', 'Europe/Vienna')
            let end = dayjs.tz(this.editedItem.termin_start.split('T')[0] + 'T' + this.editedItem.uhrzeit, 'YYYY-MM-DDTHH:mm', 'Europe/Vienna').add(this.editedItem.dauer, 'minutes')
            for (let i = 0; i < this.number_of_appointments; i++) {
                appointments.push({
                    termin_start: start,
                    end: end,
                    fk_profil_id: this.profile_id,
                    dauer: this.editedItem.dauer,
                    uid: this.session.user.id,
                    preis: this.editedItem.preis,
                    status: 0,
                })
                start = end.clone().add(this.pause_between_appointments, 'minutes')
                end = start.clone().add(this.editedItem.dauer, 'minutes')
            }

            return appointments
        },
        formTitle () {
            return this.editedIndex === -1 ? 'Neuer Buchungstermin' : 'Buchungstermin bearbeiten'
        },
        computedBookings() {
            return this.bookings.filter((booking) => {
            if (this.filter_free) {
                return booking.status === 0
            } else if (this.filter_reserved) {
                return booking.status === 1
            } else if (this.filter_confirmed) {
                return booking.status === 2
            } else {
                return true
            }
            })
        },
        computedDateFormatted() {
            return this.editedItem.termin_start ? this.getDateFromLocalDateTimeString(this.editedItem.termin_start) : ''
        },
        color: {
            get () {
                return this[this.type]
            },
            set (v) {
                this[this.type] = v
            },
        },
        showColor () {
            if (typeof this.color === 'string') return this.color

            return JSON.stringify(Object.keys(this.color).reduce((color, key) => {
                color[key] = Number(this.color[key].toFixed(2))
                return color
            }, {}), null, 2)
        },

        codeIFrame() {
            let script_tag = '<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.3/jquery.min.js"><\/script>'
            let second_script_tag = '<script>$(document).ready(function () {window.addEventListener("message", (event) => {let iframe = document.querySelector("#zeipsy-iframe");iframe.height = event.data.height;});});<\/script>'
            let iframe_tag = '<iframe id="zeipsy-iframe" src="https://booking.zeipsy.com/?id=' + this.profile_id + '&amp;primary=' + this.showColor.replace('#', '') + '" height="332" style="border:0px #ffffff none;" frameborder="0" scrolling="no" marginheight="0px" marginwidth="0px" width="100%" allowfullscreen="" sandbox="allow-scripts allow-same-origin allow-popups"></iframe>'
            return script_tag + second_script_tag + iframe_tag
        }
    },

    mounted() {
        $(document).ready(function () {
            window.addEventListener("message", (event) => {
                let iframe = document.querySelector('#zeipsy-iframe')
                iframe.height = event.data.height
            })
        })
        this.initialize()
    },

    methods: {
        initialize() {
            connector.getDataOnly(this, 'vwkunden', 'id', true)
            .then((client) => client.length > 0 ? this.$store.state.client = client[0]: false )
            // check if user has already a buchungsprofile
            .then(() => connector.getDataOnly(this, 'buchungsprofile', 'inserted_at', false))
            .then((profiles) => {
                if (profiles.length > 0) {
                    return profiles
                } else {
                    // insert new buchungsprofile
                    return connector.upsertRow(this, 'buchungsprofile', { id: this.session.user.id, email: this.session.user.email, telefon: this.$store.state.client.telefon })
                }
            })
            .then((entry) => {
                if (entry) {
                    this.profile_id = entry[0].profil_id
                    this.profile_mail = entry[0].email
                } else {
                    this.$emit('showError', 'Fehler beim Laden deines Online-Profils.')
                }
            })
            .then(() => connector.getDataOnly(this, 'vwbuchungen', 'termin_start', false))
            .then((bookings) => this.bookings = bookings.map((booking) => {
                booking.termin_start = this.getLocalDateTimeFromUTCString(booking.termin_start)
                booking.uhrzeit = this.getTimeFromLocalDateTimeString(booking.termin_start)
                return booking
            }))
            .then(() => this.$store.state.number_of_reserved_bookings = this.bookings.filter((booking) => booking.status === 1).length)
            .then(() => this.iframe_update = !this.iframe_update)
        },

        saveMailAndPhone() {
            connector.upsertRow(this, 'buchungsprofile', { id: this.session.user.id, email: this.profile_mail })
            .then(() => this.$emit('showInfo', 'Deine E-Mail-Adresse wurde erfolgreich gespeichert.'))
            .catch((error) => {
                this.$emit('showError', 'Fehler beim Speichern deiner E-Mail-Adresse.')
                console.log(error)
            })
        },

        newItem () {
            this.editedIndex = -1
            this.editedItem = Object.assign({}, this.defaultItem)
            // this.selected_item = { text: 'Frei', status: 0 }
            this.dialog = true
        },

        newItemSeries() {
            this.editedIndex = -1
            this.editedItem = Object.assign({}, this.defaultItem)
            this.number_of_appointments = 1
            this.pause_between_appointments = 0
            this.dialog_series = true
        },

        editItem (item) {
            this.editedIndex = item.id
            this.editedItem = Object.assign({}, item)
            // this.selected_item = { text: this.getStatusLabel(item.status), status: item.status }
            this.dialog = true
        },

        deleteItem (item) {
            this.editedIndex = item.id
            this.dialogDelete = true
        },

        async deleteItemConfirm () {
            let deleted = await connector.deleteRow(this, 'buchungen', 'id', this.editedIndex);
            if (!deleted) return;

            this.initialize();
            this.editedIndex = -1
            this.dialogDelete = false
        },

        getLocalDateTimeFromUTCString(date) {
            return dayjs.utc(date).tz('Europe/Vienna').format('YYYY-MM-DDTHH:mm:ss')
        },

        getDateFromLocalDateTimeString(datetime) {
            return dayjs.tz(datetime, 'YYYY-MM-DDTHH:mm:ss', 'Europe/Vienna').format('DD.MM.YYYY')
        },

        getDisplayDateTimeFromLocalDateTimeString(datetime) {
            return dayjs.tz(datetime, 'YYYY-MM-DDTHH:mm:ss', 'Europe/Vienna').format('DD.MM.YYYY, HH:mm')
        },

        getTimeFromLocalDateTimeString(datetime) {
            return dayjs.tz(datetime, 'YYYY-MM-DDTHH:mm:ss', 'Europe/Vienna').format('HH:mm')
        },

        getUTCStringFromLocalDateAndTime(datetime, time) {
            let local_datetime = dayjs.tz(datetime, 'YYYY-MM-DDTHH:mm:ss', 'Europe/Vienna')
            let viennaTime = dayjs.tz(local_datetime.format('YYYY-MM-DD') + 'T' + time, 'YYYY-MM-DDTHH:mm', 'Europe/Vienna')
            return viennaTime.utc().format('YYYY-MM-DDTHH:mm:ss')
        },

        getStatusLabel(status) {
            switch (status) {
                case 0:
                    return 'Frei'
                case 1:
                    return 'Reserviert'
                case 2:
                    return 'Bestätigt'
                default:
                    return 'Unbekannt'
            }
        },

        getStatusColor(status) {
            switch (status) {
                case 0:
                    return this.$store.state.theme.primary
                case 1:
                    return 'orange'
                case 2:
                    return 'green'
                default:
                    return 'grey'
            }
        },

        clickFree() {
            this.filter_free = !this.filter_free
            if(this.filter_free) {
                this.filter_reserved = !this.filter_free
                this.filter_confirmed = !this.filter_free
            } 
        },

        clickReserved() {
            this.filter_reserved = !this.filter_reserved
            if(this.filter_reserved) {
                this.filter_free = !this.filter_reserved
                this.filter_confirmed = !this.filter_reserved
            } 
        },

        clickConfirmed() {
            this.filter_confirmed = !this.filter_confirmed
            if(this.filter_confirmed) {
                this.filter_reserved = !this.filter_confirmed
                this.filter_free = !this.filter_confirmed
            } 
        },

        saveBooking() {
            let toInsert = Object.assign({}, this.editedItem)
            delete toInsert.id
            delete toInsert.uhrzeit
            toInsert.termin_start = this.getUTCStringFromLocalDateAndTime(this.editedItem.termin_start, this.editedItem.uhrzeit)
            toInsert.fk_profil_id = this.profile_id

            if (this.editedIndex > -1) {
            // updated 
                connector.updateRow(this, 'buchungen', toInsert, this.editedItem.id)
                .then(() => {
                    this.initialize()
                    this.dialog = false
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            } else {
            // create new
                connector.insertRow(this, 'buchungen', toInsert)
                .then(() => {
                    this.initialize()
                    this.dialog = false
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            }
        },

        saveBookingSeries() {
            let bookings = this.displayAppointmentSeries.map((booking) => {
                let toInsert = Object.assign({}, booking)
                delete toInsert.end
                toInsert.termin_start = booking.termin_start.utc().format('YYYY-MM-DDTHH:mm:ss')
                return toInsert
            })
            connector.insertRows(this, 'buchungen', bookings)
            .then(() => {
                this.initialize()
                this.dialog_series = false
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        }
    }
}
</script>