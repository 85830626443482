import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import {store } from './store/store';
import * as Sentry from "@sentry/vue";

//import '@babel/polyfill';
import 'core-js/stable';

Vue.config.productionTip = false;

Sentry.init({
  Vue,
  enabled: process.env.NODE_ENV !== 'development',
  dsn: "https://df216c8c72779286682cb78fe3a28daf@o4507214803369984.ingest.de.sentry.io/4507214808678480",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/app.zeipsy\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

new Vue({
  store: store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
