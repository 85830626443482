<template>
    <v-list nav dense>        
        <v-list-item link to="/">
            <v-list-item-icon>
                <v-icon>mdi-chart-bar</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Übersicht</v-list-item-title>
        </v-list-item>

        <v-list-item link to="/klienten" @click="logNavigation('Klienten')">
            <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
                <v-tooltip right :value="onboardingNextStep === '/klienten' && $route.name.toLowerCase() !== 'klienten' && $vuetify.breakpoint.lgAndUp">
                    <template v-slot:activator="{ on }">
                        <div>
                            Klienten
                        </div>
                    </template>
                    <v-icon left>
                        mdi-chevron-left
                    </v-icon>
                    <span>Lege den ersten Klienten an.</span>
                </v-tooltip> 
            </v-list-item-title>
        </v-list-item>

        <v-list-item link to="/dokumentation" @click="logNavigation('Dokumentation')">
            <v-list-item-icon>
                <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
                Dokumentation
            </v-list-item-title>
        </v-list-item>

        <v-list-item link to="/kalender" @click="logNavigation('Kalender')">
            <v-list-item-icon>
                <v-icon>mdi-calendar-week</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
                <v-tooltip right :value="onboardingNextStep === '/termine' && $route.name.toLowerCase() !== 'kalender' && $vuetify.breakpoint.lgAndUp">
                    <template v-slot:activator="{ on }">
                        <div>
                            Kalender
                        </div>
                    </template>
                    <v-icon left>
                        mdi-chevron-left
                    </v-icon>
                    <span>Lege den ersten Termin an.</span>
                </v-tooltip> 
            </v-list-item-title>
        </v-list-item>

        <!-- <v-list-item v-if="isInBetaCircle" link to="/terminplaner">
            <v-list-item-icon>
                <v-badge overlap v-if="numberOfReservedBookings > 0" color="orange" :content="numberOfReservedBookings">
                    <v-icon>mdi-clock-edit-outline</v-icon>
                </v-badge>
                <v-icon v-else>mdi-clock-edit-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
                    Online Terminbuchung
            </v-list-item-title>
        </v-list-item> -->

        <v-list-item link to="/termine" @click="logNavigation('Termine')">
            <v-list-item-icon>
                <v-icon>mdi-sofa-single-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
                Termine
            </v-list-item-title>
        </v-list-item>

        <v-list-item link to="/rechnungen" @click="logNavigation('Rechnungen')">
            <v-list-item-icon>
                <v-icon>mdi-invoice-text-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Rechnungen</v-list-item-title>
        </v-list-item>

        <v-list-item link to="/buchhaltung" @click="logNavigation('Buchhaltung')">
            <v-list-item-icon>
                <v-icon>mdi-account-cash</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
                Buchhaltung
            </v-list-item-title>
        </v-list-item>

        <v-list-item link to="/dienstleistungen">
            <v-list-item-icon>
                <v-icon>mdi-handshake-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
                <v-tooltip right :value="onboardingNextStep === '/dienstleistungen' && $route.name.toLowerCase() !== 'dienstleistungen' && $vuetify.breakpoint.lgAndUp">
                    <template v-slot:activator="{ on }">
                        <div>
                            Dienstleistungen
                        </div>
                    </template>
                    <v-icon left>
                        mdi-chevron-left
                    </v-icon>
                    <span>Lege deine erste Dienstleistung an.</span>
                </v-tooltip>
            </v-list-item-title>
        </v-list-item>

        <v-list-item v-if="isNotBlocked" link to="/formulare" @click="logNavigation('Formulare')">
            <v-list-item-icon>
                <v-icon>mdi-auto-fix</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
                Anträge
            </v-list-item-title>
        </v-list-item>

        <v-list-item link to="/assistent">
            <v-list-item-icon>
                <v-icon>mdi-robot-happy</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
                Dein Assistent
                <v-chip small class="ml-2" :color="$store.state.theme.green">NEU</v-chip>
            </v-list-item-title>
        </v-list-item>

        <v-list-item link to="/feedback">
            <v-list-item-icon class="d-flex align-center">
                <v-icon>mdi-gift</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
                Feedback & 
                Belohnungen
            </v-list-item-title>
        </v-list-item>

        <v-list-item link to="/einstellungen">
            <v-list-item-icon>
                <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Einstellungen</v-list-item-title>
        </v-list-item>

        <v-list-item link @click="handleLogout">
            <v-list-item-icon>
                <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Ausloggen</v-list-item-title>
        </v-list-item>
    </v-list>
</template>

<script>
import { supabase } from '../supabase';
import connector from "../helpers/supabase-connector.js";

export default {
    
    props: ['session'],

    created() {
        this.$store.dispatch('fetchOnboardingStatus', this);
    },

    computed: {
        numberOfReservedBookings() {
            return this.$store.state.number_of_reserved_bookings
        },

        isInBetaCircle() {
            return this.$store.state.client.beta_circle === true
        },

        isNotBlocked() {
            // check if email contains the substring wogri or hennerbichler, if so return true
            if (this.session.user.email.toLowerCase().includes('wogri') || this.session.user.email.toLowerCase().includes('hennerbichler')) {
                return false;
            } else {
                return true;
            }
        },

        onboardingNextStep() {
            if (!this.$store.getters.hasServices) {
                return '/dienstleistungen'
            } else if (!this.$store.getters.hasClients) {
                return '/klienten'
            } else if (!this.$store.getters.hasAppointments) {
                return '/termine'
            } else {
                return ''
            }
        },
    },

    methods: {

        logNavigation(routeName) {
            connector.logInfo({
                message: `Route: ${routeName}`,
                uid: this.session.user.id,
            });
        },

        async handleLogout() {
            try {
                const { error } = await supabase.auth.signOut()
                if (error) throw error

                if (this.$route.path !== '/') this.$router.push('/')
            } catch (error) {
                console.log(error)
                //alert('Unable to log out. Please try again.')
                this.$emit('showError', {message: error})
            } 
        }
    }
}
</script>

<style>

</style>