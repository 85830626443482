<template>
  <v-container fluid>
    <v-row no-gutters :class="$vuetify.breakpoint.xsOnly ? 'pt-3' : ''">
      <v-col class="d-flex align-center justify-space-between">
        <v-text-field v-model="search" prepend-inner-icon="mdi-magnify" label="Suche" single-line hide-details outlined dense clearable clear-icon="mdi-close-circle"/>
        <v-btn v-if="$vuetify.breakpoint.smAndUp" class="ml-5" elevation="1" :color="$store.state.theme.green" @click="newItem()" dark>
            <v-icon left>mdi-plus</v-icon>
            Neuer Termin
        </v-btn>
        <v-dialog v-model="dialog" persistent max-width="1000px" >
          <DialogTermin :google_appointments="google_appointments" :session="session" :editedIndex="editedIndex" :editedItem="editedItem" :defaultItem="defaultItem" :dialog="dialog" :appointments="appointments" @close="close" @refreshAndClose="refreshAndClose" @updateAndClose="updateAndClose" @showError="$emit('showError', $event)" />
        </v-dialog>
      </v-col>
    </v-row>
    <v-fab-transition>
      <v-btn
        v-if="$vuetify.breakpoint.xsOnly"
        :color="$store.state.theme.green"
        dark
        fixed
        bottom
        right
        fab
        @click="newItem()"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-data-table :headers="headers" sort-by="datum" :custom-filter="filterName" :search="search" sort-desc :items="computedAppointments" class="elevation-1 mt-3">
      <template v-slot:top>
        <v-tabs dense show-arrows color="grey darken-4" :background-color="$store.state.theme.background_tabs">
          <v-tabs-slider :color="$store.state.theme.primary"></v-tabs-slider>
          <v-tab @click="clickAll">
            Alle <v-badge inline :content="nAllAppointments.toString()" :color="$store.state.theme.primary" />
          </v-tab>
          <v-tab @click="clickCleared">
            Verrechnet <v-badge inline :content="nClearedAppointments.toString()" :color="$store.state.theme.green" />
          </v-tab>
          <v-tab  @click="clickUncleared">
            Nicht Verrechnet <v-badge inline :content="nUnclearedAppointments.toString()" :color="$store.state.theme.orange" />
          </v-tab>
        </v-tabs>
        <v-divider/>
        <v-dialog v-model="dialogDelete" max-width="600px" persistent>
          <v-card>
            <v-card-title class="text-h5">Soll dieser Eintrag wirklich gelöscht werden?</v-card-title>
            <v-card-text class="text-body-1">
              Die Dokumentation dieses Termins sowie zugehörige Dokumente werden ebenso gelöscht.
            </v-card-text>
            <v-card-actions class="px-6 pb-5">
              <v-spacer></v-spacer>
              <v-btn :color="$store.state.theme.primary" :disabled="deleting_appointment" text @click="closeDelete">Nein</v-btn>
              <v-btn class="ml-2" :color="$store.state.theme.red" :loading="deleting_appointment" outlined @click="deleteItemConfirm">Ja</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.nachname="{ item }">
        <span>{{ item.nachname }} {{ item.vorname }}</span><br/>
        <v-chip class="mr-2 mb-2" small :key="'termine-chip-' + merkmal.id" v-for="merkmal in item.merkmale">
          <v-tooltip bottom v-if="merkmal.merkmal.length > 20">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">{{ truncateText(merkmal.merkmal, 20) }}</span>
            </template>
            <span>{{ merkmal.merkmal }}</span>
          </v-tooltip>
          <span v-else>{{ merkmal.merkmal }}</span>
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn class="mx-2" elevation="0" icon dark small :color="$store.state.theme.primary" @click="editItem(item)">
          <v-icon dark>mdi-pencil</v-icon>
        </v-btn>
        <v-btn class="mx-2" elevation="0" icon dark small color="#f44336" @click="deleteItem(item)">
          <v-icon dark>mdi-delete</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.bezeichnung="{ item }">
        <div class="d-flex">
          <v-tooltip v-if="item.ust_befreiung" bottom open-delay="300">
            <template v-slot:activator="{ on, attrs }">
              <v-icon left small v-bind="attrs" v-on="on">
                {{ item.ust_befreiung === 1 ? 'mdi-stethoscope' : ( item.ust_befreiung === 2 ? 'mdi-school-outline' : 'mdi-account-tie-outline' ) }}
              </v-icon>
            </template>
            <span>Umsatzsteuerbefreit nach {{ ust_items.find(ust_item => ust_item.value === item.ust_befreiung).text }}.</span>
          </v-tooltip>
          <div>
            <span>{{ item.bezeichnung }}</span><br/>
            <div small v-if="item.dauer !== null || item.preis !== null" class="text-caption">
              <span v-if="item.dauer !== null && item.preis !== null">{{ item.preis }}€ / {{ item.dauer }} Minuten</span>
              <span v-else-if="item.preis !== null">{{ item.preis }} €</span>
              <span v-else-if="item.dauer !== null">{{ item.dauer }} Minuten</span>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:no-data >
        <div v-if="loading" class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <div v-else>
          <p class="mt-5">Es sind noch keine Termine angelegt.</p>
        </div>
      </template>
      <template v-slot:item.datum="{ item }">
        <div class="d-flex align-center">
          <span>{{ new Date(item.datum).toLocaleString([], {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute:'2-digit'}) }}</span>
          <v-tooltip bottom :color="$store.state.theme.primary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon right small :color="$store.state.theme.green" v-bind="attrs" v-on="on" v-show="item.erinnerung_gesendet">
                mdi-bell-ring
              </v-icon>
              <v-icon right small :color="$store.state.theme.orange" v-bind="attrs" v-on="on"
                      v-show="!item.erinnerung_gesendet && item.termin_erinnerung && isDateTimeDiffMoreThan48Hours(item.inserted_at, item.datum)">
                mdi-bell-outline
              </v-icon>
              <v-icon right small v-bind="attrs" v-on="on"
                      v-show="!item.erinnerung_gesendet && item.termin_erinnerung && !isDateTimeDiffMoreThan48Hours(item.inserted_at, item.datum)">
                mdi-bell-off
              </v-icon>
            </template>
            <span v-if="item.erinnerung_gesendet">Termin-Erinnerung wurde am {{ formatUTC2Vienna(item.erinnerung_gesendet) }} Uhr versendet.</span>
            <span v-else-if="item.termin_erinnerung  && isDateTimeDiffMoreThan48Hours(item.inserted_at, item.datum)">Termin-Erinnerung am Vortag geplant.</span>
            <span v-else-if="item.termin_erinnerung">Termin-Erinnerung werden nur für Termine die mind. 48h in der Zukunft liegen versendet.</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.nummer="{ item }">
        <v-chip color="green" dark v-if="item.nummer">{{ item.nummer }}</v-chip>
      </template>
    </v-data-table>
    <v-dialog v-model="redirectDialog" persistent max-width="500px">
      <v-card>
          <v-card-title class="text-h6">Weiterleitung</v-card-title>
          <v-card-text class="text-body-1 text-justify">
              Dein Google-Kalender Zugriff ist abgelaufen. Bitte klicke auf Erneut verbinden, um den Zugriff zu erneuern.
          </v-card-text>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :color="$store.state.theme.primary" text @click="signInWithGoogle">Erneut verbinden</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import connector from '../helpers/supabase-connector.js'
import DialogTermin from '../components/DialogTermin.vue'
import cipher from '@/helpers/cipher'
import moment from 'moment'
import { supabase } from '../supabase'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.extend(timezone)
dayjs.extend(utc)

  export default {

    props: ['session'],
    components: {DialogTermin},

    data() { 
      return {
        ust_items: [
          {'text': '§ 6 Abs. 1 Z 19 UstG (Behandlung)', 'value': 1},
          {'text': '§ 6 Abs. 1 Z 11 UstG (Ausbildungssupervision in einer Ausbildungseinrichtung)', 'value': 2},
          {'text': '§ 6 Abs. 1 Z 27 UstG (Kleinunternehmer)', 'value': 3}
        ],

        deleting_appointment: false,

        redirectDialog: false,
        gapi: null,
        gapi_inited: false,
        gapi_reload_count: 0,

        filter_cleared: false,
        filter_uncleared: false,
        search: '',
        loading: false,
        dialog: false,
        dialogDelete: false,
        headers: [
          { text: 'Name', value: 'nachname' },
          // { text: 'Vorname', value: 'vorname' },
          { text: 'Datum', value: 'datum' },
          // { text: 'Preis', value: 'preis' },
          { text: 'Dienstleistung', value: 'bezeichnung' },
          { text: 'Rechnungsnummer', value: 'nummer', sort: (a, b) => {
              a === null ? a = '0/0000' : a = a
              b === null ? b = '0/0000' : b = b
              let jahr_a = parseInt(a.split('/')[1])
              let jahr_b = parseInt(b.split('/')[1])
              if (jahr_a < jahr_b) {
                return -1
              } else if (jahr_a > jahr_b) {
                return 1
              } else {
                let nummer_a = parseInt(a.split('/')[0])
                let nummer_b = parseInt(b.split('/')[0])
                return nummer_a < nummer_b ? -1 : 1
              }
            } 
          },
          { text: 'Aktionen', value: 'actions', sortable: false, align: 'center' },
        ],
        
        selected_customer: [],
        n_appointments: 0,
        appointments: [],
        google_appointments: [],
        editedIndex: -1,
        editedItem: {
          id: null,
          uid: this.session.user.id,
          fk_klienten_id: null,
          fk_rechnungs_id: null,
          selected: null,
          vorname: null,
          nachname: null,
          uhrzeit: "12:00",
          datum: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString(),
          datumFormatted: dayjs(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).format('DD.MM.YYYY'),
          preis: this.$store.state.client.std_preis,
          dauer: this.$store.state.client.std_dauer,
          bezeichnung: null,
          ust_befreiung: null,
          fk_dienstleistung: null,
          termin_erinnerung: null,
          erinnerung_gesendet: null,
        },
        defaultItem: {
          id: null,
          uid: this.session.user.id,
          fk_klienten_id: null,
          fk_rechnungs_id: null,
          selected: null,
          vorname: null,
          nachname: null,
          uhrzeit: "12:00",
          datum: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString(),
          datumFormatted: dayjs(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).format('DD.MM.YYYY'),
          preis: this.$store.state.client.std_preis,
          dauer: this.$store.state.client.std_dauer,
          bezeichnung: null,
          ust_befreiung: null,
          fk_dienstleistung: null,
          termin_erinnerung: null,
          erinnerung_gesendet: null,
        },
      }
    },

    computed: {

      nAllAppointments() {
        return this.appointments.length;
      },

      nClearedAppointments() {
        return this.appointments.filter((appointment) => appointment.nummer).length;
      },

      nUnclearedAppointments() {
        return this.appointments.filter((appointment) => !appointment.nummer).length;
      },

      formTitle () {
        return this.editedIndex === -1 ? 'Neuer Termin' : 'Termin bearbeiten'
      },
      computedDateFormatted () {
        return this.formatDate(this.editedItem.datum)
      },
      percLoadedAppointments() {
        return this.n_appointments > 0 ? Math.round(this.appointments.length / this.n_appointments * 10) * 10 : 0
      },
      computedAppointments() {
        return this.appointments.filter((appointment) => {
          if (this.filter_cleared) {
            return appointment.nummer
          } else if (this.filter_uncleared) {
            return !appointment.nummer
          } else {
            return true
          }
        })
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    mounted () {
      this.gapi = window.gapi;
      // check if gapi client is already loaded
      if (this.gapi.client) {
        // already loaded
        this.gapi_inited = true;
        this.initialize();
      } else {
        this.gapi.load('client', this.initializeGapiClient);
      }
    },

    methods: {

      truncateText(text, length = 40) {
        return text.length > length ? text.substring(0, length) + '...' : text;
      },

      isDateTimeDiffMoreThan48Hours(date_inserted, date_appointment) {
        // account for timezone difference, as date1 is in UTC and date2 is in Vienna
        let diff = dayjs(date_appointment).diff(dayjs.utc(date_inserted).tz('Europe/Vienna'), 'hours');
        return diff >= 48;
      },

      formatUTC2Vienna(date) {
        return dayjs(date).tz('Europe/Vienna').format('DD.MM.YYYY, HH:mm');
      },

      async signInWithGoogle() {
          const { data, error } = await supabase.auth.signInWithOAuth({
              provider: 'google',
              options: {
                redirectTo: process.env.NODE_ENV === 'development' ? 'http://localhost:8080/kalender' : 'https://app.zeipsy.com/kalender',
                scopes: ['https://www.googleapis.com/auth/calendar.calendarlist.readonly', 'https://www.googleapis.com/auth/calendar.events'],
                queryParams: {
                  access_type: 'offline',
                  prompt: 'consent', // The refresh-token gets returned only immediately after consent. 
                                     // It will not be re-issued on sessionRefresh or Login.        
                                     // Therefore, "force" consent on re-login as refresh-token is not in local storage.
                },
              },
          })

          if (error) {
            localStorage.removeItem('scopes');
            localStorage.removeItem('provider_refresh_token');
            this.$emit('showError', { message: 'Fehler beim Verbinden mit Google.'});
          }
          if (data) {
            localStorage.setItem('scopes', 'https://www.googleapis.com/auth/calendar.calendarlist.readonly,https://www.googleapis.com/auth/calendar.events');
            localStorage.setItem('provider_refresh_token', data.provider_refresh_token);
          }
      },

      async getRefreshToken(refresh_token) {
          var myHeaders = new Headers();
          myHeaders.append('Authorization', 'Bearer ' + this.session.access_token);
          myHeaders.append('Accept', '*/*');
          myHeaders.append('x-client-info', 'supabase-js/2.8.0');

          var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              redirect: 'follow',
              body: JSON.stringify({
                  refresh_token: refresh_token,
              }),
          };

          let responseJson = {};
          try {
              let response = await fetch(
                  'https://qgsfsflpvymafizvkpca.functions.supabase.co/google-refresh-token/',
                  requestOptions
              );
              responseJson = await response.json();
          } catch (error) {
              connector.logError(this, {
                  uid: this.session.user.id,
                  message: 'LOG: Google Calendar: Error during POST request to google-refresh-token function.',
              });
          }

          return responseJson;
      },

      async initializeGapiClient() {
        try {

          // only initialize if we have a refresh token
          if (localStorage.provider_refresh_token) {
            let accessToken = await this.getRefreshToken(localStorage.provider_refresh_token);

            await this.gapi.client.init({
              apiKey: "AIzaSyBVojXCiR8LbVSlFt94GU0-O_yShuQpRTs",
              discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
            });

            this.gapi.client.setToken({access_token: accessToken.access_token});
            this.gapi_inited = true;
          } else {
            // check if a calendar is already set, if so, show the reconnect dialog
            if (this.$store.state.client.google_calendar) {
              this.redirectDialog = true;
              connector.logError(this, {
                uid: this.session.user.id,
                message: 'LOG: Google Calendar: Refresh token not found although calender is set. Prompting user to reconnect.',
              });
            }
          }

        } catch (error) {
          console.log(error);
          this.$emit('showError', { message: 'Fehler beim Initialisieren von Google-API.'});
        }
        
        this.initialize();
      },

      async getCalendarEvents(calendar_id) {

        let response = null
        try {
          const request = {
            'calendarId': calendar_id,
            'timeMin': moment().subtract(1, 'month').toISOString(),
            'showDeleted': false,
            'singleEvents': true,
            'maxResults': 100,
            'orderBy': 'startTime',
          };
          response = await this.gapi.client.calendar.events.list(request)
        } catch (err) {
          if (err.status === 404 || err.status === 401) {
            // renew the access token
            // check if provider refresh token is present, if so, get a new access token
            if (localStorage.provider_refresh_token) {
              if (this.gapi_reload_count > 0) {
                // if we already tried to reload the gapi client, show the reconnect dialog
                this.redirectDialog = true;
                connector.logError(this, {
                  uid: this.session.user.id,
                  message: 'LOG: Google Calendar: Getting in Auth Loop. Prompting user to reconnect.',
                });
                return []
              }
              this.gapi.load('client', this.initializeGapiClient);
              this.gapi_reload_count += 1;
            } else {
              // if not, redirect to google with prompt=consent and offline access
              this.redirectDialog = true;
              connector.logError(this, {
                uid: this.session.user.id,
                message: 'LOG: Google Calendar: Refresh token not found. Prompting user to reconnect.',
              });
            }
          }
          if (err.status === 403) {
            // insufficient permissions, probably the user revoked the permissions
            // show redirection dialog
            this.redirectDialog = true;
            connector.logError(this, {
              uid: this.session.user.id,
              message: 'LOG: Google Calendar: Insufficient permissions. Prompting user to reconnect.',
            });
          }
          return []
        }
        if (response) {
          return response.result.items.map((appointment) => {
            return {
              start: moment(appointment.start.dateTime).format('YYYY-MM-DDTHH:mm'),
              end: moment(appointment.end.dateTime).format('YYYY-MM-DDTHH:mm'),
              name: appointment.summary,
              type: 'google',
              id: appointment.description && appointment.description.includes('zeipsy.com/termine/?id=') ? parseInt(appointment.description.split('id=')[1]) : null,
              eventId: appointment.id,
            }
          })
        }
        return []
      },

      initialize() {
        
        this.loading = true

        connector.getDataOnly(this, 'vwtermine', 'datum', false)
          .then((appointments) => {
            this.n_appointments = appointments.length
            if (this.n_appointments > 0) {
              this.$store.commit('setOnboardingAppointmentStatus', true)
            } else {
              this.$store.commit('setOnboardingAppointmentStatus', false)
            }
            return appointments
          })
          .then((appointments) => cipher.decryptDataAsync(this, appointments, 'appointments', 'loading'))   
          
        connector.getDataOnly(this, 'vwkunden', 'id', true)
          .then((client) => client.length > 0 ? this.$store.state.client = client[0]: false )
          .then(() => this.setDefaultValues())
          .then(() => {
            if (this.$store.state.client.google_calendar) {
              this.getCalendarEvents(this.$store.state.client.google_calendar)
                .then((appointments) => this.google_appointments = appointments)
            } else {
              this.google_appointments = []
            }
          })
      },

      clickAll() {
        this.filter_cleared = false;
        this.filter_uncleared = false;
      },

      clickCleared() {
        this.filter_cleared = true;
        this.filter_uncleared = false;
      },

      clickUncleared() {
        this.filter_uncleared = true;
        this.filter_cleared = false;
      },

      setDefaultValues() {
        this.defaultItem.dauer = this.$store.state.client.std_dauer
        this.defaultItem.preis = this.$store.state.client.std_preis

        this.editedItem.dauer = this.$store.state.client.std_dauer
        this.editedItem.preis = this.$store.state.client.std_preis
      },

      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('T')[0].split('-')
        return `${day}.${month}.${year}`
      },

      newItem () {

        // check if user has already some services, if not prompt him to create one first
        if (!this.$store.getters.hasServices) {
          this.$emit('showError', {'message': 'Um einen Termin zu erstellen, musst du zuerst eine Dienstleistung anlegen.'})
          return
        }

        this.editedIndex = -1
        this.editedItem = Object.assign({}, this.defaultItem)
        this.dialog = true
      },
      
      editItem (item) {
        this.editedIndex = item.id
        this.editedItem = Object.assign({}, item)
        this.editedItem.selected = { name: this.editedItem.nachname + ' ' + this.editedItem.vorname, fk_klienten_id: this.editedItem.fk_klienten_id }
        this.editedItem.datumFormatted = dayjs(this.editedItem.datum).format('DD.MM.YYYY')
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = item.id
        this.editedItem = Object.assign({}, item)

        if (this.editedItem.fk_rechnungs_id) {
          this.$emit('showError', {'message': 'Der Termin kann nicht gelöscht werden, da er bereits verrechnet ist.'})
          return
        }

        this.dialogDelete = true
      },

      async deleteItemConfirm () {
        this.deleting_appointment = true;
        let deleted = await connector.delete(this, 'termine', 'id', this.editedIndex);
        if (!deleted) {
          this.deleting_appointment = false;
          return;
        };

        // check if google calendar is connected and if yes, also delete the appointment from the google calendar
        if (this.$store.state.client.google_calendar) {
          try {
            // find the event with the same id
            let google_appointment = this.google_appointments.find((appointment) => appointment.id === this.editedIndex)

            if (google_appointment) {
              await this.gapi.client.calendar.events.delete({
                'calendarId': this.$store.state.client.google_calendar,
                'eventId': google_appointment.eventId,
              });
            } else {
              console.log('No google appointment found for id: ', this.editedIndex)
            }
          } catch (err) {
            console.log('Error during deleting calendar event: ', err)
          } 
        }

        let deleted_files = true;
        // delete files from the corresponding bucket (if any)
        if (this.session.user.id && this.editedIndex && this.editedItem.fk_klienten_id) {
            let client_id = this.editedItem.fk_klienten_id;
            let path = this.session.user.id + '/' + client_id + '/' + this.editedIndex + '/';
            deleted_files = await connector.deleteAllFilesInBucket(this, 'documentation', path);
        }

        this.initialize();
        this.deleting_appointment = false;
        this.closeDelete();

        if (deleted && deleted_files) {
          this.$emit('showInfo', {
            message: 'Der Termin wurde erfolgreich gelöscht.',
            timeout: 5000
          });
        }
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      close() {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      refreshAndClose() {
        this.initialize();
        this.close();
      },

      updateAndClose(toUpdate, id) {
        let index = this.appointments.findIndex(appointment => appointment.id === id);
        for (let key in toUpdate) {
          this.$set(this.appointments[index], key, toUpdate[key]);
        }
        this.close();
      },

      filterName (value, search, item) {
        return value != null &&
          search != null &&
          typeof value === 'string' &&
          (value.toLowerCase().indexOf(search.toLowerCase()) !== -1 || 
            (item.nachname.toLowerCase() + ' ' + item.vorname.toLowerCase()).indexOf(search.toLowerCase()) !== -1
          )
      },
    }
  }
</script>