<template>
    <v-container fluid>

        <FilePreview ref="filePreview" :session="session" :client_id="selected_client ? selected_client.id : null" @showError="(arg) => $emit('showError', arg)" @showInfo="(arg) => $emit('showInfo', arg)" @getBucketFiles="getBucketFiles" />

        <v-dialog v-model="dialog_anamnese" max-width="1000px" persistent :fullscreen="$vuetify.breakpoint.xsOnly || isFullscreen">
            <v-card>
                <v-card-title class="mr-4 pr-0">
                    <span class="text-h5">Vorgeschichte / Anamnese bearbeiten</span>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom open-delay="300" v-if="$vuetify.breakpoint.smAndUp">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click="toggleFullscreen" v-bind="attrs" v-on="on">
                                <v-icon>{{ isFullscreen ? 'mdi-arrow-collapse' : 'mdi-arrow-expand' }}</v-icon>
                            </v-btn>
                        </template>
                        {{ isFullscreen ? 'Vollbild beenden.' : 'In den Vollbildmodus wechseln.' }}
                    </v-tooltip>
                </v-card-title>
                <v-card-text>
                    <TipTapEditor ref="editor-anamnese" :templates="templates" :value="edit_anamnese" @input="handleInput('edit_anamnese', $event)" @fetchTemplates="fetchTemplates" :session="session" />
                </v-card-text>
                <v-card-actions class="pb-5 px-6">
                    <v-spacer></v-spacer>
                    <v-btn text :color="$store.state.theme.red" @click="abortAnamnese">Abbrechen</v-btn>
                    <v-btn outlined :color="$store.state.theme.green" @click="saveAnamnese" :loading="saving">Speichern</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_documentation" max-width="1000px" persistent :fullscreen="$vuetify.breakpoint.xsOnly || isFullscreen">
            <v-card>
                <v-tabs v-model="tabs_documentation_type" color="grey darken-4" :background-color="$store.state.theme.background_tabs" grow class="mb-5" show-arrows>
                    <v-tabs-slider color="grey darken-4" />
                    <v-tab>
                        Dokumentation
                        <v-tooltip bottom open-delay="300">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon right v-bind="attrs" v-on="on">
                                    mdi-information
                                </v-icon>
                            </template>
                            <span>
                                Erstelle hier die Dokumentation der Einheit. Diese kann bei Bedarf vom Klienten angefordert und exportiert werden.
                            </span>
                        </v-tooltip>
                    </v-tab>
                    <v-tab>
                        Persönliche Notizen
                        <v-tooltip bottom open-delay="300">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon right v-bind="attrs" v-on="on">
                                    mdi-information
                                </v-icon>
                            </template>
                            <span>
                                Schreibe hier persönliche Notizen zur Einheit. Diese sind nicht Teil der offiziellen Dokumentation und werden nicht exportiert.
                            </span>
                        </v-tooltip>
                    </v-tab>
                    <v-tooltip bottom open-delay="300" v-if="$vuetify.breakpoint.smAndUp">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click="toggleFullscreen" class="mt-2 mx-3" v-bind="attrs" v-on="on">
                                <v-icon>{{ isFullscreen ? 'mdi-arrow-collapse' : 'mdi-arrow-expand' }}</v-icon>
                            </v-btn>
                        </template>
                        {{ isFullscreen ? 'Vollbild beenden.' : 'In den Vollbildmodus wechseln.' }}
                    </v-tooltip>
                </v-tabs>
                
                <v-card-text class="pt-1">
                    <v-tabs-items v-model="tabs_documentation_type">
                        <v-tab-item eager>
                            <TipTapEditor ref="editor-documentation" :templates="templates" :value="documentation" @input="handleInput('documentation', $event)" @fetchTemplates="fetchTemplates" :session="session" />
                        </v-tab-item>
                        <v-tab-item eager>
                            <TipTapEditor ref="editor-notes" :templates="templates" :value="notes" @input="handleInput('notes', $event)" @fetchTemplates="fetchTemplates" :session="session" />
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
                
                <v-card-actions class="pb-5 px-6">
                    <v-spacer></v-spacer>
                    <v-btn text :color="$store.state.theme.red" @click="abortDocumentation">Abbrechen</v-btn>
                    <v-btn outlined :color="$store.state.theme.green" @click="saveDocumentation" :loading="saving">Speichern</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-row no-gutters>
            <v-col class="d-flex align-center justify-space-between my-0 py-0">
                <span :class="$vuetify.breakpoint.xsOnly ? 'text-h6' : 'text-h5'">Dokumentation</span>
                <v-btn v-if="selectedClientStatistics" @click="downloadDocumentation">
                    <v-icon left>
                        mdi-file-download-outline
                    </v-icon>

                    <span v-if="$vuetify.breakpoint.smAndUp">Dokumentation herunterladen</span>
                    <span v-else>Herunterladen</span>
                </v-btn>
            </v-col>
        </v-row>

        <v-row no-gutters v-if="!selectedClientStatistics">
            <v-col>
                <v-card class="pa-5 mb-4 mt-3">

                    <p class="text-h6 mb-3">
                        Klient auswählen
                    </p>

                    <v-autocomplete prepend-inner-icon="mdi-account" v-model="selected_client" :items="formattedClients"
                        label="Klient" item-text="name" item-value="id" outlined return-object dense
                        clear-icon="mdi-close-circle" clearable placeholder="Suchbegriff..." persistent-placeholder>
                        <template v-slot:item="{ item }">
                            <div class="d-flex align-center">
                                <span>{{ item.name }}</span>
                                <v-chip v-for="merkmal in item.merkmale" :key="'chip-' + merkmal.id" class="ml-2" small>{{
                                    merkmal.merkmal }}</v-chip>
                            </div>
                        </template>
                        <template v-slot:selection="{ item }">
                            <div class="d-flex align-center">
                                <span>{{ item.name }}</span>
                                <v-chip v-for="merkmal in item.merkmale" :key="'chip-selection-' + merkmal.id" class="ml-2"
                                    small>{{
                                        merkmal.merkmal }}</v-chip>
                            </div>
                        </template>
                    </v-autocomplete>

                    <p class="text-h6 mb-3" v-if="!selectedClientStatistics">
                        Schnellauswahl
                    </p>
                    <v-row>
                        <v-col v-for="client in clientsQuickSelect" :key="client.id" cols="12" md="3">
                            <v-hover v-slot="{ hover }">
                                <v-card outlined class="file-card"
                                    @click="selected_client = formattedClients.find(c => c.id === client.id)"
                                    :elevation="hover ? '2' : '0'">
                                    <v-card-title>
                                        <v-icon left>
                                            mdi-account
                                        </v-icon>
                                        <span class="text-truncate">{{ client.name }}</span>
                                    </v-card-title>
                                    <v-card-text>
                                        <p class="mb-0">Nicht dokumentierte Termine: {{ client.nicht_dokumentiert }}</p>
                                    </v-card-text>
                                </v-card>
                            </v-hover>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <v-row v-else>
            <v-col cols="12" md="6" class="mt-0 pt-0">
                <v-card class="pa-5 mb-4">
                    <div class="d-flex flex-column align-center">
                        <v-autocomplete v-model="selected_client" :items="formattedClients" outlined append-icon="mdi-magnify"
                            item-text="name" item-value="id" return-object full-width style="width: 100%;" 
                            clear-icon="mdi-close-circle" clearable placeholder="Suchbegriff..." persistent-placeholder>
                            <template v-slot:item="{ item }">
                                <div class="d-flex align-center">
                                    <span>{{ item.name }}</span>
                                    <v-chip v-for="merkmal in item.merkmale" :key="'chip-' + merkmal.id" class="ml-2" small>
                                        {{ merkmal.merkmal }}
                                    </v-chip>
                                </div>
                            </template>
                            <template v-slot:selection="{ item }">
                                <div class="d-flex align-center justify-center" style="width: 100%;">
                                    <v-icon left large>
                                        mdi-account
                                    </v-icon>
                                    <span class="text-h6">
                                        {{ item.name }} {{ selected_client.alter ? '(' + selected_client.alter + ')' : '' }}
                                    </span>
                                </div>
                            </template>
                        </v-autocomplete>
                        <div class="subtitle-1 text-center">{{ selected_client.adresse }}</div>
                        <div full-width class="d-flex flex-wrap justify-center">
                            <v-chip v-for="merkmal in selected_client.merkmale" :key="'chip-details-' + merkmal.id"
                                class="mr-2 my-1">{{
                                    merkmal.merkmal }}
                            </v-chip>
                            <v-chip class="my-1" v-if="selected_client.svnr">SV-Nr.: {{ selected_client.svnr }}</v-chip>
                        </div>
                    </div>

                    <div v-if="selected_client.zusatztext" class="mt-3 text-center">{{ selected_client.zusatztext }}</div>

                    <v-row class="mt-3">
                        <v-col cols="6" class="d-flex flex-column align-center">
                            <div class="body-1">Einheiten</div>
                            <div class="title">{{ selectedClientStatistics.appointment_count }} ({{
                                selectedClientStatistics.appointment_sum }} Minuten)</div>
                        </v-col>

                        <v-col cols="6" class="d-flex flex-column align-center">
                            <div class="body-1">Behandlungsfrequenz</div>
                            <div class="title">{{ selectedClientStatistics.appointment_frequency }}x / Monat</div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" class="d-flex flex-column align-center">
                            <div class="body-1">Erste Einheit</div>
                            <div class="title">{{ selectedClientStatistics.first_appointment }}</div>
                        </v-col>

                        <v-col cols="6" class="d-flex flex-column align-center">
                            <div class="body-1">Letzte Einheit</div>
                            <div class="title">{{ selectedClientStatistics.last_appointment }}</div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>

            <v-col cols="12" md="6" class="mt-0 pt-0">
                <v-card class="pa-5">
                    <div class="d-flex align-center mb-5">
                        <div class="text-h6">
                            Dokumente
                            <v-progress-circular v-if="uploadedFiles.length === 0 && retrievingFiles" indeterminate
                                color="primary" size="20" class="ml-3"></v-progress-circular>
                        </div>
                        <v-btn v-if="uploadedFiles.length > 0" outlined small class="ml-3" @click="selectFiles">
                            <v-icon left>mdi-plus</v-icon>
                            Hinzufügen
                        </v-btn>
                    </div>

                    <v-row no-gutters v-if="uploadedFiles.length > 0">
                        <v-col v-for="(file) in uploadedFiles" :key="'all-' + file.name" cols="12" sm="6">
                            <v-hover v-slot="{ hover }">
                                <v-card class="d-flex align-center px-3 py-1 ma-2 file-card" :elevation="hover ? 2 : 0"
                                    outlined @click.stop="openFile(file)" :disabled="file.uploading">
                                    <v-icon v-if="!file.uploading" class="mr-3" :color="file.iconColor">{{ file.icon
                                    }}</v-icon>
                                    <v-progress-circular v-else class="mr-3" :size="30" indeterminate color="primary" />
                                    <div class="file-info-container d-flex flex-column justify-center flex-grow-1">
                                        <span class="text-caption text-truncate-file">{{ file.name }}</span>
                                        <span>
                                            {{ file.size }}
                                            <v-chip v-if="file.appointment_id" class="ml-2" small>Einheit: {{
                                                getDateFromAppointmentId(file.appointment_id) }}</v-chip>
                                        </span>
                                    </div>
                                </v-card>
                            </v-hover>
                        </v-col>
                    </v-row>

                    <v-card v-if="uploadedFiles.length === 0 && !retrievingFiles" class="pa-5 mt-3 upload-area" outlined
                        v-on:dragover.prevent="dragOver = true" v-on:dragleave.prevent="dragOver = false"
                        v-on:drop.prevent="handleDrop">
                        <div v-if="!dragOver">
                            <v-icon left>mdi-file-upload-outline</v-icon>
                            Ziehe Dokumente, Bilder, oder PDFs hierher um sie hochzuladen.
                        </div>
                        <div v-if="!dragOver" class="py-3">
                            Oder wähle die Dateien aus um sie hochzuladen.
                        </div>
                        <div v-if="dragOver" class="py-5 text-h6">Lass einfach los.</div>
                        <v-btn v-if="!dragOver" :color="$store.state.theme.primary" dark @click="selectFiles">
                            <v-icon left>
                                mdi-plus
                            </v-icon>
                            Dateien Hinzufügen
                        </v-btn>
                    </v-card>
                    <input type="file" ref="fileInput" hidden multiple @change="handleFiles" />
                    <input type="file" ref="fileInputAppointments" hidden multiple @change="handleFilesAppointments" />
                </v-card>
            </v-col>
        </v-row>

        <div v-if="selectedClientStatistics">
            <p class="text-h5 mt-5">Vorgeschichte / Anamnese</p>
            <v-card class="">
                <v-card-text class="pb-2 text-body-1 black--text" ref="container-anamnese">
                    <div v-if="anamnese" class="p-container" style="white-space: pre-line;" v-html="anamnese">
                    </div>
                    <span v-else class="font-italic">
                        Es wurde noch keine Anamnese für diesen Klienten verfasst.
                    </span>
                </v-card-text>
                <v-card-actions class="justify-end px-5 pb-5">
                    <v-btn outlined @click="editAnamnese">Bearbeiten</v-btn>
                </v-card-actions>
            </v-card>

            <div class="d-flex align-center">
                <p class="text-h5 mt-5">Behandlungsverlauf</p>
                <v-btn icon class="ml-4" @click="sort_appointments_descending = !sort_appointments_descending">
                    <v-icon>mdi-swap-vertical-bold</v-icon>
                </v-btn>
            </div>

            <v-card v-for="appointment in selectedClientAppointments" :key="appointment.id" class="mb-5">
                <!-- <v-card-title class="text-subtitle-1 font-weight-bold pb-1">
                    {{ appointment.bezeichnung ? appointment.bezeichnung : 'Einheit' }} am {{
                        getFormattedDate(appointment.datum) }} um {{ getFormattedTime(appointment.uhrzeit) }}
                </v-card-title> -->
                <v-card-title>
                    <div class="d-flex flex-column">
                        <span class="text-subtitle-1 font-weight-bold">
                            {{ appointment.appointment_count }}. Termin am {{ getFormattedDate(appointment.datum) }} um {{ getFormattedTime(appointment.uhrzeit) }}
                        </span>
                        <span class="text-subtitle-2" v-if="appointment.bezeichnung">{{ appointment.bezeichnung }}</span>
                    </div>               
                </v-card-title>
                <v-card-text class="pb-2 text-body-1 black--text">
                    <div v-if="appointment.dokumentation && appointment.dokumentation !== '<p></p>'" style="white-space: pre-line;" class="p-container" v-html="appointment.dokumentation">
                    </div>
                    <span v-else class="font-italic">
                        Diese Einheit wurde noch nicht dokumentiert.
                    </span>
                    <p v-if="appointment.notizen && appointment.notizen !== '<p></p>'" class="text-subtitle-1 font-weight-bold pb-1 mt-5 mb-0">
                        Persönliche Notizen:
                    </p>
                    <div v-if="appointment.notizen && appointment.notizen !== '<p></p>'" style="white-space: pre-line;" class="p-container" v-html="appointment.notizen">
                    </div>
                </v-card-text>
                <v-card-actions :class="$vuetify.breakpoint.smAndDown ? 'd-flex flex-column align-end px-5 pb-5' : 'justify-end px-5 pb-5'">
                    <v-row no-gutters v-if="appointmentFileIds.length > 0 && appointmentFileIds.includes(appointment.id)">
                        <v-col
                            v-for="(file) in uploadedAppointmentFiles.filter(file => file.appointment_id === appointment.id)"
                            :key="'appointment-' + file.name" cols="12" md="6" lg="4">
                            <v-hover v-slot="{ hover }">
                                <v-card class="d-flex align-center px-3 py-1 ma-2 file-card" :elevation="hover ? 2 : 0"
                                    outlined @click.stop="openFile(file)">
                                    <v-icon v-if="!file.uploading" class="mr-3" :color="file.iconColor">{{ file.icon
                                    }}</v-icon>
                                    <v-progress-circular v-else class="mr-3" :size="30" indeterminate color="primary" />
                                    <div class="file-info-container d-flex flex-column justify-center flex-grow-1">
                                        <span class="text-caption text-truncate-file">{{ file.name }}</span>
                                        <span>{{ file.size }}</span>
                                    </div>
                                </v-card>
                            </v-hover>
                        </v-col>
                    </v-row>
                    <v-btn text @click="selectFilesAppointments(appointment.id)">Dateien hinzufügen</v-btn>
                    <v-btn outlined @click="editDocumentation(appointment)">Bearbeiten</v-btn>
                </v-card-actions>
            </v-card>
        </div>
        <p v-else class="d-flex justify-center">
            <v-icon left>
                mdi-chart-timeline-variant-shimmer
            </v-icon>
            <span class="">Wähle einen Klienten aus um die Dokumentation zu sehen.</span>
        </p>
    </v-container>
</template>

<script>
import connector from '../helpers/supabase-connector.js'
import cipher from '../helpers/cipher.js'
import dayjs from 'dayjs'

import PizZip from 'pizzip'
import Docxtemplater from 'docxtemplater'
import InspectModule from 'docxtemplater/js/inspect-module'
import { saveAs } from 'file-saver'
import TipTapEditor from '../components/TipTapEditor.vue'
import FilePreview from '../components/FilePreview.vue';

export default {

    components: {
        TipTapEditor,
        FilePreview
    },

    props: ['session'],

    data() {
        return {

            templates: [], // Array to store template data

            isFullscreen: false,

            tabs_documentation_type: 0,

            dragOver: false,
            uploadedFiles: [],
            uploadedAppointmentFiles: [],
            selected_appointment_id: null,
            retrievingFiles: false,

            dialog_anamnese: false,
            dialog_documentation: false,

            clients: [],
            loading_clients: false,
            selected_client: null,

            appointments: [],
            loading_appointments: false,
            documentation: null,
            notes: null,

            documentation_appointment_id: null,
            sort_appointments_descending: true,

            anamnese: null,
            edit_anamnese: null,
            saving: false,
        }
    },

    async mounted() {
        await this.initialize();
        
    },


    watch: {
        // TODO: NOT ALWAYS LOAD THE ENTIRE CLIENTS WITH ANAMNESE, JUST LOAD THE ANAMNESE OF THE SELECTED CLIENT
        async selected_client() {
            if (this.selected_client !== null && this.selected_client !== undefined && this.selected_client.id !== undefined && this.selected_client.id !== null) {

                if (this.$route.params.id && parseInt(this.$route.params.id) !== this.selected_client.id) {
                    // remove the route parameter
                    this.$router.push({
                        path: `/dokumentation`
                    });
                }

                // reset uploaded files
                this.uploadedFiles = [];
                this.uploadedAppointmentFiles = [];

                // retrieve the client's anamnese from the database in the view vwdokumentationanamnese
                let anamnesen = await connector.getDataOnly(this, 'vwdokumentationanamnese', 'id', false);

                // TODO: check again if the selected client is still the same, as the user might have changed it in the meantime
                // this might be null if the client clears it while the data is still loading

                let anamnese = anamnesen.find(anamnese => anamnese.id === this.selected_client.id)
                if (anamnese) {
                    //cipher.decryptDataAsync(this, anamnese, 'anamnese', 'loading_anamnese')
                    let dec_anamnese = await cipher.decryptObject(this, this.$store.state.aes_key, anamnese)
                    this.anamnese = dec_anamnese.anamnese
                    this.edit_anamnese = this.anamnese
                }
                this.getBucketFiles();
            }
        }
    },

    methods: {

        async fetchTemplates() {
            try {
                let templates = await connector.getDataOnly(this, 'vwdokumentationsvorlagen', 'name', true);
                this.templates = templates.map((template) => {
                    template.deleting = false;
                    return template;
                })
            } catch (error) {
                console.error('Error during fetching templates:', error);
            }
        },

        toggleFullscreen() {
            this.isFullscreen = !this.isFullscreen;
        },

        removeTags(str) {
            if ((str === null) || (str === '') || typeof str !== 'string') return '';
    
            // Replace <br> tags with newline
            str = str.replace(/<br\s*\/?>/gi, '\n');

            // Replace closing <p> tags with newlines
            // the opening tags are replaced afterwards with empty string
            // Optionally, you might want to handle <p> more carefully to avoid too many blank lines
            str = str.replace(/<\/p>/gi, '\n');
                
            return str.replace(/(<([^>]+)>)/ig, '');
        },

        getDateFromAppointmentId(appointment_id) {
            // format it to DD.MM.YYYY
            let appointment_date = this.appointments.find(appointment => appointment.id === appointment_id).datum
            if (appointment_date) {
                return dayjs(appointment_date).format('DD.MM.YYYY')
            } else {
                return null
            }
        },

        async getBucketFiles() {

            this.retrievingFiles = true;

            // list all the files in the documentation folder of the selected client
            let client_id_folder = this.selected_client.id + '/'
            // listFilesInBucket(component, bucket, path)
            let files = await connector.listFilesInBucket(this, 'documentation', this.session.user.id + '/' + client_id_folder);

            this.uploadedFiles = [...files.filter(file => file.id !== null && file.name !== '.emptyFolderPlaceholder').map(file => {
                return {
                    id: file.id,
                    created_at: file.created_at,
                    updated_at: file.updated_at,
                    name: file.name,
                    size: this.bytesToSize(file.metadata.size),
                    icon: this.getFileIcon(file.metadata.mimetype),
                    mimetype: file.metadata.mimetype,
                    iconColor: this.getIconColor(file.metadata.mimetype),
                    uploading: false,
                }
            })];

            // get the files which are uploaded to subfolders of the client folder, which are the appointment ids which they belong to
            let uploadedAppointmentPaths = files.filter(file => file.id === null && file.name !== '.emptyFolderPlaceholder');
            this.uploadedAppointmentFiles = [];
            for (const path of uploadedAppointmentPaths) {
                let appointment_id = path.name;
                let appointment_files = await connector.listFilesInBucket(this, 'documentation', this.session.user.id + '/' + client_id_folder + appointment_id + '/');
                // concat the appointment files to the uploadedAppointmentFiles array
                this.uploadedAppointmentFiles = this.uploadedAppointmentFiles.concat(appointment_files.filter(file => file.id !== null).map(file => {
                    return {
                        id: file.id,
                        appointment_id: parseInt(appointment_id),
                        created_at: file.created_at,
                        updated_at: file.updated_at,
                        name: file.name,
                        size: this.bytesToSize(file.metadata.size),
                        icon: this.getFileIcon(file.metadata.mimetype),
                        mimetype: file.metadata.mimetype,
                        iconColor: this.getIconColor(file.metadata.mimetype),
                        uploading: false,
                    }
                }));
            }
            // add the uploadedAppointmentFiles to the uploadedFiles array
            this.uploadedFiles = this.uploadedFiles.concat(this.uploadedAppointmentFiles);

            this.retrievingFiles = false;

        },

        bytesToSize(bytes) {
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
            if (bytes === 0) return '0 Byte'
            const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
            return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
        },

        selectFiles() {
            this.$refs.fileInput.click();
        },

        selectFilesAppointments(id) {
            this.selected_appointment_id = id;
            this.$refs.fileInputAppointments.click(id);
        },

        async handleFiles(event) {
            const files = event.target.files || event.dataTransfer.files;
            let uploaded = await this.uploadFilesToSupabase(files, false);
            if (uploaded.length === 0 || uploaded.some((file) => file === null)) {
                connector.logError(this, {
                    uid: this.session.user.id,
                    message: 'LOG: Possible error during file upload in documentation.',
                });
            } 
            this.$nextTick(() => {
                this.getBucketFiles();
            });
        },

        async handleFilesAppointments(event) {
            const files = event.target.files || event.dataTransfer.files;
            let uploaded = await this.uploadFilesToSupabase(files, true);
            if (uploaded.length === 0 || uploaded.some((file) => file === null)) {
                connector.logError(this, {
                    uid: this.session.user.id,
                    message: 'LOG: Possible error during file upload in documentation.',
                });
            } 
            this.$nextTick(() => {
                this.getBucketFiles();
            });
        },

        handleDrop(event) {
            this.dragOver = false;
            this.handleFiles(event);
        },

        async uploadFilesToSupabase(files, isAppointment = false) {
            if (files.length === 0) return;

            let client_id_folder = this.selected_client.id + '/';
            if (isAppointment) {
                client_id_folder = this.selected_client.id + '/' + this.selected_appointment_id + '/';
            }

            let uploadPromises = [];

            // check if aes_key_file is set, if not then try to load it again
            if (!this.$store.state.aes_key_file) {
                let keys = await cipher.getAESKeys(this);
                this.$store.state.aes_key_file = keys['aes_key_file'];
            }

            for (const file of files) {

                let file_name = file.name;

                // iterate over each character and check if it is an umlaute, if so replace it with
                for (let i = 0; i < file_name.length; i++) {
                    if (file_name.charCodeAt(i) === 776) {
                        // umlaute, replace with e
                        file_name = file_name.replace(file_name[i], 'e');
                    } else if (file_name.charCodeAt(i) === 223) {
                        // ß, replace with ss
                        file_name = file_name.replace(file_name[i], 'ss');
                    }
                }

                // check if the file name contains special characters, if so, show an error message and skip the file
                if (file_name.match(/[^a-zA-Z0-9. \-()_]/)) {
                    this.$emit('showError', {
                        message: 'Dateinamen dürfen keine Sonderzeichen oder Umlaute enthalten.',
                    });
                    continue;
                }

                // check if the file already exists in the folder, if so, add a number to the file name
                let duplicate_files = this.uploadedFiles.filter(f => f.name === file_name);
                if (duplicate_files.length > 0) {
                    let i = 1; // Start with 1 and increment this number until a unique file name is found
                    let newName = file_name;
                    let baseName, extension;

                    // Check if the file has an extension
                    if (file_name.includes('.')) {
                        baseName = file_name.substring(0, file_name.lastIndexOf('.'));
                        extension = file_name.substring(file_name.lastIndexOf('.'));
                    } else {
                        baseName = file_name;
                        extension = '';
                    }

                    // Loop until a unique file name is found
                    while (this.uploadedFiles.some(f => f.name === newName)) {
                        newName = `${baseName} (${i})${extension}`;
                        i++;
                    }
                    file_name = newName; // Assign the unique file name
                }

                // Add template file to uploadedFiles so that the user can see the progress
                if (!isAppointment) {
                    this.$set(this.uploadedFiles, this.uploadedFiles.length, {
                        name: file_name,
                        uploading: true,
                    });
                } else {
                    this.$set(this.uploadedAppointmentFiles, this.uploadedAppointmentFiles.length, {
                        name: file_name,
                        appointment_id: this.selected_appointment_id,
                        uploading: true,
                    });
                }

                // Wrap FileReader in a promise
                let promise = new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = async (e) => {
                        const fileData = e.target.result;
                        let encrypted_file = await cipher.encryptFile(this.$store.state.aes_key_file, fileData);
                        let fileSize = e.total > 1024 * 1024 ? (e.total / (1024 * 1024)).toFixed(0) + ' MB' : (e.total / 1024).toFixed(0) + ' KB';

                        const fileDataJSON = JSON.stringify({
                            iv: encrypted_file.iv,
                            file: encrypted_file.file,
                            size: fileSize,
                        });

                        const fType = file.type ? { type: file.type } : { type: 'application/octet-stream' };
                        const blob = new Blob([fileDataJSON], fType);
                        const uploadPromise = connector.uploadFileToBucket(this, 'documentation', this.session.user.id + '/' + client_id_folder, file_name, blob, '3600', 'application/json');

                        resolve(uploadPromise); // Resolve the outer promise with the upload promise
                    };
                    reader.onerror = reject; // Reject the outer promise if reading fails

                    reader.readAsArrayBuffer(file);
                });

                // Add the wrapping promise to the array
                uploadPromises.push(promise);
            }

            // Return a promise that resolves when all file read and upload operations are complete
            return Promise.all(uploadPromises);
        },

        getFileIcon(type) {
            // Return an icon based on the file type
            switch (type) {
                case 'application/pdf':
                    return 'mdi-file-pdf-box';
                case 'image/jpeg':
                case 'image/png':
                case 'image/heic':
                    return 'mdi-file-image';
                // Add more cases for different file types
                default:
                    return 'mdi-file-document-outline';
            }
        },

        getIconColor(type) {
            // Return a color based on the file type
            switch (type) {
                case 'application/pdf':
                    return 'red';
                case 'image/jpeg':
                case 'image/png':
                case 'image/heic':
                    return 'orange';
                // Add more cases for different file types
                default:
                    return 'blue';
            }

        },

        async openFile(file) {
            file.uploading = true; // Show loading spinner
            await this.$refs.filePreview.openFile(file);
            file.uploading = false; // Hide loading spinner
        },

        async initialize() {
            this.loading_clients = true;
            this.loading_appointments = true;

            // get salt from the database
            // try {
            //     const { data, error } = await supabase
            //         .from('kunden')
            //         .select('salt')
            //     if (error) throw error
            //     if (data) {
            //         if (data.length === 0) return []
            //         return data
            //     }
            // } catch (error) {
            //     this.$emit('showError', error)
            //     return -1
            // }

            await this.fetchTemplates();
            
            let clients = await connector.getDataOnly(this, 'vwklientendokumentation', 'id', false);
            let appointments = await connector.getDataOnly(this, 'vwterminedokumentation', 'datum', false);

            if (this.$route.params.id) {
                // load sync
                this.clients = await cipher.decryptDataSync(this, clients);
                this.loading_clients = false;

                this.appointments = await cipher.decryptDataSync(this, appointments);
                this.loading_appointments = false;
                
                if (this.routeSelectedClient) {
                    this.selected_client = Object.assign({}, this.routeSelectedClient);
                } 
            } else {
                cipher.decryptDataAsync(this, clients, 'clients', 'loading_clients');
                cipher.decryptDataAsync(this, appointments, 'appointments', 'loading_appointments');
            }          
        },

        sortListOfObjectsByString(a, b) {
            const nameA = a.name.toUpperCase() // ignore upper and lowercase
            const nameB = b.name.toUpperCase() // ignore upper and lowercase
            if (nameA > nameB) {
                return 1
            }
            if (nameA < nameB) {
                return -1
            }
            // names must be equal
            return 0
        },

        getFormattedDate(date) {
            return dayjs(date).format('DD.MM.YYYY')
        },

        getFormattedTime(time) {
            return dayjs(time, 'HH:mm:ss').format('HH:mm')
        },

        async saveAnamnese() {
            this.saving = true;

            try {
                // save the anamnese to the database
                let enc_anamnese = await cipher.encryptObject(this.$store.state.aes_key, {
                    anamnese: this.edit_anamnese,
                });

                await connector.updateRow(this, 'klienten', enc_anamnese, this.selected_client.id);
                // so that user sees the edited changes
                this.anamnese = this.edit_anamnese;
                this.$refs['editor-anamnese'].clearHistory();
                this.saving = false;
                this.dialog_anamnese = false;
                
            } catch (error) {
                await connector.logError(this, {
                    uid: this.session.user.id,
                    message: 'Error saving anamnese ' + error.message,
                });
                this.saving = false;
            }
        },

        abortAnamnese() {
            // reset the anamnese to the last saved value
            this.edit_anamnese = this.anamnese
            this.$refs['editor-anamnese'].clearHistory();
            this.dialog_anamnese = false
        },

        editDocumentation(appointment) {
            this.tabs_documentation_type = 0;
            this.documentation = appointment.dokumentation;
            this.notes = appointment.notizen;

            if ('editor-documentation' in this.$refs) {
                this.$refs['editor-documentation'].setContent(this.documentation);
            }

            if ('editor-notes' in this.$refs) {
                this.$refs['editor-notes'].setContent(this.notes);
            }

            this.documentation_appointment_id = appointment.id;
            this.dialog_documentation = true;
        },

        editAnamnese() {
            this.edit_anamnese = this.anamnese;
            if ('editor-anamnese' in this.$refs) {
                this.$refs['editor-anamnese'].setContent(this.edit_anamnese);
            }
            this.dialog_anamnese = true
        },

        abortDocumentation() {
            this.$refs['editor-documentation'].clearHistory();
            this.$refs['editor-notes'].clearHistory();
            this.dialog_documentation = false;
            this.tabs_documentation_type = 0;
        },

        async saveDocumentation() {
            // save the anamnese to the database
            try {

                this.saving = true;
                let enc_documentation = await cipher.encryptObject(this.$store.state.aes_key, {
                    dokumentation: this.documentation,
                    notizen: this.notes,
                })

                await connector.updateRow(this, 'termine', enc_documentation, this.documentation_appointment_id);

                // update the dokumentation property in this.appointments with the new value where the id matches
                let appointment_index = this.appointments.findIndex(appointment => appointment.id === this.documentation_appointment_id);
                this.appointments[appointment_index].dokumentation = this.documentation;
                this.appointments[appointment_index].notizen = this.notes;

                this.$refs['editor-documentation'].clearHistory();
                this.$refs['editor-notes'].clearHistory();

                // i guess its better to not reset in case something fails, then the user can just try again as the data is still there
                //this.documentation = null
                //this.documentation_appointment_id = null
                this.dialog_documentation = false;
                this.saving = false;
                this.tabs_documentation_type = 0;
            } catch (error) {
                this.saving = false;
                await connector.logError(this, {
                    uid: this.session.user.id,
                    message: 'Error saving documentation ' + error.message,
                });
            }
            
        },

        async downloadDocumentation() {

            let div = this.$refs['container-anamnese'];
            // Replace <br> with \n
            div.querySelectorAll('br').forEach(br => br.replaceWith('\n'));
            // Add \n after </p>
            div.querySelectorAll('p').forEach(p => p.append('\n'));
            let client_anamnese = div.textContent || div.innerText || "";

            let documentation = {
                vorname: this.clients.find(client => client.id === this.selected_client.id).vorname,
                nachname: this.clients.find(client => client.id === this.selected_client.id).nachname,
                datum: dayjs().format('DD.MM.YYYY'),
                erste_einheit: this.selectedClientStatistics.first_appointment,
                letzte_einheit: this.selectedClientStatistics.last_appointment,
                anzahl_einheiten: this.selectedClientStatistics.appointment_count + ' (' + this.selectedClientStatistics.appointment_sum + ' Minuten)',
                frequenz: this.selectedClientStatistics.appointment_frequency + 'x pro Monat',
                anamnese: client_anamnese,
                verlauf: this.selectedClientAppointments.map(appointment => {
                    return (appointment.bezeichnung ? appointment.bezeichnung : 'Einheit') + ' am ' + dayjs(appointment.datum).format('DD.MM.YYYY') + ' um ' + dayjs(appointment.uhrzeit, 'HH:mm:ss').format('HH:mm') + ':\n' +
                        (appointment.dokumentation ? this.removeTags(appointment.dokumentation) : 'Diese Einheit wurde nicht dokumentiert.') + '\n\n'
                }).reduce((accumulator, currentValue) => accumulator + currentValue, '')
            }

            let bucket = 'public-templates'
            let filename = 'template-documentation.docx'
            let path = 'documentation/'

            let content = await connector.downloadFileFromBucket(this, bucket, path, filename)

            let undefined_tags = [];

            const iModule = InspectModule()
            const zip = new PizZip(content)
            const doc = new Docxtemplater(zip, {
                paragraphLoop: true,
                linebreaks: true,
                modules: [iModule],
                parser: function (tag) {
                    return {
                        get: function (scope) {
                            let result = null;
                            // scope will be {user: "John"}
                            if (tag === ".") {
                                result = scope;
                            } else {
                                // Here we use the property "user" of the object {user: "John"}
                                result = scope[tag.toLowerCase()];
                            }
                            return result;
                        },
                    };
                },
                nullGetter: function (part, scopeManager) {
                    if (!part.module) {
                        if (part.type === "placeholder" && part.value === "termin_dauer") {
                            return "-";
                        }
                        undefined_tags.push(part);
                        return "";
                    }
                    if (part.module === "rawxml") {
                        return "";
                    }
                    return "";
                }
            })

            // Render the document (Replace variables)
            doc.render(documentation)

            const blob = doc.getZip().generate({
                type: "blob",
                mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            })

            let file_name = ('Dokumentation_' + documentation.nachname + '_' + documentation.vorname + '_' + documentation.datum).replaceAll(' ', '_')
            await saveAs(blob, file_name + ".docx")
        },

        handleInput(fieldName, value) {
            this[fieldName] = value;
        },
    },

    computed: {

        routeSelectedClient() {
            if (this.$route.params.id) {
                const id = this.$route.params.id;
                const client = this.clients.find((c) => c.id == id);
                return client;
            } else {
                return null
            }
        },

        appointmentFileIds() {
            let ids = this.uploadedAppointmentFiles.map(file => file.appointment_id);
            return ids;
        },

        formattedClients() {
            return this.clients.filter(client => !client.archiviert).map(client => {
                return {
                    name: client.nachname + ' ' + client.vorname,
                    adresse: client.plz ? client.adresse + ', ' + client.plz + ' ' + client.ort : client.adresse,
                    alter: client.geburtsdatum ? dayjs().diff(dayjs(client.geburtsdatum, 'DD.MM.YYYY'), 'year') : null,
                    svnr: client.svnr ? client.svnr : null,
                    id: client.id,
                    zusatztext: client.zusatztext,
                    merkmale: client.merkmale,
                }
            }).sort(this.sortListOfObjectsByString)
        },

        selectedClientStatistics() {

            if (this.selected_client === null) {
                return null
            }

            let client_appointments = this.appointments.filter(appointment => appointment.fk_klienten_id === this.selected_client.id).sort((a, b) => dayjs(b.datum) - dayjs(a.datum))
            if (client_appointments.length > 0) {

                let months_visiting = dayjs(client_appointments[0].datum).diff(dayjs(client_appointments[client_appointments.length - 1].datum), 'month', true)

                return {
                    first_appointment: dayjs(client_appointments[client_appointments.length - 1].datum).format('DD.MM.YYYY'),
                    last_appointment: dayjs(client_appointments[0].datum).format('DD.MM.YYYY'),
                    appointment_count: client_appointments.length,
                    appointment_sum: client_appointments.reduce((sum, appointment) => sum + appointment.dauer, 0),
                    // calculate the time in months between the first and last appointment, and divide the number of appointments by that
                    appointment_frequency: months_visiting === 0 ? 1 : Math.round((client_appointments.length / months_visiting + Number.EPSILON) * 10) / 10,

                }
            } else {
                return {
                    first_appointment: 'Noch Keine',
                    last_appointment: 'Noch Keine',
                    appointment_count: 0,
                    appointment_sum: 0,
                    appointment_frequency: 0,
                }
            }
        },

        // selectedClientAppointments() {
        //     if (this.selected_client === null) {
        //         return null
        //     }
        //     if (this.sort_appointments_descending) {
        //         return this.appointments.filter(appointment => appointment.fk_klienten_id === this.selected_client.id && dayjs(appointment.datum).format('YYYY-MM-DD') <= dayjs().format('YYYY-MM-DD'))
        //             .sort((a, b) => dayjs(b.datum) - dayjs(a.datum))
        //     } else {
        //         return this.appointments.filter(appointment => appointment.fk_klienten_id === this.selected_client.id && dayjs(appointment.datum).format('YYYY-MM-DD') <= dayjs().format('YYYY-MM-DD'))
        //             .sort((a, b) => dayjs(a.datum) - dayjs(b.datum))
        //     }
        // },

        selectedClientAppointments() {
            if (this.selected_client === null) {
                return null;
            }

            const filteredAppointments = this.appointments.filter(appointment => 
                appointment.fk_klienten_id === this.selected_client.id && 
                dayjs(appointment.datum).format('YYYY-MM-DD') <= dayjs().format('YYYY-MM-DD')
            );

            if (this.sort_appointments_descending) {
                return filteredAppointments.sort((a, b) => dayjs(b.datum) - dayjs(a.datum)).map((appointment, index) => ({
                    ...appointment,
                    appointment_count: filteredAppointments.length - index
                }));
            } else {
                return filteredAppointments.sort((a, b) => dayjs(a.datum) - dayjs(b.datum)).map((appointment, index) => ({
                    ...appointment,
                    appointment_count: index + 1
                }));
            }
        },

        clientsQuickSelect() {
            return this.clients.filter(client => client.letzter_termin_nicht_dokumentiert !== null && !client.archiviert).map(client => {
                return {
                    name: client.nachname + ' ' + client.vorname,
                    id: client.id,
                    termine: client.n_termine,
                    nicht_dokumentiert: client.n_nicht_dokumentiert,
                    letzter_termin_nicht_dokumentiert: client.letzter_termin_nicht_dokumentiert,
                }
            }).sort((a, b) => dayjs(b.letzter_termin_nicht_dokumentiert) - dayjs(a.letzter_termin_nicht_dokumentiert)).slice(0, 12)

        }
    }
}
</script>

<style scoped>
.v-sheet.v-card {
  border-radius: 6px;
}

::v-deep .v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
    transform: rotate(0deg) !important;
}

.upload-area {
    border: dashed 2px lightgrey;
    border-radius: 20px;
    text-align: center;
}

.upload-area.drag-over {
    background-color: #f0f0f0;
}

.text-truncate {
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Additional styles for the file info container */
.file-info-container {
    min-width: 0;
    /* This ensures that the container can shrink as needed */
}

/* Ensure text truncation is applied */
.text-truncate-file {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.file-card {
    transition: box-shadow .3s ease;
    cursor: pointer;
    /* position: relative; */
    /* Ensure the positioning context for the speed dial */
}

span.break-words {
    display: block;
    /* Allows word-wrap to take effect */
    word-break: break-all;
    /* Breaks the word at any character */
}
::v-deep .p-container p {
    min-height: 1rem;
    margin-bottom: 0.1em;
}
</style>
