<template>
    <v-container fluid>
        <v-row v-if="$vuetify.breakpoint.mdAndUp">
            <v-col cols="6" v-for="form in forms" :key="form.id">
                <v-hover v-slot:default="{ hover }">
                    <v-card class="mx-auto file-card" style="height: 100%" @click="activateForm(form.id)" :elevation="hover && form.id ? '5' : '1'">
                        <v-img v-if="form.image" :src="form.image">
                        </v-img>
                        <v-fade-transition>
                            <div v-if="hover && !form.id" class="d-flex justify-center v-card--reveal display-3 white--text" style="height: 100%;">
                                <span class="white--text" >
                                    Bald verfügbar
                                </span>
                            </div>
                        </v-fade-transition>
                        <v-card-text class="d-flex justify-center align-center flex-column my-auto" style="position: relative;">
                            <div v-if="!form.image" class="text-h5 text-center my-5">{{ form.title }}</div>
                            <div class="font-weight-light title mb-2">{{ form.description }}</div>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>
        <div v-else>
            <v-alert border="bottom" colored-border type="warning" elevation="2" icon="mdi-alert-outline">
                Anträge können nur auf einem Gerät mit größerem Bildschirm erstellt und bearbeitet werden.
                Wechsle daher bitte auf ein Gerät mit größerem Bildschirm um Anträge zu erstellen.
            </v-alert>
        </div>

        <AntragKlinPsy ref="antrag_klinpsy" @showError="$emit('showError', $event)" @showInfo="$emit('showInfo', $event)" :session="session" />
    </v-container>
</template>

<script>
import AntragKlinPsy from '@/components/AntragKlinPsy.vue'

export default {
    
    props: ['session'],

    components: {
        AntragKlinPsy
    },
    data() {
        return {
            active_form: null,
            forms: [
                {
                    id: 'antrag_klinpsy',
                    title: 'Antrag auf Kostenzuschuss Klinische Psychologie',
                    image: require('@/assets/cover_form_klinpsy.png'),
                    description: 'Formular inklusive KI-gestützter Ausfüllhilfe.',
                },
                {
                    id: null,
                    title: 'Antrag auf Kostenzuschuss wegen Inanspruchnahme einer freiberuflich niedergelassenen Psychotherapeut/in',
                    image: null,
                    description: 'Formular mit Ausfüllhifle für den Kostenzuschuss bald verfügbar.',
                },
            ],
        }
    },
    methods: {
        activateForm(formId) {
            if (formId) {
                this.active_form = formId;
                this.$refs[formId].editForm();
            }
    
        },
    }   
}

</script>

<style scoped>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    background-color: #897f7c;
    opacity: 1;
    position: absolute;
    width: 100%;
    transition: box-shadow .3s ease;
}
.file-card {
    transition: box-shadow .3s ease;
    cursor: pointer;
    /* position: relative; */
    /* Ensure the positioning context for the speed dial */
}
</style>