<template>
    <div>
        <v-dialog v-model="dialog_save_form" max-width="700px" persistent>
            <v-card>
                <v-card-title>Speichern als</v-card-title>
                <v-card-text class="mb-0 pb-0">
                    <v-text-field v-model="file_name" dense outlined label="Dateiname" />
                    <v-alert prominent text type="info">
                        Der Antrag wird in den Dokumenten des Klienten gespeichert. 
                        Um den Antrag später weiter zu bearbeiten, öffne das Antragsdokument des Klienten und klicke auf "Antrag bearbeiten".
                    </v-alert>
                </v-card-text>
                <v-card-actions class="mt-0 pt-0 px-5">
                    <v-spacer></v-spacer>
                    <v-btn text :color="$store.state.theme.red" :disabled="saving_form" @click="dialog_save_form = false">
                        Abbrechen
                    </v-btn>
                    <v-btn text :color="$store.state.theme.green" :disabled="!file_name" :loading="saving_form" @click="saveForm">
                        Speichern
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog_google_terms" persistent fullscreen>
            <v-card>
                <v-toolbar dark :color="$store.state.theme.primary">
                    <v-toolbar-title>Nutzungsbedingungen & Datenschutz</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="rejectTerms">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="text-body-1 mt-5 text-justify">
                    <p>
                        Beim Ausfüllen dieses Antrags unterstützen wir dich mit <b>Vorschlägen</b> von einer Künstlichen Intelligenz (KI) von Google. 
                        Diese Vorschläge sollen dir helfen den Antrag auszufüllen. Es kann jedoch keine Garantie für die Richtigkeit der Vorschläge gegeben werden.
                        Um relevante Vorschläge machen zu können, benötigt die KI ein paar Informationen.<br/><br/>
                        <b>Da der Datenschutz für uns oberste Priorität hat, möchten wir dich darüber informieren, welche Daten die KI von Google benötigt, um relevante Vorschläge für dich machen zu können:</b>
                        <br/>
                        <ul>
                            <li>Die ICD-10 Diagnose & den GAF-Wert</li>
                            <li>Die Anamnese, den Behandlungsverlauf & die Zielsetzung auf dem Antragsformular</li>
                        </ul>
                        <br/>
                        <b>Bitte achte darauf, keine Namen oder andere persönliche Informationen in diese Felder einzugeben.</b>
                        Verwende am besten allgemeine Beschreibungen, da diese Anträge ja auch von Sachbearbeitern in den Krankenkassen durchgelesen werden.<br/><br/>
                        Google legt bei ihrer KI ebenso großen Wert auf Datenschutz und Sicherheit, deshalb werden die Daten von Google nur so lange gespeichert bis die KI-Vorschläge erstellt wurden.
                        Hier findest du weitere Informationen zu den <a href="https://cloud.google.com/vertex-ai/generative-ai/docs/data-governance?hl=de" target="_blank">Datenschutzbestimmungen von Google</a>.
                        <br/>
                        
                        <b>Wenn du die KI-Vorschläge nicht verwendeset, werden diese Daten auch nicht an Google gesendet.</b>
                    </p>
                    <v-card outlined > 
                        <v-card-title>
                            Hier siehst du, welche Daten für diese KI-Vorschläge an Google gesendet werden
                        </v-card-title>
                        <v-card-text>
                            <p>
                                <b>ICD-10 Diagnose:</b> {{ icd_diagnosis.length > 0 ? icd_diagnosis.map((diagnosis) => diagnosis.code).join(', ') : 'Keine Diagnose angegeben' }}
                            </p>
                            <p>
                                <b>GAF-Wert:</b> {{ gaf_value ? gaf_value : 'Kein GAF-Wert angegeben' }}
                            </p>
                            <p>
                                <b>Anamnese:</b> {{ field_1 ? field_1 : 'Keine Anamnese angegeben' }}
                            </p>
                            <p>
                                <b>Behandlungsverlauf:</b> {{ field_2 ? field_2 : 'Kein Behandlungsverlauf angegeben' }}
                            </p>
                            <p>
                                <b>Zielsetzung:</b> {{ field_3 ? field_3 : 'Keine Zielsetzung angegeben' }}
                            </p>
                        </v-card-text>
                    </v-card>

                    <p class="mt-5">
                        Solltest du noch weitere Fragen haben, kannst du dich gerne an uns wenden.
                    </p>
                </v-card-text>
                <v-card-actions class="d-flex justify-center pb-5 mt-0 pt-0">
                    <v-btn text @click="rejectTerms" :color="$store.state.theme.red" class="mr-2">
                        Schließen
                    </v-btn>
                    <v-btn @click="acceptTerms" :color="$store.state.theme.green" dark class="ml-2">
                        Akzeptieren
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog_gaf_explanation" persistent fullscreen>
            <v-card>
                <v-toolbar dark :color="$store.state.theme.primary">
                    <v-btn icon dark @click="dialog_gaf_explanation = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>GAF (Global Assessment of Functioning)</v-toolbar-title>                    
                </v-toolbar>
                <v-card-text class="pt-5">
                    <p class="text-body-1">
                        Das Global Assessment of Functioning (kurz GAF) ist eine Bewertungsskala aus der klinischen
                        Psychologie und Psychiatrie.
                        Sie wird verwendet, um das allgemeine Funktionsniveau einer Person zu erfassen.
                        Körperliche Erkrankungen und Umgebungsbedingungen sollen hierbei explizit nicht berücksichtigt
                        werden.
                        Die psychischen, sozialen und beruflichen Funktionen werden dabei auf einem hypothetischen Kontinuum
                        von psychischer Gesundheit bis Krankheit gedacht.
                    </p>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">Bereich</th>
                                    <th class="text-left">GAF-Wert</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-left">100-91</td>
                                    <td class="text-left">
                                        Hervorragende Leistungsfähigkeit in einem breiten Spektrum von Aktivitäten;
                                        Schwierigkeiten im Leben scheinen nie außer Kontrolle zu geraten; keine Symptome.
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-left">90-81</td>
                                    <td class="text-left">Keine oder nur minimale Symptome (z. B. leichte Angst vor einer
                                        Prüfung), gute Leistungsfähigkeit in allen Gebieten, interessiert und eingebunden in
                                        ein breites Spektrum von Aktivitäten, sozial effektiv im Verhalten, im Allgemeinen
                                        zufrieden mit dem Leben, übliche Alltagsprobleme oder -sorgen (z. B. nur
                                        gelegentlicher Streit mit einem Familienmitglied).</td>
                                </tr>
                                <tr>
                                    <td class="text-left">80-71</td>
                                    <td class="text-left">Wenn Symptome vorliegen, sind dies vorübergehende oder zu
                                        erwartende Reaktionen auf psychosoziale Belastungsfaktoren (z. B.
                                        Konzentrationsschwierigkeiten nach einem Familienstreit); höchstens leichte
                                        Beeinträchtigung der sozialen beruflichen und schulischen Leistungsfähigkeit (z. B.
                                        zeitweises Zurückbleiben in der Schule).</td>
                                </tr>
                                <tr>
                                    <td class="text-left">70-61</td>
                                    <td class="text-left">Einige leichte Symptome (z. B. depressive Stimmung oder leichte
                                        Schlaflosigkeit) ODER einige leichte Schwierigkeiten hinsichtlich der sozialen,
                                        beruflichen oder schulischen Leistungsfähigkeit (z. B. gelegentliches
                                        Schuleschwänzen oder Diebstahl im Haushalt), aber im Allgemeinen relativ gute
                                        Leistungsfähigkeit; Bestehen einiger wichtiger zwischenmenschlicher Beziehungen.
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-left">60-51</td>
                                    <td class="text-left">Mäßig ausgeprägte Symptome (z. B. Affektverflachung,
                                        weitschweifige Sprache, gelegentliche Panikattacken) ODER mäßig ausgeprägte
                                        Schwierigkeiten bezüglich der sozialen, beruflichen oder schulischen
                                        Leistungsfähigkeit (z. B. wenige Freunde, Konflikte mit Arbeitskollegen,
                                        Schulkameraden oder Bezugspersonen).</td>
                                </tr>
                                <tr>
                                    <td class="text-left">50-41</td>
                                    <td class="text-left">Ernste Symptome (z. B. Suizidgedanken, schwere Zwangsrituale,
                                        häufige Ladendiebstähle) ODER eine Beeinträchtigung der sozialen, beruflichen und
                                        schulischen Leistungsfähigkeit (z. B. keine Freunde, Unfähigkeit, eine Arbeitsstelle
                                        zu behalten).</td>
                                </tr>
                                <tr>
                                    <td class="text-left">40-31</td>
                                    <td class="text-left">Einige Beeinträchtigungen in der Realitätskontrolle oder der
                                        Kommunikation (z. B. Sprache zeitweise unlogisch, unverständlich oder belanglos)
                                        ODER starke Beeinträchtigung in mehreren Bereichen, z. B. Arbeit oder Schule,
                                        familiäre Beziehungen, Urteilsvermögen, Denken oder Stimmung (z. B. ein Mann mit
                                        einer Depression vermeidet Freunde, vernachlässigt seine Familie und ist unfähig zu
                                        arbeiten; ein Kind schlägt häufig jüngere Kinder, ist zu Hause trotzig und versagt
                                        in der Schule).</td>
                                </tr>
                                <tr>
                                    <td class="text-left">30-21</td>
                                    <td class="text-left">Das Verhalten ist ernsthaft durch Wahnphänomene oder
                                        Halluzinationen beeinflusst ODER ernsthafte Beeinträchtigung der Kommunikation und
                                        des Urteilsvermögens (z. B. manchmal inkohärent, handelt grob inadäquat, starkes
                                        Eingenommensein von Selbstmordgedanken) ODER Leistungsunfähigkeit in fast allen
                                        Bereichen (z. B. bleibt den ganzen Tag im Bett, hat keine Arbeit, kein Zuhause und
                                        keine Freunde).</td>
                                </tr>
                                <tr>
                                    <td class="text-left">20-11</td>
                                    <td class="text-left">Selbst- und Fremdgefährdung (z. B. Selbstmordversuche ohne
                                        eindeutige Todesabsicht, häufig gewalttätig, manische Erregung) ODER ist
                                        gelegentlich nicht in der Lage, die geringste Hygiene aufrechtzuerhalten (z. B.
                                        schmiert mit Kot) ODER grobe Beeinträchtigung der Kommunikation (größtenteils
                                        inkohärent oder stumm).</td>
                                </tr>
                                <tr>
                                    <td class="text-left">10-1</td>
                                    <td class="text-left">Ständige Gefahr, sich oder andere schwer zu verletzen (z. B.
                                        wiederholte Gewaltanwendung) ODER anhaltende Unfähigkeit, die minimale persönliche
                                        Hygiene aufrechtzuerhalten ODER ernsthafter Selbstmordversuch mit eindeutiger
                                        Todesabsicht.</td>
                                </tr>
                                <tr>
                                    <td class="text-left">0</td>
                                    <td class="text-left">Unzureichende Informationen</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                    <p class="text-h5 my-5">Anleitung</p>
                    <p class="text-body-1 mt-5">
                        <b>Schritt 1:</b><br>
                        Beginne mit dem höchsten Niveau und frage bei jeder Abstufung: "Ist entweder der Schweregrad der
                        Symptome oder die Funktionsbeeinträchtigung niedriger als auf dieser Stufe beschrieben?"
                        <br><b>Schritt 2:</b><br>
                        Die Skala ist nach unten weiterzuverfolgen, bis das Niveau erreicht wird, das am besten zum
                        Schweregrad der Symptome oder zu der Funktionsbeeinträchtigung passt, je nachdem welches von beiden
                        niedriger ausgeprägt ist.
                        <br><b>Schritt 3:</b><br>
                        Zum Vergleich ist das nächstniedrige Niveau zu betrachten, um zu verhindern, dass zu früh
                        entschieden wurde.
                        Dieses Niveau sollte für sowohl den Schweregrad der Symptome als auch für die
                        Funktionsbeeinträchtigung zu niedrig sein.
                        Ist dies der Fall, so hat man das passende GAF-Niveau erreicht (und fährt mit Schritt 4 fort).
                        Ist dies nicht der Fall, sollte zu Schritt 2 zurückgegangen und die Skala weiter nach unten verfolgt
                        werden.
                        <br><b>Schritt 4:</b><br>
                        Um den spezifischen GAF-Wert innerhalb des ausgewählten Dezilbereichs festzulegen, ist zu erwägen,
                        ob die Person hinsichtlich ihrer Funktionen am oberen oder unteren Rand des Bereichs einzuordnen
                        ist.
                        Ein Beispiel wäre eine Person, die Stimmen hört, welche jedoch das Verhalten nicht beeinflussen
                        (z.B. jemand mit seit langem bestehender Schizophrenie, der die Halluzinationen als Teil der
                        Erkrankung akzeptiert).
                        Wenn die Stimmen relativ selten auftreten (z.B. höchstens einmal wöchentlich), wäre ein Wert von 39
                        oder 40 angemessen.
                        Im Gegensatz dazu wäre ein Wert von 31 oder 32 passend, wenn die Person fast ständig Stimmen hört.
                    </p>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog_prompt_download" persistent max-width="800px">
            <v-card>
                <v-toolbar dark :color="$store.state.theme.primary">
                    <v-toolbar-title>Erinnerung</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="pt-5 text-subtitle-1 text-justify">
                    <p>
                        Stelle sicher, dass du den Antrag gespeichert hast, wenn du ihn später weiter bearbeiten möchtest.
                        Anderenfalls gehen deine Änderungen verloren.
                        Du kannst den Antrag ebenso herunterladen, und gegebenenfalls noch direkt Änderungen im PDF Dokument vornehmen.
                    </p>
                </v-card-text>
                <v-card-actions class="pa-5">
                    <v-btn dark :color="$store.state.theme.primary" class="mr-5" @click="dialog_prompt_download = false">Weiter bearbeiten</v-btn>
                    <v-btn dark :color="$store.state.theme.red" outlined @click="closeDialog">Schließen</v-btn>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom v-if="!client">
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                <v-btn outlined disabled class="mr-5" elevation="0" v-bind="attrs" v-on="on" @click="showSaveDialog">
                                    <v-icon left>mdi-content-save</v-icon>
                                    Speichern
                                </v-btn>
                            </div>
                        </template>
                        <span>Wähle einen Klienten aus um den Antrag zu speichern</span>
                    </v-tooltip>
                    <v-btn v-else outlined class="mr-5" elevation="0" @click="showSaveDialog">
                        <v-icon left>mdi-content-save</v-icon>
                        Speichern
                    </v-btn>
                    <v-btn dark :color="$store.state.theme.green" @click="downloadForm">Herunterladen</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
        <v-dialog v-model="dialog" persistent fullscreen>
            <v-card color="grey lighten-4">
                <v-toolbar dark :color="$store.state.theme.primary">
                    <v-btn icon dark @click="dialog_prompt_download = true">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Antrag auf Kostenzuschuss</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom v-if="!client">
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                <v-btn outlined disabled class="mr-5" elevation="0" v-bind="attrs" v-on="on" @click="showSaveDialog">
                                    <v-icon left>mdi-content-save</v-icon>
                                    Speichern
                                </v-btn>
                            </div>
                        </template>
                        <span>Wähle einen Klienten aus um den Antrag zu speichern</span>
                    </v-tooltip>
                    <v-btn v-else outlined class="mr-5" elevation="0" @click="showSaveDialog">
                        <v-icon left>mdi-content-save</v-icon>
                        Speichern
                    </v-btn>
                    <v-btn :color="$store.state.theme.green" dark elevation="1" @click="downloadForm">
                        <v-icon left>mdi-printer</v-icon>
                        Herunterladen
                    </v-btn>
                </v-toolbar>
                <v-tabs grow v-model="current_tab" class="mt-3" :background-color="$store.state.theme.background">
                    <v-tab>
                        <v-icon left>mdi-information</v-icon>
                        Antragsinformationen
                    </v-tab>
                    <v-tab>
                        <v-icon left>mdi-account</v-icon>
                        Angaben zum Patienten
                    </v-tab>
                    <v-tab>
                        <v-icon left>mdi-folder-information</v-icon>
                        Behandlungsinformationen
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="current_tab" style="background-color: #f5f5f5">
                    <v-tab-item eager >
                        <v-row class="px-5 py-5">
                            <v-col cols="6">
                                <v-card>
                                    <v-card-title class="d-flex justify-space-between">
                                        Daten der versicherten Person
                                    </v-card-title>
                                    <v-card-text>
                                        <div class="d-flex align-center">
                                            <v-autocomplete v-model="client" :loading="!loaded_customers" loader-height="5"
                                                class="" :disabled="!loaded_customers" :items="sortedCustomers"
                                                item-text="name" item-value="fk_klienten_id" return-object label="Klient"
                                                outlined dense clear-icon="mdi-close-circle" clearable
                                                prepend-inner-icon="mdi-account">
                                                <template v-slot:item="{ item }">
                                                    <div class="d-flex align-center">
                                                        <span>{{ item.name }}</span>
                                                        <v-chip v-for="merkmal in item.merkmale" :key="'chip-' + merkmal.id"
                                                            class="ml-2" small>{{
                                                                merkmal.merkmal }}</v-chip>
                                                    </div>
                                                </template>
                                                <template v-slot:selection="{ item }">
                                                    <div class="d-flex align-center">
                                                        <span>{{ item.name }}</span>
                                                        <v-chip v-for="merkmal in item.merkmale"
                                                            :key="'chip-selection-' + merkmal.id" class="ml-2" small>{{
                                                                merkmal.merkmal }}</v-chip>
                                                    </div>
                                                </template>
                                            </v-autocomplete>
                                            <v-checkbox v-model="self_insured" label="Patient/in ist selbstversichert"
                                                class="mt-0 pt-0 ml-5"></v-checkbox>
                                        </div>
                                        <div class="d-flex">
                                            <v-text-field class="mr-2" outlined dense label="Familienname Patient/in"
                                                v-model="lastname" />
                                            <v-text-field class="mx-2" outlined dense label="Vorname Patient/in"
                                                v-model="firstname" />
                                            <v-text-field class="ml-2" outlined dense label="Versicherungsnr. Patient/in"
                                                v-model="svnr" />
                                        </div>
                                        <v-textarea rows="2" auto-grow outlined dense label="Anschrift Patient/in"
                                            v-model="address" />
                                        <div v-if="self_insured === false" class="d-flex">
                                            <v-text-field class="mr-2" outlined dense label="Versicherte/r"
                                                v-model="insurer_name" />
                                            <v-text-field class="ml-2" outlined dense label="Versicherungsnr. Versicherte/r"
                                                v-model="insurer_svnr" />
                                        </div>
                                    </v-card-text>
                                </v-card>
                                <v-card class="mt-5">
                                    <v-card-title class="d-flex justify-space-between">
                                        Antragsinformationen
                                        <v-radio-group v-model="application_form" row>
                                            <v-radio label="Erstantrag" value="0"></v-radio>
                                            <v-radio label="Folgeantrag" value="1"></v-radio>
                                            <v-radio label="Behandlerwechsel" value="2"></v-radio>
                                        </v-radio-group>
                                    </v-card-title>
                                    <v-card-text>

                                        <v-autocomplete v-model="selected_insurer" :items="insurer"
                                            item-text="displayName" item-value="id" label="Versicherungsträger"
                                            return-object outlined dense clearable clear-icon="mdi-close-circle" />

                                        <div class="d-flex">
                                            <v-text-field class="mr-2" outlined dense label="Versicherungsträger"
                                                v-model="insurance" />
                                            <v-text-field class="mx-2" outlined dense label="Bundesland"
                                                v-model="insurance_state" />
                                            <v-text-field class="mx-2" outlined dense label="Straße & Nr."
                                                v-model="insurance_street" />
                                            <v-text-field class="ml-2" outlined dense label="PLZ & Ort"
                                                v-model="insurance_place" />
                                        </div>
                                    </v-card-text>
                                </v-card>
                                <v-card class="mt-5">
                                    <v-card-title class="d-flex justify-space-between">
                                        Daten des klinischen Psychologen
                                    </v-card-title>
                                    <v-card-text>
                                        <div class="d-flex">
                                            <v-text-field class="mr-2" outlined dense label="Name"
                                                v-model="therapist_name" />
                                            <v-text-field class="ml-2" outlined dense label="VPNR" v-model="therapist_nr" />
                                        </div>
                                        <v-textarea rows="2" auto-grow outlined dense label="Praxisadresse"
                                            v-model="therapist_address" />
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="6">
                                <object v-if="form_url" :data="form_url" type="application/pdf" width="100%"
                                    :style="{ height: computedHeight + 'px' }">
                                    <p>
                                        Formular kann nicht angezeigt werden.
                                        <a :href="form_url">Hier klicken, um es herunterzuladen.</a>
                                    </p>
                                </object>
                            </v-col>
                        </v-row>
                    </v-tab-item>

                    <v-tab-item eager>
                        <v-row class="px-5 py-5">
                            <v-col cols="6">
                                <v-row>
                                    <v-col cols="12">
                                        <v-card style="height: 100%;">
                                            <v-card-title class="text-h6 d-flex">
                                                Diagnose
                                            </v-card-title>
                                            <v-card-text>
                                                <div class="d-flex mb-5">
                                                    <v-autocomplete outlined v-model="icd_diagnosis" :items="icdCodes" hide-details
                                                        item-text="code" item-value="short_code" :dense="icd_diagnosis.length === 0"
                                                        label="Diagnose nach ICD-10" placeholder="Suchbegriff eingeben" return-object
                                                        multiple deletable-chips chips :menu-props="{ maxWidth: 750 }">
                                                    </v-autocomplete>
                                                </div>
                                                <v-chip v-if="suggestedDiagnoses.length === 0" class="mr-2 mb-2" @click="selectDiagnosis({'code': 'F32.1 Mittelgradige depressive Episode', 'short_code': 'F32.1'})">
                                                    <v-icon left>mdi-plus</v-icon>
                                                    <span>F32.1 Mittelgradige depressive Episode</span>
                                                </v-chip>
                                                <v-chip v-if="suggestedDiagnoses.length === 0" class="mr-2 mb-2" @click="selectDiagnosis({'code': 'F43.2 Anpassungsstörungen', 'short_code': 'F43.2'})">
                                                    <v-icon left>mdi-plus</v-icon>
                                                    <span>F43.2 Anpassungsstörungen</span>
                                                </v-chip>
                                                <v-chip outlined color="deep-purple accent-4" v-for="(diagnosis, index) in suggestedDiagnoses" :key="index" 
                                                        class="mt-0 pt-0 mr-2 mb-2" @click="selectDiagnosis(diagnosis)">
                                                    <v-icon left>mdi-plus</v-icon>
                                                    {{ diagnosis.code }}
                                                </v-chip>
                                                <v-chip class="mr-2 mb-2" @click="getICD10Diagnosis" :disabled="loading_diagnosis" color="success" outlined>
                                                    <v-progress-circular v-if="loading_diagnosis" indeterminate size="15" width="3" class="mr-2" />
                                                    <v-icon v-else left small>mdi-auto-fix</v-icon>
                                                    <span class="font-weight-medium">Andere Diagnosen vorschlagen</span>
                                                </v-chip>

                                                <div class="text-h6 black--text mt-3">
                                                    GAF-Wert
                                                    <v-btn icon>
                                                        <v-icon @click="dialog_gaf_explanation = true">
                                                            mdi-information
                                                        </v-icon>
                                                    </v-btn>
                                                    
                                                </div>
                                                <div class="d-flex mt-5 ">
                                                    <v-text-field class="mr-5" style="max-width: 50px;" v-model="gaf_value" @change="limitGAFValue" dense />
                                                    <v-slider v-model="gaf_value" :color="gaf_color" min="0" max="100" />
                                                    <v-btn elevation="0" @click="getGAFValue" :loading="loading_gaf" class="ml-5" rounded>
                                                        <v-icon left>mdi-auto-fix</v-icon>
                                                        Vorschlag
                                                    </v-btn>                                        
                                                </div>
                                                <div class="d-flex flex-column">
                                                    <span>
                                                        <b>Bewertung:</b> {{ gafValueDescription }}
                                                    </span>
                                                    <p v-if="suggestedGAF">
                                                        <b>Begründung:</b> {{ suggestedGAF }}
                                                    </p>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>

                                <v-card class="mt-5">
                                    <v-card-title>
                                        Berufstätigkeit & psychiatrische Behandlung
                                    </v-card-title>
                                    <v-card-text>
                                        <v-row>
                                            <v-col class="d-flex flex-column">
                                                <span class="text-overline mt-0 pt-0">Berufstätig</span>
                                                <v-btn-toggle v-model="has_job" dense mandatory>
                                                    <v-btn>
                                                        Ja
                                                    </v-btn>
                                                    <v-btn>
                                                        Nein
                                                    </v-btn>
                                                    <v-btn>
                                                        Keine Angabe
                                                    </v-btn>
                                                </v-btn-toggle>
                                
                                                <v-text-field class="mt-5" v-if="has_job !== 1" outlined dense label="Art der Berufstätigkeit" v-model="job" />
                                            </v-col>
                                            <v-col class="d-flex flex-column">
                                                <span class="text-overline mt-0 pt-0">Psychiatrische Behandlung</span>
                                                <v-btn-toggle v-model="has_psychiatrist" dense mandatory>
                                                    <v-btn>
                                                        Ja
                                                    </v-btn>
                                                    <v-btn>
                                                        Nein
                                                    </v-btn>
                                                    <v-btn>
                                                        Keine Angabe
                                                    </v-btn>
                                                </v-btn-toggle>
                                                <v-text-field class="mt-5" v-if="has_psychiatrist !== 1" outlined dense label="Name des Facharztes / der Fachärztin" v-model="psychiatrist" />

                                            </v-col>
                                        </v-row>
        
                                    </v-card-text>
                                </v-card>
                                
                                <v-card class="mt-5">
                                    <v-card-title>
                                        Behandlungsbezogene Anamnese (in Stichworten)
                                    </v-card-title>
                                    <v-card-text>
                                        <v-textarea rows="4" auto-grow outlined dense v-model="field_1" />
                                        <v-chip class="mr-2 mb-2" @click="addTextAnamnese('Traumatische Ereignisse')">
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>Traumatische Ereignisse</span>
                                        </v-chip>
                                        <v-chip class="mr-2 mb-2" @click="addTextAnamnese('Substanzabusus')">
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>Substanzabusus</span>
                                        </v-chip>
                                        <v-chip class="mr-2 mb-2" @click="addTextAnamnese('Krankenstände im Zusammenhang mit der Erkrankung')">
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>Krankenstände im Zusammenhang mit der Erkrankung</span>
                                        </v-chip>
                                        <v-chip class="mr-2 mb-2" @click="addTextAnamnese('Stationäre Aufenthalte')">
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>Stationäre Aufenthalte</span>
                                        </v-chip>
                                        <v-chip v-for="item in anamnese_recommendations" :key="item.id" @click="addTextAnamnese(item.text)" class="mr-2 mb-2" 
                                                color="deep-purple accent-4" outlined>
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>{{ item.text }}</span>
                                        </v-chip>
                                        <v-chip class="mr-2 mb-2" @click="getMoreAnamneseTextRecommendations" :disabled="loading_anamnese" color="success" outlined>
                                            <v-progress-circular v-if="loading_anamnese" indeterminate size="15" width="3" class="mr-2" />
                                            <v-icon v-else left small>mdi-auto-fix</v-icon>
                                            <span class="font-weight-medium">{{ anamnese_recommendations.length === 0 ? 'Weitere' : 'Andere' }} Textbausteine vorschlagen</span>
                                        </v-chip>
                                    </v-card-text>
                                </v-card>

                                <v-card class="mt-5">
                                    <v-card-title>
                                        Angaben zum bisherigen Behandlungsverlauf & Störungsintensität
                                    </v-card-title>
                                    <v-card-text>
                                        <v-textarea rows="4" auto-grow outlined dense
                                            v-model="field_2" />
                                            <v-chip class="mr-2 mb-2" @click="addTextProgress('Hohe Behandlungsmotivation')">
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>Hohe Behandlungsmotivation</span>
                                        </v-chip>
                                        <v-chip class="mr-2 mb-2" @click="addTextProgress('Gute Arbeitsbeziehung')">
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>Gute Arbeitsbeziehung</span>
                                        </v-chip>
                                        <v-chip class="mr-2 mb-2" @click="addTextProgress('Hohe Compliance')">
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>Hohe Compliance</span>
                                        </v-chip>
                                        <v-chip class="mr-2 mb-2" @click="addTextProgress('Psychoedukation')">
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>Psychoedukation</span>
                                        </v-chip>
                                        <v-chip v-for="item in progress_recommendations" :key="'progress-' + item.id" @click="addTextProgress(item.text)" class="mr-2 mb-2" 
                                                color="deep-purple accent-4" outlined>
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>{{ item.text }}</span>
                                        </v-chip>
                                        <v-chip class="mr-2 mb-2" @click="getMoreProgressTextRecommendations" :disabled="loading_progress" color="success" outlined>
                                            <v-progress-circular v-if="loading_progress" indeterminate size="15" width="3" class="mr-2" />
                                            <v-icon v-else left small>mdi-auto-fix</v-icon>
                                            <span class="font-weight-medium">{{ progress_recommendations.length === 0 ? 'Weitere' : 'Andere' }} Textbausteine vorschlagen</span>
                                        </v-chip>
                                    </v-card-text>
                                </v-card>

                            </v-col>
                            <v-col cols="6">
                                <object v-if="form_url" :data="form_url" type="application/pdf" width="100%" :style="{ height: computedHeight + 'px' }">
                                    <p>
                                        Formular kann nicht angezeigt werden.
                                        <a :href="form_url">Hier klicken, um es herunterzuladen.</a>
                                    </p>
                                </object>
                            </v-col>
                        </v-row>
                    </v-tab-item>

                    <v-tab-item eager>
                        <v-row class="px-5 py-5">
                            <v-col cols="6">
                                <v-card>
                                    <v-card-title class="d-flex justify-space-between">
                                        Zielsetzung im Bezug auf die ICD-10 Diagnose & GAF-Wert
                                    </v-card-title>
                                    <v-card-text>
                                        <v-textarea rows="4" auto-grow outlined dense
                                            label="Zielsetzung im Bezug auf die ICD-10 Diagnose & GAF-Wert"
                                            v-model="field_3" />
                                        <v-chip class="mr-2 mb-2 v-chip-custom" @click="addTextGoals(goal)" v-for="(goal, index) in suggestedGoals" :key="index" color="deep-purple accent-4" outlined>
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>{{ goal }}</span>
                                        </v-chip>
                                        <v-chip class="mr-2 mb-2" @click="getGoals" :disabled="loading_goals" color="success" outlined>
                                            <v-progress-circular v-if="loading_goals" indeterminate size="15" width="3" class="mr-2" />
                                            <v-icon v-else left small>mdi-auto-fix</v-icon>
                                            <span class="font-weight-medium">{{ suggestedGoals.length === 0 ? '' : 'Andere' }} Zielsetzungen vorschlagen</span>
                                        </v-chip>
                                    </v-card-text>
                                </v-card>

                                <v-card class="mt-5">
                                    <v-card-title class="d-flex justify-space-between">
                                        Beantragte Leistungen
                                    </v-card-title>
                                    <v-card-text>
                                        <div class="d-flex">
                                            <v-text-field class="mr-2" v-model="therapy_start" outlined dense
                                                label="Beginn der Behandlung" />
                                            <v-text-field class="mx-2" v-model="therapy_units_past" outlined dense
                                                label="Bisher stattgefundene Einheiten" />
                                            <v-text-field class="ml-2" v-model="therapy_units_other" outlined dense
                                                label="Anzahl Einheiten bei anderen klin. Psycholog/innen oder Psychotherapeut/innen" />
                                        </div>
                                        <div class="d-flex">
                                            <v-text-field class="mr-2" v-model="therapy_units" outlined dense
                                                label="Beantragte Einheiten" />
                                            <v-text-field class="mx-2" v-model="therapy_units_total" outlined dense
                                                label="Voraussichtlich benötigte Gesamteinheiten" />
                                            <v-text-field class="ml-2" v-model="therapy_frequency" outlined dense
                                                label="Geplante Frequenz" />
                                        </div>
                                        <v-textarea rows="3" v-model="therapy_reason" auto-grow outlined dense
                                            label="Begründung des weiteren Therapiebedarfs" />
                                        <v-chip class="mr-2 mb-2 v-chip-custom" v-for="(reason, index) in suggestedReasons" :key="index" 
                                                @click="addTextReason(reason)" color="deep-purple accent-4" outlined>
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>{{ reason }}</span>
                                        </v-chip>
                                        <v-chip class="mr-2 mb-2" @click="getReasons" :disabled="loading_reasons" color="success" outlined>
                                            <v-progress-circular v-if="loading_reasons" indeterminate size="15" width="3" class="mr-2" />
                                            <v-icon v-else left small>mdi-auto-fix</v-icon>
                                            <span class="font-weight-medium">{{ suggestedReasons.length === 0 ? '' : 'Andere' }} Begründungen vorschlagen</span>
                                        </v-chip>
                                    

                                        <p class="text-h6 mt-5">Geplantes Setting</p>
                                        <div class="d-flex align-center">
                                            <v-radio-group class="mt-0 pt-0" v-model="therapy_setting" row>
                                                <v-radio label="Einzelsetting" value="0"></v-radio>
                                                <v-radio label="Gruppensetting" value="1"></v-radio>
                                                <v-radio label="anderes: " value="2"></v-radio>
                                            </v-radio-group>
                                            <v-text-field v-model="therapy_setting_other" class="ml-2" outlined dense />
                                        </div>

                                        <span class="text-subtitle-1">Begleitende Behandlung von Bezugspersonen</span>
                                        <div class="d-flex align-center">
                                            <v-text-field v-model="other_persons" label="Art des Bezuges" outlined dense />
                                        </div>

                                        <span class="text-subtitle-1">Anmerkungen</span>
                                        <div class="d-flex align-center">
                                            <v-text-field v-model="notes" label="Anmerkungen" outlined dense />
                                        </div>
                                    </v-card-text>

                                </v-card>
                            </v-col>
                            <v-col>
                                <object v-if="form_url" :data="form_url" type="application/pdf" width="100%"
                                    :style="{ height: computedHeight + 'px' }">
                                    <p>
                                        Formular kann nicht angezeigt werden.
                                        <a :href="form_url">Hier klicken, um es herunterzuladen.</a>
                                    </p>
                                </object>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { supabase } from '../supabase';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { debounce } from 'lodash';
import { saveAs } from 'file-saver';
import connector from '../helpers/supabase-connector.js';
import cipher from '@/helpers/cipher';
import dayjs from 'dayjs';
import json from '../assets/icdCodes.json';

export default {

    props: ['session', 'current_file'],

    data() {
        return {
            tab: null,
            dialog_prompt_download: false,
            dialog_save_form: false,
            file_name: null,
            saving_form: false,

            selectedICDCode: null,
            icdCodes: [],

            dialog_google_terms: false,
            dialog_gaf_explanation: false,
            gaf_value: 0,

            loading_goals: false,
            loading_reasons: false,
            loading_diagnosis: false,
            loading_anamnese: false,
            loading_progress: false,
            loading_gaf: false,

            n_customers: null,
            customers: [],
            appointments: [],
            client: null,
            loading_appointments: false,

            // computedHeight: window.innerHeight,

            current_tab: 0,
            loaded_customers: false,
            model_response: null,
            model_response_diagnosis: null,
            model_response_gaf: null,
            model_response_goals: null,
            model_response_reasons: null,
            model_response_anamnese: null,
            model_response_progress: null,
            anamnese_recommendations: [],
            progress_recommendations: [],
            dialog: false,

            pdf_document: null,
            form_url: null,

            application_form: '0',
            insurance: '',
            insurance_state: '',
            insurance_street: '',
            insurance_place: '',
            self_insured: true,
            insurer_name: '',
            insurer_svnr: '',
            lastname: '',
            firstname: '',
            address: '',
            svnr: '',

            therapist_name: '',
            therapist_nr: '',
            therapist_address: '',

            icd_diagnosis: [],
            has_job: 0,
            job: '',
            has_psychiatrist: false,
            psychiatrist: '',
            field_1: '',
            field_2: '',
            field_3: '',
            therapy_setting: '0',
            therapy_start: '',
            therapy_units: '',
            therapy_units_past: '',
            therapy_units_other: '',
            therapy_units_total: '',
            therapy_frequency: '',
            therapy_reason: '',
            therapy_setting_other: '',
            other_persons: '',
            notes: '',

            selected_insurer: null,
            // if update, also change in Klienten.vue
            // and also in the DB (versicherungen)
            insurer: [
                { "id": 1, "name": "ÖGK", "state": "Burgenland", "address": "Siegfried Marcus-Straße 5", "place": "7000 Eisenstadt", "displayName": "ÖGK (Burgenland)" },
                { "id": 2, "name": "ÖGK", "state": "Kärnten", "address": "Kempfstraße 8", "place": "9021 Klagenfurt am Wörthersee", "displayName": "ÖGK (Kärnten)" },
                { "id": 3, "name": "ÖGK", "state": "Niederösterreich", "address": "Kremser Landstraße 3", "place": "3100 St. Pölten", "displayName": "ÖGK (Niederösterreich)" },
                { "id": 4, "name": "ÖGK", "state": "Oberösterreich", "address": "Gruberstraße 77", "place": "4021 Linz", "displayName": "ÖGK (Oberösterreich)" },
                { "id": 5, "name": "ÖGK", "state": "Salzburg", "address": "Engelbert-Weiß-Weg 10", "place": "5020 Salzburg", "displayName": "ÖGK (Salzburg)" },
                { "id": 6, "name": "ÖGK", "state": "Steiermark", "address": "Josef-Pongratz-Platz 1", "place": "8010 Graz", "displayName": "ÖGK (Steiermark)" },
                { "id": 7, "name": "ÖGK", "state": "Tirol", "address": "Klara-Pölt-Weg 2", "place": "6020 Innsbruck", "displayName": "ÖGK (Tirol)" },
                { "id": 8, "name": "ÖGK", "state": "Vorarlberg", "address": "Jahngasse 4", "place": "6850 Dornbirn", "displayName": "ÖGK (Vorarlberg)" },
                { "id": 9, "name": "ÖGK", "state": "Wien", "address": "Wienerbergstraße 15-19", "place": "1100 Wien", "displayName": "ÖGK (Wien)" },
                { "id": 10, "name": "BVAEB", "state": "Kärnten", "address": "Siebenhügelstraße 1", "place": "9020 Klagenfurt am Wörthersee", "displayName": "BVAEB (Kärnten)" },
                { "id": 11, "name": "BVAEB", "state": "Oberösterreich", "address": "Hessenplatz 14", "place": "4020 Linz", "displayName": "BVAEB (Oberösterreich)" },
                { "id": 12, "name": "BVAEB", "state": "Salzburg", "address": "Faberstraße 2A", "place": "5020 Salzburg", "displayName": "BVAEB (Salzburg)" },
                { "id": 13, "name": "BVAEB", "state": "Steiermark", "address": "Grieskai 106", "place": "8020 Graz", "displayName": "BVAEB (Steiermark)" },
                { "id": 14, "name": "BVAEB", "state": "Tirol", "address": "Meinhardstraße 1", "place": "6010 Innsbruck", "displayName": "BVAEB (Tirol)" },
                { "id": 15, "name": "BVAEB", "state": "Vorarlberg", "address": "Montfortstraße 11", "place": "6900 Bregenz", "displayName": "BVAEB (Vorarlberg)" },
                { "id": 16, "name": "BVAEB", "state": "Wien, NÖ & Burgenland", "address": "Josefstädter Straße 80", "place": "1080 Wien", "displayName": "BVAEB (Wien, NÖ & Burgenland)" },
                { "id": 17, "name": "SVS", "state": "Burgenland", "address": "Siegfried Marcus-Straße 5", "place": "7000 Eisenstadt", "displayName": "SVS (Burgenland)" },
                { "id": 18, "name": "SVS", "state": "Kärnten", "address": "Bahnhofstraße 67", "place": "9020 Klagenfurt", "displayName": "SVS (Kärnten)" },
                { "id": 19, "name": "SVS", "state": "Niederösterreich", "address": "Neugebäudeplatz 1", "place": "3100 Sankt Pölten", "displayName": "SVS (Niederösterreich)" },
                { "id": 20, "name": "SVS", "state": "Oberösterreich", "address": "Hanuschstraße 34", "place": "4020 Linz", "displayName": "SVS (Oberösterreich)" },
                { "id": 21, "name": "SVS", "state": "Salzburg", "address": "Auerspergstraße 24", "place": "5020 Salzburg", "displayName": "SVS (Salzburg)" },
                { "id": 22, "name": "SVS", "state": "Steiermark", "address": "Körblergasse 115", "place": "8010 Graz", "displayName": "SVS (Steiermark)" },
                { "id": 23, "name": "SVS", "state": "Tirol", "address": "Klara-Pölt-Weg 1", "place": "6020 Innsbruck", "displayName": "SVS (Tirol)" },
                { "id": 24, "name": "SVS", "state": "Vorarlberg", "address": "Schloßgraben 14", "place": "6800 Feldkirch", "displayName": "SVS (Vorarlberg)" },
                { "id": 25, "name": "SVS", "state": "Wien", "address": "Wiedner Hauptstraße 84-86", "place": "1051 Wien", "displayName": "SVS (Wien)" },
                { "id": 26, "name": "LKUF", "state": "Oberösterreich", "address": "Leonfeldner Straße 11", "place": "4040 Linz", "displayName": "LKUF (Oberösterreich)" }
            ],

        };
    },

    methods: {

        acceptTerms() {
            localStorage.setItem('agreed_google_terms', true);
            this.dialog_google_terms = false;

            if (this.session) { 
                connector.logError(this, {
                    uid: this.session.user.id,
                    message: this.session.user.email +  ' accepted the Google terms',
                });
            }
        },

        rejectTerms() {
            if (this.session) { 
                connector.logError(this, {
                    uid: this.session.user.id,
                    message: this.session.user.email +  ' REJECTED the Google terms',
                });
            }
            this.dialog_google_terms = false;
        },

        selectDiagnosis(diagnosis) {
            // check if the diagnosis is already in the list, if so do not add it, otherwise add it to the list
            if (this.icd_diagnosis.find((item) => item.code === diagnosis.code)) {
                return;
            } else {
                this.icd_diagnosis.push(diagnosis);
            }
        },

        limitGAFValue() {
            if (this.gaf_value < 0) {
                this.gaf_value = 0;
            } else if (this.gaf_value > 100) {
                this.gaf_value = 100;
            }
        },

        addTextAnamnese(text) {
            if (this.field_1 === '' || !this.field_1) {
                this.field_1 = text;
            } else {
                this.field_1 += ', ' + text;
                this.field_1 = this.field_1.trim();
            }
        },

        addTextProgress(text) {
            if (this.field_2 === '' || !this.field_2) {
                this.field_2 = text;
            } else {
                this.field_2 += ', ' + text;
                this.field_2 = this.field_2.trim();
            }
        },

        addTextGoals(text) {
            if (this.field_3 === '' || !this.field_3) {
                this.field_3 = text;
            } else {
                this.field_3 += ', ' + text;
                this.field_3 = this.field_3.trim();
            }
        },

        async getMoreProgressTextRecommendations() {

            if (this.icd_diagnosis.length === 0) {
                this.$emit('showError', {
                    message: 'Bitte gib zuerst eine Diagnose ein um passende Vorschläge zu erhalten.',
                    timeout: 10000
                });
                return;
            }

            // check if in the localStorage the agreement is set
            if (!localStorage.getItem('agreed_google_terms')) {
                this.dialog_google_terms = true;
                return;
            }

            // query a supabase edge function
            try {
                this.loading_progress = true;
                const { data, error } = await supabase.functions.invoke('get-text-response', {
                    body: { 
                        progress: this.field_2, 
                        diagnosis: this.icd_diagnosis.length > 0 ? this.icd_diagnosis.map((diagnosis) => diagnosis.code).join(', ') : '',
                        anamnese: this.field_1,
                        type: 'recommendProgress' }
                })

                if (error) {
                    this.$emit('showError', {
                        message: 'Ein Fehler ist beim Vorschlagen der Textbausteine aufgetreten. Bitte versuche es erneut. Fehler: ' + error,
                    })
                    this.loading_progress = false;
                    return
                }
                this.loading_progress = false;
                this.model_response_progress = data;

                const response = this.model_response_progress.map((item) => item.candidates.map((can) => can.content.parts.map((part) => part.text)).join("")).join("");
                const responseArray = response.match(/#(.+)/gm).map((item) => item.slice(1).trim()).filter((item) => (item !== "") && (item.length > 3) && (!item.includes('Behandlungsverlauf')) && (!item.includes('Störungsintensität')));

                this.progress_recommendations = [];
                for (let i = 0; i < responseArray.length; i++) {
                    this.progress_recommendations.push({
                        text: responseArray[i],
                        id: i
                    });
                }
            } catch (error) {
                this.$emit('showError', {
                    message: 'Ein Fehler ist beim Vorschlagen der Textbausteine aufgetreten. Bitte versuche es erneut. Fehler: ' + error,
                });
                this.loading_progress = false;
            }
        },

        async getMoreAnamneseTextRecommendations() {

            if (this.icd_diagnosis.length === 0) {
                this.$emit('showError', {
                    message: 'Bitte gib zuerst eine Diagnose ein um passende Vorschläge zu erhalten.',
                    timeout: 10000
                });
                return;
            }

            // check if in the localStorage the agreement is set
            if (!localStorage.getItem('agreed_google_terms')) {
                this.dialog_google_terms = true;
                return;
            }

            try {

                // query a supabase edge function
                this.loading_anamnese = true;
                const { data, error } = await supabase.functions.invoke('get-text-response', {
                    body: { 
                        prompt: this.field_1, 
                        diagnosis: this.icd_diagnosis.length > 0 ? this.icd_diagnosis.map((diagnosis) => diagnosis.code).join(', ') : '',
                        type: 'recommendAnamnese' }
                })

                if (error) {
                    this.$emit('showError', {
                        message: 'Ein Fehler ist beim Vorschlagen der Textbausteine aufgetreten. Bitte versuche es erneut. Fehler: ' + error,
                    })
                    this.loading_anamnese = false;
                    return
                }
                this.loading_anamnese = false;
                this.model_response_anamnese = data;

                const response = this.model_response_anamnese.map((item) => item.candidates.map((can) => can.content.parts.map((part) => part.text)).join("")).join("");
                const responseArray = response.split('#').map((item) => item.trim()).filter((item) => (item !== "") && (item.length > 3) && (!item.includes('Textbausteine')));
                this.anamnese_recommendations = [];
                for (let i = 0; i < responseArray.length; i++) {
                    this.anamnese_recommendations.push({
                        text: responseArray[i].replaceAll('**', ''),
                        id: i
                    });
                }
            } catch (error) {
                this.$emit('showError', {
                    message: 'Ein Fehler ist beim Vorschlagen der Textbausteine aufgetreten. Bitte versuche es erneut. Fehler: ' + error,
                });
                this.loading_anamnese = false;
            }
        
        },

        addTextReason(reason) {
            if (this.therapy_reason === '') {
                this.therapy_reason = reason;
            } else {
                this.therapy_reason += '. ' + reason;
                this.therapy_reason = this.therapy_reason.trim();
            }
        },

        async getICD10Diagnosis() {

            if (!this.field_1 || this.field_1.length < 10) {
                this.$emit('showError', {
                    message: 'Gib zuerst eine behandlungsbezogene Anamnese ein, um Vorschläge für Diagnosen zu erhalten. Diese sollte mindestens 10 Zeichen lang sein.',
                    timeout: 10000
                });
                return;
            }

            // check if in the localStorage the agreement is set
            if (!localStorage.getItem('agreed_google_terms')) {
                this.dialog_google_terms = true;
                return;
            }

            try {
                // query a supabase edge function
                this.loading_diagnosis = true;
                const { data, error } = await supabase.functions.invoke('get-text-response', {
                    body: { 
                        anamnese: this.field_1,
                        progress: this.field_2, 
                        type: 'diagnosis' 
                    }
                })

                if (error) {
                    this.$emit('showError', {
                        message: 'Ein Fehler ist beim Vorschlagen der Diagnosen aufgetreten. Bitte versuche es erneut. Fehler: ' + error,
                    });
                    this.loading_diagnosis = false;
                    return;
                }
                this.model_response_diagnosis = this.convertLLMResponseToText(data);
                this.loading_diagnosis = false;
            } catch (error) {
                this.$emit('showError', {
                    message: 'Ein Fehler ist beim Vorschlagen der Diagnosen aufgetreten. Bitte versuche es erneut. Fehler: ' + error,
                });
                this.loading_diagnosis = false;
            }
        },

        async getGAFValue() {

            if (this.icd_diagnosis.length === 0 || !this.field_1 || this.field_1.length < 10) {

                let missing = [];
                if (this.icd_diagnosis.length === 0) missing.push('Diagnose');
                if (!this.field_1 || this.field_1.length < 10) missing.push('Anamnese');

                let missing_text = missing.join(' und ');
                let message_text = 'Bitte gib zuerst eine ' + missing_text + ' ein um einen Vorschlag für den GAF-Wert zu erhalten.';
                if (missing.includes('Anamnese')) {
                    message_text += ' Die Anamnese sollte mindestens 10 Zeichen lang sein.';
                }

                this.$emit('showError', {
                    message: message_text,
                    timeout: 10000
                })
                return;
            }

            // check if in the localStorage the agreement is set
            if (!localStorage.getItem('agreed_google_terms')) {
                this.dialog_google_terms = true;
                return;
            }

            try {
                // query a supabase edge function
                this.loading_gaf = true;
                const { data, error } = await supabase.functions.invoke('get-text-response', {
                    body: { 
                        diagnosis: this.icd_diagnosis.length > 0 ? this.icd_diagnosis.map((diagnosis) => diagnosis.code).join(', ') : '',
                        anamnese: this.field_1,
                        type: 'gaf' 
                    }
                })

                if (error) {
                    this.$emit('showError', {
                        message: 'Ein Fehler ist beim Vorschlagen des GAF-Wertes aufgetreten. Bitte versuche es erneut. Fehler: ' + error,
                    })
                    this.loading_gaf = false;
                    return
                }
                this.loading_gaf = false;
                this.model_response_gaf = this.convertLLMResponseToText(data);

            } catch (error) {
                this.$emit('showError', {
                    message: 'Ein Fehler ist beim Vorschlagen des GAF-Wertes aufgetreten. Bitte versuche es erneut. Fehler: ' + error,
                });
                this.loading_gaf = false;
                return;
            }
            
        },

        async getGoals() {
            // check if diagnosis and gaf value are set, if not prompt user to set them
            if (this.icd_diagnosis.length === 0 || !this.gaf_value) {

                let missing = [];
                if (this.icd_diagnosis.length === 0) missing.push('eine Diagnose');
                if (!this.gaf_value) missing.push('den GAF-Wert');

                let missing_text = missing.join(' und ');
                let message_text = 'Bitte gib zuerst ' + missing_text + ' an um passende Ziele vorgeschlagen zu bekommen.';

                this.$emit('showError', {
                    message: message_text,
                })
                return
            }

            // check if in the localStorage the agreement is set
            if (!localStorage.getItem('agreed_google_terms')) {
                this.dialog_google_terms = true;
                return;
            }

            this.loading_goals = true;
            try {
                const { data, error } = await supabase.functions.invoke('get-text-response', {
                    body: { 
                        anamnese: this.field_1,
                        progress: this.field_2,
                        diagnosis: this.icd_diagnosis.length > 0 ? this.icd_diagnosis.map((diagnosis) => diagnosis.code).join(', ') : '', 
                        gaf: this.gaf_value,
                        type: 'goals'
                    }
                })

                if (error) {
                    this.$emit('showError', {
                        message: 'Ein Fehler ist beim Vorschlagen der Ziele aufgetreten. Bitte versuche es erneut. Fehler: ' + error,
                    })
                    this.loading_goals = false;
                    return
                }

                this.loading_goals = false;
                this.model_response_goals = this.convertLLMResponseToText(data);

            } catch (error) {
                this.$emit('showError', {
                    message: 'Ein Fehler ist beim Vorschlagen der Ziele aufgetreten. Bitte versuche es erneut. Fehler: ' + error,
                });
                this.loading_goals = false;
                return;
            }
        },

        async getReasons() {

            if (this.icd_diagnosis.length === 0 || !this.field_1 || this.field_1.length < 10 || !this.field_2 || this.field_2.length < 10 || !this.field_3 || this.field_3.length < 10) {
                
                let missing = [];
                if (this.icd_diagnosis.length === 0) missing.push('eine Diagnose');
                if (!this.field_1 || this.field_1.length < 10) missing.push('eine Anamnese');
                if (!this.field_2 || this.field_2.length < 10) missing.push('einen Behandlungsverlauf');
                if (!this.field_3 || this.field_3.length < 10) missing.push('eine Zielsetzung');

                let missing_text = missing.join(', ');
                let message_text = 'Bitte gib zuerst ' + missing_text + ' an um passende Begründungen vorgeschlagen zu bekommen. Diese sollten mindestens jeweils 10 Zeichen lang sein.';
                
                this.$emit('showError', {
                    message: message_text,
                    timeout: 10000
                });
                return;
            }

            // check if in the localStorage the agreement is set
            if (!localStorage.getItem('agreed_google_terms')) {
                this.dialog_google_terms = true;
                return;
            }

            try {
                // query a supabase edge function
                this.loading_reasons = true;
                const { data, error } = await supabase.functions.invoke('get-text-response', {
                    body: {
                        type: 'reasons',
                        anamnese: this.field_1,
                        progress: this.field_2,
                        diagnosis: this.icd_diagnosis.length > 0 ? this.icd_diagnosis.map((diagnosis) => diagnosis.code).join(', ') : '',
                        gaf: this.gaf_value,
                        goals: this.field_3
                    }
                })

                if (error) {
                    this.$emit('showError', {
                        message: 'Ein Fehler ist beim Vorschlagen der Begründung aufgetreten. Bitte versuche es erneut. Fehler: ' + error,
                    })
                    this.loading_reasons = false;
                    return;
                }
                this.loading_reasons = false;
                this.model_response_reasons = this.convertLLMResponseToText(data);
            } catch (error) {
                this.$emit('showError', {
                    message: 'Ein Fehler ist beim Vorschlagen der Begründung aufgetreten. Bitte versuche es erneut. Fehler: ' + error,
                });
                this.loading_reasons = false;
            }
            
        },

        async initialize() {

            // set data for user therapist
            if (this.$store.state.client.name) this.therapist_name = this.$store.state.client.name;
            if (this.$store.state.client.vpnr) this.therapist_nr = 'VPNR: ' + this.$store.state.client.vpnr;
            if (this.$store.state.client.adresse) this.therapist_address = this.$store.state.client.adresse;
            if (this.$store.state.client.plz) this.therapist_address += '\n' + this.$store.state.client.plz;
            if (this.$store.state.client.ort) this.therapist_address += ' ' + this.$store.state.client.ort;
            // if (this.$store.state.client.vpnr) this.therapist_nr = this.$store.state.client.vpnr;

            let customers = await connector.getDataOnly(this, 'vwklienten', 'id', true)
            this.checkReturnValueOfCustomers(customers);

            let appointments = await connector.getDataOnly(this, 'vwterminedokumentation', 'datum', false);
            cipher.decryptDataAsync(this, appointments, 'appointments', 'loading_appointments');
        },

        closeDialog() {

            this.dialog_prompt_download = false;
            this.dialog = false;
            if (this.form_url) {
                URL.revokeObjectURL(this.form_url);
                this.form_url = null;
            }
        },

        showSaveDialog() {
            this.dialog_save_form = true;
        },

        async saveForm() {

            if (!this.file_name) {
                this.$emit('showError', {
                    message: 'Bitte gib einen Dateinamen an.',
                    timeout: 10000
                });
                return;
            }

            if (!this.client) {
                this.$emit('showError', {
                    message: 'Bitte wähle zuerst einen Klienten aus.',
                    timeout: 10000
                });
                return;
            }

            // replace all german umlauts and ß with ae oe ue ss etc, also consider capital letters
            this.file_name = this.file_name.replace(/ä/g, 'ae').replace(/Ä/g, 'Ae').replace(/ö/g, 'oe').replace(/Ö/g, 'Oe').replace(/ü/g, 'ue').replace(/Ü/g, 'Ue').replace(/ß/g, 'ss');

            // check if the file name contains special characters, if so, show an error message and skip the file
            if (this.file_name.match(/[^a-zA-Z0-9. \-()_]/)) {
                this.$emit('showError', {
                    message: 'Dateinamen dürfen keine Sonderzeichen oder Umlaute enthalten.',
                });
                return;
            }

            this.saving_form = true;

            let form = {
                client_id: this.client.fk_klienten_id,
                application_form: this.application_form,
                selected_insurer: this.selected_insurer,
                insurance: this.insurance,
                insurance_state: this.insurance_state,
                insurance_street: this.insurance_street,
                insurance_place: this.insurance_place,
                self_insured: this.self_insured,
                insurer_name: this.insurer_name,
                insurer_svnr: this.insurer_svnr,
                lastname: this.lastname,
                firstname: this.firstname,
                address: this.address,
                svnr: this.svnr,
                therapist_name: this.therapist_name,
                therapist_nr: this.therapist_nr,
                therapist_address: this.therapist_address,
                icd_diagnosis: this.icd_diagnosis,
                gaf_value: this.gaf_value,
                has_job: this.has_job,
                job: this.job,
                has_psychiatrist: this.has_psychiatrist,
                psychiatrist: this.psychiatrist,
                field_1: this.field_1,
                field_2: this.field_2,
                field_3: this.field_3,
                therapy_setting: this.therapy_setting,
                therapy_start: this.therapy_start,
                therapy_units: this.therapy_units,
                therapy_units_past: this.therapy_units_past,
                therapy_units_other: this.therapy_units_other,
                therapy_units_total: this.therapy_units_total,
                therapy_frequency: this.therapy_frequency,
                therapy_reason: this.therapy_reason,
                therapy_setting_other: this.therapy_setting_other,
                other_persons: this.other_persons,
                notes: this.notes,
            };

            let formString = new TextEncoder().encode(JSON.stringify(form));

            let encrypted_file = await cipher.encryptFile(this.$store.state.aes_key_file, formString);
            let fileSize = '1 KB';

            const fileDataJSON = JSON.stringify({
                iv: encrypted_file.iv,
                file: encrypted_file.file,
                size: fileSize,
            });

            let blob = new Blob([fileDataJSON], { type: 'application/json' });
            
            let client_id_folder = this.client.fk_klienten_id + '/';

            let uploaded = await connector.uploadFileToBucket(this, 'documentation', this.session.user.id + '/' + client_id_folder, this.file_name + '.kpzpsy', blob, '0', 'application/json');
            if (uploaded) {
                this.$emit('showInfo', {
                    message: 'Antrag wurde erfolgreich gespeichert. Du kannst ihn jederzeit in den Dokumenten des Klienten wiederfinden.',
                    timeout: 10000
                });
                this.dialog_save_form = false;
            }
            this.saving_form = false;
        },

        downloadForm() {
            this.overlayTextOnPdf(true);
        },

        async loadPdf() {
            const url = '/zuschuss.pdf';
            const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
            this.pdf_document = await PDFDocument.load(existingPdfBytes);
            this.overlayTextOnPdf();
        },

        async overlayTextOnPdf(downloadAll = false) {
            try {
                // Ensure there's a PDF document loaded
                if (!this.pdf_document) return;

                const pdfDoc = await PDFDocument.create();

                // Create or get the form object from the PDF document
                const form = pdfDoc.getForm() || pdfDoc.createForm();

                const pages = downloadAll ? [0, 1, 2, 3] : [this.current_tab];

                const existingPages = await pdfDoc.copyPages(this.pdf_document, pages);
                existingPages.forEach((page) => pdfDoc.addPage(page));

                let current_page = this.current_tab;
                if (downloadAll) {
                    // set to -1 to make sure only each page is filled out once
                    current_page = -1;
                }

                // Define max width and font size
                // Choose a font
                const font = await pdfDoc.embedFont(StandardFonts.Helvetica)
                const maxWidth = 450; // Adjust as needed
                const fontSize = 12; // Adjust as needed

                for (let i = 0; i < pages.length; i++) {
                    const page = pdfDoc.getPage(i);

                    if (current_page === 0 || (downloadAll && i === 0)) {

                        if (this.application_form === '0') {
                            const textFieldFirst = form.createTextField('Erstantrag');
                            textFieldFirst.setText('X'); // Set the initial text
                            textFieldFirst.addToPage(page, {
                                x: 77,
                                y: 546,
                                height: 10,
                                width: 10,
                                borderWidth: 1,
                                borderColor: rgb(0, 0, 0),
                                font: font,
                            });
                            form.getTextField('Erstantrag').setFontSize(12);

                            const textFieldSecond = form.createTextField('Folgeantrag');
                            textFieldSecond.setText(''); // Set the initial text
                            textFieldSecond.addToPage(page, {
                                x: 228,
                                y: 546,
                                height: 10,
                                width: 10,
                                borderWidth: 1,
                                borderColor: rgb(0, 0, 0),
                                font: font,
                            });
                            form.getTextField('Folgeantrag').setFontSize(12);

                            const textFieldThird = form.createTextField('Behandlerwechsel');
                            textFieldThird.setText(''); // Set the initial text
                            textFieldThird.addToPage(page, {
                                x: 380,
                                y: 546,
                                height: 10,
                                width: 10,
                                borderWidth: 1,
                                borderColor: rgb(0, 0, 0),
                                font: font,
                            });
                            form.getTextField('Behandlerwechsel').setFontSize(12);

                        } else if (this.application_form === '1') {

                            const textFieldFirst = form.createTextField('Erstantrag');
                            textFieldFirst.setText(''); // Set the initial text
                            textFieldFirst.addToPage(page, {
                                x: 77,
                                y: 546,
                                height: 10,
                                width: 10,
                                borderWidth: 1,
                                borderColor: rgb(0, 0, 0),
                                font: font,
                            });
                            form.getTextField('Erstantrag').setFontSize(12);

                            const textFieldSecond = form.createTextField('Folgeantrag');
                            textFieldSecond.setText('X'); // Set the initial text
                            textFieldSecond.addToPage(page, {
                                x: 228,
                                y: 546,
                                height: 10,
                                width: 10,
                                borderWidth: 1,
                                borderColor: rgb(0, 0, 0),
                                font: font,
                            });
                            form.getTextField('Folgeantrag').setFontSize(12);

                            const textFieldThird = form.createTextField('Behandlerwechsel');
                            textFieldThird.setText(''); // Set the initial text
                            textFieldThird.addToPage(page, {
                                x: 380,
                                y: 546,
                                height: 10,
                                width: 10,
                                borderWidth: 1,
                                borderColor: rgb(0, 0, 0),
                                font: font,
                            });
                            form.getTextField('Behandlerwechsel').setFontSize(12);

                        } else if (this.application_form === '2') {

                            const textFieldFirst = form.createTextField('Erstantrag');
                            textFieldFirst.setText(''); // Set the initial text
                            textFieldFirst.addToPage(page, {
                                x: 77,
                                y: 546,
                                height: 10,
                                width: 10,
                                borderWidth: 1,
                                borderColor: rgb(0, 0, 0),
                                font: font,
                            });
                            form.getTextField('Erstantrag').setFontSize(12);

                            const textFieldSecond = form.createTextField('Folgeantrag');
                            textFieldSecond.setText(''); // Set the initial text
                            textFieldSecond.addToPage(page, {
                                x: 228,
                                y: 546,
                                height: 10,
                                width: 10,
                                borderWidth: 1,
                                borderColor: rgb(0, 0, 0),
                                font: font,
                            });
                            form.getTextField('Folgeantrag').setFontSize(12);

                            const textFieldThird = form.createTextField('Behandlerwechsel');
                            textFieldThird.setText('X'); // Set the initial text
                            textFieldThird.addToPage(page, {
                                x: 380,
                                y: 546,
                                height: 10,
                                width: 10,
                                borderWidth: 1,
                                borderColor: rgb(0, 0, 0),
                                font: font,
                            });
                            form.getTextField('Behandlerwechsel').setFontSize(12);
                        }

                        const textFieldInsurance = form.createTextField('Versicherungsträger');
                        textFieldInsurance.setText(this.insurance); // Set the initial text
                        textFieldInsurance.addToPage(page, {
                            x: 215,
                            y: 618,
                            height: 14,
                            borderWidth: 0,
                            borderColor: rgb(1, 1, 1, 0),
                            font: font,
                        });
                        form.getTextField('Versicherungsträger').setFontSize(12);

                        const textFieldInsuranceState = form.createTextField('Versicherungsträger Bundesland');
                        textFieldInsuranceState.setText(this.insurance_state); // Set the initial text
                        textFieldInsuranceState.addToPage(page, {
                            x: 215,
                            y: 603,
                            height: 13,
                            borderWidth: 0,
                            borderColor: rgb(1, 1, 1, 0),
                            font: font,
                        });
                        form.getTextField('Versicherungsträger Bundesland').setFontSize(12);

                        const textFieldInsuranceStreet = form.createTextField('Versicherungsträger Straße');
                        textFieldInsuranceStreet.setText(this.insurance_street); // Set the initial text
                        textFieldInsuranceStreet.addToPage(page, {
                            x: 215,
                            y: 588,
                            height: 13,
                            borderWidth: 0,
                            borderColor: rgb(1, 1, 1, 0),
                            font: font,
                        });
                        form.getTextField('Versicherungsträger Straße').setFontSize(12);

                        const textFieldInsurancePlace = form.createTextField('Versicherungsträger PLZ');
                        textFieldInsurancePlace.setText(this.insurance_place); // Set the initial text
                        textFieldInsurancePlace.addToPage(page, {
                            x: 215,
                            y: 573,
                            height: 13,
                            borderWidth: 0,
                            borderColor: rgb(1, 1, 1, 0),
                            font: font,
                        });
                        form.getTextField('Versicherungsträger PLZ').setFontSize(12);


                        const textFieldFirstname = form.createTextField('Vorname');
                        textFieldFirstname.setText(this.firstname); // Set the initial text
                        textFieldFirstname.addToPage(page, {
                            x: 80,
                            y: 405,
                            width: 250,
                            height: 16,
                            borderWidth: 0,
                            borderColor: rgb(1, 1, 1, 0),
                            font: font,
                        });
                        form.getTextField('Vorname').setFontSize(12);

                        const textFieldSVNR = form.createTextField('SVNR');
                        textFieldSVNR.setText(this.svnr); // Set the initial text
                        textFieldSVNR.addToPage(page, {
                            x: 370,
                            y: 405,
                            height: 16,
                            width: 140,
                            borderWidth: 0,
                            borderColor: rgb(1, 1, 1, 0),
                            font: font,
                        });
                        form.getTextField('SVNR').setFontSize(12);

                        const textFieldLastname = form.createTextField('Nachname');
                        textFieldLastname.setText(this.lastname); // Set the initial text
                        textFieldLastname.addToPage(page, {
                            x: 80,
                            y: 441,
                            width: 430,
                            height: 16,
                            borderWidth: 0,
                            borderColor: rgb(1, 1, 1, 0),
                            font: font,
                        });
                        form.getTextField('Nachname').setFontSize(12);

                        const textFieldAddress = form.createTextField('Adresse');
                        textFieldAddress.setText(this.address); // Set the initial text
                        textFieldAddress.enableMultiline();
                        textFieldAddress.addToPage(page, {
                            x: 80,
                            y: 352,
                            height: 2 * 16,
                            width: 430,
                            borderWidth: 0,
                            borderColor: rgb(1, 1, 1, 0),
                            font: font,
                        });
                        form.getTextField('Adresse').setFontSize(12);

                        if (this.self_insured === false) {

                            const textFieldInsurerName = form.createTextField('Versicherter Name');
                            textFieldInsurerName.setText(this.insurer_name); // Set the initial text
                            textFieldInsurerName.addToPage(page, {
                                x: 80,
                                y: 311,
                                width: 250,
                                height: 16,
                                borderWidth: 0,
                                borderColor: rgb(1, 1, 1, 0),
                                font: font,
                            });
                            form.getTextField('Versicherter Name').setFontSize(12);

                            const textFieldInsurerSVNR = form.createTextField('Versicherter SVNR');
                            textFieldInsurerSVNR.setText(this.insurer_svnr); // Set the initial text
                            textFieldInsurerSVNR.addToPage(page, {
                                x: 370,
                                y: 311,
                                height: 16,
                                width: 140,
                                borderWidth: 0,
                                borderColor: rgb(1, 1, 1, 0),
                                font: font,
                            });
                            form.getTextField('Versicherter SVNR').setFontSize(12);

                        } else {
                            // TODO, not sure if this needs to be extra filled out with the same data

                            const textFieldInsurerName = form.createTextField('Versicherter Name');
                            textFieldInsurerName.setText(''); // Set the initial text
                            textFieldInsurerName.addToPage(page, {
                                x: 80,
                                y: 311,
                                width: 250,
                                height: 16,
                                borderWidth: 0,
                                borderColor: rgb(1, 1, 1, 0),
                                font: font,
                            });
                            form.getTextField('Versicherter Name').setFontSize(12);

                            const textFieldInsurerSVNR = form.createTextField('Versicherter SVNR');
                            textFieldInsurerSVNR.setText(''); // Set the initial text
                            textFieldInsurerSVNR.addToPage(page, {
                                x: 370,
                                y: 311,
                                height: 16,
                                width: 140,
                                borderWidth: 0,
                                borderColor: rgb(1, 1, 1, 0),
                                font: font,
                            });
                            form.getTextField('Versicherter SVNR').setFontSize(12);
                        }

                        const textFieldTherapist = form.createTextField('Therapeut');
                        textFieldTherapist.setText(this.therapist_name + '\n' + this.therapist_nr + '\n' + this.therapist_address);
                        textFieldTherapist.enableMultiline();
                        textFieldTherapist.addToPage(page, {
                            x: 80,
                            y: 130,
                            width: 430,
                            height: 6 * 13,
                            borderWidth: 0,
                            borderColor: rgb(1, 1, 1, 0),
                            font: font,
                        });
                        form.getTextField('Therapeut').setFontSize(12);
                    }
                    if (current_page === 1 || (downloadAll && i === 1)) {

                        const textFieldDiagnosis = form.createTextField('Diagnose');
                        // check if the diagnosis is a dict or a string
                        if (this.icd_diagnosis.length > 0) {
                            textFieldDiagnosis.setText(this.icd_diagnosis.map((diagnosis) => diagnosis.short_code).join(', ')); // Set the initial text
                        } else {
                            textFieldDiagnosis.setText(''); // Set the initial text
                        }
                        textFieldDiagnosis.addToPage(page, {
                            x: 365,
                            y: 692,
                            height: 16,
                            width: 170,
                            borderWidth: 0,
                            borderColor: rgb(1, 1, 1, 0),
                            font: font,
                        });
                        form.getTextField('Diagnose').setFontSize(12);

                        const textFieldGAF = form.createTextField('GAF');
                        textFieldGAF.setText(this.gaf_value.toString()); // Set the initial text
                        textFieldGAF.addToPage(page, {
                            x: 365,
                            y: 664,
                            height: 16,
                            width: 170,
                            borderWidth: 0,
                            borderColor: rgb(1, 1, 1, 0),
                            font: font,
                        });
                        form.getTextField('GAF').setFontSize(12);


                        if (this.has_job === 0) {
                            const textFieldYesJob = form.createTextField('Job JA');
                            textFieldYesJob.setText('X'); // Set the initial text
                            textFieldYesJob.addToPage(page, {
                                x: 363,
                                y: 641,
                                height: 10,
                                width: 10,
                                borderWidth: 1,
                                borderColor: rgb(0, 0, 0),
                                font: font,
                            });
                            form.getTextField('Job JA').setFontSize(12);

                            const textFieldNoJob = form.createTextField('Job NEIN');
                            textFieldNoJob.setText(''); // Set the initial text
                            textFieldNoJob.addToPage(page, {
                                x: 424,
                                y: 641,
                                height: 10,
                                width: 10,
                                borderWidth: 1,
                                borderColor: rgb(0, 0, 0),
                                font: font,
                            });
                            form.getTextField('Job NEIN').setFontSize(12);


                            const textFieldJob = form.createTextField('Job');
                            textFieldJob.setText(this.job); // Set the initial text
                            textFieldJob.addToPage(page, {
                                x: 365,
                                y: 625,
                                height: 14,
                                width: 170,
                                borderWidth: 0,
                                borderColor: rgb(1, 1, 1, 0),
                                font: font,
                            });
                            form.getTextField('Job').setFontSize(11);

                        } else if (this.has_job === 1) {
                            const textFieldNoJob = form.createTextField('Job NEIN');
                            textFieldNoJob.setText('X'); // Set the initial text
                            textFieldNoJob.addToPage(page, {
                                x: 424,
                                y: 641,
                                height: 10,
                                width: 10,
                                borderWidth: 1,
                                borderColor: rgb(0, 0, 0),
                                font: font,
                            });
                            form.getTextField('Job NEIN').setFontSize(12);

                            const textFieldYesJob = form.createTextField('Job JA');
                            textFieldYesJob.setText(''); // Set the initial text
                            textFieldYesJob.addToPage(page, {
                                x: 363,
                                y: 641,
                                height: 10,
                                width: 10,
                                borderWidth: 1,
                                borderColor: rgb(0, 0, 0),
                                font: font,
                            });
                            form.getTextField('Job JA').setFontSize(12);

                            const textFieldJob = form.createTextField('Job');
                            textFieldJob.setText(''); // Set the initial text
                            textFieldJob.addToPage(page, {
                                x: 365,
                                y: 625,
                                height: 14,
                                width: 170,
                                borderWidth: 0,
                                borderColor: rgb(1, 1, 1, 0),
                                font: font,
                            });
                            form.getTextField('Job').setFontSize(11);
                        } else {
                            const textFieldNoJob = form.createTextField('Job NEIN');
                            textFieldNoJob.setText(''); // Set the initial text
                            textFieldNoJob.addToPage(page, {
                                x: 424,
                                y: 641,
                                height: 10,
                                width: 10,
                                borderWidth: 1,
                                borderColor: rgb(0, 0, 0),
                                font: font,
                            });
                            form.getTextField('Job NEIN').setFontSize(12);

                            const textFieldYesJob = form.createTextField('Job JA');
                            textFieldYesJob.setText(''); // Set the initial text
                            textFieldYesJob.addToPage(page, {
                                x: 363,
                                y: 641,
                                height: 10,
                                width: 10,
                                borderWidth: 1,
                                borderColor: rgb(0, 0, 0),
                                font: font,
                            });
                            form.getTextField('Job JA').setFontSize(12);
                            const textFieldJob = form.createTextField('Job');
                            textFieldJob.setText(''); // Set the initial text
                            textFieldJob.addToPage(page, {
                                x: 365,
                                y: 625,
                                height: 14,
                                width: 170,
                                borderWidth: 0,
                                borderColor: rgb(1, 1, 1, 0),
                                font: font,
                            });
                            form.getTextField('Job').setFontSize(11);
                        }

                        if (this.has_psychiatrist === 0) {
                            const textFieldYesPsychiatrist = form.createTextField('Psychiater JA');
                            textFieldYesPsychiatrist.setText('X'); // Set the initial text
                            textFieldYesPsychiatrist.addToPage(page, {
                                x: 363,
                                y: 593,
                                height: 10,
                                width: 10,
                                borderWidth: 1,
                                borderColor: rgb(0, 0, 0),
                                font: font,
                            });
                            form.getTextField('Psychiater JA').setFontSize(12);

                            const textFieldNoPsychiatrist = form.createTextField('Psychiater NEIN');
                            textFieldNoPsychiatrist.setText(''); // Set the initial text
                            textFieldNoPsychiatrist.addToPage(page, {
                                x: 424,
                                y: 593,
                                height: 10,
                                width: 10,
                                borderWidth: 1,
                                borderColor: rgb(0, 0, 0),
                                font: font,
                            });
                            form.getTextField('Psychiater NEIN').setFontSize(12);

                            const textFieldPsychiatrist = form.createTextField('Psychiater');
                            textFieldPsychiatrist.setText(this.psychiatrist); // Set the initial text
                            textFieldPsychiatrist.addToPage(page, {
                                x: 365,
                                y: 552,
                                height: 16,
                                width: 170,
                                borderWidth: 0,
                                borderColor: rgb(1, 1, 1, 0),
                                font: font,
                            });
                            form.getTextField('Psychiater').setFontSize(12);
                        } else if (this.has_psychiatrist === 1) {
                            const textFieldNoPsychiatrist = form.createTextField('Psychiater NEIN');
                            textFieldNoPsychiatrist.setText('X'); // Set the initial text
                            textFieldNoPsychiatrist.addToPage(page, {
                                x: 424,
                                y: 593,
                                height: 10,
                                width: 10,
                                borderWidth: 1,
                                borderColor: rgb(0, 0, 0),
                                font: font,
                            });
                            form.getTextField('Psychiater NEIN').setFontSize(12);

                            const textFieldYesPsychiatrist = form.createTextField('Psychiater JA');
                            textFieldYesPsychiatrist.setText(''); // Set the initial text
                            textFieldYesPsychiatrist.addToPage(page, {
                                x: 363,
                                y: 593,
                                height: 10,
                                width: 10,
                                borderWidth: 1,
                                borderColor: rgb(0, 0, 0),
                                font: font,
                            });
                            form.getTextField('Psychiater JA').setFontSize(12);

                            const textFieldPsychiatrist = form.createTextField('Psychiater');
                            textFieldPsychiatrist.setText(''); // Set the initial text
                            textFieldPsychiatrist.addToPage(page, {
                                x: 365,
                                y: 552,
                                height: 16,
                                width: 170,
                                borderWidth: 0,
                                borderColor: rgb(1, 1, 1, 0),
                                font: font,
                            });
                            form.getTextField('Psychiater').setFontSize(12);
                        } else {
                            const textFieldNoPsychiatrist = form.createTextField('Psychiater NEIN');
                            textFieldNoPsychiatrist.setText(''); // Set the initial text
                            textFieldNoPsychiatrist.addToPage(page, {
                                x: 424,
                                y: 593,
                                height: 10,
                                width: 10,
                                borderWidth: 1,
                                borderColor: rgb(0, 0, 0),
                                font: font,
                            });
                            form.getTextField('Psychiater NEIN').setFontSize(12);

                            const textFieldYesPsychiatrist = form.createTextField('Psychiater JA');
                            textFieldYesPsychiatrist.setText(''); // Set the initial text
                            textFieldYesPsychiatrist.addToPage(page, {
                                x: 363,
                                y: 593,
                                height: 10,
                                width: 10,
                                borderWidth: 1,
                                borderColor: rgb(0, 0, 0),
                                font: font,
                            });
                            form.getTextField('Psychiater JA').setFontSize(12);

                            const textFieldPsychiatrist = form.createTextField('Psychiater');
                            textFieldPsychiatrist.setText(''); // Set the initial text
                            textFieldPsychiatrist.addToPage(page, {
                                x: 365,
                                y: 552,
                                height: 16,
                                width: 170,
                                borderWidth: 0,
                                borderColor: rgb(1, 1, 1, 0),
                                font: font,
                            });
                            form.getTextField('Psychiater').setFontSize(12);
                        }
                        
                        // Wrap text
                        let wrappedText = this.wrapText(this.field_1, maxWidth, fontSize, font);

                        const textFieldAnamnese = form.createTextField('Anamnese');
                        textFieldAnamnese.setText(wrappedText.join('\n')); // Set the initial text
                        textFieldAnamnese.enableMultiline();
                        textFieldAnamnese.addToPage(page, {
                            x: 80,
                            y: 349,
                            height: 10 * 13,
                            width: 470,
                            borderWidth: 0,
                            borderColor: rgb(1, 1, 1, 0),
                            font: font,
                        });
                        form.getTextField('Anamnese').setFontSize(12);

                        wrappedText = this.wrapText(this.field_2, maxWidth, fontSize, font);
                        const textFieldProgress = form.createTextField('Verlauf');
                        textFieldProgress.setText(wrappedText.join('\n')); // Set the initial text
                        textFieldProgress.enableMultiline();
                        textFieldProgress.addToPage(page, {
                            x: 80,
                            y: 87,
                            height: 14 * 13,
                            width: 470,
                            borderWidth: 0,
                            borderColor: rgb(1, 1, 1, 0),
                            font: font,
                        });
                        form.getTextField('Verlauf').setFontSize(12);


                    }
                    if (current_page === 2 || (downloadAll & i === 2)) {

                        // Wrap text
                        let wrappedText = this.wrapText(this.field_3, maxWidth, fontSize, font);
                        const textFieldGoals = form.createTextField('Ziele');
                        textFieldGoals.setText(wrappedText.join('\n')); // Set the initial text
                        textFieldGoals.enableMultiline();
                        textFieldGoals.addToPage(page, {
                            x: 80,
                            y: 650,
                            height: 6 * 13 + 5,
                            width: 470,
                            borderWidth: 0,
                            borderColor: rgb(1, 1, 1, 0),
                            font: font,
                        });
                        form.getTextField('Ziele').setFontSize(12);

                        const textFieldStart = form.createTextField('Start');
                        textFieldStart.setText(this.therapy_start); // Set the initial text
                        textFieldStart.addToPage(page, {
                            x: 472,
                            y: 606,
                            height: 16,
                            width: 70,
                            borderWidth: 0,
                            borderColor: rgb(1, 1, 1, 0),
                            font: font,
                        });
                        form.getTextField('Start').setFontSize(12);

                        const textFieldUnits = form.createTextField('Einheiten');
                        textFieldUnits.setText(this.therapy_units_past); // Set the initial text
                        textFieldUnits.addToPage(page, {
                            x: 472,
                            y: 574,
                            height: 16,
                            width: 70,
                            borderWidth: 0,
                            borderColor: rgb(1, 1, 1, 0),
                            font: font,
                        });
                        form.getTextField('Einheiten').setFontSize(12);

                        const textFieldUnitsOther = form.createTextField('Einheiten Andere');
                        textFieldUnitsOther.setText(this.therapy_units_other); // Set the initial text
                        textFieldUnitsOther.addToPage(page, {
                            x: 472,
                            y: 544,
                            height: 16,
                            width: 70,
                            borderWidth: 0,
                            borderColor: rgb(1, 1, 1, 0),
                            font: font,
                        });
                        form.getTextField('Einheiten Andere').setFontSize(12);

                        const textFieldUnitsRequest = form.createTextField('Beantragte Einheiten');
                        textFieldUnitsRequest.setText(this.therapy_units); // Set the initial text
                        textFieldUnitsRequest.addToPage(page, {
                            x: 472,
                            y: 514,
                            height: 16,
                            width: 70,
                            borderWidth: 0,
                            borderColor: rgb(1, 1, 1, 0),
                            font: font,
                        });
                        form.getTextField('Beantragte Einheiten').setFontSize(12);


                        const textFieldUnitsTotal = form.createTextField('Gesamt Einheiten');
                        textFieldUnitsTotal.setText(this.therapy_units_total); // Set the initial text
                        textFieldUnitsTotal.addToPage(page, {
                            x: 472,
                            y: 483,
                            height: 16,
                            width: 70,
                            borderWidth: 0,
                            borderColor: rgb(1, 1, 1, 0),
                            font: font,
                        });
                        form.getTextField('Gesamt Einheiten').setFontSize(12);

                        // Wrap text
                        wrappedText = this.wrapText(this.therapy_reason, maxWidth, fontSize, font);
                        const textFieldReason = form.createTextField('Grund');
                        textFieldReason.setText(wrappedText.join('\n')); // Set the initial text
                        textFieldReason.enableMultiline();
                        textFieldReason.addToPage(page, {
                            x: 80,
                            y: 403,
                            height: 4 * 13 - 5,
                            width: 470,
                            borderWidth: 0,
                            borderColor: rgb(1, 1, 1, 0),
                            font: font,
                        });
                        form.getTextField('Grund').setFontSize(12);

                        const textFieldFrequency = form.createTextField('Frequenz');
                        textFieldFrequency.setText(this.therapy_frequency); // Set the initial text
                        textFieldFrequency.addToPage(page, {
                            x: 290,
                            y: 379,
                            height: 16,
                            width: 250,
                            borderWidth: 0,
                            borderColor: rgb(1, 1, 1, 0),
                            font: font,
                        });
                        form.getTextField('Frequenz').setFontSize(12);

                        if (this.therapy_setting === '0') {
                            const textFielSettingOne = form.createTextField('Setting Einzel');
                            textFielSettingOne.setText('X'); // Set the initial text
                            textFielSettingOne.addToPage(page, {
                                x: 290,
                                y: 364,
                                height: 10,
                                width: 10,
                                borderWidth: 1,
                                borderColor: rgb(0, 0, 0),
                                font: font,
                            });
                            form.getTextField('Setting Einzel').setFontSize(12);

                            const textFieldSettingGroup = form.createTextField('Setting Gruppe');
                            textFieldSettingGroup.setText(''); // Set the initial text
                            textFieldSettingGroup.addToPage(page, {
                                x: 290,
                                y: 348,
                                height: 10,
                                width: 10,
                                borderWidth: 1,
                                borderColor: rgb(0, 0, 0),
                                font: font,
                            });
                            form.getTextField('Setting Gruppe').setFontSize(12);

                            const textFieldSettingOther = form.createTextField('Setting Andere');
                            textFieldSettingOther.setText(''); // Set the initial text
                            textFieldSettingOther.addToPage(page, {
                                x: 290,
                                y: 333,
                                height: 10,
                                width: 10,
                                borderWidth: 1,
                                borderColor: rgb(0, 0, 0),
                                font: font,
                            });
                            form.getTextField('Setting Andere').setFontSize(12);

                            const textFieldSetting = form.createTextField('Setting');
                            textFieldSetting.setText(''); // Set the initial text
                            textFieldSetting.addToPage(page, {
                                x: 353,
                                y: 331,
                                height: 16,
                                width: 190,
                                borderWidth: 0,
                                borderColor: rgb(1, 1, 1, 0),
                                font: font,
                            });
                            form.getTextField('Setting').setFontSize(12);


                        } else if (this.therapy_setting === '1') {
                            const textFielSettingOne = form.createTextField('Setting Einzel');
                            textFielSettingOne.setText(''); // Set the initial text
                            textFielSettingOne.addToPage(page, {
                                x: 290,
                                y: 364,
                                height: 10,
                                width: 10,
                                borderWidth: 1,
                                borderColor: rgb(0, 0, 0),
                                font: font,
                            });
                            form.getTextField('Setting Einzel').setFontSize(12);

                            const textFieldSettingGroup = form.createTextField('Setting Gruppe');
                            textFieldSettingGroup.setText('X'); // Set the initial text
                            textFieldSettingGroup.addToPage(page, {
                                x: 290,
                                y: 348,
                                height: 10,
                                width: 10,
                                borderWidth: 1,
                                borderColor: rgb(0, 0, 0),
                                font: font,
                            });
                            form.getTextField('Setting Gruppe').setFontSize(12);

                            const textFieldSettingOther = form.createTextField('Setting Andere');
                            textFieldSettingOther.setText(''); // Set the initial text
                            textFieldSettingOther.addToPage(page, {
                                x: 290,
                                y: 333,
                                height: 10,
                                width: 10,
                                borderWidth: 1,
                                borderColor: rgb(0, 0, 0),
                                font: font,
                            });
                            form.getTextField('Setting Andere').setFontSize(12);

                            const textFieldSetting = form.createTextField('Setting');
                            textFieldSetting.setText(''); // Set the initial text
                            textFieldSetting.addToPage(page, {
                                x: 353,
                                y: 331,
                                height: 16,
                                width: 190,
                                borderWidth: 0,
                                borderColor: rgb(1, 1, 1, 0),
                                font: font,
                            });
                            form.getTextField('Setting').setFontSize(12);

                        } else if (this.therapy_setting === '2') {
                            const textFielSettingOne = form.createTextField('Setting Einzel');
                            textFielSettingOne.setText(''); // Set the initial text
                            textFielSettingOne.addToPage(page, {
                                x: 290,
                                y: 364,
                                height: 10,
                                width: 10,
                                borderWidth: 1,
                                borderColor: rgb(0, 0, 0),
                                font: font,
                            });
                            form.getTextField('Setting Einzel').setFontSize(12);

                            const textFieldSettingGroup = form.createTextField('Setting Gruppe');
                            textFieldSettingGroup.setText(''); // Set the initial text
                            textFieldSettingGroup.addToPage(page, {
                                x: 290,
                                y: 348,
                                height: 10,
                                width: 10,
                                borderWidth: 1,
                                borderColor: rgb(0, 0, 0),
                                font: font,
                            });
                            form.getTextField('Setting Gruppe').setFontSize(12);

                            const textFieldSettingOther = form.createTextField('Setting Andere');
                            textFieldSettingOther.setText('X'); // Set the initial text
                            textFieldSettingOther.addToPage(page, {
                                x: 290,
                                y: 333,
                                height: 10,
                                width: 10,
                                borderWidth: 1,
                                borderColor: rgb(0, 0, 0),
                                font: font,
                            });
                            form.getTextField('Setting Andere').setFontSize(12);
                            const textFieldSetting = form.createTextField('Setting');
                            textFieldSetting.setText(this.therapy_setting_other); // Set the initial text
                            textFieldSetting.addToPage(page, {
                                x: 353,
                                y: 331,
                                height: 16,
                                width: 190,
                                borderWidth: 0,
                                borderColor: rgb(1, 1, 1, 0),
                                font: font,
                            });
                            form.getTextField('Setting').setFontSize(12);
                        }

                        const textFieldOtherPersons = form.createTextField('Andere Personen');
                        textFieldOtherPersons.setText(this.other_persons); // Set the initial text
                        textFieldOtherPersons.addToPage(page, {
                            x: 290,
                            y: 302,
                            height: 14,
                            width: 250,
                            borderWidth: 0,
                            borderColor: rgb(1, 1, 1, 0),
                            font: font,
                        });
                        form.getTextField('Andere Personen').setFontSize(11);

                        const textFieldNotes = form.createTextField('Anmerkungen');
                        textFieldNotes.setText(this.notes); // Set the initial text
                        textFieldNotes.addToPage(page, {
                            x: 80,
                            y: 213,
                            height: 4 * 13,
                            width: 470,
                            borderWidth: 0,
                            borderColor: rgb(1, 1, 1, 0),
                            font: font,
                        });
                        form.getTextField('Anmerkungen').setFontSize(12);
                    }
                }

                // Serialize the PDFDocument to bytes (a Uint8Array)
                const pdfBytes = await pdfDoc.save();

                // Create a blob from the PDF bytes
                const blob = new Blob([pdfBytes], { type: 'application/pdf' });

                // Create a URL for the blob & only update if not downloading all
                if (!downloadAll) {
                    if (this.form_url) URL.revokeObjectURL(this.form_url);
                    this.form_url = URL.createObjectURL(blob);
                } 

                if (downloadAll) {
                    // save the pdf to file
                    // get the first two characters of the first and last name
                    let name = this.firstname.substring(0, 2) + this.lastname.substring(0, 2);
                    await saveAs(blob, "antrag_zuschuss_" + name + ".pdf")
                }

            } catch (error) {
                console.error('Error overlaying text on PDF:', error);
            }
        },

        wrapText(text, maxWidth, fontSize, font) {

            if (text === null || text === undefined || text === '') return [''];

            const lines = [];
            const words = text.split(' ');
            let currentLine = '';

            words.forEach(word => {
                // Check for explicit newline character
                if (word.includes('\n')) {
                    const parts = word.split('\n');
                    parts.forEach((part, index) => {
                        const newLine = currentLine + (currentLine ? ' ' : '') + part;
                        if (index < parts.length - 1) { // Part ends with a newline
                            lines.push(newLine);
                            currentLine = ''; // Reset current line as we added a newline
                        } else {
                            currentLine = newLine; // Last part or a part without a newline at the end
                        }
                    });
                } else {
                    const testLine = currentLine + (currentLine ? ' ' : '') + word;
                    const testWidth = font.widthOfTextAtSize(testLine, fontSize);
                    if (testWidth > maxWidth && currentLine !== '') {
                        lines.push(currentLine);
                        currentLine = word;
                    } else {
                        currentLine = testLine;
                    }
                }
            });

            if (currentLine) {
                lines.push(currentLine);
            }

            return lines;
        },

        async editForm(prefill=null) {
            this.debouncedUpdatePdf = debounce(this.overlayTextOnPdf, 500);

            await this.initialize();
            this.icdCodes = json.icdCodes;

            this.loadPdf();
            this.current_tab = 0;

            if (prefill) {
                if (this.current_file && 'name' in this.current_file) {
                    this.file_name = this.current_file.name.replace('.kpzpsy', '');
                }
                // set the selected client
                this.client = this.customers.find((customer) => customer.id === prefill.client_id);
                this.$nextTick(() => {
                    // set all the values from the prefill object
                    for (let key in prefill) {
                        if (key in this) {
                            this[key] = prefill[key];
                        }
                    }
                });
            }

            this.dialog = true;
        },

        sortListOfObjectsByString(a, b) {
            const nameA = a.name.toUpperCase() // ignore upper and lowercase
            const nameB = b.name.toUpperCase() // ignore upper and lowercase
            if (nameA > nameB) {
                return 1
            }
            if (nameA < nameB) {
                return -1
            }
            // names must be equal
            return 0
        },

        async checkReturnValueOfCustomers(customers) {
            if (customers === -1) {
                // wrong key
                this.customers = []
                this.n_customers = 0
            }
            else {
                this.customers = []
                this.n_customers = customers.length
                this.customers = await cipher.decryptDataSync(this, customers);
                this.customers = this.customers.map((dec) => {
                    let customer = dec;
                    customer.name = customer.vorname + ' ' + customer.nachname;
                    customer.fk_klienten_id = customer.id;
                    return customer;
                })

                this.loaded_customers = true;
            }
        },

        convertLLMResponseToText(response) {
            let candidate_parts = response.filter((item) => {
                return ('candidates' in item )
            }).map((item) => item.candidates).flat(1);

            let content_parts = candidate_parts.filter((item) => 'content' in item && 'parts' in item.content).map((item) => item.content.parts).flat(1);

            let text = content_parts.filter((item) => 'text' in item).map((item) => item.text).join("");
            return text;
        }
    },

    watch: {

        selected_insurer: function (newVal, oldVal) {
            if (newVal !== null && newVal !== undefined) {
                this.insurance = newVal.name;
                this.insurance_state = newVal.state;
                this.insurance_street = newVal.address;
                this.insurance_place = newVal.place;
            }
        },

        // watch if client changes, if yes, load the documentation for the client and set it. The function is asnyc.
        client() {
            if (this.client !== null && this.client !== undefined && this.client.fk_klienten_id !== undefined && this.client.fk_klienten_id !== null) {
                // retrieve the client's anamnese from the database in the view vwdokumentationanamnese

                // set all the client information
                if (this.client.vorname) this.firstname = this.client.vorname;
                if (this.client.nachname) this.lastname = this.client.nachname;

                this.address = '';
                if (this.client.adresse) this.address += this.client.adresse;
                if (this.client.plz) this.address += '\n' + this.client.plz;
                if (this.client.ort) this.address += ' ' + this.client.ort;

                if (this.client.svnr) this.svnr = this.client.svnr;
                
                this.icd_diagnosis = [];
                if (this.client.zusatztext) {
                    // try to parse diagnosis
                    let diagnoses = this.client.zusatztext.match(/([F][0-9]{2}\.[0-9]{1,2})/g);
                    if (diagnoses && diagnoses.length > 0) {
                        // find the diagnosis in the list of diagnoses
                        for (let diagnosis of diagnoses) {
                            let diagnosis_obj = this.icdCodes.find((diag) => diag.short_code === diagnosis);
                            if (diagnosis_obj) {
                                this.icd_diagnosis.push(diagnosis_obj);
                            }
                        }
                    } 
                }

                // reset selected insurer
                this.selected_insurer = null;
                // it will be set again if it is stored in the client object

                if (this.client.mitversichert_bei) {
                    this.self_insured = false;

                    let insurance_obj = null;

                    // get the insurer information and set it
                    if (this.client.mitversichert_bei === 'vater') {
                        this.insurer_name = this.client.vater_vorname + ' ' + this.client.vater_nachname;
                        this.insurer_svnr = this.client.vater_svnr;
                        insurance_obj = this.insurer.find(insurer => insurer.id === this.client.vater_versicherungsträger);
                    } else if (this.client.mitversichert_bei === 'mutter') {
                        this.insurer_name = this.client.mutter_vorname + ' ' + this.client.mutter_nachname;
                        this.insurer_svnr = this.client.mutter_svnr;
                        insurance_obj = this.insurer.find(insurer => insurer.id === this.client.mutter_versicherungsträger);
                    } else if (this.client.mitversichert_bei === 'partner') {
                        this.insurer_name = this.client.partner_vorname + ' ' + this.client.partner_nachname;
                        this.insurer_svnr = this.client.partner_svnr;
                        insurance_obj = this.insurer.find(insurer => insurer.id === this.client.partner_versicherungsträger);
                    } else {
                        this.$emit('showError', {
                            message: 'Fehler beim Lesen der Versicherten Person. Fehler: Unbekannte Person. Bitte wende dich an den Support.',
                        });
                    }

                    if (insurance_obj) {
                        this.selected_insurer = insurance_obj;
                        this.insurance = insurance_obj.name;
                        this.insurance_state = insurance_obj.state;
                        this.insurance_street = insurance_obj.address;
                        this.insurance_place = insurance_obj.place;
                    } else {
                        this.insurance = '';
                        this.insurance_state = '';
                        this.insurance_street = '';
                        this.insurance_place = '';
                    }
                } else {
                    this.self_insured = true;
                    // this.insurer_name = this.client.vater_vorname + ' ' + this.client.vater_nachname;
                    // this.insurer_svnr = this.client.vater_svnr;
                    let insurance_obj = this.insurer.find(insurer => insurer.id === this.client.versicherungsträger);

                    if (insurance_obj) {
                        this.selected_insurer = insurance_obj;
                        this.insurance = insurance_obj.name;
                        this.insurance_state = insurance_obj.state;
                        this.insurance_street = insurance_obj.address;
                        this.insurance_place = insurance_obj.place;
                    } else {
                        this.insurance = '';
                        this.insurance_state = '';
                        this.insurance_street = '';
                        this.insurance_place = '';
                    }
                }

                if (this.appointments.length > 0) {
                    let appointments = this.appointments.filter(appointment => appointment.fk_klienten_id === this.client.fk_klienten_id)
                    // get the last appointment as it is the first one and set the datum as therapy_start
                    if (appointments.length > 0) {
                        // format the date as DD.MM.YYYY using dayjs
                        this.therapy_start = dayjs(appointments[appointments.length - 1].datum).format('DD.MM.YYYY');
                    }

                    // get all past appointments and sum up the units, use dayjs to compare the dates
                    let past_appointments = appointments.filter(appointment => appointment.fk_klienten_id === this.client.fk_klienten_id && dayjs(appointment.datum).isBefore(dayjs()));
                    if (past_appointments.length > 0) {
                        //  use the length and convert the number to a string
                        this.therapy_units_past = past_appointments.length.toString();
                    }
                }

            } else {
                // clear all the fields
                this.firstname = '';
                this.lastname = '';
                this.address = '';
                this.svnr = '';
                this.insurance = '';
                this.insurance_state = '';
                this.insurance_street = '';
                this.insurance_place = '';
                this.self_insured = true;
                this.selected_insurer = null;
            }
        },

        lastname: function () {
            this.debouncedUpdatePdf();
        },
        firstname: function () {
            this.debouncedUpdatePdf();
        },
        address: function () {
            this.debouncedUpdatePdf();
        },
        svnr: function () {
            this.debouncedUpdatePdf();
        },
        insurance: function () {
            this.debouncedUpdatePdf();
        },
        insurance_state: function () {
            this.debouncedUpdatePdf();
        },
        insurance_street: function () {
            this.debouncedUpdatePdf();
        },
        insurance_place: function () {
            this.debouncedUpdatePdf();
        },
        application_form: function () {
            this.debouncedUpdatePdf();
        },
        self_insured: function () {
            this.debouncedUpdatePdf();
        },
        insurer_name: function () {
            this.debouncedUpdatePdf();
        },
        insurer_svnr: function () {
            this.debouncedUpdatePdf();
        },
        therapist_name: function () {
            this.debouncedUpdatePdf();
        },
        therapist_nr: function () {
            this.debouncedUpdatePdf();
        },
        therapist_address: function () {
            this.debouncedUpdatePdf();
        },
        current_tab: function (newVal) {
            this.debouncedUpdatePdf();
        },
        icd_diagnosis: function () {
            this.debouncedUpdatePdf();
        },
        gaf_value: function () {
            this.debouncedUpdatePdf();
        },
        has_job: function () {
            this.debouncedUpdatePdf();
        },
        job: function () {
            this.debouncedUpdatePdf();
        },
        has_psychiatrist: function () {
            this.debouncedUpdatePdf();
        },
        psychiatrist: function () {
            this.debouncedUpdatePdf();
        },
        field_1: function () {
            this.debouncedUpdatePdf();
        },
        field_2: function () {
            this.debouncedUpdatePdf();
        },
        field_3: function () {
            this.debouncedUpdatePdf();
        },
        therapy_start: function () {
            this.debouncedUpdatePdf();
        },
        therapy_units: function () {
            this.debouncedUpdatePdf();
        },
        therapy_units_past: function () {
            this.debouncedUpdatePdf();
        },
        therapy_units_other: function () {
            this.debouncedUpdatePdf();
        },
        therapy_units_total: function () {
            this.debouncedUpdatePdf();
        },
        therapy_frequency: function () {
            this.debouncedUpdatePdf();
        },
        therapy_reason: function () {
            this.debouncedUpdatePdf();
        },
        therapy_setting: function () {
            this.debouncedUpdatePdf();
        },
        therapy_setting_other: function () {
            this.debouncedUpdatePdf();
        },
        other_persons: function () {
            this.debouncedUpdatePdf();
        },
        notes: function () {
            this.debouncedUpdatePdf();
        },
    },

    computed: {

        computedHeight() {
            if (this.current_tab === 0) {
                return 800;
            } else if (this.current_tab === 1) {
                return 1200;
            } else if (this.current_tab === 2) {
                return 950;
            } else {
                return 800;
            }
        },

        gaf_color() {
            if (this.gaf_value < 11) return 'red darken-4'
            if (this.gaf_value < 21) return 'red darken-1'
            if (this.gaf_value < 31) return 'orange darken-4'
            if (this.gaf_value < 41) return 'orange darken-1'
            if (this.gaf_value < 51) return 'yellow darken-4'
            if (this.gaf_value < 61) return 'yellow darken-1'
            if (this.gaf_value < 71) return 'lime accent-4'
            if (this.gaf_value < 81) return 'light-green accent-4'
            if (this.gaf_value < 91) return 'green accent-4'
            return 'green'
        },

        gafValueDescription() {
            if (this.gaf_value === 0) return 'Unzureichende Informationen'
            if (this.gaf_value < 11) return 'Ständige Gefahr oder anhaltende Unfähigkeit'
            if (this.gaf_value < 21) return 'Selbst- und Fremdgefährlichkeit'
            if (this.gaf_value < 31) return 'Leistungsunfähigkeit in fast allen Bereichen'
            if (this.gaf_value < 41) return 'Starke Beeinträchtigung in mehreren Bereichen'
            if (this.gaf_value < 51) return 'Ernsthafte Beeinträchtigung'
            if (this.gaf_value < 61) return 'Mäßig ausgeprägte Störung'
            if (this.gaf_value < 71) return 'Leichte Beeinträchtigung'
            if (this.gaf_value < 81) return 'Höchstens leichte Beeinträchtigungen'
            if (this.gaf_value < 91) return 'Gute Leistungsfähigkeit auf allen Gebieten'
            return 'Optimale Funktion in allen Bereichen'
        },

        selectedAppointmentsDocumentation() {
            if (this.client === null) {
                return null
            }

            let documentation = '';
            let client_appointments = this.appointments.filter(appointment => (appointment.fk_klienten_id === this.client.id)).sort((a, b) => dayjs(b.datum) - dayjs(a.datum))

            // join the documentation of all appointments with formatted date, bezeichung and dokumentation
            if (client_appointments.length > 0) {
                documentation = client_appointments.map(appointment => dayjs(appointment.datum).format('DD.MM.YYYY') + ' - ' + (appointment.bezeichnung ? appointment.bezeichnung : '') + '\n' + (appointment.dokumentation ? appointment.dokumentation : 'Nicht dokumentiert')).join('\n\n');
            }
            return documentation;

        },

        selectedClientStatistics() {

            if (this.client === null) {
                return null
            }

            let client_appointments = this.appointments.filter(appointment => appointment.fk_klienten_id === this.client.id).sort((a, b) => dayjs(b.datum) - dayjs(a.datum))
            if (client_appointments.length > 0) {

                let months_visiting = dayjs(client_appointments[0].datum).diff(dayjs(client_appointments[client_appointments.length - 1].datum), 'month', true)

                return {
                    first_appointment: dayjs(client_appointments[client_appointments.length - 1].datum).format('DD.MM.YYYY'),
                    last_appointment: dayjs(client_appointments[0].datum).format('DD.MM.YYYY'),
                    appointment_count: client_appointments.length,
                    appointment_sum: client_appointments.reduce((sum, appointment) => sum + appointment.dauer, 0),
                    // calculate the time in months between the first and last appointment, and divide the number of appointments by that
                    appointment_frequency: months_visiting === 0 ? 1 : Math.round((client_appointments.length / months_visiting + Number.EPSILON) * 10) / 10,

                }
            } else {
                return {
                    first_appointment: 'Noch Keine',
                    last_appointment: 'Noch Keine',
                    appointment_count: 0,
                    appointment_sum: 0,
                    appointment_frequency: 0,
                }
            }
        },

        sortedCustomers() {
            return this.customers.sort(this.sortListOfObjectsByString)
        },

        suggestedDiagnoses() {
            if (!this.model_response_diagnosis || this.model_response_diagnosis.length === 0) {
                return "";
            }

            // match the icd codes with regex in the format FDD.D and store it in an array
            const responseArray = [...new Set(this.model_response_diagnosis.match(/([F][0-9]{2}\.[0-9]{1,2})/g))];
            // try to split the response into an array of strings using , as delimiter

            let mapped_diagnoses = [];

            // iterate over the array and try to find the most similar diagnoses from the list of icd10 diagnoses
            for (let i = 0; i < responseArray.length; i++) {
                let similarDiagnoses = this.icdCodes.filter((diagnosis) => diagnosis.short_code.toLowerCase() === responseArray[i].trim().toLowerCase());
                // add the similar diagnoses to the mapped_diagnoses array
                mapped_diagnoses.push(similarDiagnoses);
            }

            return [...new Set(mapped_diagnoses.flat(1))];
        },

        suggestedGAF() {
            if (!this.model_response_gaf) {
                return "";
            }
            if (this.model_response_gaf.includes(',')) {
                
                const regex = /(\d+)/g;
                const match = this.model_response_gaf.split(',')[0].match(regex);
                if (match) {
                    this.gaf_value = parseInt(match[0]); // directly convert matched string to a number
                } else {
                    this.gaf_value = 0;
                }

                return this.model_response_gaf.replaceAll('**', '').replaceAll('#', '');

            } else {
                return this.model_response_gaf;
            }
        },

        suggestedGoals() {
            if (!this.model_response_goals) {
                return [];
            }
            const responseArray = this.model_response_goals.split('#').map((item) => item.trim().replaceAll('*', '')).filter((item) => (item !== "") && (item.length > 3) && (!item.includes('Zielsetzungen für den')));
            return responseArray;
        },

        suggestedReasons() {
            if (!this.model_response_reasons) {
                return "";
            }
            // split the response into an array of strings using <G> as delimiter
            const responseArray = this.model_response_reasons.split('#').map((item) => item.trim().replaceAll('*', '')).filter((item) => (item !== "") && (item.length > 3) && (!item.includes('Begründungen für den')));
            return responseArray;
        },

    }
};
</script>

<style scoped>
.v-chip-custom {
    height: auto !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
    white-space: normal !important;
}
.v-sheet.v-card {
  border-radius: 6px;
}
</style>
