<template>
    <v-card elevation="1" class="mx-auto" :width="cardWidth">
        <v-list-item >
            <v-list-item-content>
                <div class="d-flex justify-space-between">
                    <slot name="icon-left"></slot>
                    <v-list-item-title class="text-button">
                    {{ title }}
                    </v-list-item-title>
                    <slot name="icon-right"></slot>
                </div>
                <v-list-item-subtitle class="mt-1" v-if="subtitle">{{ subtitle }}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-card-text class="pt-0">
            <v-row align="center">
                <v-col v-if="(number!==null)" class="text-h5" cols="auto">
                    <span :class="numberColor">{{ numberFormatted }} </span>
                </v-col>
                <v-col v-else>
                    <v-progress-circular indeterminate color="primary" />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: ['number', 'title', 'subtitle', 'cardWidth', 'color'],

    computed: {
        numberFormatted() {
            if (this.number === null) return 0
            return this.number.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
        },
        numberColor() {
            if (this.color === 'green' && this.number > 0) return 'green--text font-weight-medium'
            if (this.color === 'orange' && this.number > 0) return 'orange--text font-weight-medium'
            if (this.color === 'red' && this.number > 0) return 'red--text font-weight-medium'
            return 'font-weight-medium'
        }
    }
}
</script>

<style>

</style>